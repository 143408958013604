<template>
    <div class="w-full">
        <chart-skeleton v-if="loading"/>
        <highcharts v-if="!loading && chart" class="hc" :options="chart" ref="chart"></highcharts>
    </div>
</template>

<script>
import StatCard from "../../../components/layouts/StatCard.vue";
import ChartSkeleton from "../../../components/layouts/ChartSkeleton.vue";

export default {
    components: {ChartSkeleton, StatCard},
    props: ['dates'],
    data() {
        return {
            loading: false,
            chart: {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` + point.y.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                });
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            },

            registerChart: false,
            onboardingChart: false,
        };
    },

    watch: {
        dates() {
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            this.chart.series = [];
            axios.get('/api/analytics/product/orders', {
                params: {
                    from: this.dates[0],
                    to: this.dates[1]
                }
            }).then(({data}) => {
                Object.values(data.products).map((product) => {
                   let series = {
                      name: '',
                       lineWidth: 3,
                       data: []
                   };

                   product.map((day) => {
                       series.data.push([day.unix_date, parseInt(day.amount)]);
                   });

                   series.name = product[0].name;

                   this.chart.series.push(series);
                });

                let other = {
                    name: 'Outros',
                    lineWidth: 3,
                    data: []
                };
                data.other.map((day) => {
                    other.data.push([day.unix_date, parseInt(day.amount)]);
                })
                this.chart.series.push(other);
            }).then(() => {
                this.loading = false;
            })
        },
    }
};
</script>
