<template>
    <RadioGroup v-model="selected">
        <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
            <RadioGroupOption
                v-for="option in options" :key="option.value"
                as="template"
                :value="option.value"
                v-slot="{ checked, active }"
                :disabled="disabled"
            >
                <div
                    :class="[
                        checked ? 'border-transparent bg-indigo-500' : 'border-transparent',
                        active ? 'ring-2 ring-indigo-500' : '',
                        'relative bg-gray-600 border rounded-lg shadow-sm p-2 flex cursor-pointer focus:outline-none'
                    ]"
                >
                    <div class="flex-1 flex">
                        <div class="flex flex-col">
                            <RadioGroupLabel
                                as="span"
                                class="block text-sm font-medium text-white"
                            >
                                {{ option.label }}
                            </RadioGroupLabel>
                        </div>
                    </div>
                    <CheckCircleIcon :class="[!checked ? 'invisible' : 'text-white', 'h-5 w-5']" aria-hidden="true" />
                </div>
            </RadioGroupOption>
        </div>
    </RadioGroup>
</template>

<script>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/24/outline';

export default {
    components: { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, CheckCircleIcon},
    props: {
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selected: this.modelValue,
        }
    },

    watch: {
        selected(value) {
            this.$emit('update:modelValue', value);
        },
        modelValue(value) {
            this.selected = value;
        }
    },
}
</script>
