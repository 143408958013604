<template>
    <form class="space-y-4 md:space-y-6" @submit.prevent="login">
        <div>
            <label
                for="email"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Email
            </label>
            <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                class="text-input"
                placeholder="email@ticto.com"
                required
            />
        </div>
        <div>
            <label
                for="password"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Senha
            </label>
            <input
                v-model="form.password"
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                class="text-input"
                required
            />
        </div>
        <submit-button :loading="loading" class="w-full">
            Entrar
        </submit-button>
    </form>
</template>

<script>
import { mapWritableState } from "pinia";
import { Store } from "../../stores/store";
import { useReCaptcha } from "vue-recaptcha-v3";
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            form: {
                gToken: "",
                email: "",
                password: "",
            },
            loading: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["user", "token"]),
    },

    async mounted() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
        await recaptchaLoaded();
        this.form.gToken = await executeRecaptcha("login");
    },

    methods: {
        async login() {
            try {
                this.loading = true;
                await axios.get("/sanctum/csrf-cookie");
                const response = await axios.post("/api/login", {
                    gToken: this.form.gToken,
                    email: this.form.email,
                    password: this.form.password,
                });
                this.user = response.data;
                this.$router.push({ name: "users" });
            } catch (error) {
                if (error.response) {
                    if (
                        error.response.data.exception &&
                        error.response.data.device_uuid
                    ) {
                        this.$router.push({
                            name: "validateDevice",
                            params: { uuid: error.response.data.device_uuid },
                        });
                    }
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text: error.response.data.message,
                        },
                        4000,
                    );
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
