<template>
    <div>
        <label for="message" class="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-gray-200 mt-2">
            Motivo
            <svg @click="toggleInputType" style="cursor: pointer;" class="ml-1 w-4 h-4" fill="#000000" xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
                <path d="M20,37.5c0-0.8-0.7-1.5-1.5-1.5h-15C2.7,36,2,36.7,2,37.5v11C2,49.3,2.7,50,3.5,50h15c0.8,0,1.5-0.7,1.5-1.5  V37.5z"/>
                <path d="M8.1,22H3.2c-1,0-1.5,0.9-0.9,1.4l8,8.3c0.4,0.3,1,0.3,1.4,0l8-8.3c0.6-0.6,0.1-1.4-0.9-1.4h-4.7  c0-5,4.9-10,9.9-10V6C15,6,8.1,13,8.1,22z"/>
                <path d="M41.8,20.3c-0.4-0.3-1-0.3-1.4,0l-8,8.3c-0.6,0.6-0.1,1.4,0.9,1.4h4.8c0,6-4.1,10-10.1,10v6  c9,0,16.1-7,16.1-16H49c1,0,1.5-0.9,0.9-1.4L41.8,20.3z"/>
                <path d="M50,3.5C50,2.7,49.3,2,48.5,2h-15C32.7,2,32,2.7,32,3.5v11c0,0.8,0.7,1.5,1.5,1.5h15c0.8,0,1.5-0.7,1.5-1.5  V3.5z"/>
            </svg>
            <svg @click="toggleModal" v-if="!isTextareaVisible" style="cursor: pointer;" class="ml-1 w-4 h-4" width="800px" height="800px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
                <path fill="#000000" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm14 .069a1 1 0 01-1 1h-2.931V14a1 1 0 11-2 0v-2.931H6a1 1 0 110-2h3.069V6a1 1 0 112 0v3.069H14a1 1 0 011 1z"/>
            </svg>
        </label>

        <textarea v-if="isTextareaVisible"
            v-model="selectedReason"
            @change="emitSelection"
            id="message"
            rows="4"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your message..."
        ></textarea>

        <select v-else v-model="selectedReason" @change="emitSelection" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            <option v-for="option in options"
                :value="option.reason"
                :key="option.id">
                {{ option.reason }}
            </option>
        </select>

        <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg shadow-lg p-6">
                <!-- Conteúdo do modal -->
                <h2 class="text-lg font-medium mb-4">Adicionar motivo à lista</h2>
                
                <textarea
                    v-model="newReason"
                    id="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Motivo..."
                ></textarea>

                <button @click="saveReason" class="mt-4 bg-green-500 text-white rounded-lg py-2 px-4">Adicionar</button>
                <button @click="toggleModal" class="mt-4 bg-blue-500 text-white rounded-lg py-2 px-4">Fechar</button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['reasonType'],
        emits: ['reason-selected'],
        data() {
            return {
                options: [],
                selectedReason: '',
                newReason: '',
                isTextareaVisible: false,
                isModalOpen: false,
            };
        },
        created() {
            axios.get('/api/reason/', {params: {type: this.reasonType}}).then(({ data }) => {
                this.options = data.data;
            }).catch(error => {
                console.error(error);
            });
        },
        methods: {
            toggleModal() {
                this.isModalOpen = !this.isModalOpen;
            },
            toggleInputType() {
                this.isTextareaVisible = !this.isTextareaVisible;
            },
            emitSelection() {
                this.$emit('reason-selected', this.selectedReason);
            },
            saveReason() {
                axios.post('/api/reason/', {
                    reason: this.newReason,
                    type: this.reasonType,
                }).then(response => {
                    this.newReason = '';
                    axios.get('/api/reason/', {params: {type: this.reasonType}}).then(({ data }) => {
                        this.options = data.data;
                    }).catch(error => {
                        console.error(error);
                    });
                    this.isModalOpen = !this.isModalOpen;
                }).catch(error => {
                    console.error(error);
                });
            }
        },
    };
</script>