import { defineStore } from "pinia";
import router from "../router";
import { setWithExpiry, getWithExpiry } from "../mixins/ttlStorage.js";

export const Store = defineStore("store", {
    state: () => {
        return {
            token: false,
            userPendingRecords: 0,
            dark: false,
            cancel_tokens: [],
            permissions: false,
            authUser: false,
            authPermissions: false,
            user: false,
            storeProduct: false,
            storeCompliance: false,
            storeBankAccount: false,
            storeCashout: false,
            storeAnticipation: false,
            visitorData: false,
        };
    },
    actions: {
        cancelPendingRequests(context) {
            // Cancel all request where a token exists
            this.cancel_tokens.map((request) => {
                if (request.cancel) {
                    request.cancel();
                }
            });

            this.cancel_tokens = [];
        },
        fetchUser() {
            this.user = false;

            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.user_id
            ) {
                return false;
            }

            return axios
                .get("/api/user/" + router.currentRoute.value.params.user_id)
                .then(({ data }) => {
                    this.user = data;
                })
                .catch((err) => {
                    this.user = false;
                    router.push({ name: "login" });
                });
        },
        fetchBankAccount() {
            this.user = false;

            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.account_id
            ) {
                return false;
            }

            return axios
                .get(
                    "/api/bank-account/" +
                        router.currentRoute.value.params.account_id,
                )
                .then(({ data }) => {
                    this.storeBankAccount = data;
                })
                .catch((err) => {
                    this.user = false;
                    router.push({ name: "login" });
                });
        },
        fetchCashout() {
            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.cashout_id
            ) {
                return false;
            }

            return axios
                .get(
                    "/api/cashout/" +
                        router.currentRoute.value.params.cashout_id,
                )
                .then(({ data }) => {
                    this.storeCashout = data;
                })
                .catch((err) => {
                    router.push({ name: "login" });
                });
        },
        fetchAnticipation() {
            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.anticipation_id
            ) {
                return false;
            }

            return axios
                .get(
                    "/api/anticipation/" +
                        router.currentRoute.value.params.anticipation_id,
                )
                .then(({ data }) => {
                    this.storeAnticipation = data;
                })
                .catch((err) => {
                    router.push({ name: "login" });
                });
        },
        fetchProduct() {
            this.storeProduct = false;

            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.product_id
            ) {
                return false;
            }

            return axios
                .get(
                    "/api/product/" +
                        router.currentRoute.value.params.product_id,
                )
                .then(({ data }) => {
                    this.storeProduct = data;
                })
                .catch((err) => {
                    this.storeProduct = false;
                    router.push({ name: "login" });
                });
        },
        fetchCompliance() {
            this.storeCompliance = false;

            if (
                !router.currentRoute ||
                !router.currentRoute.value.params.user_id
            ) {
                return false;
            }

            return axios
                .get(
                    "/api/compliance/" +
                        router.currentRoute.value.params.user_id,
                )
                .then(({ data }) => {
                    this.storeCompliance = data;
                })
                .catch((err) => {
                    this.storeCompliance = false;
                    router.push({ name: "login" });
                });
        },
        fetchAuthUser() {
            this.authUser = false;

            return axios
                .get("/api/me")
                .then(({ data }) => {
                    this.authUser = data;
                    this.fetchAuthPermissions();
                })
                .catch((err) => {
                    this.authUser = false;
                    const routeName = router.currentRoute.value.name;
                    if (
                        routeName !== "login" &&
                        routeName !== "validateDevice" &&
                        routeName !== "register" &&
                        routeName !== "forgotPassword"
                    ) {
                        router.push({ name: "login" });
                    }
                });
        },
        fetchAuthPermissions() {
            this.permissions = false;
            localStorage.removeItem("permissions");
            if (this.authUser.renew_permissions) {
                localStorage.removeItem("storagePermissions");
            }
            let storagePermissions = getWithExpiry("storagePermissions");

            if (storagePermissions) {
                this.permissions = storagePermissions;
                return new Promise((resolve, reject) => {
                    resolve(this.permissions);
                });
            } else {
                return axios
                    .get("/api/permissions")
                    .then(({ data }) => {
                        //store on localstorage with expiration
                        setWithExpiry("storagePermissions", data, 3600000);
                        this.permissions = data;
                    })
                    .catch((err) => {
                        this.permissions = false;
                    });
            }
        },
        logout() {
            this.authUser = false;

            return axios
                .post("/api/logout")
                .then(({ data }) => {
                    this.authUser = false;
                })
                .catch((err) => {
                    this.authUser = false;
                })
                .finally(() => {
                    router.push({ name: "login" });
                });
        },
    },
});
