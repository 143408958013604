<template>
    <div class="mt-5">
        <h3 class="mb-3 text-lg font-medium leading-6 dark:text-white">
            Serviços da Ticto
        </h3>
        <div class="grid gap-5 sm:grid-cols-4">
            <card v-for="item in services" :key="item.name" :loading="loading">
                <a
                    @click.prevent="
                        item.name !== 'ViewAI'
                            ? redirect(item.href)
                            : (this.show = true)
                    "
                    class="cursor-pointer"
                >
                    <p
                        class="mb-2 font-bold uppercase text-gray-400 dark:text-gray-400"
                    >
                        {{ item.name }}
                    </p>
                    <img
                        :src="item.image"
                        :alt="item.name"
                        class="max-h-28 w-full rounded-md bg-contain bg-center"
                    />
                </a>
            </card>
        </div>
    </div>

    <modal class="min-h-full" :open="show" @close="show = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            ViewAI
        </h1>

        <div class="grid gap-5 sm:grid-cols-2">
            <card class="flex">
                <a
                    @click.prevent="redirectView('users')"
                    class="flex-1 cursor-pointer"
                >
                    <p
                        class="white:text-black mb-2 font-bold uppercase dark:text-white"
                    >
                        Usuários
                    </p>
                </a>
            </card>
            <card class="flex">
                <a
                    @click.prevent="redirectView('plans')"
                    class="flex-1 cursor-pointer"
                >
                    <p class="mb-2 font-bold uppercase dark:text-gray-200">
                        Planos
                    </p>
                </a>
            </card>
        </div>
    </modal>
</template>

<script>
import ShippingImage from "../../../../public/img/shipping-provider.png";
import Card from "../../components/layouts/Card.vue";
import Modal from "../../components/layouts/Modal.vue";

export default {
    components: { Card, Modal },
    data() {
        return {
            loading: false,
            show: false,
            services: [
                {
                    name: "z-api",
                    image: "https://cdn.greatsoftwares.com.br/arquivos/paginas/10603-92bb9420b363835d05d41b96a45d8f4e.png",
                    href: "zapi",
                },
                {
                    name: "ViewAI",
                    image: "https://i.ibb.co/tQmV0vx/smartplayer-logo.png",
                    href: "viewai.users",
                },
                {
                    name: "Transportadoras",
                    image: ShippingImage,
                    href: "shipping-providers",
                },
            ],
        };
    },
    methods: {
        redirect(href) {
            this.$router.push({
                name: `services.${href}`,
            });
        },
        redirectView(href) {
            this.$router.push({
                name: `services.viewai.${href}`,
            });
        },
    },
};
</script>
