<template>
    <filters
        v-model="filters"
        @fetch="
            trackings = [];
            next_page_url = false;
            fetch();
        "
        :loading="loading"
        date-filter
        search-filter
        search-label="Filtre fingerprint, IP, cidade, dispositivo, etc..."
    />

    <!--    <form-toggle v-model="singlestore" label="Singlestore" />-->

    <div class="my-3 grid grid-cols-4 gap-4 dark:text-white">
        <template v-if="singlestore">
            <ss-aggs-card
                :loading="ssLoading"
                name="Browsers"
                :buckets="ssAggs.browsers"
            />
            <ss-aggs-card
                :loading="ssLoading"
                name="Cidades"
                :buckets="ssAggs.city"
            />
            <ss-aggs-card :loading="ssLoading" name="Ip" :buckets="ssAggs.ip" />

            <ss-aggs-card
                :loading="ssLoading"
                name="Dispositivos"
                :buckets="ssAggs.device"
            />
        </template>
        <template v-else>
            <aggs-card
                :loading="loading"
                name="Browsers"
                :buckets="aggs.browser"
            />
            <aggs-card :loading="loading" name="Cidades" :buckets="aggs.city" />
            <aggs-card :loading="loading" name="Ip" :buckets="aggs.ip" />
            <aggs-card
                :loading="loading"
                name="Dispositivo"
                :buckets="aggs.device"
            />
        </template>
    </div>

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Tipo',
            'Produto',
            'Fingerprint',
            'Device',
            'Ip',
            'VisitorId',
        ]"
        @fetch="fetch"
    >
        <tr v-for="tracking in trackings">
            <td class="table-cell-sm">
                <div class="flex items-center space-x-2">
                    <ShoppingCartIcon
                        v-if="tracking.click_type === 'transaction'"
                        class="h-5 w-5 text-gray-200 dark:text-gray-200"
                        aria-hidden="true"
                        v-tooltip="'Transação'"
                    />
                    <FlagIcon
                        v-else
                        class="h-5 w-5 text-gray-200 dark:text-gray-200"
                        aria-hidden="true"
                        v-tooltip="'Clique'"
                    />
                    <span>
                        {{
                            new Date(tracking.created_at).toLocaleString(
                                "pt-BR",
                            )
                        }}
                    </span>
                </div>
            </td>
            <td class="table-cell-sm">
                <tracking-type
                    :tracking="tracking"
                    :products="products"
                    :affiliates="affiliates"
                />
            </td>
            <td class="table-cell-sm">
                <span class="w-2 truncate">
                    <span
                        v-tooltip="tracking.product.name"
                        class="inline-block rounded bg-purple-500 px-2 py-1 text-xs font-semibold uppercase uppercase text-white"
                    >
                        {{
                            tracking.product.name
                                .split(" ")
                                .map((n) => n[0])
                                .join("")
                        }}
                    </span>
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    v-tooltip="tracking.fingerprint"
                    class="block w-16 truncate"
                >
                    {{ tracking.fingerprint }}
                </span>
            </td>
            <td class="table-cell-sm">
                <div class="flex flex-col">
                    <div class="flex space-x-2" v-if="tracking.browser">
                        <browser-icon :browser="tracking.browser" />
                        <span>{{ tracking.browser_version }}</span>
                    </div>
                    <div class="flex space-x-2" v-if="tracking.platform">
                        <os-icon :os="tracking.platform" />
                        <span>{{ tracking.platform_version }}</span>
                    </div>
                    <div class="flex space-x-1" v-if="tracking.location">
                        <span class="truncate"
                            >{{ tracking.location.countryCode }}:
                        </span>
                        <span
                            class="block w-32 truncate"
                            v-if="tracking.location.cityName"
                        >
                            {{ tracking.location.cityName }}
                        </span>
                    </div>
                </div>
            </td>
            <td class="table-cell-sm">
                <span v-tooltip="tracking.ip" class="block w-32 truncate">
                    {{ tracking.ip }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    class="block max-w-[100px] truncate"
                    v-tooltip="
                        tracking.visitor_data
                            ? tracking.visitor_data.visitorId
                            : '- '
                    "
                >
                    {{
                        tracking.visitor_data
                            ? tracking.visitor_data.visitorId
                            : "- "
                    }}
                </span>
            </td>
        </tr>
    </table-component>
</template>

<script>
import { ShoppingCartIcon, FlagIcon } from "@heroicons/vue/24/outline";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import AggsCard from "../../../components/cashouts/AggsCard.vue";
import Filters from "../../../components/layouts/Filters.vue";
import TrackingType from "../../../components/user/audit/TrackingType.vue";
import OsIcon from "../../../components/layouts/antifraud/OsIcon.vue";
import BrowserIcon from "../../../components/layouts/antifraud/BrowserIcon.vue";
import SsAggsCard from "../../../components/cashouts/SsAggsCard.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";

export default {
    components: {
        FormToggle,
        SsAggsCard,
        BrowserIcon,
        OsIcon,
        TrackingType,
        Filters,
        AggsCard,
        TableComponent,
        ShoppingCartIcon,
        FlagIcon,
    },
    props: ["user", "products", "affiliates"],
    data() {
        return {
            singlestore: false,
            loading: false,
            ssLoading: false,
            trackings: [],
            next_page_url: false,
            pagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            ssAggs: {
                browser: {},
                city: [],
                ip: [],
                device: [],
            },
            aggs: {
                browser: {},
                city: {},
                ip: {},
                device: {},
            },
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetchSS(userId, params) {
            axios
                .get("/api/user/" + userId + "/ss-clicks", {
                    params: params,
                })
                .then(({ data }) => {
                    this.ssAggs = data.aggregations;
                    this.trackings = data.trackings.hits;
                    this.pagination = data.trackings;
                    this.ssLoading = false;
                    this.loading = false;
                });
        },
        fetch() {
            this.ssLoading = true;
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;

            const userId = this.$route.params.user_id ?? this.user.id;
            if (this.singlestore) {
                return this.fetchSS(userId, params);
            }

            return axios
                .get("/api/user/" + userId + "/clicks", {
                    params: params,
                })
                .then(({ data }) => {
                    this.aggs = data.aggregations;
                    this.trackings = data.hits;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.pagination.total = data.aggregations.total_count.value;
                    this.pagination.from =
                        this.pagination.current_page *
                            this.pagination.per_page -
                        this.pagination.per_page +
                        1;
                    this.pagination.to =
                        this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(
                        this.pagination.total / this.pagination.per_page,
                    );

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
