<template>
    <li class="bg-gray-300 hover:bg-gray-300 dark:bg-slate-800">
        <div class="block">
            <div class="flex items-center px-4 py-5">
                <div class="flex min-w-0 flex-1 items-center">
                    <div class="flex-shrink-0">
                        <img
                            class="h-12 w-12 rounded-full"
                            :src="
                                manager.avatar ||
                                'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                    manager.name
                            "
                            alt=""
                        />
                    </div>
                    <div
                        class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4"
                    >
                        <div>
                            <p
                                class="truncate text-sm font-medium text-gray-900 dark:text-indigo-600"
                            >
                                {{ manager.name }}
                            </p>
                            <p
                                class="mt-2 flex items-center text-sm text-gray-500"
                            >
                                <EnvelopeIcon
                                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                />
                                <span class="truncate">{{
                                    manager.email
                                }}</span>
                            </p>
                        </div>
                        <users :manager="manager" />
                    </div>
                </div>
                <div>
                    <Menu as="div" class="ml-3">
                        <div>
                            <MenuButton
                                class="flex max-w-xs items-center rounded-full text-sm focus:outline-none dark:bg-gray-800"
                            >
                                <span class="sr-only">Open user menu</span>
                                <ChevronDownIcon
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </MenuButton>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <MenuItems
                                class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                                <MenuItem
                                    v-for="item in menu"
                                    v-slot="{ active }"
                                >
                                    <button
                                        @click="modals[item.modal] = true"
                                        class="block w-full px-4 py-2 text-left text-sm text-gray-700"
                                    >
                                        {{ item.label }}
                                    </button>
                                </MenuItem>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
            <users-modal
                :manager="manager"
                :modal="modals.users"
                @close="modals.users = false"
                @fetch="
                    modals.users = false;
                    $emit('fetch');
                "
            />
            <update-modal
                :manager="manager"
                :modal="modals.update"
                @close="modals.update = false"
                @fetch="
                    modals.update = false;
                    $emit('fetch');
                "
            />
            <destroy-modal
                :manager="manager"
                :modal="modals.destroy"
                @close="modals.destroy = false"
                @fetch="
                    modals.destroy = false;
                    $emit('fetch');
                "
            />
        </div>
    </li>
</template>

<script>
import {
    Disclosure,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import {
    CheckCircleIcon,
    ChevronDownIcon,
    ClockIcon,
    EnvelopeIcon,
    NoSymbolIcon,
} from "@heroicons/vue/24/outline";
import UsersModal from "./modals/UsersModal.vue";
import UpdateModal from "./modals/UpdateModal.vue";
import Users from "./Users.vue";
import DestroyModal from "./modals/DestroyModal.vue";
export default {
    components: {
        DestroyModal,
        Users,
        UpdateModal,
        Disclosure,
        UsersModal,
        CheckCircleIcon,
        EnvelopeIcon,
        ClockIcon,
        NoSymbolIcon,
        ChevronDownIcon,
        MenuButton,
        MenuItem,
        MenuItems,
        Menu,
    },
    props: ["manager"],
    emits: ["fetch"],
    data() {
        return {
            menu: [
                { label: "Usuários", modal: "users" },
                { label: "Alterar", modal: "update" },
                { label: "Remover", modal: "destroy" },
            ],
            modals: {
                users: false,
                update: false,
                destroy: false,
            },
        };
    },
};
</script>
