<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-check"
                                    class="mx-auto mt-1 w-10 h-10 text-gray-400 dark:text-gray-200"
                                    aria-hidden="true"
                                />
                            </div>
                            <div
                                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                            >
                                <DialogTitle
                                    as="h3"
                                    class="text-lg leading-6 font-medium text-white"
                                >
                                    Aprovar Conta
                                </DialogTitle>
                                <div class="mt-2">
                                    <div class="text-md text-white">
                                        Você tem certeza que deseja aprovar a
                                        conta de
                                        <p class="text-bold">
                                            {{ user.email }} ?
                                        </p>
                                        Após a aprovação o usuário poderá criar
                                        produtos, realizar vendas, receber
                                        pagamentos e solicitar saques.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                                @click="approveUser()"
                            >
                                Aprovar Conta
                            </button>
                            <button
                                type="button"
                                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                @click="closeModal()"
                                ref="cancelButtonRef"
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { mapWritableState } from "pinia";
import { Store } from "../../../stores/store";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },


    computed: {
        ...mapWritableState(Store, ["user"]),
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        approveUser() {
            axios.post("/api/user/" + this.user.id + "/approve")
                .then(({ data }) => {
                    this.$emit("closeModalAndFetch");
                })
                .catch(({ response }) => {
                    this.$emit("closeModalAndFetch");
                });
        },
    },
};
</script>
