<template>
    <form @submit.prevent="submit">
        <div>
            <div class="my-3 mr-2">
                <div class="mb-2" v-for="(gateway, index) in gateways">
                    <div class="py-4">
                        <div
                            class="mt-1 flex justify-between text-sm text-white"
                        >
                            <div class="flex flex-col">
                                <span
                                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                    ><b>Gateway {{ index + 1 }}</b></span
                                >
                                <span class="text-gray-600 dark:text-gray-200">
                                    {{ gateway.name }}</span
                                >
                            </div>
                            <div class="flex flex-col">
                                <span
                                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                >
                                    <b>Prioridade</b>
                                </span>
                                <span class="text-gray-600 dark:text-gray-200">
                                    <input
                                        required
                                        type="number"
                                        min="1"
                                        :max="gateways.length"
                                        v-model="
                                            this.form.gateways[index].priority
                                        "
                                    />
                                </span>
                            </div>

                            <div class="flex flex-col">
                                <span
                                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                    ><b>Ativo?</b></span
                                >
                                <span class="text-gray-600 dark:text-gray-200">
                                    <form-toggle
                                        class="m-2"
                                        v-model="
                                            this.form.gateways[index].is_active
                                        "
                                    ></form-toggle>
                                </span>
                            </div>

                            <div class="flex flex-col">
                                <span
                                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                    ><b>Internacional?</b></span
                                >
                                <span class="text-gray-600 dark:text-gray-200">
                                    <form-toggle
                                        class="m-2"
                                        v-model="
                                            this.form.gateways[index]
                                                .is_international
                                        "
                                    ></form-toggle>
                                </span>
                            </div>

                            <div class="flex flex-col">
                                <span
                                    class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                >
                                    <b>Prioridade Internacional</b>
                                </span>
                                <span class="text-gray-600 dark:text-gray-200">
                                    <input
                                        required
                                        type="number"
                                        min="1"
                                        :max="gateways.length"
                                        v-model="
                                            this.form.gateways[index]
                                                .international_priority
                                        "
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button
            :disabled="loading"
            type="submit"
            :class="{
                'cursor-not-allowed': loading,
            }"
            class="mb-3 inline-flex max-w-[195px] items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
        >
            Salvar Gateways
        </button>
    </form>
</template>

<script>
import FormToggle from "../../../components/forms/FormToggle.vue";

export default {
    props: ["user"],
    components: { FormToggle },
    data() {
        return {
            loading: false,
            gateways: [],
            form: {
                gateways: [],
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/gateway")
                .then(({ data }) => {
                    this.gateways = data;

                    axios
                        .get("/api/user/" + this.user.id + "/gatewayUser")
                        .then((response) => {
                            this.gatewaysUser = response.data;

                            data?.map((gateway, i) => {
                                let gatewayUser = response.data.find((item) => {
                                    return item.gateway_id === gateway.id;
                                });

                                this.form.gateways[i] = {
                                    gateway_id: gateway.id,
                                    is_active:
                                        gatewayUser?.is_active ??
                                        gateway?.is_active,
                                    priority:
                                        gatewayUser?.priority ??
                                        gateway?.priority,
                                    is_international:
                                        gatewayUser?.is_international ??
                                        gateway?.is_international,
                                    international_priority:
                                        gatewayUser?.international_priority ??
                                        gateway?.international_priority,
                                };
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;

            var noDuplicates = new Set(
                this.form.gateways?.map((gateway) => gateway.priority),
            );

            if (noDuplicates.size < this.form.gateways?.length) {
                this.toast.error("As prioridades não podem ser iguais");
                this.loading = false;

                return;
            }

            axios
                .post(
                    "/api/user/" + this.user.id + "/gateway",
                    this.form.gateways,
                )
                .then(({ data }) => {
                    this.toast.success("Configurações salvas com sucesso.");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
