<template>
    <div>
        <form @submit.prevent="addMessage" class="flex">
            <div class="mr-2 flex-grow">
                <input
                    type="text"
                    v-model="newMessageText"
                    placeholder="Digite um novo motivo"
                    class="w-full rounded border border-gray-300 p-2"
                />
            </div>
            <div class="mr-2">
                <select
                    v-model="newMessageType"
                    class="rounded border border-gray-300 p-2"
                >
                    <option value="product">Produto</option>
                    <option value="user">Usuário</option>
                    <option value="bank_account">Conta Bancária</option>
                </select>
            </div>
            <div>
                <submit-button :loading="loading" type="submit">
                    Adicionar
                </submit-button>
            </div>
        </form>

        <div class="my-4 mb-8 grid gap-2 md:grid-cols-6">
            <div class="flex flex-col">
                <label for="types" class="block font-medium dark:text-white"
                    >Tipo:</label
                >
                <select
                    id="types"
                    v-model="filters.type"
                    class="rounded border border-gray-300 p-2"
                >
                    <option value="">Todos</option>
                    <option
                        v-for="type in types"
                        :key="type.value"
                        :value="type.value"
                    >
                        {{ type.label }}
                    </option>
                </select>
            </div>
        </div>

        <table-component
            :loading="loading"
            :pagination="pagination"
            :headers="headers"
            @fetch="fetchMessages"
        >
            <tr v-for="(message, index) in messages">
                <td class="table-cell-sm w-80">
                    <span v-if="editingRowIndex !== index">{{
                        message.reason
                    }}</span>
                    <div v-else class="flex">
                        <input
                            :class="{
                                'highlight-input': editingRowIndex === index,
                                'w-full': editingRowIndex === index,
                            }"
                            type="text"
                            ref="editedInput"
                            v-model="editedMessageText"
                        />
                        <button
                            class="ml-2"
                            @click="saveEditedMessage(message.id)"
                        >
                            <font-awesome-icon
                                icon="fa-check"
                                class="-mr-1 ml-2 h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </td>
                <td class="table-cell-sm w-10">
                    {{ message.type === "product" ? "Produto" : "Usuário" }}
                </td>
                <td class="table-cell-sm w-10">
                    <button @click="editMessage(index)">
                        <font-awesome-icon
                            icon="fa-pencil-alt"
                            class="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                    <button @click="deleteMessage(message.id)">
                        <font-awesome-icon
                            icon="fa-solid fa-trash"
                            class="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<style>
.highlight-input {
    border-color: green;
    box-shadow: 0 0 3px green;
}
</style>

<script>
import axios from "axios";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import MultipleSelect from "../../components/forms/MultipleSelect.vue";
import { useToast } from "vue-toastification";
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: {
        SubmitButton,
        useToast,
        MultipleSelect,
        Pagination,
        TableComponent,
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    watch: {
        "filters.type": {
            handler: "fetchMessages",
        },
    },
    data() {
        return {
            editingRowIndex: null,
            loading: true,
            next_page_url: false,
            messages: [],
            newMessageText: "",
            newMessageType: "product",
            editedMessageText: "",
            headers: ["Mensagem", "Tipo", "Ação"],
            types: [
                { value: "user", label: "Usuário" },
                { value: "product", label: "Produto" },
                { value: "bank_account", label: "Conta Bancária" },
            ],
            filters: {
                type: "",
            },
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },
    mounted() {
        this.fetchMessages();
    },
    methods: {
        fetchMessages() {
            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/reason/", {
                    params: params,
                })
                .then(({ data }) => {
                    this.messages = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;

                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addMessage() {
            if (this.newMessageText.length < 3) {
                return this.toast.error("Por favor, preencha o motivo.");
            }
            axios
                .post("/api/reason/", {
                    reason: this.newMessageText,
                    type: this.newMessageType,
                })
                .then((response) => {
                    this.newReason = "";
                    axios
                        .get("/api/reason/")
                        .then((response) => {
                            this.options = response.data;
                        })
                        .catch((error) => {
                            console.error(error);
                        });

                    this.fetchMessages();
                    this.toast.success(response.data.message);
                })
                .catch((error) => {
                    console.error(error);
                });

            this.newMessageText = "";
        },
        editMessage(rowIndex) {
            this.editingRowIndex = rowIndex;
            this.editedMessageText = this.messages[rowIndex].reason;

            this.$nextTick(() => {
                const inputElement = this.$refs.editedInput[rowIndex];
                if (inputElement) {
                    inputElement.focus();
                }
            });
        },
        saveEditedMessage(messageId) {
            axios
                .put("/api/reason/" + messageId, {
                    reason: this.editedMessageText,
                })
                .then((response) => {
                    this.toast.success(response.data.message);
                })
                .catch((error) => {})
                .finally(() => {
                    this.fetchMessages();
                    this.cancelEdit();
                });
        },
        cancelEdit() {
            this.editingRowIndex = null;
            this.editedMessageText = "";
        },
        deleteMessage(messageId) {
            if (confirm("Tem certeza que deseja excluir este item?")) {
                axios
                    .delete("/api/reason/" + messageId)
                    .then((response) => {
                        this.toast.success(response.data.message);
                    })
                    .catch((error) => {})
                    .finally(() => this.fetchMessages());
            }
        },
    },
};
</script>
