<template></template>

<script>
export default {
    data() {
        return {
            loading: false,
            tasks: [],
        };
    },

    mounted() {},

    methods: {
        fetch() {
            this.loading = true;
        },
    },
};
</script>
