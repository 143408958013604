<template>
    <back-button :route="{ name: 'finnancial.chargebacks.users'}"/>
    <div class="flex items-center">
        <user-heading :user="user"/>
        <button
            type="button"
            class="btn-primary-sm ml-auto text-sm"
            @click.prevent="config = !config"
        >
            <font-awesome-icon
                icon="fa-solid fa-cog"
                class="mr-1 dark:text-white"
                aria-hidden="true"
            />
            Configurações
        </button>

        <config-modal :user="user" :show="config"  @close="config = false"/>
    </div>
</template>

<script>
    import BackButton from "../../../../components/layouts/navigation/BackButton.vue";
    import UserHeading from "../../../users/UserHeading.vue";
    import ConfigModal from "../../../../components/finnancial/chargebacks/ConfigModal.vue";

    export default {
        components: {ConfigModal, UserHeading, BackButton},

        data() {
            return {
                user: false,
                loading: false,
                config: false
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                axios.get('/api/user/' + this.$route.params.user_id).then(({data}) => {
                    this.user = data;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
