<template>
    <div class="flex content-center items-center my-3">
        <h3 class="text-lg font-bold leading-6 font-medium dark:text-white flex items-center">
            Usuários Ativos
        </h3>
        <div class="ml-auto flex items-center">
            <button
                @click.prevent="exportSheet"
                type="submit"
                :disabled="loading || users.length < 1"
                class="btn-primary mr-2"
            >
                <svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <CloudArrowDownIcon class="mr-1 flex-shrink-0 self-center h-5 w-5" />
                Exportar
            </button>
            <litepie-datepicker
                class="max-w-[300px]"
                v-model="dates"
                :formatter="{
                  date: 'DD/MM/YYYY',
                  month: 'MMM'
                }"
                :shortcuts="false"
            ></litepie-datepicker>
        </div>
    </div>

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="['Data', 'Usuário', 'Comissões']"
        @fetch="fetch"
    >
        <tr v-for="user in users">
            <td class="table-cell">{{ user.created_at }}</td>
            <td class="table-cell">
                <button
                    class="text-indigo-500 hover:underline"
                    @click.prevent="manage(user)"
                >
                    {{ user.email }} - {{ user.name }}
                </button>
            </td>
            <td class="table-cell">{{ user.count }} - {{ $root.formatCurrency(user.commission) }}</td>
        </tr>
    </table-component>
</template>

<script>
    import TableComponent from "../../../components/layouts/table/TableComponent.vue";
    import SubmitButton from "../../../components/forms/SubmitButton.vue";
    import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";
    import { Store } from "../../../stores/store.js";
    import { mapWritableState } from 'pinia';
    export default {
        components: {SubmitButton, TableComponent, CloudArrowDownIcon},
        data() {
            return {
                loading: true,
                dates: [
                    moment().subtract(1, 'month').format('DD/MM/YYYY'),
                    moment().format('DD/MM/YYYY')
                ],
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
                users: [],
            }
        },

        computed: {
            ...mapWritableState(Store, ['user']),
        },

        watch: {
            dates() {
                this.fetch();
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            exportSheet() {
                this.loading = true;
                axios.get('/api/analytics/active-users/export', {
                    params: {
                        from: this.dates[0],
                        to: this.dates[1]
                    }
                }).then(({data}) => {
                    this.loading = false;
                    window.open(data, '_blank');
                });
            },
            manage(user) {
                this.user = user;
                this.$router.push({
                    name: 'user.commissions',
                    params: { user_id: user.id }
                });
            },
            fetch() {
                this.loading = true;
                axios.get('/api/analytics/active-users', {
                    params: {
                        from: this.dates[0],
                        to: this.dates[1]
                    }
                }).then(({data}) => {
                    this.users = data.data;
                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };
                    this.loading = false;
                });
            }
        }
    }
</script>
