<script>

export default {
    methods: {
        validateInputInterval(event, min, max) {
            let value = parseFloat(event.target.value);
            
            if (isNaN(value)) {
                value = 0;
            }

            if (min !== undefined && value < min) {
                return min;
            } else if (max !== undefined && value > max) {
                return max;
            }

            return value;
        }
    }
};
</script>