<template>
    <div class="w-full">
        <h3 class="text-lg leading-6 font-medium dark:text-white mb-3">Vendas</h3>
        <highcharts class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
export default {
    props: ['dates'],
    data() {
        return {
            orders: [],

            chartObject: {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` + point.y.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                });
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            }
        };
    },

    watch: {
        dates() {
            this.chartObject.series = [];
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            axios.get('/api/analytics/metrics/orders', {
                params: {
                    from: this.dates[0],
                    to: this.dates[1]
                }
            }).then(({data}) => {
                this.orders = data;
                const seriesData = [];
                const interestData = [];
                const platformCommissionData = [];

                Object.entries(this.orders).forEach(([key, value]) => {
                    seriesData.push([value.unix_date, parseInt(value.paid_amount)]);
                    interestData.push([value.unix_date, parseInt(value.interest)]);
                    platformCommissionData.push([value.unix_date, parseInt(value.platform_commission)]);
                });

                this.chartObject.series.push(
                    {
                        name: 'Vendas',
                        lineWidth: 3,
                        data: seriesData
                    },
                    {
                        name: 'Juros',
                        lineWidth: 3,
                        data: interestData
                    },
                    {
                        name: 'Comissão da plataforma',
                        lineWidth: 3,
                        data: platformCommissionData
                    }
                );
            });
        }
    }
};
</script>
