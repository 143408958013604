<template>
    <Menu as="div" class="ml-3">
        <div>
            <MenuButton
                v-slot="{ open }"
                class="p-2 max-w-xs dark:bg-gray-800 dark:hover:bg-gray-900 rounded-full flex items-center text-sm focus:outline-none hover:bg-gray-200"
            >
                <span class="sr-only">Open user menu</span>
                <ChevronDownIcon
                    :class="{
                        'rotate-180': open
                    }"
                    class="h-5 w-5 text-gray-400 transition-all"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-for="item in menu" v-slot="{ active }">
                    <button @click="modals[item.modal] = true" class="text-left w-full block px-4 py-2 text-sm text-gray-700">
                        {{ item.label }}
                    </button>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>

    <update-modal
        :cost="cost"
        :modal="modals.update"
        @close="modals.update = false"
        @fetch="modals.update = false;$emit('fetch')"
    />
    <destroy-modal
        :cost="cost"
        :modal="modals.destroy"
        @close="modals.destroy = false"
        @fetch="modals.destroy = false;$emit('fetch')"
    />
</template>

<script>
    import {ChevronDownIcon} from "@heroicons/vue/24/outline";
    import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
    import UpdateModal from "./modals/UpdateModal.vue";
    import DestroyModal from "./modals/DestroyModal.vue";

    export default {
        components: {DestroyModal, UpdateModal, ChevronDownIcon, Disclosure, Menu, MenuButton, MenuItem, MenuItems},
        props: {cost: Object},
        emits: [ 'fetch' ],
        data() {
            return {
                menu: [
                    {label: 'Alterar', modal: 'update'},
                    {label: 'Remover', modal: 'destroy'}
                ],
                modals: {
                    users: false,
                    update: false,
                    destroy: false
                }
            };
        }
    }
</script>
