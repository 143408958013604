<template>
    <Modal :open="open" @close="$emit('close')">
        <div class="mb-2 flex items-center">
            <input
                class="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                ref="file"
            />
        </div>
        <progress
            v-if="progress"
            class="w-full rounded"
            :value="progress"
            max="100"
        ></progress>
        <submit-button
            size="sm"
            :loading="loading"
            @click="submit"
            class="ml-auto"
        >
            Importar
        </submit-button>
    </Modal>
</template>

<script>
import SubmitButton from "../../../../components/forms/SubmitButton.vue";
import Modal from "../../../../components/layouts/Modal.vue";

export default {
    props: ["open"],
    components: { Modal, SubmitButton },
    emits: ["close", "fetch"],
    data() {
        return {
            loading: false,
            progress: 0,
            form: {
                file: "",
            },
        };
    },
    methods: {
        async submit() {
            if (!this.$refs.file.files[0]) return;
            this.loading = true;
            await Vapor.store(this.$refs.file.files[0], {
                progress: (progress) => {
                    this.progress = Math.round(progress * 100);
                },
            }).then((response) => {
                this.form.file = response.key;

                axios
                    .post("/api/pre-chargebacks/ethoca-import", {
                        file: this.form.file,
                    })
                    .then(({ data }) => {
                        this.loading = false;
                        this.$emit("fetch");
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
            });
        },
    },
};
</script>
