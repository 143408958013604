<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end min-h-screen pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="z-50 inline-block align-bottom bg-gray-700 rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-top sm:max-w-4xl sm:w-full"
                    >
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mt-3 text-center sm:mt-0 sm:text-left"
                            >
                                <DialogTitle
                                    as="h3"
                                    class="text-lg leading-6 font-medium text-white my-3 px-3"
                                >
                                    Vitrine
                                </DialogTitle>

                                <div class="overflow-auto not-prose relative bg-slate-50 rounded-xl rounded-br-none rounded-bl-none overflow-auto dark:bg-slate-800/25">
                                    <table class="border-collapse table-auto w-full text-sm">
                                        <thead>
                                            <tr class="text-white">
                                                <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                                    Produto
                                                </th>
                                                <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                    Produtor
                                                </th>
                                                <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                    Tipo
                                                </th>
                                                <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                    Categoria
                                                </th>

                                                <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                    Recomendado
                                                    <button
                                                        @click.prevent="toggleAll('is_recommended')"
                                                        :disabled="loading"
                                                        type="submit"
                                                        :class="{'cursor-not-allowed': loading}"
                                                        class="mx-auto inline-flex items-center p-1 font-semibold leading-6 text-xs shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                                                    >
                                                        {{ hasAllSelected('is_recommended') ? 'Desmarcar todos' : 'Marcar todos' }}
                                                    </button>
                                                </th>
                                                <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                    Avaliado
                                                    <button
                                                        @click.prevent="toggleAll('is_best_rated')"
                                                        :disabled="loading"
                                                        type="submit"
                                                        :class="{'cursor-not-allowed': loading}"
                                                        class="mx-auto inline-flex items-center p-1 font-semibold leading-6 text-xs shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                                                    >
                                                        {{ hasAllSelected('is_best_rated') ? 'Desmarcar todos' : 'Marcar todos' }}
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white dark:bg-slate-800">
                                        <tr v-if="loading && products.length < 1" v-for="n in 5">
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="h-2 w-full bg-gray-500 rounded animate-pulse"></div>
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="h-2 w-full bg-gray-500 rounded animate-pulse"></div>
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="h-2 w-full bg-gray-500 rounded animate-pulse"></div>
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="h-2 w-full bg-gray-500 rounded animate-pulse"></div>
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="h-2 w-full bg-gray-500 rounded animate-pulse"></div>
                                            </td>
                                        </tr>

                                        <tr v-for="product in products" class="">
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{ product.name }}
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{ product.user.email }}
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{ product.type }}
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{ product.category.name }}
                                            </td>

                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <form-toggle
                                                    v-model="product.is_recommended"
                                                    :loading="loading"
                                                    label=""
                                                >
                                                </form-toggle>
                                            </td>

                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <form-toggle
                                                    v-model="product.is_best_rated"
                                                    :loading="loading"
                                                    label=""
                                                >
                                                </form-toggle>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                @click.prevent="saveShowcase"
                                :disabled="loading"
                                type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mr-3 sm:mb-3 sm:w-auto sm:text-sm"
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import FormToggle from "../../components/forms/FormToggle.vue";


export default {
    props: ['ids'],
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        FormToggle
    },

    data() {
        return {
            loading: false,
            products: []
        }
    },

    mounted() {

        this.fetch();
    },

    methods: {
        hasOneSelected(field) {
            return this.products.filter(product => product[field]).length > 0;
        },
        hasAllSelected(field) {
            return this.products.filter(product => !product[field]).length === 0;
        },
        toggleAll(field) {
            if (this.hasAllSelected(field)) {
                this.products.map(product => product[field] = false);
            } else {
                this.products.map(product => product[field] = true);
            }
        },
        fetch() {
            this.loading = true;
            axios.get('/api/showcase/products',{
                params: {
                    ids: this.ids
                }
            }).then(({data}) => {
                this.products = data;
                this.loading = false;
            });
        },
        closeModal() {
            this.$emit("closeModal");
        },
        saveShowcase() {
            this.loading = true;
            axios.post("/api/showcase", {
                products: this.products
            }).then(({ data }) => {
                this.$emit("closeModalAndFetch");
            })
        }
    },
};
</script>
