<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <form @submit.prevent="blockUser()">
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-ban"
                                        class="mx-auto mt-1 w-10 h-10 text-gray-400 dark:text-gray-200"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div
                                    class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                                >
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-white"
                                    >
                                        {{
                                            user?.setting?.is_blocked
                                                ? "Desbloquear"
                                                : "Bloquear"
                                        }}
                                        Usuário
                                    </dialog-title>
                                    <div class="mt-2">
                                        <div class="text-md text-white">
                                            Você tem certeza que deseja
                                            {{
                                                user?.setting?.is_blocked
                                                    ? "desbloquear"
                                                    : "bloquear"
                                            }}
                                            a conta de
                                            <p class="text-bold">
                                                {{ user.email }} ?
                                            </p>
                                            {{
                                                user?.setting?.is_blocked
                                                    ? `Após o desbloqueio o usuário voltará a poder realizar
                                                        login e outras ações na plataforma.`
                                                    : `Após o bloqueio o usuário ficará
                                                        impedido de realizar qualquer ação
                                                        na plataforma, inclusive o login. Além disso
                                                        todos os seus produtos serão desativados.`
                                            }}
                                        </div>

                                        <div
                                            v-if="!user?.setting?.is_blocked"
                                            class="my-3"
                                        >
                                            <label
                                                for="large-input"
                                                class="block mb-2 text-sm font-medium text-gray-300"
                                                >Motivo</label
                                            >
                                            <input
                                                type="text"
                                                v-model="reason"
                                                id="large-input"
                                                class="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                            >
                                <button
                                    type="submit"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    {{
                                        user?.setting?.is_blocked
                                            ? "Desbloquear"
                                            : "Bloquear"
                                    }}
                                    Conta
                                </button>

                                <button
                                    type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { mapWritableState } from "pinia";
import { Store } from "../../../stores/store";
import { useToast } from "vue-toastification";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    data() {
        return {
            reason: "",
        };
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    computed: {
        ...mapWritableState(Store, ["user"]),
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        blockUser() {
            axios
                .post(`/api/user/${this.user?.id}/block`, {
                    reason: this.reason,
                })
                .then(({ data }) => {
                    this.toast.success(
                        `Usuário ${
                            this.user.setting.is_blocked
                                ? "desbloqueado"
                                : "bloqueado"
                        } com sucesso`
                    );
                    this.$emit("closeModalAndFetch");
                })
                .catch(({ response }) => {
                    this.toast.error(response?.data?.message);
                    this.$emit("closeModalAndFetch");
                });
        },
    },
};
</script>
