<template>
    <modal :open="open" @close="$emit('close')">
        <div class="dark:text-white">
            <h3
                class="text-md mb-4 border-b border-gray-600 pb-2 font-medium leading-6 dark:text-white"
            >
                Configurações
            </h3>

            <form
                @submit.prevent="submit"
                class="flex flex-col dark:text-white"
            >
                <label
                    for="statuses"
                    class="mb-2 block text-sm text-gray-900 dark:text-gray-200"
                >
                    Status
                </label>
                <select
                    v-model="form.status"
                    id="statuses"
                    class="mb-3 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                    <option :value="false" selected>Status</option>
                    <option v-for="option in options" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>

                <label
                    class="mb-2 block text-sm text-gray-900 dark:text-gray-200"
                >
                    Comissão
                </label>
                <input
                    type="number"
                    min="0"
                    max="3"
                    required
                    v-model="form.commission"
                    step="0.1"
                    class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                />

                <submit-button
                    class="ml-auto mt-2"
                    size="sm"
                    :loading="loading"
                >
                    Enviar
                </submit-button>
            </form>
        </div>
    </modal>
</template>

<script>
import Modal from "../layouts/Modal.vue";
import SubmitButton from "../forms/SubmitButton.vue";

export default {
    components: { SubmitButton, Modal },
    props: ["setting", "open"],
    emits: ["fetch", "close"],
    data() {
        return {
            loading: false,
            form: {
                status: "",
                commission: 0,
            },
            options: [
                { value: "active", label: "Ativo" },
                { value: "inactive", label: "Inativo" },
                { value: "expired", label: "Expirado" },
                { value: "canceled", label: "Cancelado" },
                { value: "blocked", label: "Bloqueado" },
            ],
        };
    },

    mounted() {
        if (this.setting) {
            this.form = {
                status: this.setting.status,
                commission: this.setting.commission,
            };
        }
    },

    methods: {
        submit() {
            this.loading = true;
            axios
                .put(`/api/user/${this.setting.user_id}/referrals`, this.form)
                .then(({ data }) => {
                    this.loading = false;
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
