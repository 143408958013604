<template>
    <multiselect
        v-model="users"
        :mode="multiple ? 'multiple' : 'single'"
        placeholder="Procure por Email ou Nome"
        open-direction="bottom"
        :options="options"
        :limit="10"
        no-results-text="Nenhum resultado encontrado"
        :multiple="true"
        :searchable="true"
        :min-chars="3"
        :resolve-on-load="true"
        :loading="loading"
        :filter-results="false"
        :internal-search="true"
        :clear-on-select="true"
        :close-on-select="true"
        :options-limit="300"
        limit-text="limitText"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="fetch"
        :classes="{
            container:
                'z-50 w-full dark:bg-gray-800 relative flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-black dark:text-white  leading-snug outline-none',
            containerDisabled: 'cursor-default dark:bg-gray-800',
            containerOpen: 'rounded-b-none',
            containerOpenTop: 'rounded-t-none',
            containerActive: 'ring ring-green-500 ring-opacity-30',
            singleLabel:
                'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            singleLabelText:
                'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
            assist: 'hidden',
            multipleLabel:
                'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            search: 'w-full absolute inset-0 outline-none focus:ring-0 bg-transparent box-border border-0 font-sans rounded pl-3.5 rtl:pl-0 rtl:pr-3.5',
            tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 rtl:pl-0 rtl:pr-2',
            tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
            tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
            tagRemove:
                'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
            tagRemoveIcon:
                'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
            tagsSearchWrapper:
                'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
            tagsSearch:
                'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 font-sans box-border w-full',
            tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
            placeholder:
                'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
            caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
            caretOpen: 'rotate-180 pointer-events-auto',
            clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
            clearIcon:
                'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
            spinner:
                'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
            inifite: 'flex items-center justify-center w-full',
            inifiteSpinner:
                'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
            dropdown:
                'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
            dropdownTop:
                '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
            dropdownHidden: 'hidden',
            options: 'flex flex-col p-0 m-0 list-none z-50 dark:bg-gray-800',
            optionsTop: '',
            group: 'p-0 m-0',
            groupLabel:
                'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
            groupLabelPointable: 'cursor-pointer',
            groupLabelPointed: 'bg-gray-300 text-gray-700',
            groupLabelSelected: 'bg-green-600',
            groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
            groupLabelSelectedPointed: 'bg-indigo-600 opacity-90',
            groupLabelSelectedDisabled:
                'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
            groupOptions: 'p-0 m-0',
            option: 'flex items-center justify-start box-border text-left cursor-pointer leading-snug py-2 px-3',
            optionPointed: 'bg-gray-500 dark:bg-gray-900',
            optionSelected: 'bg-indigo-600',
            optionDisabled: 'text-gray-300 cursor-not-allowed',
            optionSelectedPointed: 'bg-indigo-600 opacity-90',
            optionSelectedDisabled:
                'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
            noOptions:
                'py-2 px-3 text-gray-600 dark:bg-litepie-secondary-500 text-left',
            noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
            fakeInput:
                'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
            spacer: 'h-9 py-px box-content',
        }"
    >
        <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
                {{ values.length }} characters selected
            </div>
        </template>
        <template v-slot:spinner>
            <svg
                v-if="loading"
                class="mr-2 mt-2 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
    props: { multiple: { type: Boolean, default: true } },
    components: { Multiselect },

    data() {
        return {
            loading: false,
            users: [],
            selected: [],
            options: [],
        };
    },

    watch: {
        users() {
            this.$emit("update:modelValue", this.users);
        },
    },

    created() {
        this.$_debounceTimer = null;
    },

    mounted() {
        this.fetch();
    },

    methods: {
        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },

        fetch(query) {
            if (!query || query.length <= 3) {
                return;
            }
            this.debounce(() => {
                const params = {};
                params["filter[text]"] = query;

                this.loading = true;
                axios
                    .get("/api/user/search", {
                        params: params,
                    })
                    .then(({ data }) => {
                        this.options = data.map((user) => {
                            return { value: user.id, label: user.email };
                        });
                        setTimeout(() => (this.loading = false), 500);
                    });
            }, 300);
        },
    },
};
</script>
