<template>
    <div v-if="oldValue != newValue">
        <p class="text-sm font-boldValue text-gray-200 mt-3">
            {{ title }}
        </p>
        <div class="grid grid-cols-2 gap-4">
            <div>
                <p class="text-xs font-medium text-gray-400 mt-3 mb-1">
                    Antiga
                </p>
                <div
                    class="p-2 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholdValueer-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    {{ oldValue }}
                </div>
            </div>
            <div>
                <p class="text-xs font-medium text-gray-400 mt-3 mb-1">Nova</p>
                <div
                    class="p-2 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholdValueer-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    {{ newValue }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["title", "oldValue", "newValue"],
};
</script>

<style></style>
