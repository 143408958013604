<template>
    <div
        v-if="cards && cards.length > 0"
        v-tooltip="'Cartões Usados: '+ cards.length"
        class="block"
    >
        <font-awesome-icon icon="fa-solid fa-credit-card" />
    </div>
</template>

<script>
    export default {
        props: ['cards']
    }
</script>
