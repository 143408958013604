<template></template>

<script>
import { Store } from "../stores/store.js";
import { mapWritableState } from "pinia";
export default {
    computed: {
        ...mapWritableState(Store, ["visitorData"]),
    },
    mounted() {
        this.worker();

        let url =
            "https://m.ticto.app/unbUrrEQ60zLila3/uj5IYah8Pjcasguz?apiKey=eGz2r3xqstesBiBzxwkw";
        const fpPromise = import(
            "https://m.ticto.app/unbUrrEQ60zLila3/uj5IYah8Pjcasguz?apiKey=eGz2r3xqstesBiBzxwkw"
        ).then((FingerprintJs) =>
            FingerprintJs.load({
                scriptUrlPattern: url,
                endpoint:
                    "https://m.ticto.app/unbUrrEQ60zLila3/tGYx6BDTiZQWQkvH",
                apiKey: "eGz2r3xqstesBiBzxwkw",
                remoteControlDetection: true,
            }),
        );

        fpPromise
            .then((fp) =>
                fp.get({
                    extendedResult: true,
                }),
            )
            .then((result) => {
                // This is the visitor identifier:
                this.visitorData = result;

                console.log({ result });
                // attach default header with axios id
                axios.defaults.headers.common["X-FingerprintJS-Token"] =
                    this.visitorData.visitorId;
            });

        // const {data, error, isLoading, getData} = useVisitorData(
        //     {extendedResult: true},
        //     {immediate: false}
        // );
        //
        // return getData({ignoreCache: true}).then((data) => {
        //     this.visitorData = data;
        //     // attach default header with axios id
        //     axios.defaults.headers.common['X-FingerprintJS-Token'] = this.visitorData.visitorId;
        // })
    },

    methods: {
        worker() {
            axios
                .get("https://darwin.ticto.cloud/api/device-info")
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};
</script>
