<template>
    <div>
        <h1 class="mt-3 text-lg font-light dark:text-white">Webhooks</h1>

        <div class="mb-3 grid grid-cols-3">
            <div>
                <cards-radio-group
                    v-model="searchType"
                    :options="[
                        { label: 'Venda', value: 'order' },
                        // { label: 'Webhook', value: 'webhook' },
                    ]"
                    :disabled="loading"
                />
            </div>
        </div>

        <form @submit.prevent="fetch" class="mb-2 flex">
            <input
                v-model="search"
                type="text"
                name="webhookSearch"
                id="webhookSearch"
                class="text-input"
                required
                :placeholder="
                    searchType === 'order'
                        ? 'Ex.: TPC..., TOC...'
                        : 'Ex.: Id do Webhook'
                "
            />

            <submit-button :loading="loading" size="sm" class="ml-2">
                Buscar
            </submit-button>
        </form>

        <ticto-loading v-if="loading" />
        <div
            v-else
            class="flex flex-col space-y-3 divide-y rounded-lg pb-3 dark:divide-gray-700 dark:bg-slate-800 dark:text-white"
        >
            <div
                v-for="webhook in webhooks"
                class="flex items-center px-3 pt-3"
            >
                <CloudIcon
                    class="text-gray-30 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                />

                <span v-if="webhook.commission" class="mr-2">
                    {{ webhook.commission.user.name }} -
                    {{ webhook.commission.user.email }}
                </span>

                <span class="max-w-sm truncate" v-tooltip="webhook.webhook_url">
                    {{ webhook.webhook_url }}
                </span>

                <NewspaperIcon
                    v-tooltip="'Visualizar Payload'"
                    class="text-gray-305 ml-auto mr-2 h-5 w-5 hover:cursor-pointer"
                    @click.prevent="modal = webhook.pk"
                >
                    Payload
                </NewspaperIcon>

                <span
                    class="mr-2 rounded px-2.5 py-1.5 text-sm font-medium"
                    :class="{
                        'bg-green-500 text-white': webhook.status_code === 200,
                        'bg-red-500 text-white': webhook.status_code !== 200,
                    }"
                >
                    {{ webhook.status_code }}
                </span>

                <ClockIcon
                    class="text-gray-30 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                />

                <modal
                    :title="webhook.webhook_url"
                    size="4xl"
                    :open="modal === webhook.pk"
                    @close="modal = false"
                >
                    <p class="dark:text-white">Payload</p>
                    <div class="dark:text-white">
                        <pre class="text-xs">{{ webhook.payload }}</pre>
                    </div>
                    <p class="dark:text-white">Response</p>
                    <div class="dark:text-white">
                        <pre class="text-xs">{{ webhook.response_body }}</pre>
                    </div>
                </modal>

                {{ formatDate(webhook.created_at) }}
            </div>
        </div>
    </div>
</template>

<script>
import CardsRadioGroup from "../../components/forms/CardsRadioGroup.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import { ClockIcon, CloudIcon, NewspaperIcon } from "@heroicons/vue/24/outline";
import Modal from "../../components/layouts/Modal.vue";
export default {
    components: {
        Modal,
        TictoLoading,
        SubmitButton,
        CardsRadioGroup,
        ClockIcon,
        CloudIcon,
        NewspaperIcon,
    },
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            searchType: "order",
            search: "",
            webhooks: [],
            modal: false,
        };
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/webhook", {
                    params: {
                        type: this.searchType,
                        search: this.search,
                    },
                })
                .then(({ data }) => {
                    this.webhooks = data;
                })
                .catch(({ response }) => {
                    if ([404, 400].includes(response.status)) {
                        this.$notify(
                            {
                                group: "error",
                                title: "Error",
                                text: response.data.message,
                            },
                            4000,
                        );
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
