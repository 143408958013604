<template>
    <submit-button class="ml-2 text-sm" size="sm" @click.prevent="show = true">
        <slot>Adicionar</slot>
    </submit-button>
    <modal class="min-h-full" :open="show" @close="show = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Auditoria
        </h1>

        <audit-form @fetch="closeAndFetch" :user-id="userId" />
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import AuditForm from "./AuditForm.vue";

export default {
    props: ["userId"],
    components: { AuditForm, SubmitButton, Modal },
    emits: ["fetch"],
    data() {
        return {
            show: false,
        };
    },

    methods: {
        closeAndFetch() {
            setTimeout(() => {
                this.$emit("fetch");
                this.show = false;
            }, 500);
        },
    },
};
</script>
