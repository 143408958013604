<template>
    <h1
        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold text-gray-400"
    >
        <span class="mt-2 flex w-full items-center">
            DOCUMENTOS
        </span>
    </h1>
    <div
        class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
    >
        <ul role="list" class="divide-y rounded-md dark:divide-gray-800">
            <li
                v-if="loading"
                v-for="n in 5"
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
                <div class="h-2 w-full animate-pulse rounded bg-gray-500"></div>
            </li>
            <li
                v-else
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                v-for="document in documents"
                v-bind:key="document.id"
            >
                <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    <span class="ml-2 w-0 flex-1 truncate dark:text-white">{{
                        formatDocumentName(document.collection_name)
                    }}</span>
                </div>
                <div class="ml-4 flex-shrink-0">
                    <a
                        target="_blank"
                        :href="document.temporary_url"
                        class="font-medium text-indigo-600 hover:text-indigo-500"
                        >Download</a
                    >
                </div>
            </li>
        </ul>
    </div>

    <h1
        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold text-gray-400"
    >
        <span class="mt-2 flex w-full items-center">
            DOCUMENTOS CONTA BANCÁRIA
            <update-documents v-if="user" :user="user" @fetch="fetch" />
        </span>
    </h1>
    <div
        class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
    >
        <ul role="list" class="divide-y rounded-md dark:divide-gray-800">
            <li
                v-if="loading"
                v-for="n in 5"
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
                <div class="h-2 w-full animate-pulse rounded bg-gray-500"></div>
            </li>
            <li
                v-else
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                v-for="document in documentsBankAccount"
                v-bind:key="document.id"
            >
                <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400"
                    />
                    <span class="ml-2 w-0 flex-1 truncate dark:text-white">{{
                        formatDocumentName(document.collection_name)
                    }}</span>
                </div>
                <div class="ml-4 flex-shrink-0">
                    <a
                        target="_blank"
                        :href="document.temporary_url"
                        class="font-medium text-indigo-600 hover:text-indigo-500"
                        >Download</a
                    >
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { PaperClipIcon } from "@heroicons/vue/24/outline";
import HelperMixin from "../../mixins/HelperMixin.vue";
import UpdateDocuments from "./UpdateDocuments.vue";

export default {
    components: { UpdateDocuments, PaperClipIcon },
    props: { user: { type: Object, required: true } },
    mixins: [HelperMixin],

    data() {
        return {
            loading: false,
            documentsBankAccount: [],
            documents: [],
        };
    },

    mounted() {
        this.fetch(false);
    },

    methods: {
        fetch(update = true) {
            this.documents = [];
            this.documentsBankAccount = [];
            this.loading = true;

            if (update) {
                this.documents = [];
            }

            axios
                .get(
                    "/api/bank-account/" +
                        this.user.bank_accounts.at(-1)?.id +
                        "/documents",
                )
                .then(({ data }) => {
                    this.documentsBankAccount = [...data, ...this.documents];
                    axios
                        .get("/api/user/" + this.user.id + "/documents")
                        .then(({ data }) => {
                            this.documents = [...data, ...this.documents];
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
