<template>
    <div class="min-w-0 rounded-lg drop-shadow-sm overflow-hidden bg-white dark:bg-gray-800">
        <div v-if="loading" class="animate-pulse flex space-x-4 p-4 ">
            <div class="flex-1 space-y-6 py-1">
                <div class="h-2 bg-gray-500 rounded"></div>
                <div class="mt-1">
                    <div class="grid grid-cols-3 gap-4">
                        <div class="h-2 bg-gray-500 rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="p-4 flex items-center">
            <div class="w-full">
                <p class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm">
                    {{ name }}
                </p>
                <div class="text-2xl font-light text-gray-700 dark:text-gray-200 flex">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {{ stat }}
                        <span class="ml-2 text-sm font-medium text-gray-500" v-if="previous"> from {{ previous }} </span>
                    </div>

                    <div
                        v-if="previous"
                        :class="[
                            changeType === 'increase' ?
                                'bg-green-100 text-green-800' :
                                'bg-red-100 text-red-800',
                            'inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 ml-auto'
                        ]"
                    >
                        <ArrowSmallUpIcon v-if="changeType === 'increase'" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" aria-hidden="true" />
                        <ArrowSmallDownIcon v-else class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" aria-hidden="true" />
                        <span class="sr-only"> {{ changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
                        {{percentageChange.toFixed(2) }} %
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ArrowSmallDownIcon, ArrowSmallUpIcon} from "@heroicons/vue/24/outline";

export default {
    components: {ArrowSmallUpIcon, ArrowSmallDownIcon},
    props: ['loading', 'name', 'stat', 'previous'],

    computed: {
        changeType() {
            if (this.stat > this.previous) {
                return 'increase';
            } else if (this.stat < this.previous) {
                return 'decrease';
            } else {
                return 'equal';
            }
        },
        change() {
            const change = Math.abs(this.stat - this.previous);
            return change;
        },
        percentageChange() {
            const change = Math.abs(this.stat - this.previous);
            return (change / this.previous) * 100;
        }
    }
}
</script>
