<template>
    <update-email-modal
        v-if="update_email_modal"
        :user="user"
        :show="update_email_modal"
        @closeModal="update_email_modal = false"
        @close="update_email_modal = false"
        @closeModalAndFetch="closeAndFetchApproveModal()"
    />
    <approve-user-modal
        v-if="approve_user_modal"
        @closeModal="closeApproveModal()"
        @closeModalAndFetch="closeAndFetchApproveModal()"
    />
    <impersonate-user-modal
        v-if="impersonate_user_modal"
        @closeModal="impersonate_user_modal = false"
        @closeModalAndFetch="closeAndFetchApproveModal()"
    />
    <block-user-modal
        v-if="block_user_modal"
        @closeModal="closeBlockModal()"
        @closeModalAndFetch="closeAndFetchBlockModal()"
    />
    <div>
        <span
            v-if="balance_updating"
            :class="{ 'cursor-not-allowed': balance_updating }"
            class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white transition ease-in-out duration-150"
        >
            <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            Atualizando saldo...
        </span>

        <menu-headless as="div" class="relative inline-block text-left">
            <div>
                <menu-button
                    class="ml-2 px-2 py-2 inline-flex justify-center w-full rounded-md border: bg-indigo-500 hover:bg-indigo-400 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-700 focus:ring-indigo-500"
                >
                    Opções
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-down"
                        class="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                    />
                </menu-button>
            </div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <menu-items
                    class="origin-top-right absolute right-0 z-10 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-700 focus:outline-none"
                >
                    <div
                        class="py-1 divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
                    >
                        <menu-item v-if="hasPermission('update.user.email')" v-slot="{ active }" @click="update_email_modal = true" class="hover:cursor-pointer">
                            <p
                                :class="[
                                    active
                                        ? 'bg-gray-800/25 text-slate-200'
                                        : 'text-slate-50',
                                    'block px-4 py-2 text-sm',
                                ]"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-envelope"
                                    class="mr-2"
                                    aria-hidden="true"
                                />
                                Alterar E-mail
                            </p>
                        </menu-item>
                        <menu-item v-slot="{ active }" @click="updateBalance" class="hover:cursor-pointer">
                            <p
                                :class="[
                                    active
                                        ? 'bg-gray-800/25 text-slate-200'
                                        : 'text-slate-50',
                                    'block px-4 py-2 text-sm',
                                ]"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-money-bills"
                                    class="mr-2"
                                    aria-hidden="true"
                                />
                                Atualizar Saldo
                            </p>
                        </menu-item>
                        <div>
                            <div v-if="hasPermission('block.users')">
                                <menu-item
                                    v-if="user?.setting?.is_blocked"
                                    v-slot="{ active }"
                                    @click="openBlockModal()"
                                    class="hover:cursor-pointer"
                                >
                                    <p
                                        :class="[
                                            active
                                                ? 'bg-green-700/90 text-slate-200'
                                                : 'bg-green-700 text-slate-50',
                                            'block px-4 py-2 text-sm items-center',
                                        ]"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-check"
                                            class="mr-2"
                                            aria-hidden="true"
                                        />
                                        Desbloquear Usuário
                                    </p>
                                </menu-item>
                                <menu-item
                                    v-else
                                    v-slot="{ active }"
                                    @click="openBlockModal()"
                                    class="hover:cursor-pointer"
                                >
                                    <p
                                        :class="[
                                            active
                                                ? 'bg-red-700/90 text-slate-200'
                                                : 'bg-red-700 text-slate-50',
                                            'block px-4 py-2 text-sm items-center',
                                        ]"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-ban"
                                            class="mr-2"
                                            aria-hidden="true"
                                        />
                                        Bloquear Usuário
                                    </p>
                                </menu-item>
                            </div>
                            <menu-item
                                v-if="hasPermission('delete.users')"
                                v-slot="{ active }"
                                @click.prevent="deleteUser()"
                                class="hover:cursor-pointer"
                            >
                                <p 
                                    :class="[
                                        active
                                            ? 'bg-red-700/90 text-slate-200'
                                            : 'bg-red-700 text-slate-50',
                                        'block px-4 py-2 text-sm items-center',
                                    ]"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-times"
                                        class="mr-2"
                                        aria-hidden="true"
                                    />
                                    Deletar Usuário
                                </p>
                            </menu-item>
                            <menu-item
                                v-slot="{ active }"
                                @click="openImpersonateModal()"
                                class="hover:cursor-pointer"
                            >
                                <p class="block text-white px-4 py-2 text-sm items-center">
                                    <font-awesome-icon
                                        icon="fa-solid fa-sign-in"
                                        class="mr-2"
                                        aria-hidden="true"
                                    />
                                    Impersonate
                                </p>
                            </menu-item>
                            <menu-item
                                v-if="!user.is_approved"
                                v-slot="{ active }"
                                @click="openApproveModal()"
                                class="hover:cursor-pointer"
                            >
                                <p
                                    :class="[
                                        active
                                            ? 'bg-green-700/90 text-slate-200'
                                            : 'bg-green-700 text-slate-50',
                                        'block px-4 py-2 text-sm items-center',
                                    ]"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-check"
                                        class="mr-2"
                                        aria-hidden="true"
                                    />
                                    Aprovar Usuário
                                </p>
                            </menu-item>
                        </div>
                    </div>
                </menu-items>
            </transition>
        </menu-headless>
    </div>
</template>

<script>
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { mapWritableState } from "pinia";
import { mapActions } from "pinia";
import { Store } from "../../stores/store";
import ApproveUserModal from "./modals/ApproveUserModal.vue";
import BlockUserModal from "./modals/BlockUserModal.vue";
import ImpersonateUserModal from "./modals/ImpersonateUserModal.vue";
import UpdateEmailModal from "./modals/UpdateEmailModal.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";

export default {
    components: {
        UpdateEmailModal,
        MenuHeadless: Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ApproveUserModal,
        BlockUserModal,
        ImpersonateUserModal
    },
    data() {
        return {
            impersonate_user_modal: false,
            balance_updating: false,
            approve_user_modal: false,
            block_user_modal: false,
            update_email_modal: false
        };
    },
    mixins: [PermissionMixin],
    computed: {
        ...mapWritableState(Store, ["user", "permissions"]),
    },
    methods: {
        ...mapActions(Store, ["fetchUser"]),
        openImpersonateModal() {
            this.impersonate_user_modal = true;
        },
        openApproveModal() {
            this.approve_user_modal = true;
        },
        closeApproveModal() {
            this.approve_user_modal = false;
        },
        closeAndFetchApproveModal() {
            this.approve_user_modal = false;
            this.user = null;
            this.fetchUser();
        },
        hasPermission(name) {
            return this.permissions.find(permission => permission.name === name)
        },
        openBlockModal() {
            this.block_user_modal = true;
        },
        closeBlockModal() {
            this.block_user_modal = false;
        },
        closeAndFetchBlockModal() {
            this.block_user_modal = false;
            this.user = null;
            this.fetchUser();
        },
        updateBalance() {
            this.balance_updating = true;
            axios
                .post("/api/user/" + this.$route.params.user_id + "/balance")
                .then(({ data }) => {
                    this.balance_updating = false;
                })
                .catch(({ response }) => {
                    this.balance_updating = false;
                });
        },
        deleteUser() {
            if (confirm("Tem certeza que deseja deletar este usuário?")) {
                axios.post(
                    '/api/user/' + this.user.id + '/delete'
                ).then(({data}) => {
                    this.$notify({
                        group: "success",
                        title: "Success",
                        text: data?.message || "Usuário deletado com sucesso!"
                    }, 4000);

                    this.$router.push({ path: '/users' })
                }).catch(({response}) => {
                    this.$notify({
                        group: "error",
                        title: "Error",
                        text: response?.data?.message || response?.data?.errors[0] || "Não foi possível concluir essa solicitação."
                    }, 4000);
                });
            }
        },
    },
};
</script>

<style></style>
