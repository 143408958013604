<template>
    <modal :open="show" @close="$emit('close')">
        <h1 class="mb-3 border-b pb-1 dark:text-white">
            Configurações - {{ user.email }}
        </h1>

        <form @submit.prevent="submit" class="dark:text-white">
            <p class="mb-2 dark:text-white">Cobrar Taxa na Defesa:</p>
            <form-toggle v-model="form.chargeback_success" label="Vitória" />
            <form-toggle
                class="ml-2"
                v-model="form.chargeback_failed"
                label="Derrota"
            />

            <div v-if="form.chargeback_failed || form.chargeback_success">
                <label
                    for="password"
                    class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >Porcentagem</label
                >
                <input
                    v-model="form.chargeback_fee"
                    type="number"
                    step="0.1"
                    name="chargeback_fee"
                    id="chargeback_fee"
                    placeholder="1.5"
                    class="text-input"
                    required
                />
            </div>

            <p class="my-2 dark:text-white">Atualizar status ao vencer?</p>
            <form-toggle
                v-model="form.chargeback_update_status"
                :label="form.chargeback_update_status ? 'Sim' : 'Não'"
            />

            <submit-button :loading="loading" class="mt-2 w-full" size="sm">
                Enviar
            </submit-button>
        </form>
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import FormToggle from "../../forms/FormToggle.vue";
import MoneyInput from "../../forms/MoneyInput.vue";
import SubmitButton from "../../forms/SubmitButton.vue";

export default {
    components: { SubmitButton, MoneyInput, FormToggle, Modal },
    props: {
        user: { required: true },
        show: { type: Boolean, default: false },
    },

    data() {
        return {
            loading: false,
            form: {
                chargeback_fee: null,
                chargeback_success: false,
                chargeback_failed: false,
                chargeback_update_status: false,
            },
        };
    },

    watch: {
        user() {
            this.form.chargeback_fee = this.user.setting.chargeback_fee;
            this.form.chargeback_success = this.user.setting.chargeback_success;
            this.form.chargeback_failed = this.user.setting.chargeback_failed;
            this.form.chargeback_update_status =
                this.user.setting.chargeback_update_status;
        },
    },

    methods: {
        submit() {
            this.loading = true;

            axios
                .put("/api/chargebacks/config/" + this.user.id, this.form)
                .then(({ data }) => {
                    this.$emit("close");
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
