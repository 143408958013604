<template>
    <div>
        <div class="flex flex-col space-y-2">
            <div
                v-for="transaction in transactions"
                class="flex flex w-full w-full min-w-0 items-center overflow-hidden rounded-lg bg-indigo-100 bg-indigo-100 bg-white p-4 text-white text-white drop-shadow-sm dark:bg-gray-800"
            >
                {{ transaction.created_at }}

                <router-link
                    :to="{
                        name: 'user.order',
                        params: { order_id: transaction.order_id },
                    }"
                    target="_blank"
                    class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                >
                    #{{ transaction.id }}
                </router-link>
                <status class="ml-auto" :status="transaction.status" />
                <template v-if="transaction.card">
                    <button
                        class="btn-outline-white px-2 text-xs"
                        v-if="refunding.includes(transaction.id)"
                    >
                        <!-- Loader goes here. Replace with your actual loader component or element -->
                        Loading
                    </button>
                    <card-brand-icon :brand="transaction.card.brand" />
                    {{ transaction.card.first_6 }}*{{ transaction.card.last_4 }}
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../forms/SubmitButton.vue";
import Card from "../layouts/Card.vue";
import Status from "../layouts/Status.vue";
import CardBrandIcon from "../layouts/CardBrandIcon.vue";
export default {
    components: { CardBrandIcon, Status, Card, SubmitButton },
    props: ["audit"],
    data() {
        return {
            loading: false,
            transactions: [],
            refunding: [],
        };
    },

    mounted() {
        Echo.channel("audit-user-" + this.audit.user_id).listen(
            ".App\\Events\\User\\RefundingTransaction",
            (e) => {
                if (this.refunding.includes(e.transactionId)) {
                    this.refunding = this.refunding.filter(
                        (transactionId) => transactionId !== e.transactionId,
                    );
                } else {
                    this.refunding.push(e.transactionId);
                }

                this.transactions.find(
                    (transaction) => transaction.id === e.transactionId,
                ).status = e.status;
            },
        );

        this.store();
    },

    methods: {
        store() {
            this.loading = true;

            axios
                .post("/api/audit/" + this.audit.id + "/refund-all")
                .then(({ data }) => {
                    this.transactions = data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
