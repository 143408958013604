 <template>
    <modal
        size="lg"
        :open="show"
        @closeModal="
            show = false;
            $emit('closeModal');
        "
        @closeModalAndFetch="
            show = false;
            $emit('closeAndFetchModal');
        "
    >
        <form
            class="flex w-full flex-col flex-wrap space-y-3 text-white"
            @submit.prevent="submit"
        >
            <h1 class="border-b border-slate-500 pb-2">
                Reembolsar e Sinalizar Fraude
            </h1>
            <label for="reason" class="sr-only">Reason</label>

            <div v-if="errors.length > 0" class="alert">
                <div v-for="error in errors" :key="error">
                    {{ error }}
                </div>
            </div>

            <p>
                <strong>Atenção:</strong> Prosseguindo, essa transação será
                estornada e classificada como fraude.
            </p>

            <div class="mt-5 flex flex-row-reverse">
                <submit-button
                    :loading="loading"
                    :disabled="loading"
                    @keydown.enter="submit"
                >
                    Enviar
                </submit-button>

                <button
                    :disabled="loading"
                    type="button"
                    class="mr-2 inline-flex justify-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="$emit('closeModal')"
                >
                    Cancelar
                </button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../../components/layouts/Modal.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";

export default {
    props: ["transaction", "show"],
    components: { SubmitButton, Modal },

    data() {
        return {
            loading: false,
            reason: null,
            errors: [],
            message: null,
        };
    },

    methods: {
        submit() {
            this.errors = [];
            this.message = null;
            this.loading = true;

            axios
                .post(
                    "/api/order/" +
                        this.transaction.order_id +
                        "/transactions/" +
                        this.transaction.id +
                        "/refund",
                    {
                        refund_type: "fraud",
                        fraud_confirmed: true,
                        skip_balance: "SKIP",
                    },
                )
                .then(({ data }) => {
                    this.loading = false;
                    this.$emit("closeModalAndFetch");

                    this.$notify(
                        {
                            group: "success",
                            title: "Success",
                            text:
                                data?.message ||
                                "Solicitação realizada com sucesso!",
                        },
                        4000,
                    );
                })
                .catch(({ response }) => {
                    if (
                        response.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("errors")
                    ) {
                        this.errors = response.data.errors;
                    } else if (
                        response.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("message")
                    ) {
                        this.errors = [response.data.message];
                    }
                    this.loading = false;
                });
        },
    },
};
</script>
