<script>
    export default {
        methods: {
            chargebackStatusName(status) {
                switch (status) {
                    case 'requested': return 'Solicitado';
                    case 'pending': return 'Pendente';
                    case 'approved': return 'Aprovada';
                    case 'rejected': return 'Rejeitada';
                    default:
                        return status
                }
            },
            chargebackStatusClass(status) {
                switch (status) {
                    case "approved":
                        return "bg-green-500 text-white";
                    case "rejected":
                        return "bg-red-500 text-white";
                    case "requested":
                        return "bg-yellow-600 text-white";
                    case "pending":
                        return "bg-yellow-600 text-white";
                    default:
                        return "bg-blue-500 text-white";
                }
            },
        }
    }
</script>
