<template>
    <p class="text-base font-normal text-gray-500 dark:text-gray-200">
        <a
            v-if="cashout.status == 'approved'"
            @click="expand = !expand"
            class="hover:cursor-pointer inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
        >
            Retornar
            <svg
                class="ml-2 w-3 h-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                ></path>
            </svg>
        </a>
    </p>
    <modal
        size="sm"
        :open="expand"
        @close="expand = false"
    >
        <form class="flex flex-col flex-wrap w-full">
            <h3 class="text-lg dark:text-gray-200">Retornar saque</h3>
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200 mt-2">Motivo</label>
            <textarea
                v-model="reason"
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
                required
            ></textarea>
            <span
                v-for="error in errors"
                class="text-red-500 text-sm mt-5"
            >
                {{error[0]}}
            </span>
            <submit-button
                @click.prevent="submit"
                :loading="loading"
                class="mt-5"
            >
                Enviar
            </submit-button>
        </form>
    </modal>
</template>

<script>
    import {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    } from "@headlessui/vue";
    import FormToggle from "../../../components/forms/FormToggle.vue";
    import Modal from "../../../components/layouts/Modal.vue";
    import SubmitButton from "../../../components/forms/SubmitButton.vue";
    export default {
        components: {
            SubmitButton,
            Modal,
            DialogHeadless: Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            FormToggle
        },
        props: ['cashout'],
        emits: ['submit'],
        data() {
            return {
                errors: [],
                loading: false,
                expand: false,
                reason: ''
            }
        },

        methods: {
            submit() {
                this.loading = true;
                this.errors = [];
                axios.post('/api/cashout/' + this.cashout.id + '/return', {
                    reason: this.reason
                }).then((response) => {
                    this.loading = false;
                    this.expand = false;
                    this.$emit('submit');
                    return this.$toast.success('Saque retornado com sucesso');
                }).catch(({response}) => {
                    this.loading = false;
                    this.errors = response.data.errors;
                });
            }
        }
    }
</script>
