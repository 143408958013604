<template>
    <span
        class="rounded px-2 py-1.5 text-xs"
        :class="{
            'bg-green-700 text-white': status === 'pre-approved',
            'bg-green-500 text-white': status === 'approved',
            'bg-red-500 text-white': status === 'reproved',
            'bg-yellow-500 text-white': status === 'pending',
            'bg-white text-gray-900': status === 'draft',
            'bg-orange-500 text-white': status === 'pending-correction',
        }"
    >
        {{ statusName }}
    </span>
</template>

<script>
export default {
    props: ["status"],

    computed: {
        statusName() {
            switch (this.status) {
                case "draft":
                    return "Rascunho";
                case "approved":
                    return "Aprovado";
                case "pre-approved":
                    return "Pré Aprovado";
                case "reproved":
                    return "Reprovado";
                case "pending":
                    return "Pendente";
                case "pending-correction":
                    return "Correção Pendente";
                default:
                    return this.status;
            }
        },
    },
};
</script>
