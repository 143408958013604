<template>
    <setting-modal
        v-if="setting"
        :setting="setting"
        :open="modal"
        @fetch="
            modal = false;
            fetch();
        "
        @close="modal = false"
    />

    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="flex justify-between">
                <h3 class="mt-1 text-lg font-medium leading-6 dark:text-white">
                    Programa de Indicações
                </h3>
                <button
                    v-if="hasPermission('update.user_referrals')"
                    type="button"
                    class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-indigo-500 bg-indigo-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="modal = true"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-cog"
                        class="mr-1 dark:text-white"
                        aria-hidden="true"
                    />
                    Configurações
                </button>
            </div>
            <div class="py-4">
                <div>
                    <div
                        class="my-4 flex flex-col justify-between text-sm text-white"
                    >
                        <div class="my-4 flex flex-col">
                            <span
                                class="text-sm font-bold font-medium text-gray-600 dark:text-gray-400"
                            >
                                DESCRIÇÃO
                            </span>
                            <span class="text-gray-600 dark:text-gray-200">
                                {{ setting.description ?? "Não informado" }}
                            </span>
                        </div>
                        <div class="my-4 flex flex-col">
                            <span
                                class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                ><b>COMISSÃO PADRÃO</b></span
                            >
                            <span class="text-gray-600 dark:text-gray-200">
                                {{ setting.commission }}%</span
                            >
                        </div>
                        <div class="my-4 flex flex-col">
                            <span
                                class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                ><b>STATUS</b></span
                            >
                            <span class="pt-3 text-gray-600 dark:text-gray-200">
                                <referral-status :status="setting.status" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ReferralStatus from "../../components/layouts/ReferralStatus.vue";
import SettingModal from "../../components/referrals/SettingModal.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
export default {
    props: ["user"],

    components: {
        SettingModal,
        ReferralStatus,
    },

    mixins: [PermissionMixin],
    data() {
        return {
            setting: false,
            modal: false,
            loading: true,
            referrals: [],
        };
    },

    mounted() {
        this.fetch();
    },

    openModal() {
        this.edit_referral_modal = true;
    },

    closeModal() {
        this.edit_referral_modal = false;
    },

    closeAndFetchModal() {
        this.edit_referral_modal = false;
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get(`/api/user/${this.user.id}/referrals`)
                .then(({ data }) => {
                    this.setting = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
