<template>

</template>

<script>

    import AggsCard from "../../cashouts/AggsCard.vue";

    export default {
        components: {AggsCard},
        props: ['user'],

        data() {
            return {
                table: false,
                loading: false,
                clicks: [],
                pagination: {
                    total: 0,
                    per_page: 35,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
                aggs: {
                    browser: {},
                    city: {},
                    ip: {},
                    device: {}
                }
            }
        },
        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = {};
                params['page'] = this.pagination.current_page;

                axios.get('/api/user/' + this.user.id + '/clicks', {
                    params
                })
                    .then(({data}) => {
                        this.clicks = data.hits.hits;
                        this.aggs = data.aggregations

                        this.pagination.total = data.hits.total.value;
                        this.pagination.from = this.pagination.current_page * this.pagination.per_page - this.pagination.per_page + 1;
                        this.pagination.to = this.pagination.current_page * this.pagination.per_page;
                        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page);

                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                    });
            }
        }
    }
</script>
