<template>
    <div class="py-4">
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-dollar-sign" />
                Cobraça Fixa
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div style="width: 39%;" class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa fixa nacional (R$)</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{
                            $root.formatCurrency(settings.platform_fixed_fee)
                        }}</span
                    >
                </div>
                <div class="flex flex-col w-1/3 flex-grow">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa fixa internacional (R$)</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{
                            $root.formatCurrency(settings.international_fixed_fee)
                        }}</span
                    >
                </div>
            </div>
        </div>

        <div>
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-credit-card" />
                Cartão de Crédito e Dois Cartões
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Tempo para saque</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.credit_card_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa da plataforma</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_credit_card }}%</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa de juros para parcelamento</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.installment_fee_credit_card }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="space-x-2 text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-barcode" />
                Boleto
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Tempo para saque</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.bank_slip_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa da plataforma</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_bank_slip }}%</span
                    >
                </div>

                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa de juros para parcelamento</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.installment_fee_bank_slip }}%</span
                    >
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-brands fa-pix" />
                Pix
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Tempo para saque</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.pix_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa da plataforma</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.platform_fee_pix }}%</span
                    >
                </div>

                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                    >
                        <b>Taxa de juros para parcelamento</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200"> --- </span>
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-globe" />
                Pagamento Internacional
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Tempo para saque</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.international_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa da plataforma</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.international_platform_fee }}%</span
                    >
                </div>

                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                    >
                        <b>Taxa de juros para parcelamento</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200"> --- </span>
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa-solid fa-star" />
                Programa de Indicações
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Tempo para saque</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.referral_cashout_days }} dias</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                        ><b>Taxa da plataforma</b></span
                    >
                    <span class="text-gray-600 dark:text-gray-200"> --- </span>
                </div>

                <div class="flex flex-col">
                    <span
                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                    >
                        <b>Taxa de juros para parcelamento</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200"> --- </span>
                </div>
            </div>
        </div>
        <div class="my-6">
            <div class="text-sm font-bold uppercase text-gray-400">
                <font-awesome-icon icon="fa fa-money-bill-transfer" />
                Cashback
            </div>
            <div class="mt-1 flex justify-between text-sm text-white">
                <div style="width: 39%;" class="flex flex-col">
                    <span class="text-sm font-medium text-gray-600 dark:text-gray-400">
                        <b>Tipo de Taxa</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.cashback_type == 'percentage' ? "Percentual" : "Valor Fixo" }}
                    </span>
                </div>
                <div class="flex flex-col w-1/3 flex-grow">
                    <span class="text-sm font-medium text-gray-600 dark:text-gray-400">
                        <b>Valor da Taxa</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{ settings.cashback_fee }}
                    </span>
                </div>
                <div class="flex flex-col">
                    <span class="text-sm font-medium text-gray-600 dark:text-gray-400">
                        <b>Origem</b>
                    </span>
                    <span class="text-gray-600 dark:text-gray-200">
                        {{  settings.cashback_origin === 'platform' ? "Plataforma" : 
                            settings.cashback_origin === 'interest' ? "Juros" :
                            "Todos" }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["settings"],
};
</script>
