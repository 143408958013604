<template>
    <div class="my-5">
        <h3 class="mb-5 text-lg font-medium leading-6 dark:text-white">
            ViewAI - Planos
        </h3>
        <div class="grow">
            <SvgLoader v-if="this.loading" />

            <div class="mt-5 grid p-3 pl-0 sm:grid-cols-11">
                <button
                    :disabled="this.loading"
                    @click.prevent="openModalNew()"
                    :class="{ 'cursor-not-allowed': this.loading }"
                    class="mr-auto items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                >
                    <PlusIcon
                        class="text-white-200 mr-2 h-3 w-5 flex-shrink-0"
                        aria-hidden="true"
                    >
                    </PlusIcon>
                    Novo Plano
                </button>
            </div>

            <table-component
                :loading="loading"
                :pagination="pagination"
                :headers="[
                    'Nome do Plano',
                    'Descrição do Plano',
                    'Tipo de Cobrança',
                    'Intervalo de Pagamento',
                    'Dias de Carência',
                    'Valor do Plano',
                    'Corresponde a quais planos SmartPlayer',
                    'Ação',
                ]"
                @fetch="fetch"
            >
                <tr v-for="plan in plans">
                    <td class="table-cell-sm">
                        {{ plan.name }}
                    </td>
                    <td class="table-cell-sm">
                        {{ plan.description }}
                    </td>
                    <td class="table-cell-sm">
                        {{
                            plan.charge_type == "automatic-debit"
                                ? "Débito Automático"
                                : "Debitar da Transação"
                        }}
                    </td>
                    <td class="table-cell-sm">
                        {{ getPayment(plan.interval) }}
                    </td>
                    <td class="table-cell-sm">
                        {{ plan.cancelation_grace_days }}
                    </td>
                    <td class="table-cell-sm">
                        {{ $root.formatCurrency(plan.price) }}
                    </td>

                    <td class="table-cell-sm">
                        {{ getNames(plan.metadata.products) }}
                    </td>
                    <td class="table-cell-sm">
                        <button
                            @click.prevent="openModalToEdit(plan)"
                            :disabled="loading"
                            type="button"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="ml-2 inline-flex items-center justify-center rounded-md bg-yellow-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            <PencilIcon
                                class="text-white-400 mr-1 h-3 w-3 flex-shrink-0"
                                aria-hidden="true"
                            />Editar
                        </button>
                        <button
                            @click.prevent="deletePlan(plan.id)"
                            :disabled="loading"
                            type="button"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="ml-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            <TrashIcon
                                class="text-white-400 mr-1 h-3 w-3 flex-shrink-0"
                                aria-hidden="true"
                            />
                            Excluir
                        </button>
                    </td>
                </tr>
            </table-component>
        </div>
    </div>
    <modal class="min-h-full" size="xl" :open="show" @close="show = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Plano
        </h1>

        <form @submit.prevent="submit">
            <input
                v-model="form.id"
                type="hidden"
                name="id"
                id="id"
                class="text-input"
            />
            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Nome do Plano</label
                >
                <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    id="name"
                    class="text-input"
                    :maxlength="50"
                    required
                />
            </div>
            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Descrição do Plano</label
                >
                <input
                    v-model="form.description"
                    type="textarea"
                    name="description"
                    id="description"
                    class="text-input"
                    :maxlength="50"
                    required
                />
            </div>

            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Tipo de Cobrança</label
                >
                <select
                    v-model="form.charge_type"
                    :disabled="loading"
                    name="charge_type"
                    class="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                    <option value="automatic-debit">Debitar Automático</option>
                    <option value="transaction-debit">
                        Debitar da Transação
                    </option>
                </select>
            </div>

            <div
                class="mb-2 mr-4"
                v-if="form.charge_type === 'automatic-debit'"
            >
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Intervalo de Pagamento</label
                >
                <select
                    v-model="form.interval"
                    :disabled="loading"
                    name="interval"
                    class="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                >
                    <option value="1">Mensal</option>
                    <option value="2">Bimestral</option>
                    <option value="3">Trimestral</option>
                    <option value="4">Quadrimestral</option>
                    <option value="6">Semestral</option>
                    <option value="12">Anual</option>
                </select>
            </div>

            <div class="flex">
                <div class="mb-2 mr-4">
                    <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-white"
                        >Dias de Carência</label
                    >
                    <input
                        v-model="form.cancelation_grace_days"
                        type="number"
                        name="cancelation_grace_days"
                        id="cancelation_grace_days"
                        class="text-input"
                        required
                    />
                </div>
                <div class="mb-2">
                    <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-white"
                        >Dias para Cancelar SmartPlayer</label
                    >
                    <input
                        v-model="form.remove_grace_days"
                        type="number"
                        name="remove_grace_days"
                        id="remove_grace_days"
                        class="text-input"
                        required
                    />
                </div>
            </div>

            <div class="flex">
                <div class="mb-2 mr-4">
                    <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-white"
                        >Valor do Plano</label
                    >
                    <money-input v-model="form.price" name="price" />
                </div>
                <div class="mb-2">
                    <label
                        for="name"
                        class="mb-2 block text-sm font-medium text-white"
                        >Plano Recomendado?</label
                    >
                    <select
                        v-model="form.metadata.recommended"
                        :disabled="loading"
                        name="recommended"
                        class="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>

            <div class="mb-4 rounded-md border border-white p-4">
                <div class="flex">
                    <div class="w-1/1 mb-4 mr-4">
                        <label
                            for="name"
                            class="block text-sm font-medium text-white"
                            >Benefícios do Plano:</label
                        >
                        <div class="flex items-center">
                            <input
                                type="text"
                                v-model="nome"
                                id="name"
                                class="text-input"
                                placeholder="Digite um benefício.."
                                :maxlength="50"
                            />
                            <button
                                @click="adicionarNome"
                                type="button"
                                :class="{ 'cursor-not-allowed': this.loading }"
                                class="rounded-lg bg-gradient-to-r from-green-400 via-green-500 to-green-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
                <ul>
                    <li
                        v-for="(nome, index) in nomes"
                        :key="index"
                        style="list-style-type: square; color: white"
                    >
                        <label
                            for="name"
                            class="mb-2 block text-sm font-medium text-white"
                        >
                            {{ nome }}
                            <button
                                type="button"
                                @click="removerNome(index)"
                                :class="{ 'cursor-not-allowed': this.loading }"
                                class="mb-2 me-2 rounded-lg bg-gradient-to-r from-red-400 via-red-500 to-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
                            >
                                Remover
                            </button></label
                        >
                    </li>
                </ul>
            </div>

            <div class="mb-2">
                <label
                    for="name"
                    class="mb-2 block text-sm font-medium text-white"
                    >Corresponde a quais Planos Smart Player</label
                >

                <div
                    v-for="(plan_smart, index) in selected_smart_player"
                    :key="index"
                >
                    <input
                        type="checkbox"
                        @change="handleCheckboxChange(plan_smart)"
                        v-model="plan_smart.selected"
                    />
                    <label
                        class="text-sm font-medium text-white"
                        style="margin-left: 10px"
                    >
                        {{ plan_smart.name }} -
                        {{ plan_smart.recurrence == true ? "Mensal" : "Anual" }}
                        - {{ plan_smart.fullPriceValue }}</label
                    >
                </div>
            </div>

            <button
                :disabled="this.loading"
                type="submit"
                :class="{ 'cursor-not-allowed': this.loading }"
                class="mr-auto items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
            >
                Salvar
            </button>

            <button
                @click.prevent="closeModal()"
                type="button"
                :class="{ 'cursor-not-allowed': this.loading }"
                class="bg-red hover:bg-red mr-auto items-center rounded-md px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out"
            >
                Fechar Modal
            </button>
        </form>
    </modal>
</template>

<script>
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import MoneyInput from "../../../components/forms/MoneyInput.vue";
import Modal from "../../../components/layouts/Modal.vue";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import CreateTemplateModal from "../modals/CreateTemplateModal.vue";

export default {
    components: {
        SvgLoader,
        CreateTemplateModal,
        MoneyInput,
        TableComponent,
        Modal,
        PlusIcon,
        TrashIcon,
        PencilIcon,
    },
    data() {
        return {
            show: false,
            loading: false,
            plans: null,
            service_id: null,
            plans_smart_player: null,
            selected_smart_player: null,
            form: {
                id: null,
                name: null,
                description: null,
                price: null,
                cancelation_grace_days: null,
                remove_grace_days: null,
                interval: null,
                type: "smart-player",
                charge_type: "automatic-debit",
                metadata: {
                    products: [],
                    recommended: false,
                    benefits: [],
                },
            },
            templates: [],
            isModalOpen: false,
            templateId: null,
            nome: "",
            nomes: [],
        };
    },
    async mounted() {
        await this.fetchServices("viewai");
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    methods: {
        async fetchPlansSmartPlayer() {
            axios
                .get(`/api/v1/services/viewai/products`)
                .then((response) => {
                    const plansWithSelected = response.data.map((plan) => {
                        return { ...plan, selected: false };
                    });
                    this.plans_smart_player = plansWithSelected;
                })
                .catch((error) => console.error(error));
        },
        async fetchPlans(id) {
            axios
                .get(`/api/v1/services/${id}/plans`)
                .then((response) => {
                    this.plans = response.data;
                    this.fetchPlansSmartPlayer();
                    this.loading = false;
                })
                .catch((error) => console.error(error));
        },
        async fetchServices(type) {
            this.loading = true;
            axios
                .get(`/api/services/type/${type}`)
                .then((response) => {
                    this.service_id = response.data.id;
                    this.fetchPlans(response.data.id);
                })
                .catch((error) => console.error(error));
        },
        adicionarNome() {
            if (this.nome.trim() !== "") {
                this.nomes.push(this.nome);
                this.nome = ""; // Limpa o campo de entrada após adicionar o nome
            }
        },
        removerNome(index) {
            this.nomes.splice(index, 1);
        },
        getNames(metadata) {
            if (metadata) {
                return metadata.map(
                    (item) => `${item.name} - ${item.fullPriceValue}`,
                );
            }
        },
        handleCheckboxChange(plan_smart) {
            if (!plan_smart.description.includes("<ul>")) {
                let description = plan_smart.description.split("\n");
                let newArr = description.filter((item) => item.trim() !== "");

                if (plan_smart.selected) {
                    this.nomes = newArr;
                } else {
                    this.nomes = this.nomes.filter(
                        (nome) => !newArr.includes(nome),
                    );
                }
            } else {
                let description = plan_smart.description;
                const regex = /<li[^>]*>(.*?)<\/li>/g;
                let match;
                let newArr = [];

                while ((match = regex.exec(description)) !== null) {
                    newArr.push(match[1].trim());
                }

                if (!plan_smart.selected) {
                    this.nomes = this.nomes.filter(
                        (nome) => !newArr.includes(nome),
                    );
                } else {
                    this.nomes = newArr;
                }
            }
        },
        getPayment(interval) {
            if (interval !== null) {
                let payment = null;
                switch (interval) {
                    case 1:
                        payment = "Mensal";
                        break;

                    case 2:
                        payment = "Bimestral";
                        break;

                    case 3:
                        payment = "Trimestral";
                        break;

                    case 4:
                        payment = "Quadrimestral";
                        break;

                    case 6:
                        payment = "Semestral";
                        break;

                    case 12:
                        payment = "Anual";
                        break;

                    default:
                        payment = "-";
                        break;
                }
                return payment;
            } else {
                return "-";
            }
        },
        async openModalNew() {
            await this.fetchPlansSmartPlayer();

            setTimeout(() => {
                this.selected_smart_player = this.plans_smart_player;
                this.show = true;
            }, 1500);
        },
        async openModalToEdit(plan) {
            await this.fetchPlansSmartPlayer();
            let plansSmart = this.plans_smart_player;
            let planActualy = plan.metadata.products;

            plansSmart.forEach((mainItem) => {
                const otherItem = planActualy.find(
                    (obj) => obj.id === mainItem.id,
                );

                if (otherItem) {
                    mainItem.selected = otherItem.selected;
                }
            });

            this.form.id = plan.id;
            this.form.charge_type = plan.charge_type;
            this.form.name = plan.name;
            this.form.interval = plan.interval;
            this.form.description = plan.description;
            this.form.price = plan.price / 100;
            this.selected_smart_player = plansSmart;
            this.form.cancelation_grace_days = plan.cancelation_grace_days;
            this.form.remove_grace_days = plan.remove_grace_days;
            this.form.metadata.recommended = plan.metadata.recommended;
            this.nomes = plan.metadata.benefits ?? [];
            this.show = true;
        },

        closeModal() {
            this.form.id = null;
            this.form.charge_type = null;
            this.form.name = null;
            this.form.price = null;
            this.form.description = null;
            this.form.interval = null;
            this.form.cancelation_grace_days = null;
            this.form.metadata.products = [];
            this.selected_smart_player = this.plans_smart_player;
            this.form.remove_grace_days = null;
            this.form.metadata.recommended = false;
            this.nomes = [];
            this.show = false;
        },

        deletePlan(id) {
            axios
                .delete(`/api/v1/plans/${id}`)
                .then(({ data }) => {
                    if (data) {
                        this.toast.success("Plano deletado com sucesso");
                        this.fetchServices("viewai");
                        this.closeModal();
                    }
                })
                .catch(
                    ({
                        response: {
                            data: { message },
                        },
                    }) => {
                        this.toast.error(
                            "Erro ao excluir plano! Tem usuários vinculados a este plano!",
                        );
                    },
                );
        },
        submit() {
            const selectedItems = this.selected_smart_player.filter(
                (item) => item.selected,
            );
            if (selectedItems.length === 0) {
                this.toast.error(
                    "Obrigatório selecionar ao menos um plano do Smart Player!",
                );
                return;
            }

            if (this.form.price === 0 || this.form.price === null) {
                this.toast.error("Obrigatório informar o valor do plano!");
                return;
            }

            if (this.form.charge_type === null) {
                this.toast.error("Obrigatório informar o tipo de cobrança!");
                return;
            }

            if (
                this.form.charge_type === "automatic-debit" &&
                this.form.interval === null
            ) {
                this.toast.error(
                    "Obrigatório informar o intervalo de pagamento!",
                );
                return;
            }

            this.form.price = this.form.price * 100;

            this.form.metadata.products = selectedItems;
            if (this.form.charge_type != "automatic-debit") {
                this.form.interval = null;
            } else {
                this.form.interval = Number(this.form.interval);
            }

            this.form.metadata.benefits = this.nomes;
            if (this.form.id === null) {
                //salvar
                axios
                    .post(
                        `/api/v1/services/${this.service_id}/plans`,
                        this.form,
                    )
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success("Plano cadastrado com sucesso");
                            this.fetchServices("viewai");
                            this.closeModal();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        },
                    );
            } else {
                //editar
                axios
                    .put(`/api/v1/plans/${this.form.id}`, this.form)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success("Plano atualizado com sucesso");
                            this.fetchServices("viewai");
                            this.closeModal();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        },
                    );
            }
        },
    },
};
</script>
