<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-10 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block transform overflow-hidden rounded-lg bg-gray-700 px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
                    >
                        <SvgLoader v-if="this.loading" />
                        <form @submit.prevent="handleSave" v-else>
                            <div class="grid sm:grid-cols-1 sm:gap-5">
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mt-3 text-center sm:mt-0 sm:text-left"
                                    >
                                        <dialog-title
                                            as="h3"
                                            class="text-lg font-medium leading-6 text-white"
                                        >
                                            <p v-if="this.shippingProviderId">
                                                Editar transportadora
                                            </p>
                                            <p v-else>
                                                Cadastrar transportadora
                                            </p>
                                        </dialog-title>
                                    </div>
                                </div>
                                <div class="grid sm:grid-cols-1">
                                    <label for="name" class="dark:text-white">
                                        Nome
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        v-model="form.name"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Nome da Transportadora"
                                        required
                                    />
                                </div>
                                <div class="grid sm:grid-cols-1">
                                    <label for="slug" class="dark:text-white">
                                        Slug
                                    </label>
                                    <input
                                        id="slug"
                                        name="slug"
                                        v-model="form.slug"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Nome para referência interna"
                                        required
                                    />
                                </div>
                                <div class="grid sm:grid-cols-1">
                                    <label
                                        for="tracking_url"
                                        class="dark:text-white"
                                    >
                                        Tracking URL
                                    </label>
                                    <input
                                        type="url"
                                        id="tracking_url"
                                        name="tracking_url"
                                        v-model="form.tracking_url"
                                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        placeholder="Link de busca da transportadora"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="mt-3 grid gap-5 sm:grid-cols-2">
                                <button
                                    type="submit"
                                    class="mb-3 inline-flex w-full justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
                                >
                                    Confirmar
                                </button>

                                <button
                                    type="button"
                                    class="mb-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useToast } from "vue-toastification";
import VueSelect from "../../../components/forms/VueSelect.vue";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        VueSelect,
        QuillEditor,
        SvgLoader,
    },
    props: ["shippingProviderId"],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            form: {
                name: null,
                slug: null,
                tracking_url: null,
            },
            template: null,
        };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        fetchShippingProviders() {
            this.$emit("fetchShippingProviders");
        },
        handleSave() {
            if (!this.shippingProviderId) {
                axios
                    .post(`/api/shipping-providers`, this.form)
                    .then((response) => {
                        if (response) {
                            this.toast.success(
                                "Dados da transportadora salvos com sucesso!",
                            );
                            this.fetchShippingProviders();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        let message = error.response.data.errors
                            .map((error) => error)
                            .join(",");
                        this.toast.error(
                            message ?? "Erro ao salvar dados da transportadora",
                        );
                    });
            } else {
                axios
                    .put(
                        `/api/shipping-providers/${this.shippingProviderId}`,
                        this.form,
                    )
                    .then((response) => {
                        if (response) {
                            this.toast.success(
                                "Dados da transportadora salvos com sucesso!",
                            );
                            this.fetchShippingProviders();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        let message = error.response.data.errors
                            .map((error) => error)
                            .join(",");
                        this.toast.error(
                            message ?? "Erro ao salvar dados da transportadora",
                        );
                    });
            }
        },
        findOne(id) {
            this.loading = true;
            axios
                .get(`/api/shipping-providers/${id}`)
                .then((response) => {
                    this.shippingProvider = response.data;
                    this.form.name = this.shippingProvider.name;
                    this.form.slug = this.shippingProvider.slug;
                    this.form.tracking_url = this.shippingProvider.tracking_url;
                })
                .catch((error) => {
                    this.toast.error("Erro ao editar dados da transportadora");
                })
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        if (this.shippingProviderId) {
            this.findOne(this.shippingProviderId);
        }
    },
};
</script>
