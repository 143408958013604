<template>
    <div class="flex flex-col my-3 mt-0">
        <activity-logs-modal
            :resource="resourceToShow"
            :storeProduct="storeProduct"
            type="App\Models\Lesson"
            @resetResource="resourceToShow = null;"
        />
        <filters
            class="my-3 mt-0"
            :loading="loading"
            v-model="filters"
            dateFilter
            search-filter
            search-label="Filtre pelo nome ou ID"
            @fetch="
                lessons = [];
                next_page_url = false;
                this.pagination.current_page = 1;
                fetch();
            "
        >
        </filters>
        <table-component
            :loading="loading"
            :pagination="pagination"
            :headers="headers"
            @fetch="fetch"
            :selected="selected"
            :all-selected="all_selected"
        >
            <tr v-for="lesson in lessons" :key="lesson.id">
                <td class="table-cell-sm">
                    <span
                        :title="lesson.id"
                        class="block max-w-[80px] truncate"
                    >
                        #{{ lesson.id }}
                    </span>
                </td>
                <td class="table-cell-sm">
                    <span
                        :title="lesson.name"
                        class="block max-w-[80px] truncate"
                        v-tooltip="lesson.name"
                    >
                        {{ lesson.name }}
                    </span>
                </td>
                <td class="table-cell-sm">
                    <span
                        :title="lesson.updated_at"
                        class="block max-w-[80px]"
                    >
                        {{ lesson.updated_at }}
                    </span>
                </td>
                <td>
                    <div>
                        <button
                            type="button"
                            @click.prevent="showResource(lesson)"
                            class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                        >
                            Logs
                        </button>
                    </div>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import BackButton from "../../../../components/layouts/navigation/BackButton.vue";
import NavTabs from "../../../../components/layouts/NavTabs.vue";
import Filters from "../../../../components/layouts/Filters.vue";
import ActivityLogsModal from "../../modals/ActivityLogsModal.vue";
import TableComponent from "../../../../components/layouts/table/TableComponent.vue";
import { mapActions, mapWritableState } from "pinia";
import { Store } from "../../../../stores/store.js";
import UserHeading from "../../../users/UserHeading.vue";
export default {
    name: "ProductStudents",
    components: { BackButton, NavTabs, UserHeading, ActivityLogsModal, Filters, TableComponent },
    data() {
        return {
            loading: false,
            lessons: [],
            resourceToShow: null,
            next_page_url: false,
            selected: [],
            all_selected: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                types: [],
                date: [
                    moment().subtract(1, 'month').format('DD/MM/YYYY'),
                    moment().format('DD/MM/YYYY')
                ]
            },
        }
    },
    computed: {
        ...mapWritableState(Store, ["storeProduct", "permissions"]),
        headers() {
            return [
                "ID",
                "Nome",
                "Última atualização",
                "Ações",
            ];
        },
    },
    async mounted() {
        if (!this.storeProduct) {
            await this.fetchProduct();
        }

        this.fetch();
    },
    methods: {
        ...mapActions(Store, ["fetchProduct"]),
        showResource(resource) {
            this.resourceToShow = resource;
        },
        fetch() {
            let params = this.filters;
            params["page"] = this.pagination.current_page;
            this.loading = true;

            axios.get('/api/product/' + this.storeProduct.id + '/mozart-lessons?with-trashed=true', {
                params: params
            }).then(({data}) => {
                this.lessons = data.data;

                this.pagination = {
                    ...this.pagination,
                    total: data.total,
                    per_page: data.per_page,
                    current_page: data.current_page,
                    last_page: data.last_page,
                    from: data.from,
                    to: data.to
                };
                this.next_page_url = data.next_page_url;
                this.loading = false;
            })
        },
    }
}
</script>
