<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog
                as="div"
                class="fixed inset-0 z-40 flex md:hidden"
                @close="sidebarOpen = false"
            >
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75"
                    />
                </TransitionChild>
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <div
                        class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800"
                    >
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute top-0 right-0 pt-2 -mr-12">
                                <button
                                    type="button"
                                    class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    @click="sidebarOpen = false"
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <XMarkIcon
                                        class="w-6 h-6 text-white"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex items-center flex-shrink-0 px-4">
                            <img
                                class="w-auto h-8"
                                src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                                alt="Workflow"
                            />
                        </div>
                        <div class="flex-1 h-0 mt-5 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <!--                                <router-link-->
                                <!--                                    v-for="item in navigation"-->
                                <!--                                    :key="item.name"-->
                                <!--                                    :to="item.route"-->
                                <!--                                    :class="[-->
                                <!--                                        item.current-->
                                <!--                                            ? 'bg-gray-900 text-white'-->
                                <!--                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',-->
                                <!--                                        'group flex items-center rounded-md px-2 py-2 text-base font-medium',-->
                                <!--                                    ]"-->
                                <!--                                >-->
                                <!--                                    <component-->
                                <!--                                        :is="item.icon"-->
                                <!--                                        :class="[-->
                                <!--                                            item.current-->
                                <!--                                                ? 'text-gray-300'-->
                                <!--                                                : 'text-gray-400 group-hover:text-gray-300',-->
                                <!--                                            'mr-4 h-6 w-6 flex-shrink-0',-->
                                <!--                                        ]"-->
                                <!--                                        aria-hidden="true"-->
                                <!--                                    />-->
                                <!--                                    {{ item.name }}-->
                                <!--                                </router-link>-->
                            </nav>
                        </div>
                    </div>
                </TransitionChild>
                <div class="flex-shrink-0 w-14" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden md:fixed md:inset-y-0 md:flex md:w-48 md:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-1 min-h-0 bg-gray-800">
                <div
                    class="flex items-center flex-shrink-0 h-16 px-4 bg-gray-900"
                >
                    <img
                        class="w-auto h-8"
                        src="https://d2ucq9llvgnbyn.cloudfront.net/signature-white.png"
                        alt="Workflow"
                    />
                </div>
                <div class="flex flex-col flex-1 overflow-y-auto">
                    <nav class="flex-1 px-2 py-4 space-y-1">
                        <div
                            v-if="!permissions"
                            v-for="item in navigation"
                            class="flex items-center px-1 py-1 text-sm rounded-md font-small group animate-pulse"
                        >
                            <div
                                class="w-5 h-5 bg-gray-500 rounded-full animate-pulse"
                            ></div>
                            <div
                                class="flex-grow h-2 ml-4 bg-gray-500 rounded animate-pulse"
                            ></div>
                        </div>
                        <router-link
                            v-else
                            v-for="item in navigation"
                            :key="item.name"
                            :to="item.route"
                            class="flex items-center px-1 py-1 text-sm rounded-md font-small group"
                            active-class="text-white text-gray-300 bg-gray-900 hover:bg-gray-700 hover:text-white"
                            :class="[
                                item.prefix && isRouteActive(item.prefix)
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                !hasPermission(item.permission) ? 'hidden' : '',
                            ]"
                        >
                            <component
                                :is="item.icon"
                                :class="[
                                    item.current
                                        ? 'text-gray-300'
                                        : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-3 h-6 w-6 flex-shrink-0',
                                ]"
                                aria-hidden="true"
                            />
                            {{ item.name }}
                        </router-link>
                    </nav>
                </div>
            </div>
        </div>
        <div class="flex flex-col md:pl-48">
            <div
                class="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-gray-800 shadow"
            >
                <button
                    type="button"
                    class="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    @click="sidebarOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <Bars3Icon class="w-6 h-6" aria-hidden="true" />
                </button>
                <div class="flex justify-between flex-1 px-4">
                    <div class="flex flex-1 bg-gray-700">
                        <form
                            class="flex w-full bg-gray-700 md:ml-0"
                            action="#"
                            method="GET"
                        >
                            <label for="search-field" class="sr-only"
                                >Search</label
                            >
                            <div
                                class="relative w-full text-gray-400 focus-within:text-gray-600"
                            >
                                <div
                                    class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                                >
                                    <MagnifyingGlassIcon
                                        class="w-5 h-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                <input
                                    id="search-field"
                                    class="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 bg-gray-800 border-transparent focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 dark:text-white sm:text-sm"
                                    placeholder="Search"
                                    type="search"
                                    name="search"
                                />
                            </div>
                        </form>
                    </div>
                    <div class="flex items-center ml-4 md:ml-6">
                        <button
                            type="button"
                            @click="dark = !dark"
                            class="p-1 text-gray-400 bg-gray-800 rounded-full hover:text-white focus:outline-none sm:mr-2"
                        >
                            <span class="sr-only">View notifications</span>
                            <component
                                :is="dark ? 'SunIcon' : 'MoonIcon'"
                                class="w-6 h-6"
                                aria-hidden="true"
                            />
                        </button>

                        <button
                            type="button"
                            class="p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="w-6 h-6" aria-hidden="true" />
                        </button>

                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative ml-3">
                            <div>
                                <MenuButton
                                    class="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <img
                                        class="w-8 h-8 rounded-full"
                                        :src="
                                            authUser.avatar ||
                                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                                authUser.name
                                        "
                                        alt=""
                                    />
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="transform scale-95 opacity-0"
                                enter-to-class="transform scale-100 opacity-100"
                                leave-active-class="transition duration-75 ease-in"
                                leave-from-class="transform scale-100 opacity-100"
                                leave-to-class="transform scale-95 opacity-0"
                            >
                                <MenuItems
                                    class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <MenuItem
                                        v-for="item in userNavigation"
                                        :key="item.name"
                                        v-slot="{ active }"
                                    >
                                        <router-link
                                            v-if="item.name !== 'Sair'"
                                            :to="item.route"
                                            active-class="bg-gray-100"
                                            class="block px-4 py-2 text-sm text-gray-700"
                                        >
                                            {{ item.name }}
                                        </router-link>
                                        <button
                                            v-else
                                            @click.prevent="logout"
                                            class="block px-4 py-2 text-sm text-gray-700"
                                        >
                                            Sign Out
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <main class="flex-1">
                <div class="py-1">
                    <div class="px-4 mx-auto max-w-7xl sm:px-4">
                        <ticto-loading v-if="!permissions" />
                        <router-view v-show="permissions" />
                    </div>
                </div>
            </main>
        </div>

        <export-progress-card />
    </div>
</template>

<script>
import PermissionMixin from "../../mixins/PermissionMixin.vue";

import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    BanknotesIcon,
    Bars3Icon,
    BellIcon,
    ChartBarIcon,
    FolderIcon,
    InboxIcon,
    MagnifyingGlassIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/vue/20/solid";
import {
    AcademicCapIcon,
    BriefcaseIcon,
    ChartBarSquareIcon,
    ChatBubbleLeftEllipsisIcon,
    Cog8ToothIcon,
    CurrencyDollarIcon,
    DevicePhoneMobileIcon,
    ExclamationTriangleIcon,
    MoonIcon,
    SunIcon,
    VideoCameraIcon
} from "@heroicons/vue/24/outline";
import { Square3Stack3DIcon } from "@heroicons/vue/24/solid";
import { mapActions, mapWritableState } from "pinia";
import ExportProgressCard from "../../components/export/ExportProgressCard.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import ColSkeleton from "../../components/layouts/skeleton/ColSkeleton.vue";
import { Store } from "../../stores/store";
import permissions from "../users/permissions/Permissions.vue";

export default {
    mixins: [PermissionMixin],
    components: {
        TictoLoading,
        ColSkeleton,
        ExportProgressCard,
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        BellIcon,
        Bars3Icon,
        MagnifyingGlassIcon,
        XMarkIcon,
        MoonIcon,
        SunIcon,
        BanknotesIcon,
    },

    data() {
        return {
            sidebarOpen: false,
            navigation: [
                {
                    name: "Usuários",
                    route: { name: "users" },
                    icon: UsersIcon,
                    permission: "view.users",
                    prefix: "/user",
                },
                {
                    name: "Compliance",
                    route: { name: "compliance" },
                    icon: ChatBubbleLeftEllipsisIcon,
                    permission: "view.users",
                    prefix: "/compliance",
                },
                {
                    name: "Produtos",
                    route: { name: "products" },
                    permission: "view.products",
                    icon: Square3Stack3DIcon,
                    prefix: "/product",
                },
                {
                    name: "Vendas",
                    route: { name: "orders" },
                    permission: "view.orders",
                    icon: ChartBarIcon,
                    prefix: "/order",
                },
                {
                    name: "Emails",
                    route: { name: "emails" },
                    permission: "view.emails",
                    icon: InboxIcon,
                    prefix: "/email",
                },
                {
                    name: "Analytics",
                    route: { name: "analytics.orders" },
                    permission: "view.analytics",
                    icon: ChartBarSquareIcon,
                    prefix: "/analytic",
                },
                {
                    name: "Financeiro",
                    route: { name: "finnancial.dashboard" },
                    permission: "view.bankAccounts",
                    icon: CurrencyDollarIcon,
                    prefix: "/financial",
                },
                {
                    name: "Blacklist",
                    route: { name: "blacklist.index" },
                    permission: "view.blacklist",
                    icon: XMarkIcon,
                },
                {
                    name: "Vitrine",
                    route: { name: "showcase.recommended" },
                    permission: "view.showcase",
                    icon: FolderIcon,
                },
                {
                    name: "Serviços",
                    route: { name: "services" },
                    permission: "view.services",
                    icon: BriefcaseIcon,
                },
                {
                    name: "Ranking",
                    route: { name: "ranking.ranking" },
                    permission: "view.services",
                    icon: ChartBarIcon,
                },
                {
                    name: "Avisos",
                    route: { name: "notices" },
                    permission: "view.notices",
                    icon: ExclamationTriangleIcon,
                },
                {
                    name: "Gerentes",
                    route: { name: "account-managers" },
                    permission: "view.managers",
                    icon: AcademicCapIcon,
                },
                {
                    name: "Mobile",
                    route: { name: "mobile" },
                    permission: "view.users",
                    icon: DevicePhoneMobileIcon,
                },
                {
                    name: "Vídeos",
                    route: { name: "videos" },
                    permission: "view.users",
                    icon: VideoCameraIcon,
                },
                {
                    name: "Configurações",
                    route: { name: "config" },
                    permission: "view.products",
                    icon: Cog8ToothIcon,
                },
                {
                    name: "Auditoria",
                    route: { name: "audit" },
                    permission: "view.audits",
                    icon: MagnifyingGlassIcon,
                },
                {
                    name: "Sellers",
                    route: { name: "sellers" },
                    permission: "view.audits",
                    icon: BanknotesIcon,
                },
                {
                    name: "Antifraude",
                    route: { name: "antifraud" },
                    permission: "view.antifraud",
                    icon: Cog8ToothIcon,
                },
                {
                    name: "Traduções",
                    route: { name: "translations" },
                    permission: "view.translations",
                    icon: Cog8ToothIcon,
                },
                // { name: "Projects", href: "#", icon: FolderIcon, current: false },
                // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
                // { name: "Documents", href: "#", icon: InboxIcon, current: false },
                // { name: "Reports", href: "#", icon: ChartBarIcon, current: false },
            ],
            userNavigation: [
                {
                    name: "Seu Perfil",
                    route: { name: "finnancial.dashboard" },
                },
                {
                    name: "Configurações",
                    route: { name: "finnancial.dashboard" },
                },
                {
                    name: "Sair",
                    route: { name: "finnancial.dashboard" },
                },
            ],
        };
    },

    computed: {
        permissions() {
            return permissions;
        },
        ...mapWritableState(Store, [
            "authUser",
            "dark",
            "permissions",
            "token",
        ]),
        hasUser() {
            return this.authUser !== false;
        },
    },

    async mounted() {
        if (!this.authUser) {
            await this.fetchAuthUser();
        }
    },

    methods: {
        ...mapActions(Store, ["fetchAuthUser", "logout"]),
        isRouteActive(prefix) {
            if (!prefix) {
                return false;
            }
            return this.$route.path.startsWith(prefix);
        },
    },
};
</script>
