<template>
    <dl class="my-3 grid grid-cols-2 gap-2 sm:grid-cols-2">
        <highcharts
            v-if="!loading && lineChart"
            class="hc"
            :options="lineChart"
            ref="chart"
        ></highcharts>
        <highcharts
            v-if="!loading && barChart"
            class="hc"
            :options="barChart"
            ref="chart"
        ></highcharts>
    </dl>
</template>

<script>
import LineChart from "../analytics/charts/LineChart.vue";

export default {
    components: { LineChart },
    props: ["dates", "loading"],

    data() {
        return {
            lineChart: false,
            barChart: false,
            selfLoading: true,
            series: [],
        };
    },

    watch: {
        dates() {
            this.fetch();
        },
    },

    methods: {
        chartObject() {
            return {
                tooltip: {
                    formatter: function () {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return (
                                    `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` +
                                    point.y.toLocaleString("pt-br")
                                );
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: "%d/%m/%Y",
                    shared: true,
                    split: false,
                    enabled: true,
                },

                credits: {
                    enabled: false,
                },

                legend: {
                    enabled: true,
                },

                title: {
                    align: "left",
                    text: null,
                },

                labels: {
                    format: "{value:%l:%M %p }",
                },

                xAxis: {
                    type: "datetime",
                },

                yAxis: [
                    {
                        // Primary yAxis
                        title: {
                            text: "Regras",
                        },
                    },
                ],

                series: [],
            };
        },
        fetch() {
            this.series = [];
            this.selfLoading = true;
            const passed = {
                type: "spline",
                name: "Limpo",
                lineWidth: 3,
                data: [],
            };
            const blocked = {
                type: "spline",
                name: "Bloqueado",
                lineWidth: 3,
                data: [],
            };

            const series = [];

            const rules = Object.entries(this.dates.failed_checks).map(
                (failedCheck) => {
                    const failedCheckSeries = {
                        type: "spline",
                        name: failedCheck[0],
                        lineWidth: 3,
                        data: [],
                    };

                    failedCheck[1].map((bucket) => {
                        failedCheckSeries.data.push([
                            bucket.unix,
                            bucket.count,
                        ]);
                    });

                    return failedCheckSeries;
                },
            );

            let barChart = this.chartObject();
            barChart.series.push(...rules);
            this.barChart = barChart;

            Object.entries(this.dates.day).forEach(([key, value]) => {
                value.map((item) => {
                    item.passed.buckets.map((bucket) => {
                        if (bucket.key_as_string === "true") {
                            passed.data.push([item.key, bucket.doc_count]);
                        } else {
                            blocked.data.push([item.key, bucket.doc_count]);
                        }
                    });
                });
            });
            series.push(passed, blocked);

            this.series = series;

            let chart = this.chartObject();
            chart.series.push(...this.series);
            this.lineChart = chart;

            this.selfLoading = false;
        },
    },
};
</script>
