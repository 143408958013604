<template>
    <modal :open="open" @close="$emit('close')" size="4xl">
        <div class="mb-5 grid grid-cols-4">
            <info-col title="External Id.">
                <span
                    v-tooltip="chargeback.external_id"
                    class="block max-w-[100px] truncate"
                >
                    {{ chargeback.external_id }}
                </span>
            </info-col>
            <info-col title="Cartão">
                <div class="flex items-center">
                    <card-brand-icon :brand="chargeback.data.source" />
                    {{ chargeback.card }}
                </div>
            </info-col>
            <info-col title="Aut Em:">
                <div class="flex items-center">
                    {{ chargeback.authorized_at }}
                </div>
            </info-col>
            <info-col title="Valor:">
                <div class="flex items-center">
                    {{ $root.formatCurrency(chargeback.amount) }}
                </div>
            </info-col>
        </div>
        <div class="mb-3 flex items-end space-x-2">
            <text-input
                v-model="form.first_6"
                ref="first6Input"
                :maxlength="6"
                label="First 6"
            />
            <text-input
                v-model="form.last_4"
                ref="last4Input"
                :maxlength="4"
                label="Last 4"
            />

            <input
                class="text-input-sm max-w-[200px]"
                type="date"
                v-model="form.date"
            />

            <submit-button @click.prevent="fetch" :loading="loading">
                Buscar
            </submit-button>
            <submit-button
                v-if="selected"
                @click.prevent="submit"
                :loading="loading"
            >
                Salvar
            </submit-button>
        </div>

        <table-component
            :loading="loading"
            :headers="[
                '',
                'Data',
                'Pedido',
                'Valor',
                'First 6',
                'Last 4',
                'Bandeira',
                'Gateway',
            ]"
            :pagination="pagination"
        >
            <tr v-for="transaction in transactions">
                <td class="table-cell-sm">
                    <div
                        class="h-4 w-4 cursor-pointer rounded-full border-2 border-gray-300"
                        @click.prevent="selected = transaction.id"
                        :class="{
                            'border-indigo-800 bg-indigo-400':
                                selected === transaction.id,
                        }"
                    ></div>
                    <input
                        type="radio"
                        :value="transaction.id"
                        v-model="selected"
                        class="hidden"
                    />
                </td>
                <td class="table-cell-sm">
                    {{ transaction.created_at }}
                </td>
                <td class="table-cell-sm">
                    <router-link
                        :to="{
                            name: 'user.order',
                            params: { order_id: transaction.order_id },
                        }"
                        class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                        target="_blank"
                    >
                        #{{ transaction.order_id }}
                    </router-link>
                </td>
                <td class="table-cell-sm">
                    {{ $root.formatCurrency(transaction.paid_amount) }}
                </td>
                <td class="table-cell-sm">
                    {{ transaction.card.first_6 }}
                </td>
                <td class="table-cell-sm">
                    {{ transaction.card.last_4 }}
                </td>
                <td class="table-cell-sm">
                    <card-brand-icon :brand="transaction.card.brand" />
                </td>
                <td class="table-cell-sm">
                    <gateway-icon :gateway="transaction.gateway" />
                </td>
            </tr>
        </table-component>
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import TextInput from "../../forms/TextInput.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import TableComponent from "../../layouts/table/TableComponent.vue";
import PaginationObject from "../../../mixins/PaginationObject.vue";
import CardBrandIcon from "../../layouts/CardBrandIcon.vue";
import GatewayIcon from "../../layouts/icons/GatewayIcon.vue";
import InfoCol from "../../layouts/InfoCol.vue";

export default {
    mixins: [PaginationObject],
    props: ["open", "chargeback"],
    components: {
        InfoCol,
        GatewayIcon,
        CardBrandIcon,
        TableComponent,
        SubmitButton,
        TextInput,
        Modal,
    },
    emits: ["close", "fetch"],

    data() {
        return {
            loading: false,
            form: {
                first_6: "",
                last_4: "",
                date: "",
            },

            selected: false,
            transactions: [],
        };
    },

    mounted() {
        this.form.first_6 = this.chargeback.card.slice(0, 6);
        this.form.last_4 = this.chargeback.card.slice(-4);
        this.form.date = this.chargeback.authorized_at;
    },

    watch: {
        open() {
            if (this.open) {
                console.log({ cb: this.chargeback})
                this.fetch();
            }
        },
        "form.first_6"(val) {
            if (!this.open) {
                return;
            }

            if (val.length === 6) {
                this.$nextTick(() => {
                    this.$refs.last4Input.$el.querySelector("input").focus();
                });
            }
        },
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get("/api/pre-chargebacks/transaction-lookup", {
                    params: {
                        first_6: this.form.first_6,
                        last_4: this.form.last_4,
                        authorized_at: this.form.date,
                    },
                })
                .then(({ data }) => {
                    this.transactions = data.data;
                    this.setPagination(data);
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;

            axios
                .put("/api/pre-chargebacks/" + this.chargeback.id, {
                    transaction_id: this.selected,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.$emit("fetch");
                });
        },
    },
};
</script>
