<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <template v-if="!disableSelect">
            <label class="text-sm font-bold text-gray-600 dark:text-gray-400">
                Usuário
            </label>
            <users-select
                class="dark:text-white"
                v-model="form.user_id"
                :multiple="false"
            />
        </template>
        <div class="col-span-4 my-2 flex flex-col">
            <span class="text-sm font-bold text-gray-600 dark:text-gray-400">
                Motivo
            </span>
            <textarea
                class="rounded-lg bg-gray-100 p-4 text-gray-600 dark:bg-slate-900 dark:text-gray-200"
                v-model="form.reason"
                :disabled="loading"
            ></textarea>
        </div>

        <p class="text-sm font-bold text-gray-600 dark:text-gray-400">
            Bloquear
        </p>
        <form-toggle
            v-model="form.block_cashout"
            :loading="loading"
            label="Saques"
        >
        </form-toggle>

        <form-toggle
            class="ml-3"
            v-model="form.block_products"
            :loading="loading"
            label="Produtos"
        >
        </form-toggle>

        <submit-button
            :loading="loading"
            class="ml-auto"
            :disabled="loading || form.user_id.length < 1"
        >
            Salvar
        </submit-button>
    </form>
</template>

<script>
import { vMaska } from "maska";
import SubmitButton from "../../forms/SubmitButton.vue";
import UsersSelect from "../../forms/UsersSelect.vue";
import FormToggle from "../../forms/FormToggle.vue";
export default {
    props: { audit: { default: null }, userId: { default: null } },
    directives: { maska: vMaska },
    components: { FormToggle, UsersSelect, SubmitButton },
    emits: ["fetch"],
    data() {
        return {
            show: false,
            loading: false,
            form: {
                user_id: "",
                reason: "",
                block_cashout: true,
                block_products: true,
            },
            disableSelect: false,
        };
    },

    mounted() {
        if (this.audit) {
            this.form = {
                user_id: [this.audit.user_id],
                reason: this.audit.reason,
            };
        }

        if (this.userId) {
            this.form.user_id = this.userId;
            this.disableSelect = true;
        }
    },

    methods: {
        submit() {
            this.loading = true;
            let promise = this.audit
                ? axios.put(`/api/audit/${this.manager.uuid}`, this.form)
                : axios.post("/api/audit", this.form);

            promise
                .then((response) => {
                    this.loading = false;
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
