<template>
    <div class="mb-3 flex flex-col dark:text-white" v-if="balance">
        <span>
            Valor Total (Disponível + Pendente) =
            {{ $root.formatCurrency(balance.total_balance) }}
        </span>
        <span>
            Valor Sacado =
            {{ $root.formatCurrency(balance.cashout) }}
        </span>
        <span>
            Total + Sacado =
            {{ $root.formatCurrency(balance.sum) }}
        </span>
        <span>
            <br/>
        </span>
        <span>
            Comissões (Autorizadas + Reembolsos Solicitados) =
            {{ $root.formatCurrency(balance.approved_commissions) }}
        </span>
        <span>
            Taxas de Antecipações =
            {{ $root.formatCurrency(balance.anticipation_fee) }}
        </span>
        <span>
            Reembolsos de Outros Comissionados =
            {{ $root.formatCurrency(balance.refunded_from_others) }}
        </span>
        <span>
            Comissões - Taxas - Reembolsos =
            {{ $root.formatCurrency(balance.sub) }}
        </span>
        <span>
            <br/>
        </span>
        <span>
            Resultado =
            {{ $root.formatCurrency(balance.result) }}
        </span>
    </div>
    <h1
        class="text-md mb-2 flex flex-row flex-wrap content-center items-center font-bold dark:text-white"
    >
        Saques
    </h1>
    <dl class="my-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
            >
                Saques
            </p>
            <p
                class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
            >
                {{ cards.total }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
            >
                Total Solicitado
            </p>
            <p
                class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
            >
                {{ $root.formatCurrency(cards.total_amount) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
            >
                Total Líquido
            </p>
            <p
                class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
            >
                {{ $root.formatCurrency(cards.net_value ?? 0) }}
            </p>
        </card>
    </dl>
    <table-component
        :loading="loading"
        :headers="[
            'Data',
            'Favorecido',
            'Conta',
            'Pessoa',
            'Valor Solicitado',
            'Valor a Receber',
            'Status',
        ]"
        :pagination="pagination"
    >
        <tr v-for="cashout in cashouts">
            <td
                class="border-b border-slate-100 p-3 pl-6 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                <router-link
                    :to="{
                        name: 'finnancial.cashout-details',
                        params: { cashout_id: cashout.id },
                    }"
                    class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                >
                    #{{ cashout.id }}
                </router-link>
            </td>
            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ cashout.created_at }}
            </td>

            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ cashout.bank_account.holder_name }}
            </td>
            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ cashout.bank_account.formatted_type }}
            </td>
            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ cashout.user.type === "company" ? "JURÍDICA" : "FÍSICA" }}
            </td>
            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ $root.formatCurrency(cashout.amount) }}
            </td>
            <td
                class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                {{ $root.formatCurrency(cashout.net_value) }}
            </td>
            <td
                class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
            >
                <div
                    class="w-full rounded px-2.5 py-1.5 text-center text-sm font-medium"
                    :class="getCashoutStatusClass(cashout.status)"
                >
                    {{ getCashoutStatusName(cashout.status) }}
                </div>
            </td>
        </tr>
    </table-component>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import BankMixin from "../../../mixins/BankMixin.vue";
import Card from "../../../components/layouts/Card.vue";
import BalanceCards from "../../../components/user/BalanceCards.vue";

export default {
    components: { BalanceCards, Card, TableComponent },
    props: ["user", "products", "affiliates"],
    mixins: [BankMixin],
    data() {
        return {
            loading: false,
            cashouts: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            cards: {
                total: 0,
                total_amount: 0,
            },

            balance: false,
        };
    },

    computed: {
        userId() {
            return this.$route.params.user_id ?? this.user.id;
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.userId + "/cashout")
                .then(({ data }) => {
                    this.cashouts = data.data;
                    this.pagination = data;
                    this.loading = false;
                });

            axios
                .get("/api/user/" + this.userId + "/cashout/cards")
                .then(({ data }) => {
                    this.cards = data;
                });

            axios
                .get("/api/user/" + this.userId + "/balance/validation")
                .then(({ data }) => {
                    this.balance = data;
                });
        },
    },
};
</script>
