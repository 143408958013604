<template>
    <div class="flex" v-tooltip="methodName.name">
        <div class="col flex items-center justify-items-center">
            <font-awesome-icon :icon="methodName.icon" />
        </div>
        <span class="col mx-2 rounded text-sm font-medium" v-if="!hideName">
            {{ methodName.name }}
        </span>
    </div>
</template>

<script>
export default {
    props: ["method", "hasAmountCard2", "hideName"],

    computed: {
        methodName() {
            switch (this.method) {
                case "credit_card":
                    if (this.hasAmountCard2) {
                        return {
                            icon: "fa-solid fa-money-bills",
                            name: "Dois Cartões",
                        };
                    } else {
                        return {
                            icon: "fa-solid fa-credit-card",
                            name: "Cartão de Crédito",
                        };
                    }
                case "bank_slip":
                    return { icon: "fa-solid fa-barcode", name: "Boleto" };
                case "pix":
                    return { icon: "fa-brands fa-pix", name: "PIX" };
                default:
                    return {
                        icon: "fa-solid fa-money-bill-alt",
                        name: this.method ?? "--",
                    };
            }
        },
    },
};
</script>
