<template>
    <nav-tabs :exact="false" :tabs="[
        { label: 'Motivos de recusa', route: 'reasons' },
    ]" />
    <router-view></router-view>
</template>

<script>
import NavTabs from "../../components/layouts/NavTabs.vue";
export default {
    components: { NavTabs },

    mounted() {

    }
}
</script>
