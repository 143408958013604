<template>
    <div
        :class="[
            'mr-2 rounded px-2.5 py-1.5 text-center ',
            statusClass,
            size === 'sm' ? 'text-xs' : size === 'md' ? 'text-sm' : 'text-base',
        ]"
    >
        {{ statusName }}
    </div>
</template>

<script>
export default {
    props: { status: {}, isRefundFromHood: false, size: { default: "md" } },

    computed: {
        statusName() {
            switch (this.status) {
                case "aborted":
                    return "Abortado";
                case "delayed":
                    return "Atrasado";
                case "authorized":
                    return "Autorizado";
                case "chargeback":
                    return "Chargeback";
                case "disputed":
                    return "Em Disputa";
                case "closed":
                    return "Encerrado";
                case "expired":
                    return "Expirado";
                case "error":
                    return "Incompleta";
                case "waiting_payment":
                    return "Pendente";
                case "trial":
                    return "Período de Testes";
                case "processing":
                    return "Processando";
                case "claimed":
                    return "Reclamado";
                case "refused":
                    return "Recusado";
                case "fraud_refunded":
                    return "Estornado por Fraude";
                case "chargeback_refunded":
                    return "Estornado por Chargeback";
                case "refunded":
                    if (this.isRefundFromHood) {
                        return "Reembolsado Automaticamente";
                    }
                    return "Reembolsado";
                case "refund_requested":
                    return "Reembolso Solicitado";
                default:
                    return "Em Análise";
            }
        },
        statusClass() {
            switch (this.status) {
                case "aborted":
                    return "bg-red-600 text-white";
                case "delayed":
                    return "bg-neutral-500 text-white";
                case "authorized":
                    return "bg-green-500 text-white";
                case "chargeback":
                    return "bg-black text-white";
                case "fraud_refunded":
                    return "bg-red-900 text-white";
                case "chargeback_refunded":
                    return "bg-red-600 text-white";
                case "disputed":
                    return "bg-amber-400 text-white";
                case "closed":
                    return "bg-blue-500 text-white";
                case "expired":
                    return "bg-neutral-500 text-white";
                case "error":
                    return "bg-red-600 text-white";
                case "waiting_payment":
                    return "bg-amber-400 text-white";
                case "trial":
                    return "bg-blue-500 text-white";
                case "processing":
                    return "bg-neutral-500 text-white";
                case "claimed":
                    return "bg-amber-400 text-white";
                case "refused":
                    return "bg-red-600 text-white";
                case "refunded":
                    if (this.isRefundFromHood) {
                        return "bg-red-600 text-white text-xs";
                    }

                    return "bg-red-600 text-white";
                case "refund_requested":
                    return "bg-amber-400 text-white";
                default:
                    return "bg-blue-500 text-white";
            }
        },
    },
};
</script>
