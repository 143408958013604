<template>
    <div v-if="loading" class="flex animate-pulse p-4">
        <div
            class="-mr-3 h-8 w-8 rounded-full"
            :class="{
                'bg-gray-500': index % 2 === 0,
                'bg-gray-400': index % 2 === 1,
            }"
            v-for="(n, index) in 10"
        ></div>
    </div>
    <div class="flex items-center" v-else>
        <img
            v-for="user in users"
            class="mr-1 h-8 w-8 rounded-full"
            :src="
                user.avatar ||
                'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                    user.name
            "
            alt=""
        />
    </div>
</template>

<script>
export default {
    props: { manager: { type: Object } },
    data() {
        return {
            loading: false,
            users: [],
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/account-manager/" + this.manager.uuid + "/users")
                .then(({ data }) => {
                    // limit 10 users
                    this.users = data.slice(0, 10);
                    this.loading = false;
                });
        },
    },
};
</script>
