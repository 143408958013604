<template>
    <div
        @click.prevent="modal = true"
        class="ml-auto btn-primary-sm hover:cursor-pointer"
    >
        Editar
    </div>
    <modal
        :open="modal"
        @close="modal = false"
        size="xl"
    >
        <h1 class="flex flex-row flex-wrap content-center mb-3 text-sm font-bold text-white uppercase">
            Documentos {{ user.email}}
        </h1>
        <div class="w-full min-w-0 mb-4 overflow-hidden bg-white rounded-lg h-50 dark:bg-gray-800 drop-shadow-sm">
            <div role="list" class="divide-y rounded-md dark:divide-gray-900">
                <template v-if="user.type === 'company'">
                    <user-document
                        v-for="collection in collections.company"
                        @fetch="modal = false;$emit('fetch')"
                        :user="user"
                        :collection-label="collection.label"
                        :collection-name="collection.name"
                    />
                </template>

                <template v-if="user.type === 'person'">
                    <user-document
                        v-for="collection in collections.person"
                        @fetch="modal = false;$emit('fetch')"
                        :user="user"
                        :collection-label="collection.label"
                        :collection-name="collection.name"
                    />
                </template>
            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from "../layouts/Modal.vue";
import UserDocument from "./UserDocument.vue";

    export default {
        components: {UserDocument, Modal},
        props: ['user'],
        emits: ['fetch'],

        data() {
            return {
                collections: {
                    company: [
                        {
                            label: 'Cartão CNPJ',
                            name: 'cnpj_card'
                        },
                        {
                            label: 'Contrato Social',
                            name: 'social_contract'
                        },
                        {
                            label: 'Documento Bancário',
                            name: 'bank_document'
                        }
                    ],
                    person: [
                        {
                            label: 'Selfie',
                            name: 'selfie_photo'
                        },
                        {
                            label: 'Frente do Documento',
                            name: 'document_front'
                        },
                        {
                            label: 'Verso do Documento',
                            name: 'document_back'
                        },
                        {
                            label: 'Documento Bancário',
                            name: 'bank_document'
                        },
                        {
                            label: 'Documento de Emancipação',
                            name: 'emancipated_document'
                        }
                    ]
                },
                modal: false
            }
        }
    }
</script>
