<template>
    <font-awesome-icon
        v-tooltip="brand"
        :icon="'fa-brands fa-cc-'+ brand"
        class="w-6 h-6 text-gray-400 dark:text-gray-200"
        aria-hidden="true"
    />
</template>

<script>
    export default {
        props: {
            brand: {
                type: String,
                required: true
            }
        },
    }
</script>
