<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full lg:max-w-7xl sm:p-6"
                    >
                        <form @submit.prevent="blockFingerprint()">
                            <div class="flex">
                                <div
                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-ban"
                                        class="mx-auto mt-1 w-10 h-10 text-gray-400 dark:text-gray-200"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-white text-center my-5"
                                    >
                                        Adicionar fingerprint do comprador a Blacklist
                                    </dialog-title>
                                    <p
                                        v-for="error in errors"
                                        class="text-red-500"
                                    >
                                        {{ error }}
                                    </p>
                                    <div class="text-md text-white my-3">
                                        <dialog-title
                                            as="h3"
                                            class="text-lg leading-6 font-medium text-white my-5"
                                        >
                                            Tipos de bloqueios
                                        </dialog-title>
                                        <div class="flex gap-1 p-2" v-for="type in blacklistTypes" :key="type.value">
                                            <div class="flex items-center h-5">
                                                <input class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" :id="type.value" v-model="selectedBlacklistTypes" :value="type.value">
                                            </div>
                                            <div class="ms-2 text-sm">
                                                <label class="font-bold text-white capitalize" :for="type.value">{{ type.value }}</label>
                                                <p class="text-white font-normal">{{ type.description }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="my-3">
                                        <textarea
                                            v-model="form.reason"
                                            rows="4"
                                            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Motivo..."
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="submit"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Bloquear
                                </button>
                                <button
                                    type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            errors: [],
            reason: "",
            form: "",
            blacklistTypes: [],
            selectedBlacklistTypes: []
        };
    },
    mounted() {
        this.form = {...this.order, ...this.form};
        this.getBlacklistTypes();
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        blockFingerprint() {
            this.errors = [];
            this.form.blacklist = this.selectedBlacklistTypes;
            axios.post("/api/blacklist", this.form)
                .then(({data}) => {
                    this.$emit("closeModalAndFetch");
                })
                .catch(({response}) => {
                    this.errors = response.data.errors;
                });
        },
        getBlacklistTypes() {
            axios.get("/api/blacklist/blacklist-type").then(({data}) => {
                this.blacklistTypes = data.data;
            });
        },
    },
};
</script>
