<template>
    <h2 class="mb-2 mt-5 text-lg font-bold font-medium text-gray-400">
        Recomendados
    </h2>
    <filters
        class="mt-3"
        v-model="filters"
        :loading="loading"
        category-filter
        :categories="categories"
        :types="types"
        :statuses="[
            { value: 'pending', label: 'Pendente' },
            { value: 'approved', label: 'Aprovado' },
            { value: 'reproved', label: 'Reprovado' },
        ]"
        type-filter
        type-label="Tipo"
        search-filter
        search-label="Filtre pelo id ou nome"
        @fetch="
            products = [];
            next_page_url = false;
            fetch();
        "
        id-filter
    >
        <form-toggle
            v-model="is_recommended"
            :loading="loading"
            label="Recomendados"
        >
        </form-toggle>

        <form-toggle
            class="ml-2"
            v-model="is_best_rated"
            :loading="loading"
            label="Bem Avaliados"
        >
        </form-toggle>
    </filters>

    <TransitionRoot
        appear
        :show="selected.length > 0"
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <div
            class="absolute z-10 mb-4 grid max-w-lg grid-cols-2 divide-x divide-slate-700 rounded-lg bg-slate-800 text-white shadow"
        >
            <div
                class="rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                @click.prevent="selectAll"
            >
                <input
                    @click.stop="selectAll"
                    :checked="all_selected"
                    id="select-all-cashouts"
                    aria-describedby="comments-description"
                    name="all-cashouts"
                    type="checkbox"
                    class="h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                    :value="true"
                />
                {{ selected.length }} Selecionado{{
                    selected.length > 1 ? "s" : ""
                }}
            </div>
            <div
                @click="openModal"
                class="flex content-center justify-center rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
            >
                Salvar
            </div>
        </div>
    </TransitionRoot>

    <div
        class="not-prose relative overflow-hidden overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
    >
        <table class="w-full table-auto border-collapse text-sm">
            <thead>
                <tr class="text-white">
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        <input
                            @click.stop="selectAll"
                            :checked="all_selected"
                            id="select-all-cashouts"
                            aria-describedby="comments-description"
                            name="all-cashouts"
                            type="checkbox"
                            class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                            :value="true"
                        />
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Produto
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Produtor
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Vendas
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Tipo
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Categoria
                    </th>
                    <th
                        scope="col"
                        class="px-6 py-4 text-left text-sm font-medium"
                    >
                        Velocidade
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-if="loading && !next_page_url" v-for="n in 5">
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div
                            class="h-2 w-full animate-pulse rounded bg-gray-500"
                        ></div>
                    </td>
                </tr>

                <tr v-for="showcase in products" class="">
                    <td
                        class="border-b border-slate-100 p-4 pl-6 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div class="flex">
                            <input
                                :id="'check-user-' + showcase.product.id"
                                aria-describedby="comments-description"
                                name="cashouts"
                                type="checkbox"
                                class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                                v-model="selected"
                                :value="showcase.product.id"
                            />
                            #{{ showcase.product.id }}
                        </div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <div class="flex items-center">
                            <img
                                v-if="showcase.product.cover_picture"
                                :src="showcase.product.cover_picture"
                                style="width: 38px; height: 39px"
                            />
                            <svg
                                v-else
                                style="width: 38px; height: 39px"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                enable-background="new 0 0 226.8 226.8"
                                version="1.1"
                                viewBox="0 0 226.8 226.8"
                                xml:space="preserve"
                            >
                                <path
                                    d="M113.3 226.8h-94c-5.5 0-10.2-1.8-14-5.7-3.7-3.8-5.3-8.4-5.3-13.6V104.2v-85C0 13.1 2.2 8 7 4.2c3.6-3 7.8-4.2 12.5-4.2H207.4c6.2 0 11.4 2.1 15.4 7 3 3.6 4.2 7.8 4.2 12.4v188c0 5.3-1.5 9.9-5.3 13.8-3.9 4-8.6 5.7-14.1 5.7h-79.4c-5.1-.1-10-.1-14.9-.1zm-66-146h70.8c1.8 0 3.5.1 4.3 2.1.9 2-.3 3.3-1.6 4.6l-23.1 23.1c-2.2 2.2-3.3 4.7-3.3 7.8v64.7c0 1.3.3 1.7 1.7 1.7 11.3-.1 22.7 0 34 0 2.1 0 2.1 0 2.1-2.1V82.9c0-2.1 0-2.1 2.2-2.1h43.3c.6 0 1.1 0 1.7-.1 0-.4.1-.6.1-.8V53.6c0-1.3-.7-1.2-1.5-1.2H48.7c-.5 0-.9 0-1.5.1.1 9.5.1 18.8.1 28.3z"
                                ></path>
                            </svg>
                            <span class="ml-2">
                                {{ showcase.product.name }}
                            </span>
                        </div>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        {{ showcase.product.user.name }}
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <span>
                            {{ $root.formatCurrency(showcase.sales_amount) }}
                        </span>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <product-type
                            :type="showcase.product.type"
                        ></product-type>
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        {{ showcase.product.category.name }}
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        {{ showcase.speedometer }}
                    </td>
                    <td
                        class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                    >
                        <span
                            v-if="showcase.is_recommended"
                            class="mr-1 inline-block rounded bg-indigo-500 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
                        >
                            Recomendado
                        </span>
                        <span
                            v-if="showcase && showcase.is_best_rated"
                            class="mr-1 inline-block rounded bg-blue-500 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
                        >
                            Avaliado
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="my-5 flex w-full">
            <button
                v-if="next_page_url"
                @click.prevent="fetch"
                :disabled="!next_page_url"
                type="button"
                :class="{ 'cursor-not-allowed': loading }"
                class="mx-auto inline-flex items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
            >
                <svg
                    v-if="loading"
                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                Carregar mais
            </button>
        </div>
    </div>
    <showcase-modal
        v-if="modal"
        @closeModal="modal = false"
        @closeModalAndFetch="closeModalAndFetch()"
        :ids="selected"
    />
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import ShowcaseModal from "./ShowcaseModal.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import FormToggle from "../../components/forms/FormToggle.vue";
import Filters from "../../components/layouts/Filters.vue";
import ProductType from "../../components/layouts/ProductType.vue";
export default {
    components: {
        ProductType,
        VueSelect,
        TransitionRoot,
        FormToggle,
        ShowcaseModal,
        Filters,
    },
    data() {
        return {
            modal: false,
            types: [],
            categories: [],
            filters: {
                recommended: false,
                id: "",
                text: "",
                status: [],
                type: [],
                category: [],
            },
            is_best_rated: false,
            is_recommended: false,
            selected: [],
            all_selected: false,
            loading: true,
            products: [],
            next_page_url: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    beforeMount() {
        axios.get("/api/product/types").then(({ data }) => {
            this.types = Object.entries(data).map((entry) => {
                return { value: entry[0], label: entry[1] };
            });
        });
        axios.get("/api/product/categories").then(({ data }) => {
            this.categories = data.data;
        });
    },
    mounted() {
        setTimeout(() => {
            this.fetch();
        }, 1000);
    },

    methods: {
        selectAll() {
            this.all_selected = !this.all_selected;
            if (this.all_selected) {
                this.selected = this.products.map((product) => product.id);
            } else {
                this.selected = [];
            }
        },
        clearFilters() {
            this.products = [];
            this.filters.is_recommended = false;
            this.filters.is_best_rated = false;
            this.filters.status = [];
            this.filters.type = [];
            this.filters.category = [];
            this.filters.text = "";
            this.filters.id = "";
        },
        closeModalAndFetch() {
            this.modal = false;
            this.clearFilters();
            this.fetch();
        },
        openModal() {
            if (this.selected.length > 0) {
                this.modal = true;
            }
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["filter[status]"] = "approved";
            if (this.is_recommended) {
                params.is_recommended = true;
            }
            if (this.is_best_rated) {
                params.is_best_rated = true;
            }
            params.has_offers = true;
            params.showcases = true;

            axios
                .get(this.next_page_url || "/api/product/showcase", {
                    params: params,
                })
                .then(({ data }) => {
                    this.products = [...this.products, ...data.data];
                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };
                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
