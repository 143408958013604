<template>
    <div
        class="dark:text-w rounded-lg border bg-white p-4 shadow-lg dark:bg-gray-800"
    >
        <div class="mb-4 flex flex-col items-center justify-between">
            <div class="text-xs font-semibold uppercase text-gray-400">
                Score de Risco
            </div>
            <span
                class="text-3xl font-bold text-gray-800 dark:text-white"
                v-text="score"
            ></span>
        </div>
        <div class="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
            <div :class="gradientClass" class="h-2.5 rounded-full"></div>
        </div>
        <div
            class="mt-2 flex justify-between text-xs font-medium text-gray-500"
        >
            <span>Não Confiável</span>
            <span>Merece atenção</span>
            <span>Confiável</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        score: {
            type: Number,
            default: 750,
        },
    },
    computed: {
        gradientClass() {
            // Define classes for various score ranges
            if (this.score <= 25) {
                return "bg-gradient-to-r from-red-600 to-red-300 w-1/4"; // 0-25% score
            } else if (this.score <= 50) {
                return "bg-gradient-to-r from-red-600 to-yellow-300 w-1/2"; // 26-50% score
            } else if (this.score <= 75) {
                return "bg-gradient-to-r from-red-600 via-yellow-300 to-green-400 w-3/4"; // 51-75% score
            } else {
                return "bg-gradient-to-r from-red-600 via-yellow-300 to-green-400 w-full"; // 76-100% score
            }
        },
        scoreBarWidth() {
            // Assuming the score is out of 1000, calculate the width percentage
            const width = (this.score / 100) * 100;
            return { width: `${width}%` };
        },
    },
};
</script>

<style>
/* You can add additional styles if necessary */
</style>
