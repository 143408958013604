<template>
    <h3 class="text-lg font-bold leading-6 font-medium dark:text-white mb-3">
        Produtos
    </h3>

    <product-totals/>
    <div class="flex content-center items-center mb-3">
        <div class="grow">
            <h3 class="text-lg font-bold leading-6 font-medium dark:text-white">
                Cadastros
            </h3>
        </div>
        <div class="w-[230px] max-w-[250px] ml-auto">
            <litepie-datepicker
                class="max-w-[300px]"
                v-model="filters.date"
                :formatter="{
                  date: 'DD/MM/YYYY',
                  month: 'MMM'
                }"
                :shortcuts="false"
            ></litepie-datepicker>
        </div>
    </div>

    <status-totals
        :loading="loading"
        :products="products"
        :previous-products="previousProducts"
    />
    <products-chart :products="products" :loading="loading" class="mt-4"/>
</template>

<script>
    import ProductTotals from "../../../../components/analytics/products/ProductTotals.vue";
    import ProductsChart from "../../../../components/analytics/products/ProductsChart.vue";
    import StatusTotals from "../../../../components/analytics/products/StatusTotals.vue";

    export default {
        components: {StatusTotals, ProductsChart, ProductTotals},
        data() {
            return {
                loading: false,
                products: [],
                previousProducts: [],
                filters: {
                    date: [
                        moment().subtract(1, 'month').format('DD/MM/YYYY'),
                        moment().format('DD/MM/YYYY')
                    ]
                }
            }
        },

        watch: {
            'filters.date': function () {
                this.fetch();
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/analytics/compliance/products', {
                    params: {
                        from: this.filters.date[0],
                        to: this.filters.date[1]
                    }
                }).then(({data}) => {
                    this.products = data.current;
                    this.previousProducts = data.previous;
                    this.loading = false;
                });
            }
        }

    }
</script>
