<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Ofertas
    </h3>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Código',
            'Nome',
            'Preço',
            'Status',
            'Ativo?',
        ]"
    >
        <tr v-for="offer in offers">
            <td class="border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{offer.code}}
            </td>
            <td class="border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{offer.name}}
            </td>
            <td class="border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{ $root.formatCurrency(offer.price)}}
            </td>
            <td class="border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                <span class="text-sm font-medium mr-2 px-2.5 py-1.5 rounded bg-green-500 text-white ml-2">Status?</span>
            </td>
            <td class="border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{offer.is_active ? 'Sim' : 'Não'}}
            </td>
        </tr>
    </table-component>
</template>

<script>
    import Pagination from "../../components/layouts/Pagination.vue";
    import TableComponent from "../../components/layouts/table/TableComponent.vue";

    export default {
        components: {TableComponent, Pagination},
        props: ['product'],
        data() {
            return {
                loading: false,
                next_page_url: false,
                offers: [],
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = {};
                params['page'] = this.pagination.current_page;

                axios.get('/api/product/' + this.product.id + '/offer', {
                    params: params
                }).then(({data}) => {
                    this.offers = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
