<template>
    <div class="flex flex-col">
        <submit-button :loading="loading" @click="fetch" class="w-1/2">
            Fetch
        </submit-button>
    </div>
    <div class="grid grid-cols-4 gap-2">
        <div v-for="object in objects" class="flex flex-col">
            <submit-button
                :loading="loading"
                @click="addToRemove(object)"
                class="w-1/2"
            >
                Remover
            </submit-button>
            <img
                v-if="object.type !== 'application/pdf'"
                :key="object.Key"
                :src="object.url"
                :alt="object.Key"
                class="w-1/2"
            />
            <a :href="object.url" target="_blank" v-else>Doc</a>
            <span>{{ object.key }}</span>
            <span>{{ object.type }}</span>
            <span>{{ bitsToMb(object.size) }}</span>
            <span>{{ object.created_at }}</span>
        </div>
    </div>

    <div class="flex flex-col space-y-3">
        <submit-button :loading="loading" @click="fetch" class="w-1/2">
            Start After
        </submit-button>
        <span class="text-white" v-for="remove in removes">{{
            remove.key
        }}</span>

        <submit-button :loading="loading" @click="deleteKeys" class="w-1/2">
            Delete
        </submit-button>
    </div>
</template>

<script>
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            removes: [],
            loading: false,
            objects: [],
            lastKey: false,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            this.objects = [];
            axios
                .get("/api/s3", {
                    params: {
                        startAfter: this.lastKey,
                    },
                })
                .then(({ data }) => {
                    this.objects = data;
                    this.loading = false;
                    this.lastKey = this.objects[this.objects.length - 1]?.key;
                });
        },
        deleteObject(key) {
            axios.delete(`/api/s3`, { params: { key: key } }).then(() => {
                this.objects = this.objects.filter(
                    (object) => object.key !== key,
                );
                this.loading = false;
                this.removes = this.removes.filter((obj) => obj.key !== key);
            });
        },
        deleteKeys() {
            this.loading = true;
            axios
                .post(`/api/s3/deleteKeys`, {
                    keys: this.removes.map((obj) => obj.key),
                })
                .then(() => {
                    this.loading = false;
                    this.removes = [];
                });
        },
        deleteObjects() {
            this.loading = true;
            this.removes.map((obj) => this.deleteObject(obj.key));
        },
        addToRemove(object) {
            this.removes.push(object);
            this.objects = this.objects.filter((obj) => obj.key !== object.key);
        },
        bitsToMb(bits) {
            return (bits / 8 / 1024 / 1024).toFixed(2);
        },
    },
};
</script>
