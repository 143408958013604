<template>
    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="mx-auto w-full rounded-2xl">
                <div class="flex justify-between">
                    <h3
                        class="mt-1 text-lg font-medium leading-6 dark:text-white"
                    >
                        Configurações Financeiras
                    </h3>
                    <button
                        v-if="!audit"
                        :disabled="loading"
                        type="button"
                        class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-indigo-500 bg-indigo-500 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-100 sm:mt-0 sm:w-auto sm:text-sm"
                        @click="openModal()"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-cog"
                            class="mr-1 dark:text-white"
                            aria-hidden="true"
                        />
                        Editar
                    </button>
                </div>

                <ticto-loading v-if="loading" />
                <div v-else class="mt-3 border-t dark:border-slate-900">
                    <div class="mb-4 flex-grow border-t border-gray-400"></div>
                    <dl class="divide-y dark:divide-slate-900">
                        <div class="py-4">
                            <div
                                v-if="audit"
                                class="mb-3 border-l-4 border-yellow-600 bg-yellow-200 p-4 text-yellow-700"
                                role="alert"
                            >
                                <p class="font-medium">
                                    A auditoria está em andamento para este
                                    usuário. Alterações na configuração
                                    permanecerão desativadas até a conclusão da
                                    auditoria.
                                </p>
                            </div>
                            <div
                                v-if="!audit"
                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-credit-card"
                                />
                                Gateways de pagamento
                            </div>
                            <div v-if="!audit">
                                <gateway-form :user="user" />
                            </div>
                        </div>
                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Situação de saques
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <cashout-status
                                    :status="settings.is_cashout_locked"
                                />
                            </dd>
                        </div>
                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Situação do reembolso das comissões dos
                                fornecedores
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <refund-provider-from-producer
                                    :status="
                                        settings.refund_providers_commission_from_producer
                                    "
                                />
                            </dd>
                        </div>
                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Cancelamento de reembolsos
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <refund-status
                                    :status="settings.is_refund_locked"
                                />
                            </dd>
                        </div>

                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Saque de saldo em análise
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <cashout-under-review
                                    :status="
                                        settings.allow_cashout_under_review
                                    "
                                />
                            </dd>
                        </div>
                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Novo checkout internacional
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <is-allowed
                                    :status="
                                        settings.allow_new_international_checkout
                                    "
                                />
                            </dd>
                        </div>
                        <div class="py-4">
                            <dt class="text-sm font-medium text-gray-400">
                                Antecipação
                            </dt>
                            <div
                                class="mt-1 flex justify-between text-sm text-white"
                            >
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                        ><b>Status</b></span
                                    >
                                    <div class="mt-1 flex text-sm text-white">
                                        <anticipation-status
                                            :status="
                                                settings.can_anticipate ?? 0
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                        ><b>Versão</b></span
                                    >
                                    <span
                                        class="text-gray-600 dark:text-gray-200"
                                    >
                                        {{
                                            settings.anticipation_version
                                        }}</span
                                    >
                                </div>
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                        ><b>Taxa</b></span
                                    >
                                    <span
                                        class="text-gray-600 dark:text-gray-200"
                                    >
                                        {{ settings.anticipation_fee }}%</span
                                    >
                                </div>
                                <div class="flex flex-col">
                                    <span
                                        class="text-sm font-medium text-gray-600 dark:text-gray-400"
                                        ><b>Limite</b></span
                                    >
                                    <span
                                        class="text-gray-600 dark:text-gray-200"
                                    >
                                        {{
                                            settings.anticipation_limit ?? 60
                                        }}%</span
                                    >
                                </div>
                            </div>
                        </div>

                        <financial-methods-info :settings="settings" />

                        <edit-financial-settings-modal
                            :settings="settings"
                            :user="user"
                            :open="edit_financial_modal"
                            v-if="edit_financial_modal"
                            @closeModal="closeModal()"
                            @closeModalAndFetch="closeAndFetchModal()"
                        />
                    </dl>
                </div>
            </div>
        </div>
        <UserFinancialLogs v-if="!loading" :user="user" />
    </div>
</template>

<script>
import HelperMixin from "../../../mixins/HelperMixin.vue";
import AnticipationStatus from "../../../components/layouts/AnticipationStatus.vue";
import CashoutStatus from "../../../components/layouts/CashoutStatus.vue";
import RefundProviderFromProducer from "../../../components/layouts/RefundProviderFromProducer.vue";
import RefundStatus from "../../../components/layouts/RefundStatus.vue";
import IsAllowed from "../../../components/layouts/IsAllowed.vue";
import CashoutUnderReview from "../../../components/layouts/CashoutUnderReview.vue";
import FinancialMethodsInfo from "../../../components/layouts/FinancialMethodsInfo.vue";
import EditFinancialSettingsModal from "../modals/EditFinancialSettingsModal.vue";
import UserFinancialLogs from "./UserFinancialLogs.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import { useToast } from "vue-toastification";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import GatewayForm from "../settings/GatewayForm.vue";

export default {
    props: ["user"],
    components: {
        GatewayForm,
        TictoLoading,
        AnticipationStatus,
        FinancialMethodsInfo,
        EditFinancialSettingsModal,
        UserFinancialLogs,
        CashoutStatus,
        RefundProviderFromProducer,
        RefundStatus,
        IsAllowed,
        CashoutUnderReview,
        FormToggle,
    },
    mixins: [HelperMixin],

    data() {
        return {
            audit: false,
            loading: true,
            settings: {},
            gateways: {},
            gatewaysUser: {},
            form: {
                gateways: [],
            },
            edit_financial_modal: false,
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/settings")
                .then(({ data }) => {
                    this.settings = data;
                })
                .finally(() => {
                    this.loading = false;
                });

            const params = {};
            params["filter[user_id]"] = this.$route.params.user_id;

            axios
                .get("/api/audit", {
                    params,
                })
                .then(({ data }) => {
                    this.audit = data.data.find((audit) => {
                        return audit.status === "open";
                    });
                });
        },

        openModal() {
            this.edit_financial_modal = true;
        },
        closeModal() {
            this.edit_financial_modal = false;
        },
        closeAndFetchModal() {
            this.edit_financial_modal = false;
            this.fetch();
        },
    },
};
</script>
