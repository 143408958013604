<template>
    <table-component
        :loading="loading"
        :headers="['Nome', 'User', 'Valor']"
        :pagination="pagination"
        @fetch="fetch"
    >
        <tr v-for="metric in metrics" :key="metric.id">
            <td class="table-cell-sm">
                <span v-tooltip="metric.name" class="block w-32 truncate">
                    {{ metric.name }}
                </span>
            </td>
            <td class="table-cell-sm">
                <router-link
                    :to="{
                        name: 'user.trackings',
                        params: { user_id: metric.user_id },
                    }"
                    v-tooltip="metric.user_id"
                    class="block w-32 truncate"
                >
                    {{ metric.user_id }}
                </router-link>
            </td>
            <td class="table-cell-sm">
                <span v-tooltip="metric.amount" class="block w-32 truncate">
                    {{ metric.amount }}
                </span>
            </td>
        </tr>
    </table-component>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";

export default {
    components: { TableComponent },
    data() {
        return {
            loading: false,
            metrics: [],
            pagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            const params = {};
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/audit/metrics", {
                    params: params,
                })
                .then(({ data }) => {
                    this.metrics = data.hits;

                    this.pagination.total = data.aggregations.total_count.value;
                    this.pagination.from =
                        this.pagination.current_page *
                            this.pagination.per_page -
                        this.pagination.per_page +
                        1;
                    this.pagination.to =
                        this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(
                        this.pagination.total / this.pagination.per_page,
                    );

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                });
        },
    },
};
</script>
