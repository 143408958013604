<template>
    <transition-root
        :show="csvExport.hasOwnProperty('id')"
        enter="transition-opacity duration-150"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <card class="fixed bottom-2 left-2 z-50">
            <div class="flex items-center justify-center dark:text-white">
                <svg
                    v-if="csvExport.status === 'processing'"
                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>

                <h1>Exportando {{ csvExport.name }}</h1>

                <span
                    :class="{
                        'bg-green-500': csvExport.status === 'completed',
                        'animate-pulse bg-yellow-500':
                            csvExport.status === 'processing',
                        'bg-red-500': csvExport.status === 'failed',
                    }"
                    class="ml-2 rounded px-2 py-1 text-center text-white dark:text-gray-200"
                >
                    {{ statusName(csvExport.status) }}
                </span>
                <strong
                    class="ml-2"
                    v-if="csvExport.rows || csvExport.progress"
                >
                    {{ csvExport.progress || 0 }}/{{ csvExport.rows }}
                </strong>

                <a
                    v-if="csvExport.status === 'completed' && s3Link"
                    :href="s3Link"
                    target="_blank"
                    class="btn-primary-sm ml-3"
                >
                    Download
                    <cloud-arrow-down-icon
                        class="ml-1 h-5 w-5 text-gray-400 transition-all"
                    />
                </a>
                <x-mark-icon
                    @click="csvExport = {}"
                    class="ml-1 h-5 w-5 text-gray-400 transition-all hover:cursor-pointer"
                />
            </div>
        </card>
    </transition-root>
</template>

<script>
import {
    XMarkIcon,
    CloudArrowDownIcon,
    ChevronDownIcon,
} from "@heroicons/vue/24/outline";
import Card from "../layouts/Card.vue";
import { TransitionRoot } from "@headlessui/vue";
import { Store } from "../../stores/store";
import { mapWritableState } from "pinia";

export default {
    components: {
        ChevronDownIcon,
        TransitionRoot,
        Card,
        XMarkIcon,
        CloudArrowDownIcon,
    },
    data() {
        return {
            show: false,
            csvExport: {},
            s3Link: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["authUser"]),
    },

    watch: {
        authUser() {
            if (this.authUser) {
                this.connectBroadcasting();
            }
        },
    },

    mounted() {
        setTimeout(() => {
            this.show = true;
        }, 1000);
    },

    methods: {
        connectBroadcasting() {
            Echo.channel("user-" + this.authUser.id)
                .listen(".App\\Events\\Export\\ExportStarted", (e) => {
                    this.csvExport = e.csvExport;
                })
                .listen(
                    ".Ticto\\SheetExporter\\Events\\Export\\ExportStarted",
                    (e) => {
                        this.csvExport = e.csvExport;
                    },
                )
                .listen(".Ticto\\SheetExporter\\Events\\ExportUpdated", (e) => {
                    this.csvExport = e.sheetExport;
                })
                .listen(
                    ".Ticto\\SheetExporter\\Events\\ExportCompleted",
                    (e) => {
                        this.csvExport = e.sheetExport;
                        this.s3Link = e.s3Link;
                    },
                )
                .listen(".App\\Events\\Export\\ExportUpdated", (e) => {
                    this.csvExport = e.csvExport;
                })
                .listen(".App\\Events\\Export\\ExportCompleted", (e) => {
                    this.csvExport = e.csvExport;
                    this.s3Link = e.s3Link;
                });
        },
        statusName(status) {
            switch (status) {
                case "pending":
                    return "Pendente";
                case "processing":
                    return "Processando";
                case "completed":
                    return "Concluído";
                case "failed":
                    return "Falhou";
                default:
                    status: return status;
            }
        },
    },
};
</script>
