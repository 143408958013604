<template>
    <div>
        <devices :user="user" />
        <table-component
            :loading="loading"
            :pagination="pagination"
            :headers="['Data', 'Ip', 'Localização', 'Dispositivo', 'Navegador']"
            @fetch="fetch"
        >
            <tr v-for="login in logins">
                <td class="table-cell-sm">{{ login.created_at }}</td>
                <td class="table-cell-sm">{{ login.ip }}</td>
                <td class="table-cell-sm">
                    <div class="flex space-x-2" v-if="login.location">
                        <span>{{ login.location.countryCode }}:</span>
                        <span>{{ login.location.cityName }}</span>
                    </div>
                </td>
                <td class="table-cell-sm">
                    <div class="flex space-x-2" v-if="login.device">
                        <os-icon
                            :os="login.device.platform"
                            v-tooltip="login.device.platform"
                        />
                        <span>{{ login.device.platform_version }}</span>
                    </div>
                </td>
                <td class="table-cell-sm">
                    <div class="flex space-x-2" v-if="login.device">
                        <browser-icon
                            :browser="login.device.browser"
                            v-tooltip="login.device.browser"
                        />
                        <span>{{ login.device.browser_version }}</span>
                    </div>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import OsIcon from "../../../components/layouts/antifraud/OsIcon.vue";
import BrowserIcon from "../../../components/layouts/antifraud/BrowserIcon.vue";
import Devices from "../../../components/user/devices/Devices.vue";

export default {
    components: { Devices, BrowserIcon, OsIcon, TableComponent },
    props: ["user", "products", "affiliates"],
    data() {
        return {
            loading: false,
            aggs: [],
            logins: [],
            pagination: {
                total: 10,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    computed: {
        userId() {
            return this.$route.params.user_id ?? this.user.id;
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.userId + "/logins", {
                    params: {
                        page: this.pagination.current_page,
                    },
                })
                .then(({ data }) => {
                    this.aggs = data.aggregations;
                    this.logins = data.hits;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.pagination.total = data.aggregations.total_count.value;
                    this.pagination.from =
                        this.pagination.current_page *
                            this.pagination.per_page -
                        this.pagination.per_page +
                        1;
                    this.pagination.to =
                        this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(
                        this.pagination.total / this.pagination.per_page,
                    );

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
