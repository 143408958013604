<template>
    <div class="mt-5">
        <div class="grid grid-cols-5 gap-4">
            <div>
                <navigation
                    :navigation="[
                        {
                            name: 'Usuários',
                            route: { name: 'finnancial.chargebacks.users' },
                            icon: UsersIcon,
                        },
                        {
                            name: 'Disputas',
                            route: { name: 'finnancial.chargebacks.disputes' },
                            icon: CubeIcon,
                        },
                        {
                            name: 'Pre Chargebacks',
                            route: {
                                name: 'finnancial.chargebacks.pre-chargebacks',
                            },
                            icon: BellIcon,
                        },
                    ]"
                />
            </div>
            <div class="col-span-4">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "../../../components/layouts/navigation/vertical/Navigation.vue";
import { UsersIcon, CubeIcon, BellIcon } from "@heroicons/vue/24/outline";

export default {
    components: { Navigation },
    methods: { UsersIcon, CubeIcon, BellIcon },
};
</script>
