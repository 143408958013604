<template>
    <span
        class="mr-2 rounded"
        :class="{
            'bg-green-500 text-white': status == 1,
            'bg-red-500 text-white': status == 0,
            'bg-yellow-500 text-white': status != 0 && status != 1,
            'px-2.5 py-1.5 text-sm': size === 'md',
            'px-1.5 py-1 text-xs': size === 'sm',
        }"
    >
        {{ statusName }}
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
        },
        size: {
            type: String,
            default: "md",
        },
    },

    computed: {
        statusName() {
            switch (this.status) {
                case 0:
                case false:
                    return "Bloqueado";
                case 1:
                case true:
                    return "Liberado";
                default:
                    return this.status;
            }
        },
    },
};
</script>
