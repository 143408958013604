<template>
    <modal
        :open="modal"
        @close="$emit('closeModal')"
    >
        <h3 class="text-lg leading-6 text-white font-bold border-b border-slate-400 mb-3 pb-2">
            Editar Usuário
        </h3>
        <form @submit.prevent="submit">
            <div class="mb-2">
                <label for="name" class="block mb-2 text-sm font-medium text-white">Pessoa</label>
                <select
                    v-model="form.type"
                    id="type"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    <option value="person">Física</option>
                    <option value="company">Jurídica</option>
                </select>
            </div>

            <div class="mb-2">
                <label for="name" class="block mb-2 text-sm font-medium text-white">Nome</label>
                <input
                    v-model="form.name"
                    type="text"
                    name="name"
                    id="name"
                    class="text-input"
                    required
                >
            </div>

            <div class="mb-2" v-if="form.type === `company`">
                <label for="social_name" class="block mb-2 text-sm font-medium text-white">Nome Social</label>
                <input
                    v-model="form.social_name"
                    type="text"
                    name="social_name"
                    id="social_name"
                    class="text-input"
                    required
                >
            </div>

            <div v-if="form.type === `person`">
                <div class="mb-2">
                    <label for="birthDate" class="block mb-2 text-sm font-medium text-white">Data de Nascimento</label>
                    <input
                        v-maska
                        data-maska="##/##/####"
                        v-model="form.birth"
                        type="text"
                        name="birthDate"
                        id="birthDate"
                        class="text-input"
                        required
                    >
                </div>
                <div class="mb-2">
                    <label for="cpf" class="block mb-2 text-sm font-medium text-white">CPF</label>
                    <input
                        v-model="form.cpf"
                        v-maska
                        data-maska="###.###.###-##"
                        type="text"
                        name="cpf"
                        id="cpf"
                        class="text-input"
                        required
                    >
                </div>
            </div>
            <div v-else>
                <div class="mb-2">
                    <label for="cnpj" class="block mb-2 text-sm font-medium text-white">CNPJ</label>
                    <input
                        v-model="form.cnpj"
                        v-maska
                        data-maska="##.###.###/####-##"
                        type="text"
                        name="cnpj"
                        id="cnpj"
                        class="text-input"
                        required
                    >
                </div>


                <h3 class="text-lg font-medium leading-6 text-white font-bold border-b border-slate-400 mb-3 pb-2">
                    Sócio Administrador
                </h3>

                <div class="mb-2">
                    <label for="company_partner_cpf" class="block mb-2 text-sm font-medium text-white">Nome</label>
                    <input
                        v-model="form.company_partner.name"
                        type="text"
                        name="company_partner_name"
                        id="company_partner_name"
                        class="text-input"
                        required
                    >
                </div>
                <div class="mb-2">
                    <label for="company_partner_cpf" class="block mb-2 text-sm font-medium text-white">CPF</label>
                    <input
                        v-model="form.company_partner.cpf"
                        v-maska
                        data-maska="###.###.###-##"
                        type="text"
                        name="company_partner_cpf"
                        id="company_partner_cpf"
                        class="text-input"
                        required
                    >
                </div>
                <div class="mb-2">
                    <label for="birthDate" class="block mb-2 text-sm font-medium text-white">Data de Nascimento</label>
                    <input
                        v-model="form.company_partner.birth"
                        v-maska
                        data-maska="##/##/####"
                        type="text"
                        name="birthDate"
                        id="birthDate"
                        class="text-input"
                        required
                    >
                </div>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    :disabled="loading"
                    type="submit"
                    class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Enviar
                </button>
                <button
                    :disabled="loading"
                    type="button"
                    class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="closeModal()"
                    ref="cancelButtonRef"
                >
                    Cancelar
                </button>
            </div>
        </form>
    </modal>
</template>

<script>

import {mapWritableState, mapActions} from "pinia";
import {Store} from '../../stores/store.js';
import dayjs from 'dayjs'
import {useToast} from "vue-toastification";
import TictoLoading from "../layouts/TictoLoading.vue";
import Modal from "../layouts/Modal.vue";
import {vMaska} from "maska"


export default {
    props: {modal: {type: Boolean, default: false}},
    components: {Modal, TictoLoading},
    directives: {maska: vMaska},
    setup() {
        const toast = useToast();
        return {toast};
    },
    data() {
        return {
            loading: false,
            form: {
                type: '',
                name: '',
                birth: '',
                social_name: '',
                cpf: '',
                cnpj: '',
                company_partner: {
                    name: '',
                    cpf: '',
                    birth: ''
                },
            }
        }
    },

    computed: {
        ...mapWritableState(Store, ["user", "storeCompliance"]),
    },

    watch: {
        modal(bool) {
            if (bool && this.storeCompliance) {
                this.form.name = this.storeCompliance.name;
                this.form.birth = this.storeCompliance.birth;
                this.form.social_name = this.storeCompliance.social_name;
                this.form.cpf = this.storeCompliance.cpf;
                this.form.cnpj = this.storeCompliance.cnpj;
                this.form.company_partner_cpf = this.storeCompliance.company_partner_cpf;
                this.form.type = this.storeCompliance.type;

                if (this.storeCompliance.company_partner) {
                    this.form.company_partner = this.storeCompliance.company_partner;

                    this.form.company_partner.birth = this.storeCompliance.company_partner.birth ?
                        dayjs(this.storeCompliance.company_partner.birth).format('DD/MM/YYYY') :
                        '';
                }
            }
        }
    },

    methods: {
        ...mapActions(Store, ['fetchCompliance']),
        closeModal() {
            this.$emit("closeModal");
        },
        submit() {
            this.loading = true;
            axios.put('/api/user/' + this.storeCompliance.id, this.form)
                .then(async ({data}) => {
                    await this.fetchCompliance()
                    this.toast.success('Salvo com sucesso.');
                    this.$emit("closeModalAndFetch");
                    this.loading = false;
                }).catch(async ({response}) => {
                if (response.data.hasOwnProperty('errors')) {
                    this.toast.error(response.data.errors[0]);
                } else {
                    this.toast.error(response.data.message);
                }

                this.loading = false;
            });
        },
    },
};
</script>
