<template>
    <div class="bg-white dark:bg-gray-800">
        <div class="animate-pulse py-1">
            <div class="flex space-x-4 items-baseline m-6">
                <template v-for="n in 3">
                    <div class="flex-1 h-24 bg-gray-200 rounded"></div>
                    <div class="flex-1 h-36 bg-gray-200 rounded"></div>
                    <div class="flex-1 h-48 bg-gray-200 rounded"></div>
                    <div class="flex-1 h-64 bg-gray-300 rounded"></div>
                    <div class="flex-1 h-72 bg-gray-200 rounded"></div>
                    <div class="flex-1 h-64 bg-gray-300 rounded"></div>
                    <div class="flex-1 h-48 bg-gray-200 rounded"></div>
                    <div class="flex-1 h-36 bg-gray-200 rounded"></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            classes: [
                'h-24',
                'h-36',
                'h-48',
                'h-64',
            ],
            line: 0,
            operation: 'add'
        }
    },
    methods: {
        height(index) {
            //alter between h-24, h-36, h-48, h-64, h-48, h-36, h-24 up and down
            if (index >= 4) {
                index = index - (index * 1);
            }

            return this.classes[index];
        },
        getBackgroundColor(index) {
            // Retorna uma classe de cor de fundo alternada para cada linha
            return index % 2 === 0 ? 'bg-gray-400' : 'bg-gray-300';
        }
    }
}
</script>
