<template>
    <submit-button class="ml-2 text-sm" size="sm" @click.prevent="show = true;">Adicionar</submit-button>
    <modal
        class="min-h-full"
        :open="notice || show"
        @close="show = false;$emit('resetNotice')"
    >
        <div class="flex flex-col flex-wrap w-full space-y-3 text-white divide-y divide-slate-500 min-h-fit">
        </div>
        <h1 class="flex flex-row flex-wrap items-center content-center pb-1 mb-2 text-sm text-lg font-bold text-white border-b border-slate-500">
            Adicionar Aviso
        </h1>

        <notice-form @fetch="show= false;$emit('fetch');$emit('resetNotice')" :notice="notice" />
    </modal>
</template>

<script>
    import Modal from "../../layouts/Modal.vue";
    import SubmitButton from "../../forms/SubmitButton.vue";
    import NoticeForm from "../NoticeForm.vue";

    export default {
        props: {notice: {type: Object, default: null}},
        components: {NoticeForm, SubmitButton, Modal},
        emits: ['fetch'],
        data() {
            return {
                show: false,
            }
        },
    }
</script>
