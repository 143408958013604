<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="
                    flex
                    items-end
                    justify-center
                    min-h-screen
                    pt-4
                    px-4
                    pb-20
                    text-center
                    sm:block sm:p-0
                "
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="
                            fixed
                            inset-0
                            bg-gray-600 bg-opacity-75
                            transition-opacity
                        "
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="
                            inline-block
                            align-bottom
                            bg-gray-700
                            rounded-lg
                            px-4
                            pt-5
                            pb-4
                            text-left
                            overflow-hidden
                            shadow-xl
                            transform
                            transition-all
                            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
                        "
                    >
                        <SvgLoader v-if="this.loading" />
                        <form @submit.prevent="handleSave" v-else>
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="
                                        mt-3
                                        text-center
                                        sm:mt-0 sm:text-left
                                    "
                                >
                                    <dialog-title
                                        as="h3"
                                        class="
                                            text-lg
                                            leading-6
                                            font-medium
                                            text-white
                                        "
                                    >
                                        Conquistas
                                    </dialog-title>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex flex-col">
                                <div class="grid sm:grid-cols-1 gap-2 mb-3">
                                    <div>
                                        <label class="text-white">
                                            Título
                                        </label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            v-model="form.title"
                                            class="
                                                bg-gray-50
                                                border border-gray-300
                                                text-gray-900 text-sm
                                                rounded-lg
                                                focus:ring-blue-500
                                                focus:border-blue-500
                                                block
                                                w-full
                                                p-2.5
                                                dark:bg-gray-700
                                                dark:border-gray-600
                                                dark:placeholder-gray-400
                                                dark:text-white
                                                dark:focus:ring-blue-500
                                                dark:focus:border-blue-500
                                            "
                                            placeholder="Nome"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Descrição
                                        </label>
                                        <input
                                            type="text"
                                            id="description"
                                            minlength="10"
                                            name="description"
                                            v-model="form.description"
                                            class="
                                                bg-gray-50
                                                border border-gray-300
                                                text-gray-900 text-sm
                                                rounded-lg
                                                focus:ring-blue-500
                                                focus:border-blue-500
                                                block
                                                w-full
                                                p-2.5
                                                dark:bg-gray-700
                                                dark:border-gray-600
                                                dark:placeholder-gray-400
                                                dark:text-white
                                                dark:focus:ring-blue-500
                                                dark:focus:border-blue-500
                                            "
                                            placeholder="Descrição"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label class="text-white"> Tipo </label>
                                        <select
                                            required
                                            v-model="form.type"
                                            class="
                                                w-full
                                                bg-gray-50
                                                border border-gray-300
                                                text-gray-900 text-sm
                                                rounded-lg
                                                focus:ring-blue-500
                                                focus:border-blue-500
                                                p-2.5
                                                dark:bg-gray-700
                                                dark:border-gray-600
                                                dark:placeholder-gray-400
                                                dark:text-white
                                                dark:focus:ring-blue-500
                                                dark:focus:border-blue-500
                                            "
                                        >
                                            <option
                                                v-for="(
                                                    achievementType, key
                                                ) in achievementTypes"
                                                :key="key"
                                                :value="key"
                                            >
                                                {{ achievementType }}
                                            </option>
                                        </select>
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Nível que desbloqueia a conquista
                                        </label>
                                        <select
                                            required
                                            v-model="form.level_id"
                                            class="
                                                w-full
                                                bg-gray-50
                                                border border-gray-300
                                                text-gray-900 text-sm
                                                rounded-lg
                                                focus:ring-blue-500
                                                focus:border-blue-500
                                                p-2.5
                                                dark:bg-gray-700
                                                dark:border-gray-600
                                                dark:placeholder-gray-400
                                                dark:text-white
                                                dark:focus:ring-blue-500
                                                dark:focus:border-blue-500
                                            "
                                        >
                                            <option
                                                v-for="level in levels"
                                                :key="level.id"
                                                :value="level.id"
                                            >
                                                {{ level.id }} -
                                                {{ level.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Imagem
                                        </label>
                                        <div v-if="form.image_url && !form.image_url?.includes('tmp')">
                                            <img
                                                :src="form.image_url"
                                                :alt="form.title"
                                            />
                                            <button
                                                @click.prevent="
                                                    handleDeleteImage()
                                                "
                                                type="button"
                                                :class="{
                                                    'cursor-not-allowed':
                                                        loading,
                                                }"
                                                class="
                                                    inline-flex
                                                    justify-center
                                                    items-center
                                                    mt-2
                                                    py-1
                                                    px-2
                                                    font-semibold
                                                    leading-6
                                                    text-sm
                                                    shadow
                                                    rounded-md
                                                    text-white
                                                    bg-red-500
                                                    hover:bg-red-400
                                                    transition
                                                    ease-in-out
                                                    duration-150
                                                "
                                            >
                                                <TrashIcon
                                                    class="
                                                        h-4
                                                        w-4
                                                        cursor-pointer
                                                        disabled:cursor-not-allowed
                                                    "
                                                    :disabled="loading"
                                                    @click="
                                                        selectedUser = false
                                                    "
                                                    aria-hidden="true"
                                                />
                                                Remover imagem
                                            </button>
                                        </div>
                                        <file-pond
                                            v-else="form.image_url"
                                            name="image_url"
                                            ref="pond"
                                            class-name="my-pond"
                                            label-idle="Arraste ou clique para adicionar arquivos..."
                                            allow-multiple="false"
                                            accepted-file-types="image/jpeg, image/png"
                                            :server="{
                                                process: uploadFile,
                                            }"
                                        />
                                    </div>
                                </div>

                                <div class="mt-3 grid sm:grid-cols-2 gap-5">
                                    <button
                                        :disabled="loading"
                                        type="submit"
                                        class="
                                            mb-3
                                            w-full
                                            inline-flex
                                            justify-center
                                            rounded-md
                                            border border-transparent
                                            shadow-sm
                                            px-4
                                            py-2
                                            bg-green-500
                                            text-base
                                            font-medium
                                            text-white
                                            focus:outline-none
                                            sm:w-auto sm:text-sm
                                        "
                                    >
                                        Confirmar
                                    </button>

                                    <button
                                        :disabled="loading"
                                        type="button"
                                        class="
                                            mb-3
                                            w-full
                                            inline-flex
                                            justify-center
                                            rounded-md
                                            border border-gray-300
                                            shadow-sm
                                            px-4
                                            py-2
                                            bg-white
                                            text-base
                                            font-medium
                                            text-gray-700
                                            hover:bg-gray-50
                                            focus:outline-none
                                            focus:ring-2
                                            focus:ring-offset-2
                                            focus:ring-indigo-500
                                            sm:mt-0 sm:w-auto sm:text-sm
                                        "
                                        @click="closeModal()"
                                        ref="cancelButtonRef"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useToast } from "vue-toastification";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";
import MoneyInput from "../../../components/forms/MoneyInput.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";

//filepond
import vueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        SvgLoader,
        MoneyInput,
        FilePond,
        TrashIcon,
    },
    props: ["achievementId"],
    setup() {
        const achievementTypes = {
            plate: "Placas",
        };
        const toast = useToast();
        return { toast, achievementTypes };
    },
    data() {
        return {
            loading: false,
            form: {
                title: null,
                type: null,
                description: null,
                image_url: null,
                level_id: null,
            },
            achievement: null,
            levels: null,
        };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        fetchAchievements() {
            this.$emit("fetchAchievements");
        },
        fetchLevels() {
            this.loading = true;

            axios
                .get("/api/levels")
                .then(({ data }) => {
                    this.levels = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        handleSave() {
            if (!this.achievementId) {
                axios
                    .post(`/api/achievements/`, this.form)
                    .then((response) => {
                        if (response) {
                            this.toast.success("Conquista salva com sucesso");
                            this.fetchAchievements();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar conquista");
                    });
            } else {
                axios
                    .put(`/api/achievements/${this.achievementId}`, this.form)
                    .then((response) => {
                        if (response) {
                            this.toast.success("Conquista salva com sucesso");
                            this.fetchAchievements();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar conquista");
                    });
            }
        },
        findOne(id) {
            this.loading = true;

            axios
                .get(`/api/achievements/${id}`)
                .then((response) => {
                    this.achievement = response.data;
                    this.form.title = this.achievement.title;
                    this.form.type = this.achievement.type;
                    this.form.description = this.achievement.description;
                    this.form.image_url = this.achievement.image_url;
                    this.form.level_id = this.achievement.level_id;
                })
                .catch((error) => {
                    this.toast.error("Erro ao editar conquista");
                })
                .finally(() => (this.loading = false));
        },

        // !!! TODO - componentizar o upload de imagem
        async store(file, options = null) {
            const response = await axios.post(
                "/vapor/signed-storage-url",
                {
                    bucket: options.bucket || "",
                    content_type: options.contentType || file.type,
                    expires: options.expires || "",
                    visibility: "public-read",
                    ...options.data,
                },
                {
                    // baseURL: options.baseURL || null,
                    ...options.options,
                }
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Erro ao fazer upload.");
            }

            let headers = response.data.headers;

            if ("Host" in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === "undefined") {
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || "";

            await axios.put(response.data.url, file, {
                cancelToken: cancelToken,
                headers: headers,
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    options.progress(
                        progressEvent.loaded / progressEvent.total
                    );
                },
            });

            response.data.extension = file.name.split(".").pop();

            return response.data;
        },
        uploadFile(
            fieldName,
            file,
            metadata,
            load,
            error,
            filePondProgress,
            abort
        ) {
            this.store(file, {
                progress: async (progress) => {
                    filePondProgress(Math.round(progress * 100));
                },
                abort,
            })
                .then((response) => {
                    load(response);
                    this.form.image_url = response?.key;
                })
                .catch((error) => {
                    abort();
                    this.form.image_url = null;
                });
        },
        handleDeleteImage() {
            this.form.image_url = null;
        },
    },
    mounted() {
        this.fetchLevels();
        if (this.achievementId) {
            this.findOne(this.achievementId);
        }
    },
};
</script>
