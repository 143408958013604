<template>
    <card title="Browsers">
        <div class="w-full">
            <p class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm">
                {{ name }}
            </p>
        </div>
        <div class="flex flex-col">
            <div v-if="loading" class="space-y-4">
                <div v-for="(n, index) in 10" :key="index" class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div class="flex animate-pulse">
                        <div class="h-2.5 first:rounded-l-lg last:rounded-r-lg bg-blue-700"
                             :style="'width: ' + ((10 - index) * 0.9) * 10 + '%'">
                        </div>
                    </div>
                </div>
            </div>
            <div v-else v-for="bucket in buckets.buckets">
                <div class="flex justify-between">
                    <span class="truncate">{{bucket.key_as_string || bucket.key}}</span>
                    <span>{{bucket.doc_count}}</span>
                </div>
                <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-3">
                    <div class="flex">
                        <div
                            class="h-2.5 first:rounded-l-lg last:rounded-r-lg bg-blue-700"
                            :style="'width: ' + (bucket.doc_count / totalCount) * 100 + '%'"
                        ></div>
                    </div>
                </div>
            </div>
            <div v-if="!loading">
                <div class="flex justify-between">
                    <span>Outros</span>
                    <span>{{buckets.sum_other_doc_count}}</span>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import Card from "../layouts/Card.vue";

export default {
    components: {Card},
    props: {
        buckets: {type: Object, required: true},
        name: {type: String, required: true},
        loading: {type: Boolean, required: true}
    },
    data() {
        return {

        }
    },

    computed: {
        totalCount() {
            if (!this.buckets || !this.buckets.buckets) {
                return 0
            }

            return this.buckets.buckets.reduce((acc, cur) => acc + cur.doc_count, 0)
        }
    }
}
</script>
