<template>
     <span
         :class="{
            'bg-blue-600': type === 'Produtor',
            'bg-green-600': type === 'Afiliado'
         }"
         v-tooltip="tracking.affiliate_id ?? 'Produtor'"
         class=" text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white uppercase"
     >
     {{ type }}
</span>

</template>

<script>
    export default {
        props: ['tracking', 'products', 'affiliates'],

        computed: {
            type() {
                return this.tracking.affiliate_id ?
                    'Afiliado' :
                    'Produtor';
            },
        }
    }
</script>
