<template>
    <div class="not-prose relative mt-5 overflow-hidden dark:bg-slate-800/25">
        <button
            class="btn-primary-sm my-2 ml-auto"
            :disabled="loading"
            @click.prevent="exportRanking"
        >
            <ArrowDownTrayIcon
                class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                aria-hidden="true"
            />
            Exportar
        </button>
        <ticto-loading v-if="loading"></ticto-loading>

        <div class="flex h-[700px] w-full gap-4 overflow-x-scroll">
            <div
                class="h-full w-full min-w-[300px] rounded-t-lg bg-slate-50 dark:bg-slate-800/25"
                v-for="(level, key) in levels"
                :key="key"
            >
                <div class="mb-2 rounded-t-lg bg-slate-800 p-2 text-white">
                    <p
                        class="text-md align-center mb-2 flex font-medium text-white"
                    >
                        {{ key }}
                        <span class="ml-auto">
                            {{ $root.formatCurrency(level.amount) }}
                        </span>
                    </p>
                </div>

                <div
                    class="flex max-h-[600px] flex-col items-start gap-2 overflow-y-scroll p-2"
                >
                    <div
                        v-for="user in level.users"
                        :key="user.id"
                        class="h-14 w-full cursor-pointer rounded-md bg-gray-100 px-1 dark:bg-slate-800"
                    >
                        <div
                            class="flex items-center gap-3"
                            @click.prevent="manageUser(user)"
                        >
                            <img
                                class="h-6 w-6 rounded-full"
                                :src="
                                    user.avatar ||
                                    'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                        user.name
                                "
                                alt=""
                            />
                            <div>
                                <small class="font-semibold dark:text-white">
                                    {{ user.name }}
                                </small>
                                <p>
                                    <small class="dark:text-white">
                                        {{
                                            $root.formatCurrency(
                                                user.total_commissions,
                                            )
                                        }}
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import Card from "../../../components/layouts/Card.vue";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import { Store } from "../../../stores/store";

export default {
    components: {
        TransitionRoot,
        TictoLoading,
        Card,
        ArrowDownTrayIcon,
    },

    setup() {
        return {};
    },

    data() {
        return {
            levels: [],
            loading: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["user"]),
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get("api/user/ranking-user-commission")
                .then(({ data }) => {
                    this.levels = data[0];
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        manageUser(user) {
            this.user = user;
            this.$router.push({
                name: "user.commissions",
                params: {
                    user_id: user.id,
                },
            });
        },
        exportRanking() {
            this.loading = true;

            axios
                .get("api/user/ranking-user-commission/export")
                .then(({ data }) => {
                    window.open(data, '_blank');
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
