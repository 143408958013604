<template>
    <div class="flex flex-col dark:text-white">
        <div class="my-5 flex flex-col">
            <form
                class="flex content-center items-center"
                @submit.prevent="
                    anticipations = [];
                    next_page_url = false;
                    fetch();
                "
            >
                <div class="grow">
                    <input
                        v-model="filters.text"
                        type="text"
                        id="text"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Filtre pelo nome ou e-mail do usuário"
                        required
                    />
                </div>
                <div class="flex-none flex">
                    <button
                        @click.prevent="clearFilters"
                        :disabled="false"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mr-2 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-indigo-500 mx-2 hover:text-white hover:bg-indigo-500 bg-transparent border-2 border-indigo-500 transition ease-in-out duration-150"
                    >
                        Limpar filtros
                    </button>
                    <button
                        @click.prevent="
                            anticipations = [];
                            next_page_url = false;
                            fetch();
                        "
                        :disabled="false"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mx-auto inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                    >
                        <svg
                            v-if="loading"
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Filtrar
                    </button>
                </div>
            </form>
        </div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <div
                    class="overflow-hidden not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25"
                >
                    <table class="border-collapse table-auto w-full text-sm">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                ></th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Data
                                </th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Nome do Favorecido
                                </th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Tipo da Pessoa
                                </th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Valor Solicitado
                                </th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Valor a Receber
                                </th>
                                <th
                                    scope="col"
                                    class="text-sm font-medium px-6 py-4 text-left"
                                >
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white dark:bg-slate-800">
                            <tr v-for="anticipation in anticipations">
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    <div
                                        @click.prevent="manage(anticipation)"
                                        class="text-indigo-400 hover:underline hover:text-indigo-700 hover:cursor-pointer"
                                    >
                                        #{{ anticipation.id }}
                                    </div>
                                </td>
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    {{ anticipation.created_at }}
                                </td>

                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    {{ anticipation.user.name }}
                                </td>
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    {{
                                        anticipation.user.company_type ===
                                        "company"
                                            ? "JURÍDICA"
                                            : "FÍSICA"
                                    }}
                                </td>
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    {{
                                        $root.formatCurrency(
                                            anticipation.amount
                                        )
                                    }}
                                </td>
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                                >
                                    {{
                                        $root.formatCurrency(
                                            anticipation.net_value
                                        )
                                    }}
                                </td>
                                <td
                                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200 items-center justify-center"
                                >
                                    <div
                                        class="text-sm font-medium px-2.5 py-1.5 rounded w-full text-center"
                                        :class="
                                            getCashoutStatusClass(
                                                anticipation.status
                                            )
                                        "
                                    >
                                        {{
                                            getCashoutStatusName(
                                                anticipation.status
                                            )
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="flex w-full my-5">
                        <div class="flex flex-col items-center mx-auto">
                            <span class="mb-1">
                                Exibindo {{pagination.from}} a {{pagination.to}} registros de {{pagination.total}}
                            </span>
                            <pagination
                                :pagination="pagination"
                                :offset="1"
                                @paginate="fetch"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSelect from "../../components/forms/VueSelect.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import { mapWritableState, mapActions } from "pinia";
import { Store } from "../../stores/store.js";
import HelperMixin from "../../mixins/HelperMixin.vue";
import BankMixin from "../../mixins/BankMixin.vue";

export default {
    components: { VueSelect, Pagination },
    mixins: [HelperMixin, BankMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            anticipations: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
            filters: {
                text: "",
                status: [],
                types: [],
            },
        };
    },

    computed: {
        ...mapWritableState(Store, ["storeAnticipation"]),
    },

    mounted() {
        this.storeProduct = false;

        this.fetch();
    },

    methods: {
        clearFilters() {
            this.anticipations = [];
            this.filters.status = [];
            this.filters.types = [];
            this.filters.text = "";
            this.next_page_url = false;
            this.fetch();
        },
        manage(anticipation) {
            this.storeAnticipation = anticipation;
            this.$router.push({
                name: "finnancial.anticipation-details",
                params: { anticipation_id: anticipation.id },
            });
        },
        fetch() {
            this.loading = true;

            const params = {};
            params['page'] = this.pagination.current_page;
            params["filter[text]"] = this.filters.text;

            axios
                .get("/api/anticipation", {
                    params: params,
                })
                .then(({ data }) => {
                    this.anticipations = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast.error(error.response.data.message);
                });
        },
    },
};
</script>
