<template>
    <div class="flex flex-col dark:text-white">
        <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
            Pedido
        </h3>
        <div
            class="min-w-0 w-full h-50 rounded-lg overflow-hidden bg-white dark:bg-gray-800 mb-4 drop-shadow-sm p-5"
        >
            <div class="grid grid-cols-3 gap-3">
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Número</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-if="order"
                        >#{{ order.id }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Código</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-if="order"
                        >{{ order.order_hash }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Valor do Pedido</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-if="order"
                        >{{ $root.formatCurrency(order.amount + order.amount_card2) }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Dois Cartões</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.is_two_cards" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.is_two_cards" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Boleto Parcelado</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.has_bank_slip_installment" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.has_bank_slip_installment" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
                <div class="flex flex-col">
                      <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Parcelamento Inteligente</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.has_smart_installment" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.has_smart_installment" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
                <div class="flex flex-col">
                      <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Upsell</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.is_upsell" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.is_upsell" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
                <div class="flex flex-col">
                      <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Possui Order Bump</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.has_order_bump" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.has_order_bump" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
                <div class="flex flex-col">
                      <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Possui Assinatura</span
                    >

                    <info-col v-if="order">
                        <img v-if="order.has_subscription" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!order.has_subscription" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../mixins/HelperMixin.vue";

export default {
    props: ["order"],
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            commissions: [],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },
};
</script>
