<template>
    <div class="flex flex-col justify-center dark:text-white">
        <img
            class="w-13 mx-auto"
            src="https://d2ucq9llvgnbyn.cloudfront.net/error.svg"
        >
        <h2 class="font-bold mx-auto text-2xl my-3">Sem registros aqui</h2>
        <p class="font-light mx-auto text-xl">Não há registros para serem exibidos</p>
    </div>
</template>

<script>
    export default {

    }
</script>
