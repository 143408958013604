<template>
    <h3 class="text-xl leading-6 font-large dark:text-white my-3 flex item-center">
        Custos Mensais <store-modal @fetch="fetch"/>
    </h3>

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Categoria',
            'Valor',
            'Descrição',
            ''
        ]"
        @fetch="fetch"
    >
        <tr v-for="cost in costs">
            <td class="table-cell">{{cost.date}}</td>
            <td class="table-cell">{{ costName(cost.category) }}</td>
            <td class="table-cell">{{ $root.formatCurrency(cost.amount) }}</td>
            <td class="table-cell">{{ cost.description }}</td>
            <td class="table-cell">
                <cost-actions :cost="cost" @fetch="fetch"/>
            </td>
        </tr>
    </table-component>
</template>

<script>
import StoreModal from "../../components/costs/modals/StoreModal.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import CostActions from "../../components/costs/CostActions.vue";
export default {
    components: {TableComponent, StoreModal, CostActions},
    data() {
        return {
            loading: false,
            costs: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        costName(name) {
            switch (name) {
                case 'personnel':
                    return 'Despesas com Pessoal';
                case 'administrative':
                    return 'Despesas administrativas';
                case 'financial':
                    return 'Despesas financeiras';
                case 'withdrawals':
                    return 'Despesas diretas com operações (SAQUES)';
                case 'marketing':
                    return 'Marketing';
                case 'third_party':
                    return 'Terceiros';
                case 'tools':
                    return 'Ferramentas';
                case 'infrastructure':
                    return 'Infraestrutura';
                case 'taxes':
                    return 'Impostos';
                case 'pro_labore':
                    return 'Pro Labore';
                default :
                    return 'Desconhecido';
            }
        },
        fetch() {
            this.loading = true;

            let params = {};
            params['page'] = this.pagination.current_page;

            axios.get('/api/cost', {
                params
            }).then(({data}) => {
                this.costs = data.data;
                this.pagination = data;
                this.loading = false;
            })
        }
    }
}
</script>
