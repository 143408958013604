<template>
    <div class="flex flex-col dark:text-white">
        <filters
            class="mt-3"
            :loading="loading"
            v-model="filters"
            category-filter
            :categories="categories"
            :types="types"
            status-filter
            id-filter
            :statuses="[
                { value: 'draft', label: 'Rascunho' },
                { value: 'pre-approved', label: 'Pré Aprovado' },
                { value: 'pending', label: 'Pendente' },
                { value: 'pending-correction', label: 'Correção Pendente' },
                { value: 'approved', label: 'Aprovado' },
                { value: 'reproved', label: 'Reprovado' },
            ]"
            type-filter
            type-label="Tipo"
            search-filter
            search-label="Filtre pelo id ou nome"
            @fetch="
                products = [];
                next_page_url = false;
                this.pagination.current_page = 1;
                fetch();
            "
        >
            <form-toggle
                class="ml-3"
                v-model="commissions"
                :loading="loading"
                label="Vendas"
            >
            </form-toggle>
        </filters>
        <table-component
            :loading="loading"
            :pagination="pagination"
            :headers="headers"
            select-all
            @fetch="fetch"
            @selectAll="selectAll"
            :selected="selected"
            :all-selected="all_selected"
        >
            <template v-slot:exportHeader>
                <update-products
                    :products="selected"
                    @fetch="
                        selected = [];
                        fetch();
                    "
                />
            </template>
            <tr v-for="product in products">
                <td class="table-cell-sm">
                    <div class="flex">
                        <input
                            :id="'check-product-' + product.id"
                            name="orders"
                            type="checkbox"
                            class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                            v-model="selected"
                            :value="product.id"
                        />
                        <a
                            @click.prevent="manage(product)"
                            class="flex items-center text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                        >
                            #{{ product.id }}
                        </a>
                    </div>
                </td>
                <td class="table-cell-sm">
                    {{ product.created_at }}
                </td>
                <td class="table-cell-sm">
                    <span
                        :title="product.name"
                        class="block max-w-[80px] truncate"
                        v-tooltip="product.name"
                    >
                        {{ product.name }}
                    </span>
                </td>
                <td class="table-cell-sm">
                    <span
                        :title="product.user.name"
                        class="block max-w-[50px] truncate"
                        v-tooltip="product.user.name"
                    >
                        {{ product.user.name }}
                    </span>
                </td>
                <td class="table-cell-sm flex py-2">
                    <product-status :status="product.status"></product-status>
                </td>
                <td class="table-cell-sm">
                    <span v-if="product.commission">
                        {{ $root.formatCurrency(product.commission) }}
                    </span>
                    <span v-else>
                        {{ $root.formatCurrency(0) }}
                    </span>
                </td>
                <td class="table-cell-sm">
                    <product-type :type="product.type"></product-type>
                </td>
                <td class="table-cell-sm">
                    {{ product.category.name }}
                </td>
                <td class="table-cell-sm">
                    <div class="item-center flex">
                        <component
                            :is="
                                !product.is_hidden
                                    ? 'CheckCircleIcon'
                                    : 'MinusCircleIcon'
                            "
                            class="mr-1.5 h-5 w-5 flex-shrink-0"
                            :class="{
                                'text-red-400': product.is_hidden,
                                'text-green-400': !product.is_hidden,
                            }"
                        />
                        {{ product.is_hidden ? "Oculto" : "Visível" }}
                    </div>
                </td>
                <td v-if="ebookSelected" class="table-cell-sm">
                    <div
                        class="h-2 w-full animate-pulse rounded bg-gray-500"
                        v-if="loadingEbooks"
                    ></div>
                    <button
                        v-if="!loadingEbooks && productEbook(product)"
                        @click="viewEbook(product)"
                        :disabled="loadingViewEbook"
                        class="ml-auto font-medium text-indigo-600 hover:cursor-pointer hover:text-indigo-500"
                    >
                        Download
                    </button>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import {
    MinusCircleIcon,
    CheckCircleIcon,
    CloudArrowDownIcon,
} from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import FormToggle from "../../components/forms/FormToggle.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import Filters from "../../components/layouts/Filters.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import ProductStatus from "../../components/layouts/ProductStatus.vue";
import ProductType from "../../components/layouts/ProductType.vue";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import UpdateProducts from "../../components/product/UpdateProducts.vue";
import { Store } from "../../stores/store.js";

export default {
    components: {
        FormToggle,
        UpdateProducts,
        Filters,
        TableComponent,
        VueSelect,
        Pagination,
        CheckCircleIcon,
        MinusCircleIcon,
        ProductStatus,
        ProductType,
        CloudArrowDownIcon,
        SvgLoader,
    },
    data() {
        return {
            loading: false,
            next_page_url: false,
            selected: [],
            all_selected: false,
            products: [],
            types: [],
            categories: [],
            commissions: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                status: [],
                types: [],
                categories: [],
            },
            categoriesFilter: {
                type: Array,
                default: [],
            },
            ebooks: [],
            loadingEbooks: true,
            loadingViewEbook: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["storeProduct"]),

        filteredStatuses() {
            return this.filters.status.map((status) => {
                return {
                    value: status,
                    label: this.statuses.find((s) => s.value === status).label,
                };
            });
        },
        filteredTypes() {
            return this.filters.types.map((type) => {
                return {
                    value: type,
                    label: this.typesFilter.find((s) => s.value === type).label,
                };
            });
        },
        filteredCategories() {
            return this.filters.categories.map((category) => {
                return {
                    value: category,
                    label: this.categoriesFilter.find(
                        (s) => s.value === category,
                    ).label,
                };
            });
        },
        ebookSelected() {
            if (this.filters["filter[type]"]) {
                return this.filters["filter[type]"].includes("ebook");
            }

            return false;
        },
        headers() {
            let headers = [
                "",
                "Data",
                "Produto",
                "Produtor",
                "Status",
                "Vendas",
                "Tipo",
                "Categoria",
                "Visibilidade",
            ];
            if (this.ebookSelected) {
                headers.push("Anexo");
            }

            return headers;
        },
    },

    watch: {
        ebookSelected() {
            if (this.ebookSelected) {
                this.fetchEbooks();
            }
        },
    },

    mounted() {
        this.storeProduct = false;
        axios.get("/api/product/types").then(({ data }) => {
            this.types = Object.entries(data).map((entry) => {
                return { value: entry[0], label: entry[1] };
            });
        });
        axios.get("/api/product/categories").then(({ data }) => {
            this.categories = data.data;
            this.categoriesFilter = data.data;
        });
        this.fetch();
    },

    methods: {
        manage(product) {
            this.storeProduct = product;
            this.$router.push({
                name: "product",
                params: { product_id: product.id },
            });
        },
        selectAll() {
            this.all_selected = !this.all_selected;
            if (this.all_selected) {
                axios
                    .get("/api/product/select-all", {
                        params: this.filters,
                    })
                    .then(({ data }) => {
                        this.selected = data;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.selected = [];
            }
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;
            if (this.commissions) {
                params.commissions = true;
            }
            axios
                .get("/api/product", {
                    params: params,
                })
                .then(({ data }) => {
                    this.products = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;

                    if (this.ebookSelected) {
                        this.fetchEbooks();
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$toast.error(error.response.data.message);
                });
        },
        fetchEbooks() {
            this.loadingEbooks = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/product/ebooks", {
                    params: params,
                })
                .then(({ data }) => {
                    this.ebooks = data;
                    this.loadingEbooks = false;
                })
                .catch((error) => {
                    this.loadingEbooks = false;
                    // this.$toast.error(error.response.data.message);
                });
        },
        productEbook(product) {
            return this.ebooks.find((ebook) => ebook.product_id === product.id);
        },
        viewEbook(product) {
            this.loadingViewEbook = true;
            axios
                .get("/api/product/" + product.id + "/ebook/show")
                .then(({ data }) => {
                    this.loadingViewEbook = false;
                    window.open(data.url, "_blank");
                });
        },
    },
};
</script>
