<template>
    <nav class="space-y-1" aria-label="Sidebar">
        <router-link
            v-for="item in navigation"
            :key="item.name"
            :to="item.route"
            class="group flex items-center px-3 py-2 text-sm font-medium rounded-md hover:text-indigo-900"
            v-bind="activeClass"
            exact-active-class="bg-gray-100 text-gray-900 dark:text-indigo-800"
            :class="[item.route.name === $route.name ? '' : 'dark:text-white dark:hover:text-indigo-900 text-gray-600 hover:bg-gray-50 hover:text-indigo-900']"
        >
            <component
                :is="item.icon"
                :class="[
                    item.route.name === $route.name ?
                        'text-indigo-800' :
                        'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                ]"
                aria-hidden="true"
            />
            <span class="truncate">
                {{ item.name }}
            </span>
        </router-link>
    </nav>
</template>

<script>
    export default {
        props: {
            navigation: {
                type: Array,
                default: []
            },

            exact: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            activeClass() {
                let classString = 'bg-gray-100 text-gray-900 dark:text-indigo-800'
                return this.exact ?
                    { 'exact-active-class': classString } :
                    { 'active-class': classString }
            }
        }
    }
</script>
