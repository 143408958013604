<template>
    <div class="grid grid-cols-5 mb-4 gap-1 m-4">
        <info-col title="Req Id" :loading="!visitorData">
            {{visitorData.requestId}}
        </info-col>
        <info-col title="Visitor Id" :loading="!visitorData">
            {{visitorData.visitorId}}
        </info-col>
        <info-col title="Browser" :loading="!visitorData">
            {{visitorData.browserName}} {{visitorData.browserVersion}}
        </info-col>
        <info-col title="Os" :loading="!visitorData">
            {{visitorData.os}} {{visitorData.osVersion}}
        </info-col>
        <info-col title="Ip" :loading="!visitorData">
            {{visitorData.ip}}
        </info-col>
    </div>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Req Id',
            'Visitor Id',
            'Browser',
            'Os',
            'Ip',
            'Local',
            'Url'
        ]"
        @fetch="fetch"
    >
        <tr v-for="event in events">
            <td class="table-cell-sm">
                <router-link
                    to="/"
                    class="text-indigo-600 hover:cursor:pointer hover:underline block w-32 truncate"
                    v-tooltip="event._source.requestId"
                >
                    {{event._source.requestId}}
                </router-link>
            </td>
            <td
                class="table-cell-sm"
                :class="{
                    // 'bg-yellow-500 text-gray-800': event._source.visitorId === visitorData.visitorId
                }"
            >
                <router-link
                    :to="{
                        name: 'visitor.events',
                        params: {
                            visitor_id: event._source.visitorId
                        }
                    }"
                    class="text-indigo-600 hover:cursor:pointer hover:underline block w-32 truncate"
                >
                    {{event._source.visitorId}}
                </router-link>
            </td>
            <td class="table-cell-sm">
                <div class="flex space-x-1">
                    <browser-icon :browser="event._source.browserDetails.browserName"/>
                    <span>{{event._source.browserDetails.browserFullVersion}}</span>
                </div>
            </td>
            <td
                class="table-cell-sm"
                :class="{
                    // 'bg-yellow-500 text-gray-800': event._source.browserDetails.os === visitorData.os && event._source.browserDetails.osVersion === visitorData.osVersion
                }"
            >
                <div class="flex space-x-1">
                    <os-icon :os="event._source.browserDetails.os"/>
                    <span>{{event._source.browserDetails.osVersion}}</span>
                </div>
            </td>
            <td
                class="table-cell-sm"
                :class="{
                    // 'bg-yellow-500 text-gray-800': event._source.ip === visitorData.ip
                }"
            >
                <div class="truncate w-24 block">
                    {{event._source.ip}}
                </div>
            </td>
            <td class="table-cell-sm">
                <div class="flex space-x-1">
                    <span class="truncate">{{ event._source.ipLocation.country.code }}: </span>
                    <span
                        class="truncate"
                        v-if="event._source.ipLocation.city"
                    >
                        {{ event._source.ipLocation.city.name }}
                    </span>
                </div>
            </td>
            <td class="table-cell-sm">
                <div class="truncate w-32 block" v-tooltip="event._source.url">
                    {{event._source.url}}
                </div>
            </td>
        </tr>
    </table-component>
</template>

<script>
    import { Store } from "../../stores/store.js";
    import { mapWritableState } from "pinia";
    import Card from "../layouts/Card.vue";
    import InfoCol from "../layouts/InfoCol.vue";
    import TableComponent from "../layouts/table/TableComponent.vue";
    import BrowserIcon from "../layouts/antifraud/BrowserIcon.vue";
    import OsIcon from "../layouts/antifraud/OsIcon.vue";
    export default {
        components: {OsIcon, BrowserIcon, TableComponent, InfoCol, Card},
        computed: {
            ...mapWritableState(Store, ['visitorData'])
        },

        data() {
            return {
                loading: false,
                events: [],
                pagination: {
                    total: 0,
                    per_page: 35,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = {};
                params['page'] = this.pagination.current_page;

                axios.get('/api/visitor/events', {
                    params: params
                }).then(({ data }) => {
                    this.events = data.hits.hits;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.pagination.total = data.aggregations.object_count.value;
                    this.pagination.from = this.pagination.current_page * this.pagination.per_page - this.pagination.per_page + 1;
                    this.pagination.to = this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page);

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
