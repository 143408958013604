<template>
    <div class="flex flex-col dark:text-white">
        <filters
            v-model="filters"
            @fetch="users = []; fetch();"
            :loading="loading"
            search-filter
            search-label="Filtre por nome, e-mail, cpf ou cnpj"
        />

        <ticto-loading v-if="loading" />

        <div class="mt-3 flex flex-col divide-y dark:divide-slate-700 bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
            <router-link
                v-for="user in users"
                :to="{
                    name: 'finnancial.chargebacks.user',
                    params: { user_id: user.id }
                }"
                class="px-2 hover:cursor-pointer hover:bg-gray-300 dark:hover:bg-slate-900"
            >
                <user-heading :user="user"/>
            </router-link>
        </div>
    </div>
</template>

<script>
    import TictoLoading from "../../../../components/layouts/TictoLoading.vue";
    import Filters from "../../../../components/layouts/Filters.vue";
    import UserHeading from "../../../users/UserHeading.vue";

    export default {
        components: {UserHeading, Filters, TictoLoading},
        data() {
            return {
                filters: {
                    text: "",
                },
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
                loading: false,
                users: []
            }
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = this.filters;
                params['page'] = this.pagination.current_page;

                axios.get("/api/user", {
                    params: params
                }).then(({ data }) => {
                    this.users = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
