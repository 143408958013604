<template>
    <button
        @click.prevent="toggle"
        type="button"
        :class="{'cursor-not-allowed': loading}, {'bg-indigo-500': !notice.is_active, 'bg-red-500': notice.is_active}"
        class="inline-flex items-center px-4 py-2 mx-auto text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
    >
        <svg v-if="loading" class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {{ notice.is_active ? 'Desativar' :'Ativar' }}
    </button>
</template>

<script>
    export default {
        props: ['notice'],

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            toggle() {
                this.loading = true;

                axios.put('/api/notices/' + this.notice.id + '/toggle').then(({data}) => {
                    this.$emit('fetch');
                    this.loading = false;
                })
            }
        }
    }
</script>
