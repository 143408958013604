<template>
    <modal
        size="lg"
        :open="show"
        @close="show = false"
    >
        <form class="flex flex-col flex-wrap w-full space-y-3 text-white" @submit.prevent="submit">
            <h1 class="border-b pb-2 mb-2 border-slate-500">Alterar Email</h1>

            <div class="mb-4">
                <div
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-red-500">
                    {{ error }}
                </div>
            </div>

            <label for="email" class="sr-only">Email</label>

            <input
                type="email"
                id="email"
                class="w-full text-input"
                v-model="email"
                required
            >



            <div class="flex flex-col gap-1">
                <label for="reason">Motivo:</label>
                <input
                    type="text"
                    id="reason"
                    class="w-full text-input"
                    v-model="reason"
                    required
                >
            </div>


            <submit-button
                :loading="loading"
                :disabled="loading"
                class="w-full"
            >
                Enviar
            </submit-button>
        </form>
    </modal>
</template>

<script>
import Modal from "../../../components/layouts/Modal.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";
import { useToast } from "vue-toastification";



export default {
    props: ['user', 'show'],
    components: {SubmitButton, Modal},

    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            email: this.user.email,
            reason: '',
            errors: []
        }
    },

    methods: {
        submit() {
            this.errors = [];
            this.loading = true;

            axios.put('/api/user/' + this.user.id + '/email', {
                email: this.email,
                reason: this.reason
            }).then(() => {
                this.loading = false;
                this.toast.success('Email alterado com sucesso!');
                this.$emit('closeModalAndFetch');
            }).catch(({response}) => {
                if(response.data.errors){
                    this.errors = Object.values(response.data.errors).flat();
                }

                if(response.data.message) {
                    this.errors[this.errors.length + 1] = response.data.message;
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>
