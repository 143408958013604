<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Comissões
    </h3>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3 mt-2">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                <table class="border-collapse table-auto w-full text-sm">
                    <thead>
                        <tr>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Usuário
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Hash da Transação
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Produto
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Tipo
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Comissão
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr v-for="commission in order.commissions">
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                <button
                                    class="text-indigo-500 hover:underline"
                                    @click.prevent="manage(commission.user)"
                                >
                                    {{ commission.user.name }} ({{ commission.user.email }})
                                </button>
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ commission.transaction.hash }}
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ order.items.find(item => item.id === commission.order_item_id).product.name }}
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ formatCommissionType(commission.type) }}
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ $root.formatCurrency(commission.amount) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapWritableState} from "pinia";
    import {Store} from "../../stores/store";
    import CommissionType from "../../mixins/CommissionType.vue";
    export default {
        props: {order: {type: Object, required: true}},
        mixins: [CommissionType],
        computed: {
            ...mapWritableState(Store, ['user']),
        },

        methods: {
            manage(user) {
                this.user = user;
                this.$router.push({
                    name: "user.commissions",
                    params: {
                        user_id: user.id,
                    },
                });
            }
        }
    }
</script>
