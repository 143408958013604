<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Histórico
    </h3>
    <div class="flex flex-col dark:text-white">
        <div>
            <div
                class="min-w-0 w-full h-50 rounded-lg overflow-hidden bg-white dark:bg-gray-800 mb-4 flex drop-shadow-sm p-5"
                v-if="order"
            >
                <ol class="relative border-l border-gray-200 dark:border-gray-700">
                    <li class="mb-10 ml-4" v-for="history in order.histories">
                        <div
                            class="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900"
                            :class="{
                                'bg-green-500': ['authorized'].indexOf(history.status) !== -1,
                                'bg-red-600': ['chargeback', 'error', 'refused', 'refunded', 'aborted'].indexOf(history.status) !== -1,
                                'bg-amber-400': ['waiting_payment', 'refund_requested', 'disputed', 'claimed'].indexOf(history.status) !== -1,
                                'bg-neutral-500': ['delayed', 'expired', 'processing'].indexOf(history.status) !== -1,
                                'bg-blue-500': ['closed', 'trial'].indexOf(history.status) !== -1,
                            }"
                        ></div>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            {{ transactionStatusName(history.status) }}
                        </p>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200 flex flex-wrap"
                        >
                            <ClockIcon class="w-5 mr-1"></ClockIcon>
                            {{ history.created_at }}
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../mixins/HelperMixin.vue";
import Status from "../../components/layouts/Status.vue";
import { ClockIcon } from "@heroicons/vue/24/outline";

export default {
    props: ["order"],
    mixins: [HelperMixin],
    components: {Status, ClockIcon},
    data() {
        return {};
    },
};
</script>
