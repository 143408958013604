<template>
    <div v-if="details">
        <h3
            class="mb-2 mt-5 text-lg font-bold font-medium text-gray-400"
            v-if="details.audit && details.audit.portals"
        >
            Portais
        </h3>

        <template v-if="!details.audit.new_portals_structure">
            <card
                v-if="
                    details.audit &&
                    details.audit.portals &&
                    details.audit.portals.length > 0
                "
                v-for="portal in details.audit.portals"
                :loading="loading"
                class="mb-3"
            >
                <div class="flex items-center dark:text-white">
                    <img
                        class="mr-2 h-8 w-8 rounded-full"
                        :src="
                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                            portal.name
                        "
                        :alt="'Portal ' + portal.name"
                    />
                    #{{ portal.id }} {{ portal.name }}
                </div>
                <div class="mt-3 grid grid-cols-3 gap-4 dark:text-white">
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <UserGroupIcon class="mr-2 h-6 w-6" />
                            {{ Object.keys(details.audit.customers).length }}
                            Alunos
                        </div>
                    </card>
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <DocumentMagnifyingGlassIcon class="mr-2 h-6 w-6" />
                            {{
                                details.audit.lessons_watched.reduce(
                                    (total, lesson) =>
                                        total + lesson.lessons_viewed,
                                    0,
                                )
                            }}
                            Aulas Assistidas
                        </div>
                    </card>
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <AcademicCapIcon class="mr-2 h-6 w-6" />
                            {{
                                Object.keys(details.audit.lessons_watched)
                                    .length
                            }}
                            Alunos Ativos
                        </div>
                    </card>
                </div>
            </card>
        </template>
        <template v-else>
            <card
                v-if="details.audit && details.audit.portals"
                v-for="portal in details.audit.portals"
                :loading="loading"
                class="mb-3"
            >
                <div class="flex items-center dark:text-white">
                    <img
                        class="mr-2 h-8 w-8 rounded-full"
                        :src="
                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                            portal.name
                        "
                        :alt="'Portal ' + portal.name"
                    />
                    #{{ portal.id }} {{ portal.name }}
                </div>
                <div class="mt-3 grid grid-cols-3 gap-4 dark:text-white">
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <UserGroupIcon class="mr-2 h-6 w-6" />
                            {{ portal.students }}
                            Alunos
                        </div>
                    </card>
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <DocumentMagnifyingGlassIcon class="mr-2 h-6 w-6" />
                            {{ portal.lessons_watched }}
                            Aulas Assistidas
                        </div>
                    </card>
                    <card
                        class="border border-gray-200 shadow-lg dark:border-gray-700"
                        :loading="loading"
                    >
                        <div class="flex items-center dark:text-white">
                            <AcademicCapIcon class="mr-2 h-6 w-6" />
                            {{ portal.active_students }}
                            Alunos Ativos
                        </div>
                    </card>
                </div>
            </card>
        </template>

        <h3
            class="my-2 text-lg font-bold font-medium text-gray-400"
            v-if="products && details.products"
        >
            Produtos
        </h3>
        <div class="grid grid-cols-4 gap-4 dark:text-white" v-if="products">
            <card
                v-for="product in details.products"
                :loading="loading"
                class="shadow-lg"
            >
                <div class="flex flex-col dark:text-white">
                    <img
                        class="w-full rounded-lg"
                        :src="
                            product.cover_picture
                                ? product.cover_picture
                                : 'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                  product.name
                        "
                        :alt="product.name"
                    />
                    <strong class="text-md mb-3 mt-2">
                        {{ product.id }} {{ product.name }}
                    </strong>
                    <span class="text-lg text-indigo-500">
                        {{ product.comissions_count }}
                        {{ $root.formatCurrency(product.amount) }}
                    </span>
                </div>
            </card>
        </div>
    </div>

    <h2 class="mb-2 mt-3 text-lg font-bold font-medium text-gray-400">
        Recebíveis
    </h2>
    <p
        v-for="paymentMethod in paymentMethods"
        class="my-3 font-bold text-gray-200"
    >
        <span
            :class="paymentMethodColor(paymentMethod)"
            class="mr-1 inline-block rounded px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
        >
            <payment-type :method="paymentMethod.payment_method"></payment-type>
        </span>

        {{ $root.formatCurrency(paymentMethod.total) }}

        <span
            v-if="(paymentMethod.total / this.cashout.amount) * 100 > 90"
            class="mr-1 inline-block rounded bg-red-700 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
        >
            > 90%
        </span>
    </p>
    <div class="mb-3 h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
        <div class="flex">
            <div
                v-for="paymentMethod in paymentMethods"
                :class="paymentMethodColor(paymentMethod)"
                class="h-2.5 first:rounded-l-lg last:rounded-r-lg"
                :style="
                    'width: ' +
                    (paymentMethod.total / this.cashout.amount) * 100 +
                    '%'
                "
            ></div>
        </div>
    </div>

    <table-component
        v-if="table"
        :loading="loading"
        :pagination="pagination"
        :headers="['Comissão', 'Valor', 'Pagto.', 'Disponível em', 'Data']"
        @fetch="fetch"
    >
        <tr v-for="cashoutPayable in cashoutPayables">
            <td class="table-cell">
                #{{ cashoutPayable.payable.id }} -
                {{ cashoutPayable.payable.commission_id }}
            </td>
            <td class="table-cell">
                {{ $root.formatCurrency(cashoutPayable.payable.amount) }}
            </td>
            <td class="table-cell">
                {{ cashoutPayable.payable.payment_method }}
            </td>
            <td class="table-cell">
                {{ cashoutPayable.payable.available_at }}
            </td>
            <td class="table-cell">
                {{ cashoutPayable.payable.created_at }}
            </td>
        </tr>
    </table-component>
</template>

<script>
import {
    AcademicCapIcon,
    DocumentMagnifyingGlassIcon,
    UserGroupIcon,
} from "@heroicons/vue/24/outline";
import Pagination from "../../../components/layouts/Pagination.vue";
import Card from "../../../components/layouts/Card.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";
import PaymentType from "../../../components/layouts/PaymentType.vue";
export default {
    components: {
        PaymentType,
        TableComponent,
        Card,
        Pagination,
        AcademicCapIcon,
        DocumentMagnifyingGlassIcon,
        UserGroupIcon,
    },
    mixins: [HelperMixin],
    props: {
        cashout: { required: true },
        products: { type: Boolean, default: false },
        table: { type: Boolean, default: false },
    },
    data() {
        return {
            colors: ["bg-blue-700", "bg-orange-700", "bg-red-700"],
            loading: true,
            cashoutPayables: [],
            paymentMethods: [],
            nextPageUrl: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            details: false,
        };
    },

    mounted() {
        this.fetch();
        this.fetchPaymentMethods();
    },

    methods: {
        paymentMethodColor(paymentMethod) {
            return this.colors[this.paymentMethods.indexOf(paymentMethod)];
        },
        fetchPaymentMethods() {
            this.loading = true;
            axios
                .get(
                    "/api/cashout/" +
                        this.cashout.id +
                        "/payables/payment-methods",
                )
                .then(({ data }) => {
                    this.paymentMethods = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        fetch() {
            this.loading = true;

            const params = {};
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/cashout/" + this.cashout.id + "/payables", {
                    params: params,
                })
                .then(({ data }) => {
                    this.cashoutPayables = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.nextPageUrl = data.nextPageUrl;
                    this.loading = false;

                    this.fetchPayableDetails();
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        fetchPayableDetails() {
            axios
                .get("/api/cashout/" + this.cashout.id + "/payables/details")
                .then(({ data }) => {
                    this.details = data;
                });
        },
    },
};
</script>
