<template>
    <filters
        v-model="filters"
        @fetch="emails = []; fetch()"
        :loading="loading"
        search-filter
        search-label="Filtre por e-mail"
        class="my-5"
    />

    <table-component
        class="mt-3"
        :loading="loading"
        :pagination="pagination"
        @fetch="emails = []; fetch()"
        :headers="[
            'Data',
            'Destinatário',
            'Assunto',
            'Aberturas',
            'Clicks',
        ]"
    >
        <tr v-for="email in emails">
            <td class="table-cell">
                <div class="flex flex-row flex-wrap items-center">
                    {{ formatDate(email.created_at) }}
                </div>
            </td>
            <td class="table-cell">
                {{ email.recipient_email }} {{ email.recipient_name }}
            </td>
            <td class="table-cell">
                {{ email.subject }}
            </td>
            <td class="table-cell">
                {{ email.opens }}
            </td>
            <td class="table-cell">
                {{ email.clicks }}
            </td>
        </tr>
    </table-component>
</template>

<script>
    import TableComponent from "../../components/layouts/table/TableComponent.vue";
    import HelperMixin from "../../mixins/HelperMixin.vue";
    import {ArrowTopRightOnSquareIcon} from '@heroicons/vue/24/outline'
    import Filters from "../../components/layouts/Filters.vue";
    export default {
        components: {Filters, TableComponent, ArrowTopRightOnSquareIcon},
        mixins: [HelperMixin],
        data() {
            return {
                loading: true,
                emails: [],
                filters: {
                    text: ''
                },
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = this.filters;
                params['page'] = this.pagination.current_page;

                axios.get('/api/email', {
                    params: params
                }).then(({data}) => {
                    this.emails = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                }).catch((error) => {
                    this.$root.$emit('error', error.response.data);
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>
