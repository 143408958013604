<template>
    <div class="w-full">
        <h3 class="text-lg leading-6 font-medium dark:text-white text-gray-900 mb-3">Produtos</h3>
        <highcharts class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
export default {
    props: ['products'],
    data() {
        return {
            totalDays: 0,
            days: [],

            chartObject: {
                tooltip: {
                    formatter: function() {
                        let chart = this;
                        let formattedDate = moment(chart.x).add(1, 'days').format('DD/MM/YYYY');
                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ${point.y}`;
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            }
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            Object.values(this.products).map((product) => {
                const data = [];
                product.map((day) => {
                    data.push([day.unix_date, day.clicks]);
                });

                let productName = product[0].name;
                this.chartObject.series.push({
                    name: productName,
                    lineWidth: 3,
                    data: data
                });
            });
        }
    }
};
</script>
