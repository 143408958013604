<template>
    <div
        :class="'grid-cols-' + cols"
        class="grid gap-3"
    >
        <info-col v-for="n in cols" :loading="true"></info-col>
    </div>
</template>

<script>
    import InfoCol from "../InfoCol.vue";

    export default {
        components: {InfoCol},
        props: {cols: {type: Number, default: 4}, loading: {type: Boolean, default: false}}
    }
</script>
