<template>
    <modal
        class="min-h-full"
        :open="!!student || show"
        @close="show = false;$emit('resetStudent')"
    >
        <div class="flex flex-col flex-wrap w-full space-y-3 text-white divide-y divide-slate-500 min-h-fit">
        </div>
        <h1 class="flex flex-row flex-wrap items-center content-center pb-1 mb-2 text-sm text-lg font-bold text-white border-b border-slate-500">
            Acessos do aluno
        </h1>

        <table class="w-full text-sm border-collapse table-auto">
            <thead class="dark:text-white">
                <tr>
                    <th class="p-3 text-sm font-medium text-left">Turma</th>
                    <th class="p-3 text-sm font-medium text-left">Data de expiração</th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-for="classroom in student?.classrooms" :key="classroom.id">
                    <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                        {{ classroom.name }}
                    </td>
                    <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                        {{ classroom?.expires_at ? classroom?.expires_at : 'Vitalício' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </modal>
</template>

<script>
    import Modal from "../../../components/layouts/Modal.vue";

    export default {
        props: {student: {type: Object, default: null}},
        components: {Modal},
        emits: ['fetch'],
        data() {
            return {
                show: false,
            }
        },
    }
</script>
