<template>
    <modal
        class="min-h-full"
        :open="!!properties || show"
        @close="show = false;$emit('resetProperties')"
    >
        <div class="flex flex-col flex-wrap w-full space-y-3 text-white divide-y divide-slate-500 min-h-fit">
            <h1 class="flex flex-row flex-wrap items-center content-center pb-1 mb-2 text-sm text-lg font-bold text-white border-b border-slate-500">
                O que foi alterado
            </h1>
            <div v-if="properties['old'] ?? false">
                <h3 class="mt-5 text-sm font-bold">Antes da modificação:</h3>
                <table class="w-full text-sm border-collapse table-auto">
                    <thead class="dark:text-white">
                        <tr>
                            <th class="p-3 text-sm font-medium text-left">Propriedade</th>
                            <th class="p-3 text-sm font-medium text-left">Valor</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr v-for="(value, property) in properties['old']" :key="property.id">
                            <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                                {{ property }}
                            </td>
                            <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                                {{ value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="properties['new'] ?? false">
                <h3 class="mt-5 text-sm font-bold">Após modificacão:</h3>
                <table class="w-full text-sm border-collapse table-auto">
                    <thead class="dark:text-white">
                        <tr>
                            <th class="p-3 text-sm font-medium text-left">Propriedade</th>
                            <th class="p-3 text-sm font-medium text-left">Valor</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr v-for="(value, property) in properties['new']" :key="property.id">
                            <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                                {{ property }}
                            </td>
                            <td class="p-3 border-b border-slate-100 text-slate-500 dark:border-slate-700 dark:text-slate-200">
                                {{ value }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from "../../../components/layouts/Modal.vue";

    export default {
        props: {properties: {type: Object, default: null}},
        components: {Modal},
        emits: ['fetch'],
        data() {
            return {
                show: false,
            }
        },
    }
</script>
