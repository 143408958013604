<template>
    <button
        type="button"
        @click="openModal"
        class="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
        Detalhes
    </button>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog :open="isOpen" @close="setIsOpen" class="relative z-50">
            <!-- The backdrop, rendered as a fixed sibling to the panel container -->
            <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

            <!-- Full-screen scrollable container -->
            <div class="fixed inset-0 overflow-y-auto">
                <!-- Container to center the panel -->
                <div class="flex min-h-full items-center justify-center">
                    <!-- The actual dialog panel -->
                    <DialogPanel class="w-full max-w-4xl rounded bg-white dark:bg-slate-500 pt-3">
                        <DialogTitle class="text-lg font-medium leading-6 dark:text-white px-3 mb-3">
                            Cálculo de Antecipação
                        </DialogTitle>

                        <div class="overflow-hidden not-prose relative bg-slate-50 overflow-hidden dark:bg-slate-800/25 px-3 pb-1">
                            <div class="flex flex-col text-lg divide-y divide-slate-700" v-if="history.can_anticipate">
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        Data:
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ history.created_at }}
                                    </span>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        <span>
                                            Limite:
                                        </span>
                                        <span style="font-size:10px;display:block;line-height:100%">
                                            Limite de antecipação definida para o usuário no momento do cálculo
                                        </span>
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ history.limit }}%
                                    </span>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        <span>
                                            Saldo Pendente:
                                        </span>
                                        <span style="font-size:10px;display:block;line-height:100%">
                                            Soma das comissões pendentes no momento do cálculo, desconsiderando as vendas feitas no mesmo dia
                                        </span>
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ $root.formatCurrency(history.pending_amount) }}
                                    </span>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        <span>
                                            Saldo Antecipado:
                                        </span>
                                        <span style="font-size:10px;display:block;line-height:100%">
                                            Soma das comissões já antecipados nos últimos 30 dias
                                        </span>
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ $root.formatCurrency(history.anticipated_amount) }}
                                    </span>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        <span>
                                            Cálculo ({{ user.setting.anticipation_version }}):
                                        </span>
                                        <span v-if="user.setting.anticipation_version == 'v2'" style="font-size:10px;display:block;line-height:100%">
                                            Representa o limite teórico de quanto pode ser antecipado, ({{ history.limit }}% * ({{ $root.formatCurrency(history.pending_amount) }} + {{ $root.formatCurrency(history.anticipated_amount) }})) - {{ $root.formatCurrency(history.anticipated_amount) }}
                                        </span>
                                        <span v-else style="font-size:10px;display:block;line-height:100%">
                                            Representa o limite teórico de quanto pode ser antecipado, ({{ history.limit }}% * {{ $root.formatCurrency(history.pending_amount) }}) - {{ $root.formatCurrency(history.anticipated_amount) }}
                                        </span>
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ $root.formatCurrency(history.anticipatable_pending_amount - history.anticipated_amount) }}
                                    </span>
                                </div>
                                <div class="flex flex-wrap items-center">
                                    <span class="py-1">
                                        <span>
                                            Disponível para Antecipação:
                                        </span>
                                        <span style="font-size:10px;display:block;line-height:100%">
                                            Como não é possível antecipar uma comissão parcialmente, esse é o maior valor que pode ser antecipado sem ultrapassar o limite calculado acima
                                        </span>
                                    </span>
                                    <span class="mr-2 px-2.5 py-1.5 rounded ml-auto">
                                        {{ $root.formatCurrency(history.anticipatable_payables_amount) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
Dialog,
DialogDescription,
DialogPanel,
DialogTitle,
TransitionChild,
TransitionRoot,
} from '@headlessui/vue';
import { mapWritableState } from "pinia";
import { Store } from "../../../stores/store";

export default {
    components: {Dialog, DialogPanel, DialogTitle, DialogDescription, TransitionRoot, TransitionChild},

    props: ['history'],

    data() {
        return {
            isOpen: false,
        }
    },

    computed: {
        ...mapWritableState(Store, ["user"]),
    },
    methods: {
        closeModal() {
            this.isOpen = false;
        },
        openModal() {
            this.isOpen = true;
        },
        setIsOpen(boolean) {
            this.isOpen = boolean;
        }
    },
};
</script>
