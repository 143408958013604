<template>
  <div class="flex">
    <span class="col text-sm font-medium mx-2 rounded" v-if="!hideName">
      {{ methodName }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["method", "hasAmountCard2", "brandCard1", "brandCard2", "hideName"],

  computed: {
    methodName() {
      switch (this.method) {
        case "credit_card":
            let captalizedBrandCard1 = '---';
            if (this.brandCard1) {
              captalizedBrandCard1 = this.brandCard1[0].toUpperCase() + this.brandCard1.slice(1).toLowerCase();
            }

            let captalizedBrandCard2 = '---';
            if (this.brandCard2) {
              captalizedBrandCard2 = this.brandCard2[0].toUpperCase() + this.brandCard2.slice(1).toLowerCase();
            }

            if (this.hasAmountCard2) {
              return "Dois Cartões (" + captalizedBrandCard1 + "+" + captalizedBrandCard2 + ")";
            } else {
              return "Cartão de Crédito (" + captalizedBrandCard1 + ")";
            }
        case "bank_slip":
          return "Boleto";
        case "pix":
          return "PIX";
        default:
          return this.method ?? "--";
      }
    },
  },
};
</script>
