<template>
    <div v-tooltip="browser">
        <font-awesome-icon
            :icon="browserIcon"
            class="h-5 w-5 text-gray-200 dark:text-gray-200"
            aria-hidden="true"
        />
    </div>
</template>

<script>
export default {
    props: { browser: { required: true } },

    computed: {
        browserIcon() {
            if (!this.browser) {
                return "fa-brands fa-chrome";
            }

            if (this.browser.includes("Safari")) {
                return "fa-brands fa-safari";
            }

            if (this.browser.includes("Opera")) {
                return "fa-brands fa-opera";
            }

            if (this.browser.includes("Firefox")) {
                return "fa-brands fa-firefox";
            }

            if (this.browser.includes("Edge")) {
                return "fa-brands fa-edge";
            }

            if (this.browser.includes("Instagram")) {
                return "fa-brands fa-instagram";
            }

            return "fa-brands fa-chrome";
        },
    },
};
</script>
