<template>
    <div class="flex flex-col gap-3 mt-3">
        <h3 class="text-lg leading-6 font-medium dark:text-white mb-5">
            Transportadoras
        </h3>
        <hr />
        <button @click.prevent="openModal" :disabled="this.loading" type="button"
            :class="{ 'cursor-not-allowed': this.loading }"
            class="mr-auto items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400 transition ease-in-out duration-150">
            Cadastrar nova transportadora
        </button>

        <table class="border-collapse table-auto w-full text-sm">
            <thead class="bg-white dark:bg-slate-800">
                <tr>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        ID
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Nome
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Slug
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Tracking URL
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Ativo?
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-for="shippingProvider in shippingProviders" :key="shippingProvider.id">
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        {{ shippingProvider.id }}
                    </td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        {{ shippingProvider.name }}
                    </td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        {{ shippingProvider.slug }}
                    </td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        {{ shippingProvider.tracking_url }}
                    </td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        <span class="text-gray-600 dark:text-gray-200">
                            <form-toggle class="m-2" :model-value="shippingProvider.is_active" @change.prevent="handleToggleIsActive(shippingProvider.id)" />
                        </span>
                    </td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200">
                        <div>
                            <button @click.prevent="openModalToEdit(shippingProvider.id)" :disabled="loading" type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150">
                                Editar
                            </button>
                            <button @click.prevent="
                                handleDeleteShippingProvider(shippingProvider.id)
                                " :disabled="loading" type="button" :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-500 hover:bg-red-400 transition ease-in-out duration-150">
                                Excluir
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <add-shipping-provider-modal v-if="isModalOpen" @closeModal="closeModal"
            @fetchShippingProviders="fetchShippingProviders" :shipping-provider-id="this.shippingProviderId" />
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import FormToggle from "../../components/forms/FormToggle.vue";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import AddShippingProviderModal from "./modals/AddShippingProviderModal.vue";

export default {
    components: { FormToggle, SvgLoader, AddShippingProviderModal },
    data() {
        return {
            loading: false,
            shippingProviders: [],
            isModalOpen: false,
            shippingProviderId: null,
        };
    },
    mounted() {
        this.fetchShippingProviders();
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    methods: {
        fetchShippingProviders() {
            this.loading = true;
            axios
                .get(`/api/shipping-providers`)
                .then((response) => {
                    this.shippingProviders = response.data;
                    this.loading = false;
                })
                .catch((error) => console.error(error));
        },
        closeModal() {
            this.isModalOpen = false;
            this.shippingProviderId = null;
        },
        openModal() {
            this.isModalOpen = true;
        },
        openModalToEdit(id) {
            this.isModalOpen = true;
            this.shippingProviderId = id;
        },
        handleDeleteShippingProvider(id) {
            if (confirm("Tem certeza que deseja excluir a transportadora?")) {
                axios
                    .delete(`/api/shipping-providers/${id}`)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success("Transportadora excluída com sucesso");
                            this.fetchShippingProviders();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        }
                    );
            }
        },
        handleToggleIsActive(id) {
            axios
                .post(`/api/shipping-providers/${id}/toggle-is-active`)
                .then(({ data }) => {
                    if (data) {
                        this.toast.success("Status alterado com sucesso");
                        this.fetchShippingProviders();
                    }
                })
                .catch(
                    ({
                        response: {
                            data: { message },
                        },
                    }) => {
                        this.toast.error(message);
                    }
                );
        }
    },
};
</script>
