<template>
    <div v-if="cards">
        <card-brand-icon :brand="cardBrand" />
        {{ card.first_6 }}*{{ card.last_4 }}
    </div>
</template>

<script>
import CardBrandIcon from "../../layouts/CardBrandIcon.vue";

export default {
    components: { CardBrandIcon },
    props: {
        cards: { required: true },
    },
    computed: {
        cardBrand() {
            return this.cards && this.cards[0].brand;
        },
        card() {
            return this.cards && this.cards[0] && this.cards[0];
        },
    },
};
</script>
