<template>
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
            <img
                class="w-13 mr-2"
                :src="dark ?
                    'https://d2ucq9llvgnbyn.cloudfront.net/signature-white.png' :
                    'https://d2ucq9llvgnbyn.cloudfront.net/Ticto+Logo.svg'"
                alt="logo"
                style="max-width: 200px; width: 200px;"
            >
        </a>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "pinia";
    import { Store } from '../../stores/store.js'

    export default {
        computed: {
            ...mapState(Store, ["dark"]),
        }
    }
</script>
