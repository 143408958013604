<template>
    <div class="flex flex-col">
        <commission-cards :user="user"></commission-cards>

        <table-component
            :loading="loading"
            :headers="['Data', 'Nome', 'Valor', 'Meta']"
        >
            <tr v-for="metric in metrics">
                <td class="table-cell-sm">
                    {{ formatDate(metric.created_at) }}
                </td>
                <td class="table-cell-sm">{{ metric.name }}</td>
                <td class="table-cell-sm">{{ metric.amount }}</td>
                <td class="table-cell-sm flex flex-col">
                    <div v-for="(meta, index) in metric.meta">
                        <strong>{{ index }}: </strong> {{ meta }}
                    </div>
                </td>
            </tr>
        </table-component>

        <div class="mb-4 mt-4 grid grid-cols-3 gap-3">
            <chargeback-metric
                v-for="metric in chargebackMetrics"
                :key="metric.name"
                :metric="metric"
            />
        </div>

        <monthly-chargeback-rate :user="user" v-if="false" />

        <score :user="user" class="mt-3" />
    </div>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import CommissionCards from "../../../components/user/CommissionCards.vue";
import Score from "../../../components/user/Score.vue";
import ChargebackMetric from "../../../components/user/metrics/ChargebackMetric.vue";
import MonthlyChargebackRate from "../../../components/user/metrics/MonthlyChargebackRate.vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";

export default {
    components: {
        MonthlyChargebackRate,
        ChargebackMetric,
        Score,
        CommissionCards,
        TableComponent,
    },
    props: ["user"],
    data() {
        return {
            loading: true,
            next_page_url: false,
            chargebackMetrics: [],
            metrics: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },

    mixins: [HelperMixin],

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            const userId = this.$route.params.user_id ?? this.user.id;

            axios
                .get("/api/user/" + userId + "/metrics")
                .then(({ data }) => {
                    this.metrics = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });

            axios
                .get("/api/user/" + userId + "/metrics/chargebacks", {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then(({ data }) => {
                    let metrics = [];
                    metrics.push(
                        {
                            name: "Competência",
                            tpn: data.tpn_comp,
                            tpv: data.tvp_comp,
                        },
                        {
                            name: "Caixa",
                            tpn: data.tpn_box,
                            tpv: data.tpv_box,
                        },
                        {
                            name: "Global",
                            tpn: data.tpn_global,
                            tpv: data.tpv_global,
                        },
                    );
                    this.chargebackMetrics = metrics;
                    this.loading = false;
                });
        },
    },
};
</script>
