<template>
    <ul
        class="list-reset flex w-auto rounded border border-indigo-500 font-sans text-white transition duration-150 ease-in-out"
    >
        <li v-if="pagination.current_page != 1">
            <a
                class="hover:bg-blue text-blue block border-r border-indigo-500 px-3 py-2 text-gray-600 hover:cursor-pointer hover:bg-indigo-500 hover:text-white dark:text-white"
                @click.prevent="change(1)"
            >
                {{ "Primeira" }}
            </a>
        </li>
        <li v-if="pagination.current_page > 1">
            <a
                class="hover:bg-blue block border-r border-indigo-500 px-3 py-2 text-gray-600 hover:cursor-pointer hover:text-white dark:text-white"
                @click.prevent="change(pagination.current_page - 1)"
            >
                {{ "<" }}
            </a>
        </li>
        <li v-for="page in pages" :key="page">
            <a
                :class="[
                    page == pagination.current_page
                        ? 'bg-blue border-r border-indigo-500 bg-indigo-500 text-white dark:text-white'
                        : 'hover:bg-blue text-blue block border-r border-indigo-500 px-3 py-2 text-gray-600 hover:bg-indigo-500 hover:text-white dark:text-white',
                    ' block px-3 py-2 transition duration-150 ease-in-out hover:cursor-pointer',
                ]"
                @click.prevent="change(page)"
            >
                {{ page }}
            </a>
        </li>
        <li v-if="pagination.current_page < pagination.last_page">
            <a
                class="hover:bg-blue text-blue block border-r border-indigo-500 px-3 py-2 text-gray-600 hover:cursor-pointer hover:bg-indigo-500 hover:text-white dark:text-white"
                @click.prevent="change(pagination.current_page + 1)"
            >
                {{ ">" }}
            </a>
        </li>
        <li v-if="pagination.current_page != pagination.last_page">
            <a
                class="hover:bg-blue text-blue block px-3 py-2 text-gray-600 hover:cursor-pointer hover:bg-indigo-500 hover:text-white dark:text-white"
                @click.prevent="change(pagination.last_page)"
            >
                {{ "Última" }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            required: true,
        },
        offset: {
            type: Number,
            default: 4,
        },
    },
    computed: {
        pages() {
            if (!this.pagination.to) {
                return null;
            }
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            let to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            let pages = [];
            for (let page = from; page <= to; page++) {
                pages.push(page);
            }
            return pages;
        },
    },
    methods: {
        change: function (page) {
            this.pagination.current_page = page;
            console.log({ c_page: this.pagination.current_page });
            this.$emit("paginate", page);
        },
    },
};
</script>
