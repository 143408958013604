<template>
    <h3 class="text-xl leading-6 font-large dark:text-white my-3 flex item-center">
        Gerentes de Conta <store-modal @fetch="fetch"/>
    </h3>

    <ticto-loading v-if="loading" />
    <div class="not-prose relative rounded-xl rounded">
        <empty-state v-if="!loading && !managers.length"/>
        <ul role="list" class="divide-y dark:divide-slate-700">
            <manager
                v-if="!loading"
                v-for="(manager, index) in managers"
                :manager="manager"
                :class="{
                    'rounded-t-lg': index === 0,
                    'rounded-b-lg': index === managers.length - 1
                }"
                @fetch="fetch"
            />
        </ul>
    </div>
</template>

<script>
    import TictoLoading from "../../components/layouts/TictoLoading.vue";
    import UsersSelect from "../../components/forms/UsersSelect.vue";
    import VueSelect from "../../components/forms/VueSelect.vue";
    import Manager from "../../components/manager/Manager.vue";
    import StoreModal from "../../components/manager/modals/StoreModal.vue";
    import EmptyState from "../../components/layouts/EmptyState.vue";
    export default {
        components: {EmptyState, StoreModal, Manager, VueSelect, UsersSelect, TictoLoading},
        data() {
            return {
                loading: false,
                managers: [],
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/account-manager/').then(({data}) => {
                    this.managers = data;
                    this.loading = false;
                })
            }
        }
    }
</script>
