<template>
    <div class="flex items-center justify-between p-3">
        <div class="flex items-center justify-between">
            <img
                :src="
                    user.avatar ||
                    'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                        user.name
                "
                alt
                class="h-auto max-w-full rounded-full border-none align-middle shadow"
                style="width: 35px; height: 35px"
            />
            <span class="ml-2 text-sm dark:text-white">{{ user.email }}</span>
        </div>

        <div class="flex items-center">
            <span
                :class="{
                    'bg-green-700': detectorImport.assignedLabel === 'ok',
                    'bg-red-700': detectorImport.assignedLabel === 'fraud',
                }"
                class="mx-2 rounded px-2 py-1 text-center text-sm text-white dark:text-gray-200"
            >
                {{ detectorImport.assignedLabel }}
            </span>
            <span
                :class="{
                    'bg-yellow-500': ['starting', 'pending'].includes(
                        detectorImport.status,
                    ),
                    'bg-green-500': detectorImport.status === 'finished',
                    'animate-pulse bg-yellow-500':
                        detectorImport.status === 'running',
                    'bg-red-500': detectorImport.status === 'error',
                }"
                class="mr-2 rounded px-2 py-1 text-center text-sm text-white dark:text-gray-200"
            >
                {{ statusName(detectorImport.status) }}
            </span>
            <strong
                class="mr-2"
                v-if="detectorImport.rows || detectorImport.progress"
            >
                {{ detectorImport.progress || 0 }}/{{ detectorImport.rows }}
            </strong>
            <p class="flex flex-wrap text-sm dark:text-white">
                <ClockIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                <time :datetime="user.created_at">{{ user.created_at }}</time>
            </p>
        </div>
    </div>
</template>

<script>
import { ClockIcon, TrashIcon } from "@heroicons/vue/24/outline";
export default {
    components: { ClockIcon, TrashIcon },
    props: ["detectorImport"],

    computed: {
        user() {
            return this.detectorImport.user;
        },
    },

    methods: {
        statusName(status) {
            switch (status) {
                case "pending":
                    return "Pendente";
                case "running":
                    return "Executando";
                case "processing":
                    return "Processando";
                case "finished":
                    return "Concluído";
                case "failed":
                    return "Falhou";
                case "starting":
                    return "Iniciando";
                default:
                    status: return status;
            }
        },
    },
};
</script>
