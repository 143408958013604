<template>
    <modal class="min-h-full" :open="open" @close="$emit('close')">
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Enviar Notificação
        </h1>

        <form @submit.prevent="submit">
            <div class="mt-5 flex-col sm:mt-4 sm:flex">
                <div class="mb-3 grid gap-2 sm:grid-cols-1">
                    <div>
                        <label class="text-sm text-white"> Título </label>
                        <input
                            type="text"
                            id="title"
                            v-model="form.title"
                            name="title"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Título"
                            required
                        />
                    </div>
                    <div>
                        <label class="text-white"> Descrição </label>
                        <input
                            type="text"
                            id="body"
                            v-model="form.body"
                            minlength="10"
                            name="body"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Descrição"
                            required
                        />
                    </div>

                    <div>
                        <label class="text-white"> Link </label>
                        <input
                            v-model="form.link"
                            type="url"
                            id="link"
                            name="link"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Link"
                        />
                    </div>

                    <div>
                        <label class="text-white"> Tópicos </label>
                        <div class="flex space-x-2 dark:text-white">
                            <div class="flex flex-row gap-2">
                                <label for="ios">Ios</label>
                                <input
                                    type="checkbox"
                                    id="ios"
                                    name="topics"
                                    value="ios"
                                    v-model="form.topics"
                                />
                            </div>
                            <div class="flex flex-row gap-2">
                                <label for="ios">Android</label>
                                <input
                                    type="checkbox"
                                    id="android"
                                    name="topics"
                                    value="android"
                                    v-model="form.topics"
                                />
                            </div>
                        </div>
                    </div>

                    <submit-button :loading="loading"> Enviar </submit-button>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../components/layouts/Modal.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import { useToast } from "vue-toastification";

export default {
    props: ["open"],
    components: { SubmitButton, Modal },
    emits: ["close", "fetch"],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            form: {
                body: null,
                title: null,
                link: null,
                topics: ["ios", "android"],
            },
        };
    },
    methods: {
        submit() {
            this.loading = true;
            axios
                .post("/api/mobile-devices/send-many", this.form)
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success("Notificação enviada com sucesso.");
                    this.$emit("fetch");
                });
        },
    },
};
</script>
