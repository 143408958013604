<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <div>
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
            <input v-model="form.name" type="text" name="email" id="name" class="text-input" required>
        </div>
        <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input v-model="form.email" type="email" name="email" id="email" class="text-input" required>
        </div>
        <div>
            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telefone</label>
            <input
                v-maska
                data-maska="+## (##) # ####-####"
                v-model="form.phone"
                type="text"
                name="phone"
                id="phone"
                class="text-input"
                required
            >
        </div>

        <div>
            <label for="file_input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Avatar</label>
            <div class="flex items-center">
                <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" ref="file">
            </div>
        </div>

        <submit-button :loading="loading" class="ml-auto">Salvar</submit-button>
    </form>
</template>

<script>
    import { vMaska } from "maska"
    import SubmitButton from "../forms/SubmitButton.vue";

    export default {
        props: {manager: {type: Object, default: null}},
        directives: { maska: vMaska },
        components: {SubmitButton},
        emits: ['fetch'],
        data() {
            return {
                show: false,
                loading: false,
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    avatar: ''
                }
            }
        },

        mounted() {
            if (this.manager) {
                this.form = {
                    name: this.manager.name,
                    email: this.manager.email,
                    phone: this.manager.phone,
                    avatar: this.manager.avatar
                }
            }
        },

        methods: {
            async upload() {
                if (!this.$refs.file.files[0]) return;
                this.loading = true;
                await Vapor.store(this.$refs.file.files[0], {
                    progress: progress => {
                        this.progress = Math.round(progress * 100);
                    }
                }).then(response => {
                    this.form.avatar = response.key;
                });

                return this.form.avatar;
            },
            async submit() {
                await this.upload();

                this.loading = true;

                let promise = this.manager ?
                    axios.put(`/api/account-manager/${this.manager.uuid}`, this.form) :
                    axios.post('/api/account-manager', this.form);

                promise.then(response => {
                        this.loading = false;
                        this.$emit('fetch');
                    })
                    .catch(error => {
                        this.loading = false;
                    })

            }
        }

    }
</script>
