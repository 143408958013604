<template>
    <div class="mt-5">
        <div class="flex items-center content-center mb-3">
            <div class="grow">
                <h3 class="text-lg font-medium font-bold leading-6 dark:text-white">
                    Analytics
                </h3>
            </div>
            <div class="w-[230px] max-w-[250px] ml-auto">
               <litepie-datepicker
                   class="max-w-[300px]"
                   v-model="filters.date"
                   :formatter="{
                      date: 'DD/MM/YYYY',
                      month: 'MMM'
                    }"
                   :shortcuts="false"
               ></litepie-datepicker>
            </div>
        </div>
        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-4">
            <card
                v-for="item in stats" :key="item.name"
                :loading="loading"
            >
                <p class="mb-2 text-sm font-bold text-gray-400 uppercase dark:text-gray-400">
                    {{ item.name }}
                </p>
                <div class="flex text-2xl font-light text-gray-700 dark:text-gray-200">
                    <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
                        <span>
                            {{ item.stat }}
                        </span>
                        <span class="ml-3" v-if="item.paid_amount">
                            {{ $root.formatCurrency(item.paid_amount) }}
                        </span>
                    </div>
                </div>
            </card>
        </dl>
        <ticto-loading v-if="loading"></ticto-loading>
        <product-trackings-chart v-if="products && !loading" :products="products" class="mt-4"></product-trackings-chart>
        <product-orders-chart v-if="!loading" :dates="filters.date" class="mt-4"></product-orders-chart>
        <trackings-chart v-if="!loading && days.length > 0" :days="days" class="mt-4"></trackings-chart>
        <orders-chart v-if="!loading" :dates="filters.date" class="mt-4"></orders-chart>
    </div>
</template>

<script>
    import Card from "../../../components/layouts/Card.vue";
    import TictoLoading from "../../../App.vue";
    import ProductTrackingsChart from "./ProductTrackingsChart.vue";
    import TrackingsChart from "./TrackingsChart.vue";
    import OrdersChart from "./OrdersChart.vue";
    import ProductOrdersChart from "../../../components/analytics/orders/ProductOrdersChart.vue";

    export default {
        components: {ProductOrdersChart, OrdersChart, TrackingsChart, ProductTrackingsChart, TictoLoading, Card},
        data() {
            return {
                filters: {
                    date: [
                        moment().subtract(1, 'month').format('DD/MM/YYYY'),
                        moment().format('DD/MM/YYYY')
                    ]
                },
                loading: false,
                stats: [],
                clicks: [],
                sumClicks: 0,
                previousClicks: [],
                sumPreviousClicks: 0,
                days: [],
                products: false
            }
        },

        watch: {
            'filters.date': function () {
                this.products = false;
                this.fetch();
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                this.stats = [];

                axios.get('/api/analytics/metrics/clicks', {
                    params: {
                        from: this.filters.date[0],
                        to: this.filters.date[1]
                    }
                }).then(({data}) => {
                    this.products = data.products;
                    this.days = data.days;
                    this.sumClicks = this.days.reduce((total, day) => total + day.clicks, 0);
                    this.stats.push({ name: 'Clicks', stat: this.sumClicks.toLocaleString() });
                });

                axios.get('/api/analytics/metrics', {
                    params: {
                        from: this.filters.date[0],
                        to: this.filters.date[1]
                    }
                }).then(({data}) => {
                    this.stats.push(
                        {
                            name: 'Vendas', stat: this.$root.formatCurrency(data.paid_amount)
                        },
                        {
                            name: 'Juros', stat: this.$root.formatCurrency(data.interest)
                        },
                        {
                            name: 'Cms Plataforma', stat: this.$root.formatCurrency(data.platform_commission)
                        },
                    );
                }).finally(() => this.loading = false);
            },
            isWhatPercentOf(numA, numB) {
                return (numA / numB) * 100;
            }
        }
    }
</script>
