<script>
export default {
    methods: {
        getCashoutStatusName(status) {
            switch (status) {
                case "approved":
                    return "Efetuado";
                case "sent_to_bank":
                    return "Enviado ao Banco";
                case "returned":
                    return "Retornado";
                case "building":
                    return "Em Construção";
                case "in_audit":
                    return "Em Auditoria";
                case "requested":
                    return "Solicitado";
                default:
                    return status;
            }
        },
        getCashoutAccountType(type) {
            switch (type) {
                case "checking":
                    return 'Conta Corrente';
                case "saving":
                    return 'Conta Poupança';
                default:
                    return type;
            }
        },
        getCashoutStatusClass(status) {
            switch (status) {
                case "approved":
                    return "bg-green-500 text-white";
                case "returned":
                    return "bg-red-500 text-white";
                case "building":
                    return "bg-yellow-600 text-white";
                case "in_audit":
                    return "bg-violet-600 text-white";
                case "requested":
                    return "bg-black text-white";
                case "sent_to_bank":
                    return "bg-yellow-500 text-white";
                default:
                    return "bg-blue-500 text-white";
            }
        },

        formatBankCode(code = '') {
            return code.padStart(3, 0);
        },
    },
};
</script>
