<template>
    <div class="w-full">
        <highcharts v-if="show" class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
export default {
    props: ['dates'],
    data() {
        return {
            show: false,
            totalDays: 0,
            days: [],

            chartObject: {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });


                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ` + point.y.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                });
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            }
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            const data = [];
            await this.dates.map((day) => {
                data.push([day.unix_date, parseInt(day.amount)]);
            });

            this.chartObject.series.push({
                name: 'Saldo',
                lineWidth: 3,
                data: data
            });

            this.show = true;
        }
    }
};
</script>
