<template>
    <h2 class="mt-5 mb-2 text-gray-400 text-lg">Saldo</h2>
    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Disponível
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.available) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Antecipável
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.anticipation) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Pendente
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.pending) }}
            </p>
        </card>
    </dl>
    <payables-schedule-chart class="mt-3"/>
    <h3 class="text-lg leading-6 font-medium text-gray-400 my-3">Recebíveis</h3>
    <filters
        v-model="filters"
        :loading="loading"
        @fetch="payables = []; next_page_url = false; fetch();"
        search-filter
        search-label="Filtre pelo nome ou e-mail do usuário"
        status-filter
        :statuses="[
            { value: 'available', label: 'Disponível' },
            { value: 'blocked', label: 'Bloqueado' },
            { value: 'pending', label: 'Pendente' },
            { value: 'cashout', label: 'Sacado' },
            { value: 'subtracted', label: 'Subtraído' }
        ]"
        payment-method-filter
        date-filter
    ></filters>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                <table class="border-collapse table-auto w-full text-sm">
                    <thead>
                        <tr class="text-white">
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                #
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Usuário
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Data
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Disp. Em
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Status
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Método de Pagto.
                            </th>
                            <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                Valor
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                    <tr v-for="payable in payables">
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            {{ payable.id }}
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <button
                                class="text-indigo-500 hover:underline"
                                @click.prevent="manageUser(payable.user)"
                            >
                                {{ payable.user.name }} {{ payable.user.email }}
                            </button>

                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            {{ payable.created_at }}
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            {{ payable.available_at }}
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <span
                                class="text-sm font-medium mr-2 px-2.5 py-1.5 rounded"
                                :class="{
                                    'bg-green-500 text-white': payable.status === 'available',
                                    'bg-red-500 text-white': payable.status === 'blocked',
                                    'bg-yellow-500 text-white': payable.status === 'pending',
                                    'bg-purple-500 text-white': payable.status === 'cashout',
                                    'bg-red-200 text-white': payable.status === 'subtracted',
                                }"
                            >
                                {{ statusName(payable) }}
                            </span>
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <payment-type
                                :method="payable.payment_method"
                                :hasAmountCard2="payable.order?.amount_card2"
                            />
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            {{ $root.formatCurrency(payable.amount) }}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="flex w-full my-5">
                    <div class="flex flex-col items-center mx-auto">
                        <span class="mb-1 text-white">
                            Exibindo {{pagination.from}} a {{pagination.to}} registros de {{pagination.total}}
                        </span>
                        <pagination
                            :pagination="pagination"
                            :offset="1"
                            @paginate="fetch"
                        ></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Card from "../../components/layouts/Card.vue";
    import Pagination from "../../components/layouts/Pagination.vue";
    import PaymentType from "../../components/layouts/PaymentType.vue";
    import {mapWritableState} from "pinia";
    import {Store} from "../../stores/store";
    import PayablesScheduleChart from "./payables/PayablesScheduleChart.vue";
    import Filters from "../../components/layouts/Filters.vue";
    export default {
        components: {Filters, Card, Pagination, PaymentType, PayablesScheduleChart},
        data() {
            return {
                loading: false,
                balance: {
                    anticipation: 0,
                    available: 0,
                    pending: 0
                },
                filters: {
                    text: "",
                    date: [],
                    status: [],
                    payment_method: [],
                    types: []
                },
                payables: [],
                next_page_url: null,
                pagination: {
                    total: 0,
                    per_page: 0,
                    from: 0,
                    to: 0,
                    current_page: 0
                },
            }
        },

        computed: {
            ...mapWritableState(Store, ['user']),
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/financial/stats').then(({data}) => {
                    this.balance = data;
                    this.loading = false;
                }).catch(({response}) => {
                    this.loading = false;
                })

                const params = this.filters;
                params['page'] = this.pagination.current_page;

                axios.get('/api/financial/payable', {
                    params: params
                }).then(({data}) => {
                    this.payables = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };
                }).catch(({response}) => {
                    this.loading = false;
                })
            },
            manageUser(user) {
                this.user = user;
                this.$router.push({
                    name: 'user.commissions',
                    params: { user_id: user.id }
                });
            },
            statusName(payable) {
                switch (payable.status) {
                    case 'available':
                        return 'Disponível';
                    case 'blocked':
                        return 'Bloqueado';
                    case 'pending':
                        return 'Pendente';
                    case 'cashout':
                        return 'Saque';
                    case 'subtracted':
                        return 'Subtraído';
                }
            }
        }
    }
</script>
