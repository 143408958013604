<template>
    <div class="w-full">
        <h3 class="text-lg leading-6 font-medium dark:text-white mb-3">Clicks</h3>
        <highcharts class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
export default  {
    props: { days: { type: Array, required: true, default: [] } },
    data() {
        return {
            totalDays: 0,

            chartObject: {
                tooltip: {
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            }
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            const data = [];
            this.days.map((day) => {
                data.push([day.unix_date, day.clicks]);
            });

            this.chartObject.series.push({
                name: 'Clicks',
                lineWidth: 3,
                data: data
            });
        }
    }
};
</script>
