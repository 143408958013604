<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Logs
    </h3>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3" v-if="!loading && logs.length > 0">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                <table class="border-collapse table-auto w-full text-sm">
                    <thead>
                    <tr>
                        <th scope="col" class="text-sm font-medium dark:text-slate-200  px-6 py-4 text-left">
                            # Log
                        </th>
                        <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                        </th>
                        <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                        </th>
                        <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                    <tr v-for="log in logs" class="">
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            {{log.log_type}} {{log.created_at}}
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <span v-if="log.log_type === 'WEBHOOK'">{{log.status}}</span>
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <span v-if="log.log_type === 'WEBHOOK'">{{log.status}}</span>
                        </td>
                        <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                            <span v-if="log.log_type === 'WEBHOOK'">{{log.status}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="flex w-full my-5">
                    <div class="flex flex-col items-center mx-auto">
                        <span class="mb-1">
                            Exibindo {{pagination.from}} a {{pagination.to}} registros de {{pagination.total}}
                        </span>
                        <pagination
                            :pagination="pagination"
                            :offset="1"
                            @paginate="fetch"
                        ></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "../../components/layouts/Pagination.vue";

    export default {
        components: {Pagination},
        props: ['order'],
        data() {
            return {
                loading: false,
                next_page_url: false,
                logs: [],
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
            }
        },

        mounted() {
            this.fetch()
        },

        methods: {
            fetch() {
                this.loading = true

                const params = {};
                params['page'] = this.pagination.current_page;

                axios.get('/api/order/' + this.order.id + '/log', {
                    params: params
                }).then(({data}) => {
                    this.logs = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            }
        }
    }
</script>
