<template>
    <InformationCircleIcon @click="show = !show" class="ml-1 flex-shrink-0 self-center h-5 w-5 hover:cursor-pointer" />
    <modal
        size="lg"
        :open="show"
        @close="show = false"
    >
        <div class="flex flex-col flex-wrap w-full divide-y space-y-3 divide-slate-500 text-white">
            <div v-for="check in status.checks" class="dark:text-white flex flex-col pt-3">
                <div class="flex items-center">
                    <div class="font-bold text-lg">
                        {{ checkName(check.name) }}
                    </div>
                    <CheckCircleIcon v-if="check.check" class="text-green-400 ml-auto w-7 h-7" />
                    <XCircleIcon v-if="!check.check" class="text-red-400 ml-auto w-7 h-7" />
                </div>
                <div class="flex justify-between">
                    <span class="flex flex-col">
                        <span class="text-slate-500">Inserido</span>
                        {{check?.input ?? "Sem informação"}}
                    </span>
                    <span class="flex flex-col">
                        <span class="text-slate-500">Api</span>
                        {{check?.api ?? "Sem informação"}}
                    </span>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import { InformationCircleIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
    import Modal from "../../components/layouts/Modal.vue";
    export default {
        props: ['status'],
        components: {Modal, InformationCircleIcon, CheckCircleIcon, XCircleIcon},

        data() {
            return {
                show: false
            }
        },

        methods: {
            checkName(name) {
                switch (name) {
                    case 'cpf_name':
                        return 'Nome no CPF';
                    case 'cpf_regular':
                        return 'CPF Regular';
                    case 'user_birth':
                        return 'Data de Nascimento';
                    case 'cpf_not_found':
                        return 'CPF não encontrado';
                    case 'cnpj_not_found':
                        return 'CNPJ não encontrado';
                    case 'cnpj_active':
                        return 'CNPJ Ativo';
                    case 'mei_name':
                        return 'Nome no MEI';
                    case 'manager_name':
                        return 'Nome do Sócio';
                    case 'manager_birth':
                        return 'Data de Nascimento do Sócio';
                    default:
                        return name;
                }

            }
        }
    }
</script>
