<template>
    <div class="flex flex-col dark:text-white">
        <filters
            v-model="filters"
            @fetch="
                commissions = [];
                next_page_url = false;
                fetch();
            "
            :loading="loading"
            date-filter
            search-filter
            status-filter
            payment-method-filter
            search-label="Filtre pelo nome ou e-mail do produtor ou cliente"
            @export="exportCommissions"
        />
        <div class="mb-8 grid gap-6 md:grid-cols-2 xl:grid-cols-3">
            <card :loading="loading">
                <p
                    class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
                >
                    Vendas
                    <span class="ml-auto">
                        {{ cards.count }}
                    </span>
                </p>
                <p
                    class="text-white-100 flex text-lg font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(cards.amount) }}
                </p>
            </card>
            <card :loading="loading">
                <p
                    class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
                >
                    Placa
                </p>
                <p
                    class="text-white-100 flex text-lg font-semibold dark:text-gray-200"
                >
                    {{ cards?.level?.level?.label ?? "Não possui ranking" }}
                    ({{
                        $root.formatCurrency(cards?.level?.level?.amount ?? 0)
                    }})
                </p>
                <small class="">
                    Próxima placa:
                    {{
                        `${
                            cards?.level?.next_level?.label ?? "Desconhecido"
                        } (${$root.formatCurrency(
                            cards?.level?.next_level?.amount ?? 0,
                        )})`
                    }}
                </small>
            </card>
        </div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <table-component
                    :loading="loading"
                    :headers="[
                        'Pedido',
                        'Produto',
                        'Cliente',
                        'Método de Pagto.',
                        'Comissão',
                        'Data',
                        'Status',
                    ]"
                    :pagination="pagination"
                    @fetch="fetch"
                >
                    <template v-slot:tableHeader>
                        <div class="mb-3 flex">
                            <div class="ml-auto flex items-center">
                                <button
                                    class="btn-primary-sm ml-auto mr-2"
                                    :disabled="loading"
                                    @click.prevent="exportCommissions"
                                >
                                    <ArrowDownTrayIcon
                                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                    />
                                    Exportar
                                </button>

                                Exibindo {{ pagination.from }} a
                                {{ pagination.to }} registros de
                                {{ pagination.total }}
                            </div>
                        </div>
                    </template>
                    <tr v-for="commission in commissions">
                        <td class="table-cell-sm">
                            <router-link
                                v-if="commission.transaction"
                                :to="{
                                    name: 'user.order',
                                    params: {
                                        user_id: this.user.id,
                                        order_id: commission.order.id,
                                    },
                                }"
                                class="text-indigo-400 hover:text-indigo-700 hover:underline"
                            >
                                #{{ commission.order.id }}
                            </router-link>
                        </td>
                        <td class="table-cell-sm">
                            {{
                                commission.product
                                    ? commission.product.name
                                    : "-"
                            }}
                        </td>
                        <td class="table-cell-sm">
                            {{
                                commission.order.customer
                                    ? commission.order.customer["name"]
                                    : "-"
                            }}<br />
                            {{
                                commission.order.customer
                                    ? commission.order.customer["email"]
                                    : "-"
                            }}
                        </td>
                        <td class="table-cell-sm">
                            <payment-method-icon
                                :method="commission.transaction.payment_method"
                                :hasAmountCard2="commission.order.amount_card2"
                            ></payment-method-icon>
                        </td>
                        <td class="table-cell-sm">
                            {{ $root.formatCurrency(commission.amount) }}
                        </td>
                        <td class="table-cell-sm">
                            {{ commission.created_at }}
                        </td>
                        <td class="table-cell-sm">
                            <status
                                v-if="commission.transaction"
                                :status="commission.transaction.status"
                            ></status>
                        </td>
                    </tr>
                </table-component>
            </div>
        </div>
    </div>
</template>

<script>
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import SubmitButton from "../../../components/forms/SubmitButton.vue";
import Card from "../../../components/layouts/Card.vue";
import EmptyState from "../../../components/layouts/EmptyState.vue";
import Filters from "../../../components/layouts/Filters.vue";
import Pagination from "../../../components/layouts/Pagination.vue";
import PaymentType from "../../../components/layouts/PaymentType.vue";
import Status from "../../../components/layouts/Status.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import PaymentMethodIcon from "../../../components/layouts/PaymentMethodIcon.vue";

export default {
    components: {
        PaymentMethodIcon,
        TableComponent,
        EmptyState,
        Pagination,
        Filters,
        Status,
        PaymentType,
        Card,
        SubmitButton,
        ArrowDownTrayIcon,
    },
    props: ["user"],
    data() {
        return {
            loading: true,
            next_page_url: false,
            cards: {
                amount: 0,
                count: 0,
            },
            commissions: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        exportCommissions() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/commission/export", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.toast.success(
                        "Você receberá um e-mail com o link para baixar o arquivo.",
                    );
                    this.loading = false;
                });
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/user/" + this.user.id + "/commission/cards", {
                    params: params,
                })
                .then(({ data }) => {
                    this.cards = data;
                });

            axios
                .get("/api/user/" + this.user.id + "/commission", {
                    params: params,
                })
                .then(({ data }) => {
                    this.commissions = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
