<template>
    <submit-button class="ml-2 text-sm" size="sm" @click.prevent="show = true">Adicionar</submit-button>
    <modal
        class="min-h-full"
        :open="show"
        @close="show = false;"
    >
        <div class="flex flex-col flex-wrap w-full divide-y space-y-3 divide-slate-500 text-white min-h-fit">
        </div>
        <h1 class="text-lg text-white font-bold text-sm flex flex-row flex-wrap items-center content-center border-b border-slate-500 pb-1 mb-2">
            Adicionar Gerente
        </h1>

        <manager-form @fetch="show= false;$emit('fetch')"/>
    </modal>
</template>

<script>
    import Modal from "../../layouts/Modal.vue";
    import SubmitButton from "../../forms/SubmitButton.vue";
    import ManagerForm from "../ManagerForm.vue";

    export default {
        components: {ManagerForm, SubmitButton, Modal},
        emits: ['fetch'],
        data() {
            return {
                show: false,
            }
        },
    }
</script>
