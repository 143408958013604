<template>
  <div class="flex">
    <div class="flex col items-center justify-items-center">
      <font-awesome-icon :icon="typeName.icon" />
    </div>
    <span class="col text-sm font-medium mx-2 rounded">
      {{ typeName.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["type"],

  computed: {
    typeName() {
      switch (this.type) {
        case "physical":
          return { icon: "fa-solid fa-box", name: "Físico" };
        case "event":
          return { icon: "fa-solid fa-person-chalkboard", name: "Evento" };
        case "course":
          return { icon: "fa-solid fa-video", name: "Curso" };
        case "software":
          return { icon: "fa-solid fa-code", name: "Software" };
        case "ebook":
          return { icon: "fa-solid fa-book", name: "Ebook" };
        case "subscription":
          return { icon: "fa-solid fa-sack-dollar", name: "Assinatura" };
        case "other":
          return { icon: "fa-solid fa-cart-shopping", name: "Outro" };
        default:
          return { icon: "fa-solid fa-cart-shopping", name: this.type };
      }
    },
  },
};
</script>
