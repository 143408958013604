<template>
    <div v-for="n in [30, 60, 0]" class="mb-1 grid grid-cols-4 gap-1">
        <template v-if="commissions && commissions.hasOwnProperty(n)">
            <div class="col-span-4">
                <p class="font-md text-gray-400">
                    {{ n === 0 ? "Todo o Período" : "D" + n }}
                </p>
            </div>
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Pagas
                    <span class="ml-auto">
                        {{ commissions[n].paid.count }}
                    </span>
                </p>
                <p
                    class="text-white-100 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(commissions[n].paid.amount) }}
                </p>
            </card>
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Reembolsadas
                    <span class="ml-auto">
                        {{ commissions[n].refunded.count }}
                        {{ commissions[n].refunded.percentage }}%
                    </span>
                </p>
                <p
                    class="text-white-100 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(commissions[n].refunded.amount) }}
                </p>
            </card>
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Disputa
                    <span class="ml-auto">
                        {{ commissions[n].disputed.count }}
                        {{ commissions[n].disputed.percentage }}%
                    </span>
                </p>
                <p
                    class="text-white-100 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(commissions[n].disputed.amount) }}
                </p>
            </card>
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Chargeback
                    <span class="ml-auto">
                        {{ commissions[n].chargeback.count }}
                        {{ commissions[n].chargeback.percentage }}%
                    </span>
                </p>
                <p
                    class="text-white-100 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(commissions[n].chargeback.amount) }}
                </p>
            </card>
        </template>
    </div>
</template>

<script>
import Card from "../../layouts/Card.vue";

export default {
    components: { Card },
    props: {
        review: { default: false },
        cashout: { default: false },
    },

    emits: ["update"],

    data() {
        return {
            loading: false,
            commissions: {
                30: {
                    paid: {
                        count: 0,
                        amount: 0,
                    },
                    refunded: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    disputed: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    chargeback: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                },
                60: {
                    paid: {
                        count: 0,
                        amount: 0,
                    },
                    refunded: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    disputed: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    chargeback: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                },
                0: {
                    paid: {
                        count: 0,
                        amount: 0,
                    },
                    refunded: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    disputed: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                    chargeback: {
                        count: 0,
                        amount: 0,
                        percentage: 0,
                    },
                },
            },
        };
    },

    watch: {
        cashout() {
            if (this.cashout) {
                this.fetch();
            }
        },
    },

    mounted() {
        if (this.cashout && !this.review) {
            this.fetch();
        }

        if (this.review) {
            this.commissions = this.review.commissions;
        }
    },

    methods: {
        fetch() {
            this.loading = true;
            [30, 60, 0].map((interval) => {
                this.interval(interval).then((data) => {
                    this.commissions[interval] = data;
                });
            });

            setTimeout(() => {
                this.loading = false;
                this.$emit("update", { commissions: this.commissions });
            }, 2000);
        },
        interval(interval) {
            return axios
                .get(
                    "/api/user/" +
                        this.cashout.user_id +
                        "/commission/summaryCards",
                    {
                        params: {
                            interval: interval,
                        },
                    },
                )
                .then(({ data }) => {
                    return data;
                });
        },
    },
};
</script>
