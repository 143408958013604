<template>
    <img v-tooltip="gateway" class="h-7 w-7 max-w-[100px]" :src="url" />
</template>

<script>
export default {
    props: {
        gateway: {
            type: String,
            required: true,
        },
    },

    computed: {
        url() {
            switch (this.gateway) {
                case "pagseguro":
                    return "https://d1hwwmlgegwbz2.cloudfront.net/assets/icons/pagseguro-icon.svg";
                case "mercadopago":
                    return "https://cdn.icon-icons.com/icons2/3913/PNG/512/mercadopago_logo_icon_248494.png";
                default:
                    return this.gateway;
            }
        },
    },
};
</script>
