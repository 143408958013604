<template>
    <nav-tabs :tabs="routes" />
    <router-view></router-view>
</template>

<script>
import NavTabs from "../../components/layouts/NavTabs.vue";

export default {
    components: {NavTabs},
    data() {
        return {
            routes: [
                { label: "Recomendados", route: "showcase.recommended" },
                { label: "Configurações", route: "showcase.settings" },
            ],
        };
    },
};
</script>
