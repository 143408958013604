<template>
    <div class="my-5">
        <h3 class="mb-5 text-lg font-medium leading-6 dark:text-white">
            ViewAI - Usuários
        </h3>
        <div class="grow">
            <SvgLoader v-if="this.loading" />

            <filters
                v-model="filters"
                @fetch="
                    users = [];
                    fetch();
                "
                :loading="loading"
                search-filter
                status-filter
                :statuses="[
                    { value: 'active', label: 'Ativo' },
                    { value: 'canceled', label: 'Cancelado' },
                ]"
                search-label="Filtre por e-mail"
                className="mb-5"
            />

            <table-component
                :loading="loading"
                :pagination="pagination"
                :headers="[
                    'Usuário',
                    'Nome do Plano',
                    'Tipo de Cobrança',
                    'Intervalo de Pagamento',
                    'Carência',
                    'Valor do Plano',
                    'Status do Plano',
                    'Status Pagamento',
                    'Próxima Cobrança',
                    'Ação',
                ]"
                @fetch="fetch"
            >
                <tr v-for="user in users">
                    <td class="table-cell-sm">
                        {{ user.name }}
                    </td>
                    <td class="table-cell-sm">
                        {{ user.plan_name }}
                    </td>
                    <td class="table-cell-sm">
                        {{
                            user.subscription_charge_type == "automatic-debit"
                                ? "Débito Automático"
                                : "Debitar da Transação"
                        }}
                    </td>
                    <td class="table-cell-sm">
                        {{ getPayment(user.interval) }}
                    </td>
                    <td class="table-cell-sm">
                        {{ user.subscription_cancelation_grace_days }} dias
                    </td>
                    <td class="table-cell-sm">
                        {{ $root.formatCurrency(user.price) }}
                    </td>
                    <td class="table-cell-sm">
                        {{
                            user.subscription_status === "active"
                                ? "Ativo"
                                : "Cancelado"
                        }}
                    </td>
                    <td class="table-cell-sm">
                        {{ user.subscription_payment_status === 'on_day' ? 'Em Dia' : 'Em Atraso' }}
                    </td>
                    <td class="table-cell-sm">
                        {{ user.next_charge }}
                    </td>
                    <td class="table-cell-sm">
                        <button
                            v-if="user.subscription_status === 'active'"
                            @click.prevent="
                                cancelSignature(user.subscription_id)
                            "
                            :disabled="loading"
                            type="button"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-red-500 rounded-md shadow hover:bg-indigo-400"
                        >
                            <TrashIcon
                                class="flex-shrink-0 w-3 h-3 mr-1 text-white-400"
                                aria-hidden="true"
                            />
                            Cancelar Assinatura
                        </button>
                    </td>
                </tr>
            </table-component>
        </div>
    </div>
</template>

<script>
import {
LinkIcon,
PencilIcon,
PlusIcon,
TrashIcon,
} from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import MoneyInput from "../../../components/forms/MoneyInput.vue";
import Filters from "../../../components/layouts/Filters.vue";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import CreateTemplateModal from "../modals/CreateTemplateModal.vue";

export default {
    components: {
        SvgLoader,
        CreateTemplateModal,
        MoneyInput,
        TableComponent,
        LinkIcon,
        PlusIcon,
        TrashIcon,
        PencilIcon,
        Filters,
    },
    data() {
        return {
            filters: {
                text: "",
                status: [],
            },
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            loading: false,
            users: [],
        };
    },
    async mounted() {
        await this.fetch();
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    methods: {
        async fetch() {
            this.loading = true;
            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get(`/api/viewai-subscriptions/users`, {
                    params: params,
                })
                .then((response) => {
                    this.users = response.data.data;
                    this.pagination = {
                        ...this.pagination,
                        ...response.data,
                    };

                    this.next_page_url = response.data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => console.error(error));
        },

        getPayment(interval) {
            if (interval !== null) {
                let payment = null;
                switch (interval) {
                    case 1:
                        payment = "Mensal";
                        break;

                    case 2:
                        payment = "Bimestral";
                        break;

                    case 3:
                        payment = "Trimestral";
                        break;

                    case 4:
                        payment = "Quadrimestral";
                        break;

                    case 6:
                        payment = "Semestral";
                        break;

                    case 12:
                        payment = "Anual";
                        break;

                    default:
                        payment = "-";
                        break;
                }
                return payment;
            } else {
                return "-";
            }
        },
        cancelSignature(id) {
            axios
                .post(`/api/viewai-subscriptions/${id}/cancel-renovation`)
                .then(({ data }) => {
                    if (data) {
                        this.toast.success("Assinatura cancelada com sucesso");
                        this.fetch();
                    }
                })
                .catch(
                    ({
                        response: {
                            data: { message },
                        },
                    }) => {
                        this.toast.error(message);
                    },
                );
        },
    },
};
</script>
