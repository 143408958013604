<template>
    <filters
        v-model="filters"
        @fetch="
            pagination.meta.current_page = 1;
            reviews = [];
            fetch();
        "
        status-filter
        :statuses="[
            { label: 'Solicitado', value: 'pending' },
            { label: 'Aprovado', value: 'approved' },
            { label: 'Reprovado', value: 'rejected' },
        ]"
    >
        <text-input
            placeholder="Min Approved"
            v-model="minApproved"
            type="number"
        />
    </filters>

    <div class="space-y-1">
        <empty-state v-if="!loading && reviews.length < 1" />
        <div
            v-for="review in reviews"
            :key="review.id"
            class="flex items-center justify-between rounded-lg bg-white px-3 py-4 shadow dark:bg-slate-800"
        >
            <review-modal
                :key="review.id"
                :cashout="review.cashout"
                :open="openedReview === review.id"
                :review="review.status !== 'pending' ? review : false"
                @close="openedReview = null"
                @fetch="
                    pagination.meta.current_page = 1;
                    reviews = [];
                    openedReview = null;
                    fetch();
                "
            />
            <div class="flex items-center">
                <img
                    v-tooltip="
                        review.cashout.user.name +
                        ' - ' +
                        review.cashout.user.email
                    "
                    @click.prevent="openedReview = review.id"
                    class="h-8 w-8 rounded-full hover:cursor-pointer hover:ring-2"
                    :src="
                        review.cashout.user.avatar ||
                        'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                            review.cashout.user.name
                    "
                />

                <div class="ml-2 flex flex-col">
                    <a
                        href="#"
                        @click.prevent="openedReview = review.id"
                        class="font-medium text-indigo-600 hover:text-indigo-800"
                    >
                        {{ review.cashout.user.email }}
                    </a>
                    <p class="text-sm text-gray-600 dark:text-gray-300">
                        {{ review.cashout.user.name }} -
                        <span v-tooltip="review.created_at">
                            {{ review.human_created_at }}
                        </span>
                    </p>
                </div>
                <cashout-status
                    class="ml-3"
                    :status="review.cashout.status"
                    size="sm"
                />
                <div
                    v-if="review.seller"
                    class="text-lg font-bold"
                    v-tooltip="'Score'"
                    :class="{
                        'text-red-500': review.seller.score <= 25,
                        'text-yellow-500':
                            review.seller.score > 25 &&
                            review.seller.score <= 50,
                        'text-green-500': review.seller.score > 50,
                    }"
                >
                    {{ review.seller.score }}
                </div>
            </div>
            <div class="flex items-center">
                <div
                    v-if="review.reviews && review.reviews.warning"
                    class="mr-2 flex items-center text-yellow-500"
                    v-tooltip="'Reviews Atenção'"
                >
                    {{ review.reviews.warning }}
                    <ExclamationTriangleIcon
                        class="ml-1 h-6 w-6 focus-visible:ring-0"
                        aria-hidden="true"
                    />
                </div>
                <div
                    v-if="review.reviews && review.reviews.approved"
                    class="mr-2 flex items-center text-green-500"
                    v-tooltip="'Reviews Aprovados'"
                >
                    {{ review.reviews.approved }}
                    <CheckCircleIcon
                        class="ml-1 h-6 w-6 focus-visible:ring-0"
                        aria-hidden="true"
                    />
                </div>
                <div
                    v-if="review.reviews && review.reviews.rejected"
                    class="mr-2 flex items-center text-red-500"
                    v-tooltip="'Reviews Reprovados'"
                >
                    {{ review.reviews.rejected }}
                    <NoSymbolIcon
                        class="ml-1 h-6 w-6 focus-visible:ring-0"
                        aria-hidden="true"
                    />
                </div>
                <div class="relative">
                    <img
                        v-tooltip="review.user.name + ' - ' + review.user.email"
                        class="h-8 w-8 rounded-full"
                        :src="review.user.avatar || 'default-avatar-url'"
                    />
                </div>
                <div class="ml-4">
                    <p
                        class="text-sm font-medium text-gray-900 dark:text-white"
                    >
                        {{ $root.formatCurrency(review.cashout.net_value) }}
                    </p>
                    <p
                        :class="statusClass(review.status)"
                        class="text-xs font-medium"
                    >
                        {{ statusName(review.status) }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="
                pagination &&
                pagination.meta.current_page < pagination.meta.last_page
            "
            class="flex items-center justify-center py-2"
        >
            <submit-button
                :loading="loading"
                @click.prevent="
                    pagination.meta.current_page++;
                    fetch();
                "
                size="sm"
            >
                Carregar Mais
            </submit-button>
        </div>
    </div>
</template>

<script>
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import { CheckIcon } from "@heroicons/vue/20/solid";
import ReviewModal from "../../components/cashouts/reviews/ReviewModal.vue";
import Filters from "../../components/layouts/Filters.vue";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    NoSymbolIcon,
    ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline";
import CashoutStatus from "../../components/layouts/CashoutStatus.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import TextInput from "../../components/forms/TextInput.vue";
export default {
    components: {
        TextInput,
        EmptyState,
        ExclamationTriangleIcon,
        SubmitButton,
        CashoutStatus,
        ExclamationCircleIcon,
        Filters,
        ReviewModal,
        CheckIcon,
        TableComponent,
        CheckCircleIcon,
        NoSymbolIcon,
    },
    data() {
        return {
            loading: false,
            reviews: [],
            openedReview: null,
            filters: {},
            minApproved: "",
            pagination: {
                meta: {
                    current_page: 1,
                    last_page: 10,
                    page: 1,
                    per_page: 10,
                    total: 0,
                },
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            let filters = this.filters;
            filters["filter[minApproved]"] = this.minApproved;

            axios
                .get("/api/cashout/review", {
                    params: {
                        page: this.pagination.meta.current_page,
                        ...this.filters,
                    },
                })
                .then(({ data }) => {
                    this.reviews = [...this.reviews, ...data.data];
                    this.pagination = data;
                    this.loading = false;
                });
        },
        statusName(name) {
            switch (name) {
                case "pending":
                    return "Solicitado";
                case "approved":
                    return "Aprovado";
                case "warning":
                    return "Atenção";
                case "rejected":
                    return "Reprovado";
            }
        },
        statusClass(status) {
            switch (status) {
                case "pending":
                case "warning":
                    return "text-yellow-500";
                case "approved":
                    return "text-green-500";
                case "rejected":
                    return "text-red-500";
            }
        },
    },
};
</script>
