<template>
    <filters
        class="my-3"
        v-model="filters"
        @fetch="translations = []; next_page_url = false; fetch();"
        :loading="loading"

        language-filter
        :languages="languages"
        models-filter
        :models="models"
    />

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Model',
            'Original',
            'Língua',
            'Tradução',
            'Ações'
        ]"
        @fetch="fetch"
    >
        <tr v-for="t in translations" :key="t.id">
            <td class="table-cell-sm">
                {{ t.model_type }}
            </td>
            <td class="table-cell-sm">
                {{ t.original }}
            </td>
            <td class="table-cell-sm">
                {{ t.locale }}
            </td>
            <td class="table-cell-sm">
                <input 
                    v-if="t.editing" 
                    v-model="t.translation" 
                    class="form-input w-full" 
                    type="text"
                    :ref="'input-' + t.id"
                    @keyup.enter="saveTranslation(t)"
                />
                <span v-else>
                    {{ t.translation }}
                </span>
            </td>
            <td class="table-cell-sm">
                <button @click="editTranslation(t)" v-if="!t.editing">Editar</button>
                <button @click="saveTranslation(t)" v-if="t.editing">Salvar</button>
                <button @click="cancelEdit(t)" v-if="t.editing">Cancelar</button>
            </td>
        </tr>
    </table-component>
</template>

<script>
    import Filters from "../../components/layouts/Filters.vue";
    import TableComponent from "../../components/layouts/table/TableComponent.vue";
    import BrowserIcon from "../../components/layouts/antifraud/BrowserIcon.vue";
    import OsIcon from "../../components/layouts/antifraud/OsIcon.vue";

    export default {
        components: {OsIcon, BrowserIcon,TableComponent, Filters},
        data() {
            return {
                models: [],
                languages: [],
                translations: [],
                next_page_url: false,
                pagination: {
                    total: 0,
                    per_page: 25,
                    current_page: 1,
                    last_page: 10,
                    from: 1,
                    to: 10
                },
                filters: {
                    text: ''
                },
            }
        },

        directives: {
            focus: {
                inserted(el) {
                    el.focus();
                }
            }
        },

        mounted() {
            this.fetch();
            this.fetchFilterOptions();
        },

        methods: {
            fetch() {
                this.loading = true;

                axios.get('/api/translations', { 
                    params: {
                        ...this.filters,
                        page: this.pagination.current_page
                }
                }).then(({data}) => {
                    this.translations = data.data;
                    this.loading = false;

                    this.pagination = {
                        ...this.pagination,
                        ...data
                    };

                    this.pagination.total = data.total;
                    this.pagination.from = this.pagination.current_page * this.pagination.per_page - this.pagination.per_page + 1;
                    this.pagination.to = this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page);


                    this.next_page_url = data.next_page_url;
                }).catch(error => {
                    this.loading = false;
                });
            },
            fetchFilterOptions() {
                axios.get('/api/translations/filters').then(({ data }) => {
                    this.models = data.models;
                    this.languages = data.languages;

                    this.models = data.models.map(model => {
                        return { value: model.model_type, label: model.model_type.replace('App\\Models\\', '') };
                    });

                    this.languages = data.locales.map(locale => {
                        return { value: locale.locale, label: locale.locale };
                    });

                }).catch(error => {
                    console.error(error);
                });
            },
            editTranslation(translation) {
                translation.editing = true;
                translation.originalTranslation = translation.translation;
                
                this.$nextTick(() => {
                    const input = this.$refs[`input-${translation.id}`][0];
                    if (input) {
                        input.focus();
                    }
                });
            },
            cancelEdit(translation) {
                translation.translation = translation.originalTranslation;
                translation.editing = false;
            },
            saveTranslation(translation) {
                if(confirm("Tem certeza que deseja salvar a tradução?")){
                    axios.put(`/api/translations/${translation.id}`, {
                        translation: translation.translation
                    }).then(() => {
                        translation.editing = false;
                        translation.originalTranslation = translation.translation;
                    }).catch(error => {
                        console.error(error);
                    });
                }
            }
        }
    }
</script>
