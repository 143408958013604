<template>
    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-4 mb-3">
        <card
            v-if="loading"
            v-for="n in 4"
            :loading="true"
        />
        <stat-card
            v-if="!loading"
            name="Total"
            :stat="cards.count"
        />
        <stat-card
            v-if="!loading"
            name="Ativos"
            :stat="cards.active"
        />
    </dl>
</template>

<script>
    import StatCard from "../../layouts/StatCard.vue";
    import Card from "../../layouts/Card.vue";

    export default {
        components: {StatCard, Card},
        data() {
            return {
                loading: true,
                cards: {
                    count: 0,
                    active: 0
                },
            };
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                axios.get('/api/analytics/user/totals').then(({data}) => {
                    this.cards = data;
                    this.loading = false;
                })
            }
        }
    }
</script>
