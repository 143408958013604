<template>
    <span
        class="ml-auto text-sm text-indigo-500 hover:cursor-pointer hover:underline"
        @click="open = !open"
    >
        Editar
    </span>
    <modal :open="open" @close="open = false">
        <form class="flex flex-col" @submit.prevent="submit">
            <h3 class="mb-2 font-bold dark:text-white">
                Editar Conta #{{ bankAccount?.id }} -
                {{ bankAccount?.receiver_type === 'bank' ? bankAccount?.bank?.name : 'PIX' }}
            </h3>

            <div v-if="bankAccount.receiver_type === 'bank'">
                <text-input
                    v-model="form.branch_number"
                    label="Agencia"
                    class="mb-2"
                />
                <div class="grid grid-cols-4 gap-2">
                    <text-input
                        v-model="form.account_number"
                        label="Conta"
                        class="col-span-3"
                    />
                    <text-input v-model="form.account_check_digit" label="DV"/>
                </div>
            </div>
            <div v-else>
                <text-input
                    v-model="form.pix_key"
                    label="Chave PIX"
                    class="mb-2"
                />
            </div>
            <submit-button class="mt-4" :loading="loading">
                Enviar
            </submit-button>
        </form>
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import TextInput from "../../forms/TextInput.vue";

export default {
    components: {TextInput, SubmitButton, Modal},
    props: ["bankAccount"],
    data() {
        return {
            loading: false,
            open: false,
            form: {
                account_number: this.bankAccount.account_number,
                account_check_digit: this.bankAccount.account_check_digit,
                branch_number: this.bankAccount.branch_number,
                branch_check_digit: this.bankAccount.branch_check_digit,
                pix_key: this.bankAccount.pix_key,
                bank_id: this.bankAccount.bank_id,
            },
        };
    },

    methods: {
        submit() {
            this.loading = true;

            axios
                .put("/api/bank-account/" + this.bankAccount.id, this.form)
                .then(({data}) => {
                    this.loading = false;
                    this.open = false;
                    this.$emit("updated");
                });
        },
    },
};
</script>
