<template>
    <div class="w-full">
        <bank-data-totals
            :loading="loading"
            :previous-signups="previousSignups"
            :signups="signups"
        />

        <chart-skeleton v-if="loading"/>
        <highcharts v-if="!loading && registerChart" class="hc" :options="registerChart" ref="chart"></highcharts>

        <h3 class="text-lg font-bold leading-6 font-medium dark:text-white my-3">
            Onboarding
        </h3>

        <onboarding-totals
            :loading="loading"
            :previous-signups="previousSignups"
            :signups="signups"
        />

        <chart-skeleton v-if="loading"/>
        <highcharts v-if="!loading && onboardingChart" class="hc" :options="onboardingChart" ref="chart"></highcharts>
    </div>
</template>

<script>
import StatCard from "../../../components/layouts/StatCard.vue";
import OnboardingTotals from "../../../components/analytics/users/OnboardingTotals.vue";
import ChartSkeleton from "../../../components/layouts/ChartSkeleton.vue";
import BankDataTotals from "../../../components/analytics/users/BankDataTotals.vue";

export default {
    components: {BankDataTotals, ChartSkeleton, OnboardingTotals, StatCard},
    props: ['dates'],
    data() {
        return {
            loading: false,
            onboardingTotals: [],
            previousSignups: [],
            signups: [],

            registerChart: false,
            onboardingChart: false,
        };
    },

    watch: {
        dates() {
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        chartObject() {
            return {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ${point.y}`;
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            };
        },
        fetch() {
            this.loading = true;
            this.onboardingTotals = [];
            axios.get('/api/analytics/compliance/signups', {
                params: {
                    from: this.dates[0],
                    to: this.dates[1]
                }
            }).then(({data}) => {
                let registerChart = this.chartObject();
                this.signups = data.current;
                this.previousSignups = data.previous;

                const pendingSeries = {
                    name: 'Pendentes',
                    lineWidth: 3,
                    data: []
                };
                const approvedSeries = {
                    name: 'Aprovados',
                    lineWidth: 3,
                    data: []
                }
                const reprovedSeries = {
                    name: 'Reprovados',
                    lineWidth: 3,
                    data: []
                }
                const pendingCorrectionSeries = {
                    name: 'Pendentes de correção',
                    lineWidth: 3,
                    data: []
                }

                Object.entries(this.signups).forEach(([key, value]) => {
                    pendingSeries.data.push([value.unix_date, value.pending]);
                    approvedSeries.data.push([value.unix_date, value.approved]);
                    reprovedSeries.data.push([value.unix_date, value.reproved]);
                    pendingCorrectionSeries.data.push([value.unix_date, value['pending-correction']]);
                });

                registerChart.series.push(pendingSeries, approvedSeries, reprovedSeries, pendingCorrectionSeries);
                this.registerChart = registerChart;
                this.setOnboardingChart(data);

                this.loading = false;
            });
        },
        setOnboardingChart() {
            let onboardingChart = this.chartObject();

            const waitingSeries = {
                name: 'Cadastros',
                lineWidth: 3,
                data: []
            };
            const pendingSeries = {
                name: 'Pendentes',
                lineWidth: 3,
                data: []
            };
            const approvedSeries = {
                name: 'Aprovados',
                lineWidth: 3,
                data: []
            }
            const reprovedSeries = {
                name: 'Reprovados',
                lineWidth: 3,
                data: []
            }
            const pendingCorrectionSeries = {
                name: 'Pendentes de correção',
                lineWidth: 3,
                data: []
            }

            Object.entries(this.signups).forEach(([key, value]) => {
                waitingSeries.data.push([value.unix_date, value['onboarding-waiting']]);
                pendingSeries.data.push([value.unix_date, value['onboarding-pending']]);
                approvedSeries.data.push([value.unix_date, value['onboarding-approved']]);
                reprovedSeries.data.push([value.unix_date, value['onboarding-reproved']]);
                pendingCorrectionSeries.data.push([value.unix_date, value['onboarding-pending-correction']]);
            });
            onboardingChart.series.push(waitingSeries, pendingSeries, approvedSeries, reprovedSeries, pendingCorrectionSeries);

            this.onboardingChart = onboardingChart;

        }
    }
};
</script>
