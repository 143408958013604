<template>
    <modal
        class="min-h-full"
        :open="modal"
        @close="$emit('close')"
    >
        <div class="flex flex-col flex-wrap w-full divide-y space-y-3 divide-slate-500 text-white min-h-fit">
        </div>
        <h1 class="text-lg text-white font-bold text-sm flex flex-row flex-wrap items-center content-center border-b border-slate-500 pb-1 mb-2">
            Remover Gerente {{manager.name}} {{manager.email}}
        </h1>

        <submit-button @click.prevent="submit"  :loading="loading" class="w-full">Remover</submit-button>
    </modal>
</template>

<script>
    import Modal from "../../layouts/Modal.vue";
    import SubmitButton from "../../forms/SubmitButton.vue";
    import ManagerForm from "../ManagerForm.vue";

    export default {
        components: {ManagerForm, SubmitButton, Modal},
        props: ['manager', 'modal'],
        emits: ['fetch'],
        data() {
            return {
                show: false,
                loading: false,
            }
        },

        methods: {
            submit() {
                this.loading = true;
                axios.delete(`/api/account-manager/${this.manager.uuid}`,).then(response => {
                    this.loading = false;
                    this.$emit('fetch');
                }).catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
