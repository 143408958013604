<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Itens
    </h3>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3 mt-2">
        <div class="inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden not-prose relative bg-slate-50 rounded-xl overflow-hidden dark:bg-slate-800/25">
                <table class="border-collapse table-auto w-full text-sm">
                    <thead>
                        <tr>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Produto
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                    Oferta
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Quantidade
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Valor
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Tipo
                            </th>
                            <th scope="col" class="text-sm font-medium dark:text-slate-200 px-6 py-4 text-left">
                                Categoria
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-slate-800">
                        <tr v-for="(order, orderIdx) in order.items" :key="order.email">
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                <button
                                    class="text-indigo-500 hover:underline"
                                    @click.prevent="redirectToProduct(order.product.id)"
                                >
                                    {{ order.product.name }}
                                </button>
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                <button
                                    class="text-indigo-500 hover:underline"
                                    @click.prevent="redirectToOffer(order.offer.id)"
                                >
                                    {{ order.offer.code }}
                                </button>
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ order.quantity }}
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ $root.formatCurrency(order.amount) }}
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                <product-type :type="order.product.type"/>
                            </td>
                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                {{ order.product.category.name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import ProductType from "../../components/layouts/ProductType.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";

export default {
    components: { ProductType },
    props: ["order"],
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            commissions: [],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },
    methods: {
        addressTitle(isDefault) {
            if (isDefault) {
                return "Endereço Principal";
            }
            return "Endereço Secundário";
        },
        redirectToProduct(productId) {
            this.$router.replace({
                name: "product",
                params: { product_id: productId },
            });
        },
        redirectToOffer(offerId) {
            this.$router.replace({
                name: "user.order",
                params: { offer_id: offerId },
            });
        },
    },
};
</script>
