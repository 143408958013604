<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-10 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block transform overflow-hidden rounded-lg bg-gray-700 px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
                    >
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-money-check-dollar"
                                    class="mx-auto mt-1 h-10 w-10 text-gray-200 dark:text-gray-200"
                                    aria-hidden="true"
                                />
                            </div>
                            <div
                                class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left"
                            >
                                <dialog-title
                                    as="h3"
                                    class="text-lg font-medium leading-6 text-white"
                                >
                                    Informações da alteração #{{ history.id }}
                                </dialog-title>
                                <div class="mt-2">
                                    <div
                                        class="flex min-w-0 flex-1 items-center"
                                    >
                                        <div class="flex-shrink-0">
                                            <img
                                                class="h-12 w-12 rounded-full"
                                                :src="
                                                    history.user.avatar ||
                                                    'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                                        history.user.name
                                                "
                                                alt=""
                                            />
                                        </div>
                                        <div class="min-w-0 flex-1 px-4">
                                            <div>
                                                <p
                                                    class="truncate text-sm font-bold text-gray-300"
                                                >
                                                    {{ history.user.name }}
                                                </p>
                                                <p
                                                    class="mt-1 flex items-center text-sm text-gray-300"
                                                >
                                                    <EnvelopeIcon
                                                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-300"
                                                        aria-hidden="true"
                                                    />
                                                    <span class="truncate">{{ history.user.email }}</span>
                                                </p>
                                                <p
                                                    class="mt-2 flex items-center text-sm text-gray-300"
                                                >
                                                    <ClockIcon
                                                        class="text-gray-30 mr-1.5 h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                    <span class="truncate">{{ history.created_at }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <div v-if="haveCashoutUnderReviewChanges">
                                        <div
                                            class="mb-4 flex-grow border-t border-gray-400"
                                        ></div>
                                        <div
                                            v-if="
                                                history.properties.old
                                                    .allow_cashout_under_review !==
                                                history.properties.new
                                                    .allow_cashout_under_review
                                            "
                                        >
                                            <p
                                                class="mt-3 text-sm font-bold text-gray-200"
                                            >
                                                Saque de saldo em análise
                                            </p>
                                            <div class="row flex">
                                                <div class="mcol w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Antiga
                                                    </p>
                                                    <cashout-under-review
                                                        :status="
                                                            history.properties
                                                                .old
                                                                .allow_cashout_under_review
                                                        "
                                                    />
                                                </div>
                                                <div class="col w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Nova
                                                    </p>
                                                    <cashout-under-review
                                                        :status="
                                                            history.properties
                                                                .new
                                                                .allow_cashout_under_review
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <comparation-log-item
                                            title="Taxa de Antecipação (em %)"
                                            :oldValue="
                                                history.properties.old
                                                    .anticipation_fee
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .anticipation_fee
                                            "
                                        />
                                    </div>
                                    <div v-if="haveAnticipationChanges">
                                        <div
                                            class="mb-4 flex-grow border-t border-gray-400"
                                        ></div>
                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-money-bills"
                                            />
                                            Configurações de antecipação
                                        </div>
                                        <div
                                            v-if="
                                                history.properties.old
                                                    .can_anticipate !==
                                                history.properties.new
                                                    .can_anticipate
                                            "
                                        >
                                            <p
                                                class="mt-3 text-sm font-bold text-gray-200"
                                            >
                                                Versão da Antecipação
                                            </p>
                                            <div class="row flex">
                                                <div class="mcol w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Antiga
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .old
                                                                .anticipation_version
                                                        "
                                                    />
                                                </div>
                                                <div class="col w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Nova
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .new
                                                                .anticipation_version
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <comparation-log-item
                                            title="Versao da Antecipação"
                                            :oldValue="
                                                history.properties.old
                                                    .anticipation_version
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .anticipation_version
                                            "
                                        />
                                    </div>
                                    <div v-if="haveAnticipationChanges">
                                        <div
                                            class="mb-4 flex-grow border-t border-gray-400"
                                        ></div>
                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-money-bills"
                                            />
                                            Configurações de antecipação
                                        </div>
                                        <div
                                            v-if="
                                                history.properties.old
                                                    .can_anticipate !==
                                                history.properties.new
                                                    .can_anticipate
                                            "
                                        >
                                            <p
                                                class="mt-3 text-sm font-bold text-gray-200"
                                            >
                                                Situação da antecipação
                                            </p>
                                            <div class="row flex">
                                                <div class="mcol w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Antiga
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .old
                                                                .can_anticipate
                                                        "
                                                    />
                                                </div>
                                                <div class="col w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Nova
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .new
                                                                .can_anticipate
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <comparation-log-item
                                            title="Taxa de Antecipação (em %)"
                                            :oldValue="
                                                history.properties.old
                                                    .anticipation_fee
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .anticipation_fee
                                            "
                                        />
                                    </div>

                                    <div v-if="haveFixedFeeChanges || haveInternationalFeeChanges">
                                        <div
                                            class="mb-4 flex-grow border-t border-gray-400"
                                        ></div>
                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-dollar-sign"
                                            />
                                            Cobrança Fixa
                                        </div>
                                        <div v-if="haveFixedFeeChanges">
                                            <p
                                                class="mt-3 text-sm font-bold text-gray-200"
                                            >
                                                Taxa fixa nacional (R$)
                                            </p>
                                            <div class="row flex">
                                                <div class="mcol w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Antiga
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .old
                                                                .platform_fixed_fee
                                                        "
                                                    />
                                                </div>
                                                <div class="col w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Nova
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .new
                                                                .platform_fixed_fee
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="haveInternationalFeeChanges">
                                            <p
                                                class="mt-3 text-sm font-bold text-gray-200"
                                            >
                                                Taxa fixa internacional (R$)
                                            </p>
                                            <div class="row flex">
                                                <div class="mcol w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Antiga
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .old
                                                                .international_fixed_fee
                                                        "
                                                    />
                                                </div>
                                                <div class="col w-48">
                                                    <p
                                                        class="mb-3 text-sm font-medium text-gray-400"
                                                    >
                                                        Nova
                                                    </p>
                                                    <anticipation-status
                                                        :status="
                                                            history.properties
                                                                .new
                                                                .international_fixed_fee
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="haveCreditCardChanges">
                                        <div
                                            class="my-4 flex-grow border-t border-gray-400"
                                        ></div>
                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-credit-card"
                                            />
                                            Cartão de Crédito e Dois Cartões
                                        </div>
                                        <comparation-log-item
                                            title="Tempo para saque (em
                                                    dias)"
                                            :oldValue="
                                                history.properties.old
                                                    .credit_card_cashout_days
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .credit_card_cashout_days
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa da plataforma (em
                                                    %)"
                                            :oldValue="
                                                history.properties.old
                                                    .platform_fee_credit_card
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .platform_fee_credit_card
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa de juros por parcela (em
                                                %)"
                                            :oldValue="
                                                history.properties.old
                                                    .installment_fee_credit_card
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .installment_fee_credit_card
                                            "
                                        />
                                    </div>
                                    <div v-if="haveBankSlipChanges">
                                        <div
                                            class="my-5 flex-grow border-t border-gray-400"
                                        ></div>

                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-barcode"
                                            />
                                            Boleto
                                        </div>
                                        <comparation-log-item
                                            title="Tempo para saque (em
                                                    dias)"
                                            :oldValue="
                                                history.properties.old
                                                    .bank_slip_cashout_days
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .bank_slip_cashout_days
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa da plataforma (em
                                                    %)"
                                            :oldValue="
                                                history.properties.old
                                                    .platform_fee_bank_slip
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .platform_fee_bank_slip
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa de juros por parcela (em
                                                %)"
                                            :oldValue="
                                                history.properties.old
                                                    .installment_fee_bank_slip
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .installment_fee_bank_slip
                                            "
                                        />
                                    </div>
                                    <div v-if="havePixChanges">
                                        <div
                                            class="my-5 flex-grow border-t border-gray-400"
                                        ></div>

                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-brands fa-pix"
                                            />
                                            Pix
                                        </div>
                                        <comparation-log-item
                                            title="Tempo para saque (em
                                                    dias)"
                                            :oldValue="
                                                history.properties.old
                                                    .pix_cashout_days
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .pix_cashout_days
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa da plataforma (em
                                                    %)"
                                            :oldValue="
                                                history.properties.old
                                                    .platform_fee_pix
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .platform_fee_pix
                                            "
                                        />

                                        <div
                                            class="my-5 flex-grow border-t border-gray-400"
                                        ></div>
                                    </div>
                                    <div v-if="haveInternationalPaymentChanges">
                                        <div
                                            class="my-5 flex-grow border-t border-gray-400"
                                        ></div>

                                        <div
                                            class="space-x-2 text-sm font-bold uppercase text-gray-300"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-globe"
                                            />
                                            Pagamento Internacional
                                        </div>
                                        <comparation-log-item
                                            title="Tempo para saque (em
                                                    dias)"
                                            :oldValue="
                                                history.properties.old
                                                    .international_cashout_days
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .international_cashout_days
                                            "
                                        />
                                        <comparation-log-item
                                            title="Taxa da plataforma (em
                                                    %)"
                                            :oldValue="
                                                history.properties.old
                                                    .international_platform_fee
                                            "
                                            :newValue="
                                                history.properties.new
                                                    .international_platform_fee
                                            "
                                        />

                                        <div
                                            class="my-5 flex-grow border-t border-gray-400"
                                        ></div>
                                    </div>
                                    <div class="my-3">
                                        <label
                                            for="large-input"
                                            class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                            >Motivo da alteração</label
                                        >
                                        <input
                                            type="text"
                                            disabled
                                            v-model="history.observation"
                                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                @click="closeModal()"
                                ref="cancelButtonRef"
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import {
    EnvelopeIcon,
    GlobeAltIcon,
    CloudArrowUpIcon,
    ClockIcon,
} from "@heroicons/vue/24/outline";

import HelperMixin from "../../../mixins/HelperMixin.vue";
import AnticipationStatus from "../../../components/layouts/AnticipationStatus.vue";
import CashoutUnderReview from "../../../components/layouts/CashoutUnderReview.vue";
import ComparationLogItem from "../../../components/layouts/ComparationLogItem.vue";
export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        AnticipationStatus,
        CashoutUnderReview,
        ComparationLogItem,
        EnvelopeIcon,
        GlobeAltIcon,
        CloudArrowUpIcon,
        ClockIcon,
    },
    mixins: [HelperMixin],
    props: {
        history: {
            type: Object,
        },
    },
    computed: {
        haveFixedFeeChanges() {
            return (
                this.history.properties.old.platform_fixed_fee !=
                this.history.properties.new.platform_fixed_fee
            );
        },
        haveCashoutUnderReviewChanges() {
            return (
                this.history.properties.old.allow_cashout_under_review !=
                this.history.properties.new.allow_cashout_under_review
            );
        },
        haveInternationalFeeChanges() {
            return (
                this.history.properties.old.international_fixed_fee !=
                this.history.properties.new.international_fixed_fee
            );
        },
        haveAnticipationChanges() {
            return (
                this.history.properties.old.anticipation_version !==
                    this.history.properties.new.anticipation_version ||
                this.history.properties.old.can_anticipate !==
                    this.history.properties.new.can_anticipate ||
                this.history.properties.old.anticipation_days !==
                    this.history.properties.new.anticipation_days ||
                this.history.properties.old.anticipation_fee !==
                    this.history.properties.new.anticipation_fee
            );
        },
        haveCreditCardChanges() {
            return (
                this.history.properties.old.credit_card_cashout_days !==
                    this.history.properties.new.credit_card_cashout_days ||
                this.history.properties.old.platform_fee_credit_card !==
                    this.history.properties.new.platform_fee_credit_card ||
                this.history.properties.old.installment_fee_credit_card !==
                    this.history.properties.new.installment_fee_credit_card
            );
        },
        haveBankSlipChanges() {
            return (
                this.history.properties.old.bank_slip_cashout_days !==
                    this.history.properties.new.bank_slip_cashout_days ||
                this.history.properties.old.platform_fee_bank_slip !==
                    this.history.properties.new.platform_fee_bank_slip ||
                this.history.properties.old.installment_fee_bank_slip !==
                    this.history.properties.new.installment_fee_bank_slip
            );
        },
        havePixChanges() {
            return (
                this.history.properties.old.pix_cashout_days !==
                    this.history.properties.new.pix_cashout_days ||
                this.history.properties.old.platform_fee_pix !==
                    this.history.properties.new.platform_fee_pix
            );
        },

        haveInternationalPaymentChanges() {
            return (
                this.history.properties.old.international_cashout_days !==
                    this.history.properties.new.international_cashout_days ||
                this.history.properties.old.international_platform_fee !==
                    this.history.properties.new.international_platform_fee
            );
        },
    },

    methods: {
        closeModal() {
            this.$emit("closeModal");
        },

        changeCanAnticipate() {
            this.history.properties.new.can_anticipate =
                !this.history.properties.new.can_anticipate;
        },
    },
};
</script>
