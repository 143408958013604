<template>
    <div class="my-3 flex items-center justify-between">
        <div class="flex items-center">
            <img
                :src="
                    user.avatar ||
                    'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                        user.name
                "
                alt
                class="h-auto max-w-full rounded-full border-none align-middle shadow"
                style="width: 40px; height: 40px"
            />
            <span class="ml-2 dark:text-white">{{ user.email }}</span>
            <span
                v-if="user?.setting?.is_blocked"
                class="ml-2 inline-block rounded bg-red-600 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
            >
                <font-awesome-icon
                    icon="fa-solid fa-ban"
                    class="mr-2"
                    aria-hidden="true"
                />
                Bloqueado
            </span>
            <router-link
                target="_blank"
                class="ml-1 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-1 py-1 text-base font-medium shadow-sm transition-all hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-transparent dark:text-white dark:hover:bg-gray-300 dark:hover:text-gray-900 sm:mt-0 sm:w-auto sm:text-sm"
                v-if="!$route.path.includes('user')"
                :to="{
                    name: 'user.commissions',
                    params: { user_id: user.id },
                }"
            >
                Detalhes
                <ArrowTopRightOnSquareIcon class="h-4 w-4" aria-hidden="true" />
            </router-link>
            <span
                v-if="user.legacy_id"
                class="ml-2 inline-block rounded bg-yellow-600 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
            >
                Migração Legado
            </span>
            <span
                v-if="user.is_producer"
                class="ml-2 inline-block rounded bg-blue-600 px-2 py-1 text-xs font-semibold uppercase uppercase text-white last:mr-0"
            >
                Produtor
            </span>
        </div>
        <slot></slot>
    </div>
    <div class="mb-4 bg-indigo-900 py-4 text-center lg:px-4">
        <div
            class="flex items-center bg-indigo-800 p-2 leading-none text-indigo-100 lg:inline-flex lg:rounded-full"
            role="alert"
        >
            <span
                class="mr-3 flex rounded-full bg-green-500 px-2 py-1 text-xs font-bold uppercase"
                >Aviso</span
            >

            <span
                v-if="isUserBirthday(user)"
                class="mr-2 flex-auto text-left font-semibold"
            >
                Este usuário está fazendo aniversário hoje!
            </span>
            <span
                v-else-if="isUserNotCompany(user) && daysRemaining > 0"
                class="mr-2 flex-auto text-left font-semibold"
            >
                {{ remainingText }} {{ daysRemaining }} {{ dayText }} para o
                aniversário deste usuário.
            </span>
            <span
                v-else-if="isUserCompanyWithoutBirth(user)"
                class="mr-2 flex-auto text-left font-semibold"
            >
                Este usuário está cadastrado na Ticto há {{ userAge }}.
            </span>
            <span
                v-else-if="user?.type == 'company' && birth == null"
                class="mr-2 flex-auto text-left font-semibold"
            >
                Usuário não preencheu data de nascimento.
            </span>
        </div>
    </div>
</template>

<script>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
export default {
    props: ["user"],
    components: {
        ArrowTopRightOnSquareIcon,
    },
    computed: {
        daysRemaining() {
            return this.daysToNextBirthday(this.user);
        },
        remainingText() {
            return this.daysRemaining === 1 ? "Falta" : "Faltam";
        },
        dayText() {
            return this.daysRemaining === 1 ? "dia" : "dias";
        },
        userAge() {
            if (this.user?.age?.age_ticto == 0) {
                const months = this.user?.age?.age_ticto_month;
                return `${months} ${months === 1 ? "mês" : "meses"}`;
            } else {
                const years = this.user?.age?.age_ticto;
                return `${years} ${years === 1 ? "ano" : "anos"}`;
            }
        },
    },
    methods: {
        isUserNotCompany(user) {
            return user?.type !== "company";
        },
        isUserBirthday(user) {
            const today = new Date().toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
            });
            if (user.birth !== null) {
                const [day, month] = user.birth.split("/").slice(0, 2);
                const birthFormatted = `${day}/${month}`;
                return this.isUserNotCompany(user) && birthFormatted === today;
            }
        },
        daysToNextBirthday(user) {
            if (!user?.age?.birth) return null;
            const today = new Date();
            const birthDate = new Date(user.age.birth);
            birthDate.setFullYear(today.getFullYear());
            if (birthDate < today) {
                birthDate.setFullYear(today.getFullYear() + 1);
            }
            const diffTime = Math.abs(birthDate - today);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        },
        isUserCompanyWithoutBirth(user) {
            return user?.type === "company";
        },
    },
};
</script>
