<template>
    <back-button :route="{ name: 'products' }"/>
    <div class="flex flex-col my-3">
        <user-heading
            v-if="storeProduct"
            :user="storeProduct.user"
        ></user-heading>

        <activity-logs-modal
            :resource="resourceToShow"
            @resetResource="resourceToShow = null;"
        />

        <nav-tabs :exact="false" :tabs="tabs" class="mb-5" />

        <div class="grid grid-cols-6 gap-4">
            <div>
                <navigation
                    :navigation="[
                        {
                            name: 'Conteúdos',
                            route: { name: 'product.members.contents' },
                            icon: BookOpenIcon,
                        },
                        {
                            name: 'Módulos',
                            route: { name: 'product.members.modules' },
                            icon: RectangleStackIcon,
                        },
                        {
                            name: 'Aulas',
                            route: { name: 'product.members.lessons' },
                            icon: VideoCameraIcon,
                        },
                    ]"
                />
            </div>
            <div class="col-span-5">
                <ticto-loading v-if="loading" />
                <router-view v-if="!loading" :user="user"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from "../../../components/layouts/navigation/BackButton.vue";
import NavTabs from "../../../components/layouts/NavTabs.vue";
import Filters from "../../../components/layouts/Filters.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import { mapActions, mapWritableState } from "pinia";
import ActivityLogsModal from "../modals/ActivityLogsModal.vue";
import { Store } from "../../../stores/store.js";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import UserHeading from "../../users/UserHeading.vue";
import Navigation from "../../../components/layouts/navigation/vertical/Navigation.vue";
import {
    BookOpenIcon,
    RectangleStackIcon,
    VideoCameraIcon,
} from "@heroicons/vue/24/outline";

export default {
    name: "ProductMembersContents",
    components: { BackButton, NavTabs, UserHeading, Filters, ActivityLogsModal, TictoLoading, Navigation, TableComponent },
    data() {
        return {
            tabs: [
                {
                    name: "details",
                    label: "Detalhes",
                    route: "product",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "students",
                    label: "Alunos",
                    route: "product.students",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "members",
                    label: "Area de membros",
                    route: "product.members",
                    params: { product_id: this.$route.params.product_id },
                }
            ],
            loading: false,
            impersonateLoading: false,
            students: [],
            studentToShow: null,
            next_page_url: false,
            selected: [],
            all_selected: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                types: [],
                date: [],
            },
        }
    },
    computed: {
        ...mapWritableState(Store, ["storeProduct", "permissions"]),
        headers() {
            return [
                "Nome",
                "Ações",
            ];
        },
    },
    async mounted() {
        if (!this.storeProduct) {
            await this.fetchProduct();
        }
        this.fetch();
    },
    methods: {
        ...mapActions(Store, ["fetchProduct"]),
        fetch() {
            let params = this.filters;
            params["page"] = this.pagination.current_page;
            this.loading = true;
            axios.get('/api/product/' + this.storeProduct.id + '/mozart-contents', {
                params: params
            }).then(({data}) => {
                this.contents = data.data;
                this.pagination = {
                    ...this.pagination,
                    ...data,
                };
                this.next_page_url = data.next_page_url;
                this.loading = false;
            })
        },
        RectangleStackIcon,
        VideoCameraIcon,
        BookOpenIcon
    }
}
</script>
