<template>
    <div class="flex flex-col dark:text-white">
        <filters
            v-model="filters"
            @fetch="
                commissions = [];
                next_page_url = false;
                fetch();
            "
            :loading="loading"
            date-filter
            search-filter
            status-filter
            payment-method-filter
            search-label="Filtre pelo nome ou e-mail do produtor ou cliente"
            @export="exportCommissions"
        />

        <div class="mb-8 grid gap-6 md:grid-cols-2 xl:grid-cols-4">
            <card :loading="loading">
                <p
                    class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
                >
                    Vendas
                    <span class="ml-auto">
                        {{ cards.count }}
                    </span>
                </p>
                <p
                    class="text-white-100 mt-auto flex text-lg font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(cards.amount) }}
                </p>
            </card>
            <card :loading="loading">
                <p
                    class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
                >
                    Chargeback
                    <span
                        class="ml-auto"
                        v-if="
                            cards.chargeback &&
                            cards.chargeback.hasOwnProperty('count')
                        "
                    >
                        {{ cards.chargeback.count }}/{{
                            cards.paid ? cards.paid.count : 0
                        }}
                        {{ chargebackPercentage }}%
                    </span>
                </p>
                <p
                    class="text-white-100 text-lg font-semibold dark:text-gray-200"
                >
                    {{
                        cards.chargeback
                            ? $root.formatCurrency(cards.chargeback.amount)
                            : 0
                    }}
                </p>
            </card>
            <card :loading="loading">
                <p
                    class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
                >
                    Bounces

                    <ExclamationCircleIcon
                        class="ml-1 h-4 w-4 text-gray-500 focus-visible:ring-0"
                        aria-hidden="true"
                        v-tooltip="
                            'E-mails de clientes que não foram entregues'
                        "
                    />
                </p>
                <p
                    class="text-white-100 text-lg font-semibold dark:text-gray-200"
                >
                    {{ cards.bounces.count }}
                </p>
            </card>
            <card :loading="loading">
                <div class="flex flex-col">
                    <div
                        v-for="(paymentMethod, index) in cards.payment_methods"
                        class="flex flex-col text-sm"
                    >
                        <div class="flex">
                            <span>
                                {{ paymentMethod.payment_method }}
                            </span>

                            <span class="ml-auto">
                                {{ $root.formatCurrency(paymentMethod.amount) }}
                            </span>
                        </div>
                        <div
                            v-if="cards.paid"
                            :class="{
                                'bg-blue-700': index === 0,
                                'bg-orange-700': index === 1,
                                'bg-red-700': index === 2,
                            }"
                            class="h-1 rounded-md"
                            :style="
                                'width: ' +
                                (paymentMethod.total / cards.paid.amount) *
                                    100 +
                                '%'
                            "
                        ></div>
                    </div>
                </div>
            </card>
        </div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <table-component
                    :loading="loading"
                    :headers="[
                        'Data',
                        'Produto',
                        'Cliente',
                        'Pagto.',
                        'Tipo',
                        'Comissão',
                        'Local.',
                        'Device',
                        'Ip',
                        'Visitor',
                        'Status',
                    ]"
                    :pagination="pagination"
                    @fetch="fetch"
                >
                    <template v-slot:tableHeader>
                        <div class="mb-3 flex">
                            <div class="ml-auto flex items-center">
                                <button
                                    class="btn-primary-sm ml-auto mr-2"
                                    :disabled="loading"
                                    @click.prevent="exportCommissions"
                                >
                                    <ArrowDownTrayIcon
                                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                    />
                                    Exportar
                                </button>

                                Exibindo {{ pagination.from }} a
                                {{ pagination.to }} registros de
                                {{ pagination.total }}
                            </div>
                        </div>
                    </template>
                    <tr v-for="commission in commissions">
                        <td class="table-cell-sm">
                            <router-link
                                v-if="commission.transaction"
                                :to="{
                                    name: 'user.order',
                                    params: {
                                        user_id: this.user.id,
                                        order_id: commission.order.id,
                                    },
                                }"
                                class="text-indigo-400 hover:text-indigo-700 hover:underline"
                            >
                                {{ commission.transaction.created_at }}
                            </router-link>
                        </td>
                        <td class="table-cell-sm">
                            <div class="flex space-x-1">
                                <commission-icons :commission="commission" />
                                <span
                                    v-tooltip="commission.product.name"
                                    class="inline-block rounded bg-purple-500 px-2 py-1 text-xs font-semibold uppercase uppercase text-white"
                                >
                                    {{
                                        commission.product.name
                                            .split(" ")
                                            .map((n) => n[0])
                                            .join("")
                                    }}
                                </span>
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <div class="flex items-center">
                                <span
                                    class="max-w-[90px] truncate"
                                    v-tooltip="
                                        commission.order.customer
                                            ? commission.order.customer['email']
                                            : '-'
                                    "
                                >
                                    {{
                                        commission.order.customer
                                            ? commission.order.customer["email"]
                                            : "-"
                                    }}
                                </span>
                                <ExclamationCircleIcon
                                    v-if="commission.order.bounce"
                                    v-tooltip="'Bounce'"
                                    class="ml-1.5 h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <div class="flex flex-wrap items-center">
                                <payment-method-icon
                                    v-if="
                                        commission.transaction
                                            .payment_method !== 'credit_card'
                                    "
                                    :hide-name="true"
                                    :method="
                                        commission.transaction.payment_method
                                    "
                                    :hasAmountCard2="
                                        commission.order.amount_card2
                                    "
                                ></payment-method-icon>

                                <template v-if="commission.transaction.card">
                                    <card-brand-icon
                                        :brand="
                                            commission.transaction.card.brand
                                        "
                                    />
                                    {{ commission.transaction.card.first_6 }}*{{
                                        commission.transaction.card.last_4
                                    }}
                                </template>
                                <commission-cards
                                    v-else-if="
                                        commission.transaction
                                            .payment_method === 'credit_card' &&
                                        commission.order.user.cards
                                    "
                                    :cards="commission.order.user.cards"
                                />
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <div class="flex space-x-1">
                                {{ formatCommissionType(commission.type) }}

                                <div v-if="commission.affiliate">
                                    <BuildingStorefrontIcon
                                        v-if="
                                            commission.affiliate.origin ===
                                            'showcase'
                                        "
                                        v-tooltip="'Vitrine'"
                                        class="ml-1 h-5 w-5"
                                    />
                                </div>

                                <CursorArrowRaysIcon
                                    v-if="
                                        commission.type == 'producer' &&
                                        commission.affiliates
                                    "
                                    v-tooltip="'Possui Afiliados'"
                                    class="h-5 w-5"
                                />
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            {{ $root.formatCurrency(commission.amount) }}
                        </td>
                        <td class="table-cell-sm">
                            <div
                                class="flex flex-col"
                                v-if="commission.order.location"
                            >
                                {{ commission.order.location.country }}
                                {{ commission.order.location.state }}
                                {{ commission.order.location.city }}
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <div
                                class="flex flex-col"
                                v-if="commission.order.device"
                            >
                                <div class="flex space-x-2">
                                    <browser-icon
                                        :browser="
                                            commission.order.device.browser.name
                                        "
                                    />
                                    <span>{{
                                        commission.order.device.browser.version
                                    }}</span>
                                </div>
                                <div class="flex space-x-2">
                                    <os-icon
                                        :os="
                                            commission.order.device.platform
                                                .name
                                        "
                                    />
                                    <span>{{
                                        commission.order.device.platform.version
                                    }}</span>
                                </div>
                            </div>
                            <div
                                class="flex flex-col"
                                v-else-if="commission.tracking"
                            >
                                <div
                                    class="flex space-x-2"
                                    v-if="commission.tracking.browser"
                                >
                                    <browser-icon
                                        :browser="commission.tracking.browser"
                                    />
                                    <span>{{
                                        commission.tracking.browser_version
                                    }}</span>
                                </div>
                                <div
                                    class="flex space-x-2"
                                    v-if="commission.tracking.platform"
                                >
                                    <os-icon
                                        :os="commission.tracking.platform"
                                    />
                                    <span>{{
                                        commission.tracking.platform_version
                                    }}</span>
                                </div>
                            </div>
                        </td>
                        <td class="table-cell-sm">
                            <span
                                class="block w-24 truncate"
                                v-if="commission.order.device"
                            >
                                {{ commission.order.device.ip }}
                            </span>
                            <span
                                v-if="commission.tracking"
                                v-tooltip="commission.tracking.ip"
                                class="block w-24 truncate"
                            >
                                {{ commission.tracking.ip }}
                            </span>
                        </td>
                        <td class="table-cell-sm">
                            {{ commission.order.visitor_id }}
                        </td>
                        <td class="table-cell-sm">
                            <status
                                v-if="commission.transaction"
                                :status="commission.transaction.status"
                                size="sm"
                            ></status>
                        </td>
                    </tr>
                </table-component>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "../../../components/layouts/Pagination.vue";
import Filters from "../../../components/layouts/Filters.vue";
import Card from "../../../components/layouts/Card.vue";
import PaymentType from "../../../components/layouts/PaymentType.vue";
import Status from "../../../components/layouts/Status.vue";
import EmptyState from "../../../components/layouts/EmptyState.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";
import {
    ArrowDownTrayIcon,
    ExclamationCircleIcon,
    QueueListIcon,
    ShoppingBagIcon,
    CursorArrowRaysIcon,
    BuildingStorefrontIcon,
} from "@heroicons/vue/24/outline";
import BrowserIcon from "../../../components/layouts/antifraud/BrowserIcon.vue";
import OsIcon from "../../../components/layouts/antifraud/OsIcon.vue";
import CardBrandIcon from "../../../components/layouts/CardBrandIcon.vue";
import CommissionIcons from "../../../components/user/commission/CommissionIcons.vue";
import CommissionType from "../../../mixins/CommissionType.vue";
import { useToast } from "vue-toastification";
import PaymentMethodIcon from "../../../components/layouts/PaymentMethodIcon.vue";
import CommissionCards from "../../../components/user/commission/CommissionCards.vue";
export default {
    components: {
        BuildingStorefrontIcon,
        CommissionCards,
        PaymentMethodIcon,
        CommissionIcons,
        ShoppingBagIcon,
        QueueListIcon,
        CursorArrowRaysIcon,
        CardBrandIcon,
        OsIcon,
        BrowserIcon,
        TableComponent,
        EmptyState,
        Pagination,
        Filters,
        Status,
        PaymentType,
        Card,
        SubmitButton,
        ArrowDownTrayIcon,
        ExclamationCircleIcon,
    },
    props: ["user"],
    mixins: [CommissionType],
    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            loading: true,
            next_page_url: false,
            cards: {
                amount: 0,
                count: 0,
                chargeback: {
                    total: 0,
                    count: 0,
                },
                paid: {
                    total: 0,
                    count: 0,
                },
                bounces: {
                    count: 0,
                },
                paymentMethods: [],
            },
            commissions: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },

    computed: {
        fingerprints() {
            if (!this.commissions.length) {
                return [];
            }

            return this.commissions
                .filter((commission) => {
                    return !commission.order.device;
                })
                .map((commission) => {
                    return commission.order.fingerprint;
                });
        },
        chargebackPercentage() {
            if (this.cards.chargeback.count === 0) {
                return 0;
            }

            if (
                !this.cards.paid &&
                this.cards.chargeback &&
                this.cards.chargeback.count > 0
            ) {
                return "100";
            }

            return (
                (this.cards.chargeback.count / this.cards.paid.count) *
                100
            ).toFixed(2);
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        exportCommissions() {
            this.loading = true;
            axios
                .get(
                    "/api/user/" +
                        this.user.id +
                        "/commission/export/anonymous",
                    {
                        params: this.filters,
                    },
                )
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success(
                        "Exportação iniciada com sucesso, você receberá o relatório por e-mail em alguns minutos.",
                    );
                });
        },
        fetchFingerprints() {
            if (this.fingerprints.length < 1) {
                return;
            }
            axios
                .get("/api/user/" + this.user.id + "/commission/fingerprints", {
                    params: {
                        fingerprints: this.fingerprints,
                    },
                })
                .then(({ data }) => {
                    this.fingerprints = data;

                    data.map((fingerprint) => {
                        this.commissions.map((commission) => {
                            if (
                                commission.order.fingerprint ===
                                fingerprint.fingerprint
                            ) {
                                commission.tracking = fingerprint;
                            }
                        });
                    });
                });
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/user/" + this.user.id + "/commission/cards", {
                    params: params,
                })
                .then(({ data }) => {
                    this.cards = data;
                });

            axios
                .get("/api/user/" + this.user.id + "/commission", {
                    params: params,
                })
                .then(({ data }) => {
                    this.commissions = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;

                    this.fetchFingerprints();

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
