<template>
    <modal :open="open" @close="$emit('close')" size="4xl">
        <div class="flex flex-col">
            <h3 class="dark:text-white">
                Usuários: <strong>{{ device.visitor_id }}</strong>
            </h3>
            <table-component
                :loading="loading"
                :headers="[
                    'Usuário',
                    'Ativo em:',
                    'Ip',
                    'Localização',
                    'Dispositivo',
                    'Navegador',
                ]"
                :pagination="devices"
            >
                <tr v-for="device in devices.data">
                    <td class="table-cell-sm">
                        <router-link
                            target="_blank"
                            :to="{
                                name: 'user.metrics',
                                params: { user_id: device.user.id },
                            }"
                            class="flex items-center text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-600"
                        >
                            {{ device.user.email }}
                            <ArrowTopRightOnSquareIcon
                                class="h-5 w-5"
                                aria-hidden="true"
                            />
                        </router-link>
                    </td>
                    <td class="table-cell-sm">
                        {{ device.last_activity }}
                    </td>
                    <td class="table-cell-sm">
                        {{ device.ip }}
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2" v-if="device.location">
                            {{ device.location?.country?.code }}
                            {{ device.location?.subdivisions }}
                            {{ device.location?.city?.name }}
                        </div>
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2">
                            <os-icon
                                :os="device.platform.name"
                                v-tooltip="device.platform.name"
                            />
                            <span>
                                {{ device.platform.version }}
                            </span>
                        </div>
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex space-x-2">
                            <browser-icon
                                :browser="device.browser.name"
                                v-tooltip="device.browser.name"
                            />
                            <span>{{ device.browser.version }}</span>
                        </div>
                    </td>
                </tr>
            </table-component>
        </div>
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import TableComponent from "../../layouts/table/TableComponent.vue";
import OsIcon from "../../layouts/antifraud/OsIcon.vue";
import BrowserIcon from "../../layouts/antifraud/BrowserIcon.vue";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
export default {
    props: {
        open: { type: Boolean, default: () => false },
        device: { type: Object, default: () => {} },
    },
    emits: ["close"],
    components: {
        ArrowTopRightOnSquareIcon,
        BrowserIcon,
        OsIcon,
        TableComponent,
        Modal,
    },

    data() {
        return {
            loading: false,
            devices: [],
        };
    },

    watch: {
        open() {
            if (this.open) {
                this.fetch();
            }
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/visitor/" + this.device.visitor_id + "/devices")
                .then(({ data }) => {
                    this.devices = data;
                    this.loading = false;
                });
        },
    },
};
</script>
