<template>
    <!-- Profile dropdown -->
    <Menu as="div" class="relative z-20 ml-3">
        <div>
            <MenuButton class="focus:outline-none">
                <span class="sr-only">Open user menu</span>
                <Bars3Icon class="h-6 w-6 text-white" />
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <MenuItem v-slot="{ active, close }">
                    <a
                        @click.prevent="
                            close();
                            $emit('refund');
                        "
                        class="block px-4 py-2 text-sm hover:cursor-pointer"
                    >
                        Reembolsar Transações
                    </a>
                </MenuItem>
                <MenuItem v-slot="{ active, close }">
                    <a
                        @click.prevent="
                            close();
                            notifyBlocked();
                        "
                        class="block px-4 py-2 text-sm hover:cursor-pointer"
                    >
                        AccountBlocked
                    </a>
                </MenuItem>
                <MenuItem v-slot="{ active, close }">
                    <a
                        @click.prevent="
                            close();
                            refundNeeded();
                        "
                        class="block px-4 py-2 text-sm hover:cursor-pointer"
                    >
                        RefundNeeded
                    </a>
                </MenuItem>
                <MenuItem v-slot="{ active, close }">
                    <a
                        @click.prevent="
                            close();
                            $emit('exportPdf');
                        "
                        class="block px-4 py-2 text-sm hover:cursor-pointer"
                    >
                        Exportar PDF
                    </a>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { Bars3Icon } from "@heroicons/vue/20/solid";

export default {
    props: ["audit"],
    components: {
        Bars3Icon,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    },

    emits: ["refund", "exportPdf", "fetch"],

    methods: {
        refundNeeded() {
            axios
                .post("/api/audit/" + this.audit.id + "/notification/refund")
                .then(({ data }) => {
                    console.log(data);
                    this.$emit("fetch");
                });
        },
        notifyBlocked() {
            axios
                .post("/api/audit/" + this.audit.id + "/notification/blocked")
                .then(({ data }) => {
                    console.log(data);
                    this.$emit("fetch");
                });
        },
    },
};
</script>
