<template>
    <back-button :route="{ name: 'finnancial.chargebacks.users'}"/>
    <filters
        v-model="filters"
        @fetch="chargebacks = [];fetch();"
        :loading="loading"
        search-filter
        status-filter
        :statuses="[
            { label: 'Solicitado', value: 'requested' },
            { label: 'Pendente', value: 'pending' },
            { label: 'Aprovada', value: 'approved' },
            { label: 'Rejeitada', value: 'rejected' },
        ]"
        search-label="Ex.: TPCMA..."
    />

    <div class="mb-3 flex">
        <div class="ml-auto flex items-center text-white">
            <button
                class="btn-primary-sm ml-auto mr-2"
                :disabled="loading"
                @click.prevent="exportChargebacks"
            >
                <ArrowDownTrayIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                    aria-hidden="true"
                />
                Exportar
            </button>

            Exibindo {{ pagination.from }} a {{ pagination.to }} registros
            de {{ pagination.total }}
        </div>
    </div>

    <table-component
        :loading="loading"
        :headers="[
            '#',
            'Data',
            'Transação',
            'Id Externo',
            'Status'
        ]"
        :pagination="pagination"
        @fetch="fetch"
    >
        <tr v-for="chargeback in chargebacks">
            <td class="table-cell-sm">
                <router-link
                    :to="{
                        name: 'finnancial.chargebacks.dispute',
                        params: {
                            dispute_id: chargeback.id
                        }
                    }"
                    class="hover:underline text-indigo-500"
                >
                    {{chargeback.id}}
                </router-link>
            </td>
            <td class="table-cell-sm">
                {{chargeback.created_at}}
            </td>
            <td class="table-cell-sm">
                {{chargeback.transaction.hash}}
            </td>
            <td class="table-cell-sm">
                <span
                    v-if="chargeback.transaction"
                    class="block truncate max-w-[200px]"
                    v-tooltip="chargeback.transaction.external_id ? chargeback.transaction.external_id.replace('CHAR_', '') : ''"
                >
                    {{ chargeback.transaction.external_id ? chargeback.transaction.external_id.replace('CHAR_', '') : '' }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    class="text-sm font-medium mr-2 px-2.5 py-1.5 rounded"
                    :class="{
                        'bg-green-500 text-white': chargeback.status === 'approved',
                        'bg-red-500 text-white': chargeback.status === 'rejected',
                        'bg-yellow-500 text-white': chargeback.status === 'requested',
                        'bg-orange-500 text-white': chargeback.status === 'pending',
                    }"
                >
                    {{ chargebackStatusName(chargeback.status) }}
                </span>
            </td>
        </tr>
    </table-component>
</template>

<script>
import BackButton from "../../../../components/layouts/navigation/BackButton.vue";
import PaginationObject from "../../../../mixins/PaginationObject.vue";
import TableComponent from "../../../../components/layouts/table/TableComponent.vue";
import Filters from "../../../../components/layouts/Filters.vue";
import ChargebackStatusName from "../../../../mixins/ChargebackStatusName.vue";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";

export default {
    components: {ArrowDownTrayIcon, Filters, TableComponent, BackButton},
    mixins: [PaginationObject, ChargebackStatusName],

    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            chargebacks: [],
            filters: {
                text: '',
                status: [],
            },
            loading: false
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        exportChargebacks() {
            this.loading = true;
            const params = this.filters;

            axios
                .get("/api/chargebacks/export/csv", {
                    params: {
                        ...params,
                    },
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success(
                        "Exportação iniciada com sucesso, você receberá o relatório por e-mail em alguns minutos.",
                    );
                });
        },
        fetch() {
            this.loading = true;

            axios.get('/api/chargebacks/', {
                params: {
                    ...this.filters,
                    page: this.pagination.current_page
                }

            }).then(({data}) => {
                this.chargebacks = data.data;
                this.setPagination(data);
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            });
        },

    }
}
</script>
