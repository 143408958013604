<template>
    <card :loading="loading">
        <div class="flex items-center dark:text-white">
            <div
                class="-left-1.5 mr-1 h-3 w-3 rounded-full border border-white dark:border-gray-900"
                :class="{
                    'bg-green-500': ['authorized', 'completed'].includes(
                        transfer.status,
                    ),
                    'bg-red-500': ['rejected', 'rejected_on_file'].includes(
                        transfer.status,
                    ),
                    'bg-yellow-500': [
                        'pending_authorization',
                        'pending_execution',
                        'pending_change',
                    ].includes(transfer.status),
                    'bg-gray-500': !transfer,
                }"
            ></div>
            <span v-if="transfer">
                <span class="mr-1">{{ transfer.created_at }}</span
                >Pix {{ statusName }} em {{ transfer.provider }}
            </span>
            <span v-else class="item-center flex flex-grow justify-between">
                <span> Transferência não encontrada </span>
                <button
                    @click.prevent="submit"
                    class="ml-auto flex text-indigo-400 hover:cursor-pointer hover:text-indigo-500 hover:underline"
                >
                    <spinner class="mr-2" v-if="loading" />
                    Enviar
                </button>
            </span>
        </div>
    </card>
</template>

<script>
import Card from "../layouts/Card.vue";
import Spinner from "../forms/Spinner.vue";

export default {
    components: { Spinner, Card },
    props: ["cashout"],

    data() {
        return {
            loading: false,
            transfer: false,
        };
    },

    computed: {
        statusName() {
            if (!this.transfer) {
                return "";
            }

            switch (this.transfer.status) {
                case "pending_authorization":
                    return "Autorização Pendente";
                case "pending_execution":
                    return "Execução Pendente";
                case "authorized":
                    return "Autorizado";
                case "completed":
                    return "Transferido";
                case "rejected":
                    return "Rejeitado";
                case "pending_change":
                    return "Mudança Pancelado";
                case "rejected_on_file":
                    return "Rejeitado";
            }
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/cashout/" + this.cashout.id + "/transfer")
                .then(({ data }) => {
                    this.transfer = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            axios
                .post("/api/cashout/" + this.cashout.id + "/transfer")
                .then(({ data }) => {
                    this.transfer = data;
                    this.fetch();
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
