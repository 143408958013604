<template>
    <div class="flex flex-col dark:text-white">
        <div
            class="min-w-0 w-full h-50 rounded-lg overflow-hidden bg-white dark:bg-gray-800 mb-4 drop-shadow-sm p-5"
        >
            <div class="grid grid-cols-3 gap-2 mb-4">
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >CLIENTE</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-if="order"
                        >{{ order.customer.name }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >EMAIL</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-if="order"
                        >{{ order.customer.email }}</span
                    >
                </div>
                <div
                    class="flex flex-col"
                    v-if="order.customer && order.customer.cpf"
                >
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >CPF</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        order.customer.cpf
                    }}</span>
                </div>
                <div
                    class="flex flex-col"
                    v-if="order.customer && order.customer.cnpj"
                >
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >CNPJ</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        order.customer.cnpj
                    }}</span>
                </div>
                <div
                    class="flex flex-col"
                    v-if="order.customer && order.customer.phones"
                >
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >TELEFONES</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        v-for="phone in order.customer.phones"
                    >
                        {{ "+" + phone.ddi || "" }} ({{ phone.ddd }})
                        {{ formatPhone(phone.number) }}</span
                    >
                </div>
            </div>

            <div
                class="grid grid-cols-7 grid-flow-row gap-3 mb-4"
                v-if="order"
                v-for="address in order.addresses"
            >
                <div class="flex flex-col col-span-7 mt-4">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >{{ addressTitle(address.is_default) }}</span
                    >
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >CEP</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.zip_code
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Rua</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.street
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Número</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.street_number
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Complemento</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.complement
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Referencia</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.reference
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Bairro</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.neighborhood
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Cidade</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.city
                    }}</span>
                </div>
                <div class="flex flex-col col-span-2">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Estado</span
                    >
                    <span class="text-gray-400 dark:text-gray-200">{{
                        address.state
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../mixins/HelperMixin.vue";

export default {
    props: ["order"],
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            commissions: [],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },
    methods: {
        addressTitle(isDefault) {
            if (isDefault) {
                return "Endereço Principal";
            }
            return "Endereço Secundário";
        },
    },
};
</script>
