import "./bootstrap";
import moment from "moment";
window.moment = moment;

import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";
const pinia = createPinia();

import router from "./router";
import App from "./App.vue";

import LitepieDatepicker from "litepie-datepicker-tw3";

import "../css/app.css";

import axios from "axios";
import { Model } from "vue-api-query";

// inject global axios instance as http client to Model
Model.$http = axios;
import Notifications from "notiwind";
import VueClipboard from "vue3-clipboard";
import Toast from "vue-toastification";
import FloatingVue from "floating-vue";
import "vue-toastification/dist/index.css";
import "floating-vue/dist/style.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faGlobe,
    faStar,
    faDollarSign,
    faMoneyBillTransfer,
    faSignIn,
    faBook,
    faBox,
    faPersonChalkboard,
    faCode,
    faSackDollar,
    faCartShopping,
    faVideo,
    faCreditCard,
    faMoneyBillAlt,
    faBarcode,
    faCirclePlus,
    faChevronDown,
    faTrash,
    faBan,
    faCheck,
    faMoneyCheckDollar,
    faGear,
    faFileExcel,
    faCircleInfo,
    faTimes,
    faEnvelope,
    faMoneyBills,
    faPencilAlt,
    faPencil,
    faCreditCardAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    faPix,
    faChrome,
    faSafari,
    faOpera,
    faFirefox,
    faApple,
    faWindows,
    faAndroid,
    faEdge,
    faInstagram,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faGlobe,
    faStar,
    faDollarSign,
    faMoneyBillTransfer,
    faCreditCardAlt,
    faInstagram,
    faSignIn,
    faBox,
    faPersonChalkboard,
    faBook,
    faCode,
    faSackDollar,
    faCartShopping,
    faVideo,
    faCreditCard,
    faPix,
    faMoneyBillAlt,
    faBarcode,
    faCirclePlus,
    faChevronDown,
    faTrash,
    faBan,
    faCheck,
    faBarcode,
    faMoneyCheckDollar,
    faGear,
    faCircleInfo,
    faFileExcel,
    faTimes,
    faEnvelope,
    faMoneyBills,
    faPencilAlt,
    faPencil,
    faCartShopping,
    faChrome,
    faSafari,
    faOpera,
    faFirefox,
    faApple,
    faWindows,
    faAndroid,
    faEdge,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
);
import HighchartsVue from "highcharts-vue";

const app = createApp(App)
    .use(HighchartsVue)
    .use(LitepieDatepicker)

    .use(Notifications)
    .use(pinia)
    .use(Toast)
    .use(FloatingVue)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(VueReCaptcha, { siteKey: "6LeCmXYlAAAAAPRP_--XeSnXTRlatxnrcYeawIE7" });

app.use(router).mount("#app");
