<template>
    <div
        @click.prevent="approve"
        class="p-3 hover:cursor-pointer hover:bg-slate-900 rounded-lg flex content-center justify-center"
    >
        <SvgLoader v-if="loadingApprove"/>
        <CheckCircleIcon class="mr-1 flex-shrink-0 self-center h-5 w-5" />
        Aprovar
    </div>
    <div
        @click.prevent="reprove"
        class="p-3 hover:cursor-pointer hover:bg-slate-900 rounded-lg flex content-center justify-center"
    >
        <SvgLoader v-if="loadingReprove"/>
        <XCircleIcon class="mr-1 flex-shrink-0 self-center h-5 w-5" />
        Reprovar
    </div>

    <modal
        class="z-60"
        size="sm"
        :open="expand"
        @close="expand = false"
    >
        <form class="flex flex-col flex-wrap w-full">
            <h3 class="text-lg dark:text-gray-200">{{ choice === 'approved' ? 'Aprovar' : 'Reprovar'}} Produtos</h3>
            <p class="text-md font-bold text-indigo-500 flex items-center my-2">
                {{ products.length }} Produtos selecionados
            </p>
            <h4 class="text-md text-orange-400 flex items-center mb-2" v-if="choice === 'reproved'">
                Surte efeito apenas em Produtos não aprovado.
            </h4>
            <label v-if="choice === 'reproved'" for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200 mt-2">Motivo</label>
            <textarea
                v-if="choice === 'reproved'"
                v-model="description"
                id="message"
                rows="4"
                class="block mb-4 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
                required
            ></textarea>
            <submit-button
                @click.prevent="submit"
                :loading="loading"
            >
                {{ choice === 'approved' ? 'Aprovar' : 'Reprovar'}}
            </submit-button>
        </form>
    </modal>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import Modal from "../layouts/Modal.vue";
import SubmitButton from "../forms/SubmitButton.vue";

export default {
    props: ['products'],
    components: {SubmitButton, Modal, CheckCircleIcon, XCircleIcon, SvgLoader},
    emits: ['fetch'],
    data() {
        return {
            loadingApprove: false,
            loadingReprove: false,
            choice: 'approved',
            expand: false,
            loading: false,
            description: ''
        }
    },

    methods: {
        approve() {
            this.choice = 'approved';
            this.expand = true;
        },
        reprove() {
            this.choice = 'reproved';
            this.expand = true;
        },
        submit() {
            this.loading = true;
            axios.post('/api/product/update-many', {
                product_ids: this.products,
                choice: this.choice,
                description: this.description
            }).then(() => {
                this.$emit('fetch');
            }).finally(() => {
                this.loading = false;
                this.expand = false;
            })
        }
    }
}
</script>
