<template>
    <modal :open="open" @close="$emit('close')" size="4xl">
        <h3 class="mb-3 flex border-b border-gray-500 pb-2 text-lg font-bold leading-6 text-white capitalize">
            {{ gatewayName }}
        </h3>
        <form @submit.prevent="submit" class="mb-3 grid grid-cols-3 gap-1">
            <p class="mb-1 col-span-3 text-sm text-gray-300">
                Personalize o valor do markup (em %) para cada moeda ou deixe vazio para utilizar o valor padrão do sistema (30%).
            </p>
            <div 
                v-for="currency in currencies"
                :key="currency.id"
            >
                <card :loading="loading" size="sm">
                    <p class="mb-1 text-xs font-medium text-gray-400">
                        {{ currency.name }}
                    </p>
                    <input
                        v-model="currency.markup"
                        type="number"
                        max="100"
                        min="0"
                        step="0.01"
                        name="markup"
                        id="markup"
                        class="text-input"
                    />
                </card>
            </div>
            <div class="mt-3 col-span-3 sm:flex sm:flex-row-reverse">
                <submit-button :loading="loading">Salvar</submit-button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../layouts/Modal.vue";
import { Store } from "../../stores/store.js";
import { mapWritableState } from "pinia";
import Card from "../layouts/Card.vue";
import SubmitButton from "../forms/SubmitButton.vue";
import { useToast } from "vue-toastification";

export default {
    components: {
        SubmitButton,
        Card,
        Modal,
    },
    props: {
        open: { type: Boolean, default: () => false },
        gatewayId: { required: true },
        gatewayName: { required: true }
    },
    emits: ["close", "fetch"],

    data() {
        return {
            loading: false,
            currencies: [],
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    watch: {
        open() {
            if (this.open) {
                this.fetchGateway();
            }
        },
    },

    computed: {
        ...mapWritableState(Store, ["authUser"]),
    },

    methods: {
        submit() {
            this.loading = true;
            let modifiedCurrencies = this.currencies.filter(
                (currency) => currency.markup !== null && currency.markup !== ""
            )
            console.log(modifiedCurrencies);
            axios
                .put("/api/currency-gateway/" + this.gatewayId, modifiedCurrencies)
                .then(({ data }) => {
                    this.$emit("close");
                    this.toast.success('Alterações salvas com sucesso!');
                })
                .catch(error => {
                    this.toast.error('Erro ao salvar as alterações.');
                })
                .finally(() => (this.loading = false));
        },
        fetchGateway() {
            this.loading = true;
            this.currencies = [];
            axios
                .get("/api/currency-gateway/" + this.gatewayId)
                .then(({ data }) => {
                    data.forEach((currency) => {
                        this.currencies.push({
                            id: currency.id,
                            name: currency.name,
                            markup: currency.gateways[0]?.pivot?.markup ?? null
                        });
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
</script>
