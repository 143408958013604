<template>
    <card title="Browsers">
        <div class="w-full">
            <p
                class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
            >
                {{ name }}
            </p>
        </div>
        <div class="flex flex-col">
            <div v-if="loading" class="space-y-4">
                <div
                    v-for="(n, index) in 10"
                    :key="index"
                    class="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"
                >
                    <div class="flex animate-pulse">
                        <div
                            class="h-2.5 bg-blue-700 first:rounded-l-lg last:rounded-r-lg"
                            :style="'width: ' + (10 - index) * 0.9 * 10 + '%'"
                        ></div>
                    </div>
                </div>
            </div>
            <div v-for="bucket in buckets" v-if="!loading">
                <template v-if="bucket.label !== 'Outros'">
                    <div class="flex justify-between">
                        <span class="truncate">{{ bucket.label }}</span>
                        <span>{{ bucket.count }}</span>
                    </div>
                    <div
                        class="mb-3 h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"
                    >
                        <div class="flex">
                            <div
                                class="h-2.5 bg-blue-700 first:rounded-l-lg last:rounded-r-lg"
                                :style="
                                    'width: ' +
                                    (bucket.count / totalCount) * 100 +
                                    '%'
                                "
                            ></div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="!loading">
                <div class="flex justify-between">
                    <span>Outros</span>
                    <span>{{ otherDocCount }}</span>
                </div>
            </div>
        </div>
    </card>
</template>

<script>
import Card from "../layouts/Card.vue";

export default {
    components: { Card },
    props: {
        buckets: { type: Object, required: true },
        name: { type: String, required: true },
        loading: { type: Boolean, required: true },
    },
    data() {
        return {};
    },

    computed: {
        otherDocCount() {
            if (!this.buckets) {
                return 0;
            }
            let other = this.buckets.filter(
                (bucket) => bucket.label === "Outros",
            );
            return other[0]?.count;
        },
        totalCount() {
            if (!this.buckets) {
                return 0;
            }
            return this.buckets.reduce((acc, cur) => acc + cur.count, 0);
        },
    },
};
</script>
