<template>
    <div class="mt-4 space-y-1">
        <empty-state v-if="!loading && gateways.length < 1" />
        <div
            v-for="gateway in gateways"
            :key="gateway.id"
            class="flex items-center justify-between rounded-lg bg-white px-3 py-4 shadow dark:bg-slate-800"
        >
            <currency-gateway-modal
                :gatewayId="gateway.id"
                :gatewayName="gateway.name"
                :open="openedGateway === gateway.id"
                @close="openedGateway = null"
                @fetch="
                    openedGateway = null;
                    fetch();
                "
            />
            <div class="flex items-center">
                <div class="ml-2 flex flex-col">
                    <a
                        href="#"
                        @click.prevent="openedGateway = gateway.id"
                        class="font-medium capitalize text-indigo-600 hover:text-indigo-800"
                    >
                        {{ gateway.name }}
                    </a>
                    <p class="mt-1 mb-2 text-sm text-gray-600 dark:text-gray-300">
                        Prioridade: {{ gateway.priority }}
                    </p>
                    <div>
                        <is-active :status="gateway.is_active"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CurrencyGatewayModal from "../../components/currencyGateways/CurrencyGatewayModal.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import IsActive from "../../components/layouts/IsActive.vue";
export default {
    components: {
        IsActive,
        EmptyState,
        CurrencyGatewayModal,
    },
    data() {
        return {
            loading: false,
            gateways: [],
            openedGateway: null,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/currency-gateway")
                .then(({ data }) => {
                    this.gateways = [...this.gateways, ...data.data];
                    this.loading = false;
                });
        },
        statusName(name) {
            switch (name) {
                case 1:
                    return "Ativado";
                case 0:
                    return "Desativado";
            }
        },
    },
};
</script>
