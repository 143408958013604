<template>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Limite',
            'Saldo Pendente',
            'Saldo Antecipado',
            'Disponível para Antecipação',
            'Ações',
        ]"
        @fetch="fetch"
    >
        <tr v-for="history in histories">
            <td class="table-cell">
                {{history.created_at}}
            </td>
            <td class="table-cell">
                {{history.limit?? 0}}%
            </td>
            <td class="table-cell">
                {{ formatCurrency(history.pending_amount) }}
            </td>
            <td class="table-cell">
                {{ formatCurrency(history.anticipated_amount) }}
            </td>
            <td class="table-cell">
                {{ formatCurrency(history.anticipatable_payables_amount) }}
            </td>
            <td class="table-cell">
                <anticipation-history-modal :history="history"></anticipation-history-modal>
            </td>
        </tr>
    </table-component>
</template>

<script>
import Filters from "../../../components/layouts/Filters.vue";
import LogModal from "./log/LogModal.vue";
import AnticipationHistoryModal from "./AnticipationHistoryModal.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";

export default {
    components: {TableComponent, Filters, LogModal, AnticipationHistoryModal},
    props: ['user'],
    data() {
        return {
            loading: false,
            next_page_url: false,
            histories: [],
            selected_history: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            const params = {};
            params['page'] = this.pagination.current_page;

            axios.get('/api/user/' + this.user.id + '/anticipation/history', {
                params: params
            }).then(({data}) => {
                this.histories = data.data;

                this.pagination = {
                    ...this.pagination,
                    ...data
                };

                this.next_page_url = data.next_page_url;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                // this.$toast.error(error.response.data.message);
            });
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        }
    }
}
</script>
