<template>
    <div v-if="loading" class="flex space-x-1">
        <div
            v-for="n in 3"
            class="animate-pulse rounded py-1 px-2 bg-gray-300"
        >
            <div class="h-2 w-12 bg-gray-500 rounded animate-pulse"></div>
        </div>
    </div>
    <span
        v-else
        v-for="tag in tags"
        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white uppercase last:mr-0 mr-1"
        :class="{
            'bg-red-700': tag.type === 'danger',
            'bg-yellow-600': tag.type === 'warning',
        }"
    >
      {{ tag.name }}
    </span>
</template>

<script>
    import {DialogOverlay} from "@headlessui/vue";

    export default {
        components: {DialogOverlay},
        props: {cashout: {required: true}},

        data() {
            return {
                loading: true,
                tags: []
            }
        },

        mounted() {
            if (this.cashout) {
                this.fetch();
            }
        },

        watch: {
            cashout() {
                if (this.cashout) {
                    this.fetch();
                }
            }
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/cashout/' + this.cashout.id + '/tags').then(({data}) => {
                    this.tags = data;
                    this.loading = false;
                })
            }
        }
    }
</script>
