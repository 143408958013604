<template>
    <div class="mt-5">
        <div class="grid grid-cols-9 gap-2">
            <div>
                <navigation
                    :navigation="[
                        {
                            name: 'Clicks',
                            route: { name: 'user.trackings' },
                            icon: UsersIcon,
                        },
                        {
                            name: 'Produtos',
                            route: { name: 'user.audit.products' },
                            icon: CubeIcon,
                        },
                        {
                            name: 'Afiliados',
                            route: { name: 'user.audit.affiliates' },
                            icon: UsersIcon,
                        },
                        {
                            name: 'Vendas',
                            route: { name: 'user.audit.commissions' },
                            icon: BanknotesIcon,
                        },
                        {
                            name: 'Logins',
                            route: { name: 'user.audit.logins' },
                            icon: UserIcon,
                        },
                        {
                            name: 'Financeiro',
                            route: { name: 'user.audit.finnancial' },
                            icon: CurrencyDollarIcon,
                        },
                        {
                            name: 'Metricas',
                            route: { name: 'user.metrics' },
                            icon: ChartBarIcon,
                        },
                    ]"
                />
            </div>
            <div class="col-span-8">
                <ticto-loading v-if="loading" />
                <router-link
                    v-if="audit"
                    :to="{
                        name: 'audit.interactions',
                        params: { audit_id: audit.id },
                    }"
                    target="_blank"
                    class="mb-3 flex justify-end text-red-300 hover:cursor-pointer hover:underline"
                >
                    <ExclamationCircleIcon
                        class="h-6 w-6"
                        v-tooltip="'Usuário em Auditoria'"
                    />
                    Auditoria
                    {{ audit.status === "closed" ? "finalizada" : "iniciada" }}
                    {{ audit.created_at }}
                </router-link>
                <div v-if="!audit" class="mb-3 flex justify-end text-red-300">
                    <store-modal @fetch="fetch" :user-id="user.id">
                        Iniciar Auditoria
                    </store-modal>
                </div>
                <router-view v-if="!loading" :user="user"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import {
    UsersIcon,
    CubeIcon,
    BanknotesIcon,
    UserIcon,
    CurrencyDollarIcon,
    ExclamationCircleIcon,
    ChartBarIcon,
} from "@heroicons/vue/24/outline";
import UserClicks from "../../../components/user/audit/UserClicks.vue";
import Navigation from "../../../components/layouts/navigation/vertical/Navigation.vue";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import StoreModal from "../../../components/audit/modals/StoreModal.vue";
export default {
    components: {
        StoreModal,
        TictoLoading,
        Navigation,
        UserClicks,
        ExclamationCircleIcon,
    },
    props: ["user"],

    data() {
        return {
            audit: false,
            affiliates: [],
            products: [],
            table: false,
            loading: false,
            clicks: [],
            pagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            aggs: {
                browser: {},
                city: {},
                ip: {},
                device: {},
            },
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            const params = {};
            params["filter[user_id]"] = this.$route.params.user_id;

            axios
                .get("/api/audit", {
                    params,
                })
                .then(({ data }) => {
                    this.audit = data.data[0];
                });
        },
        UsersIcon,
        CubeIcon,
        BanknotesIcon,
        UserIcon,
        CurrencyDollarIcon,
        ChartBarIcon,
    },
};
</script>
