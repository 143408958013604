<template>
    <button
        @click.prevent="openModal()"
        :disabled="loading"
        type="button"
        :class="{ 'cursor-not-allowed': loading }"
        class="
            mt-3
            inline-flex
            justify-center
            items-center
            ml-2
            py-1
            px-2
            font-semibold
            leading-6
            text-sm
            shadow
            rounded-md
            text-white
            bg-indigo-500
            hover:bg-indigo-400
            transition
            ease-in-out
            duration-150
            mb-3
        "
    >
        Adicionar novo
    </button>

    <div
        class="
            overflow-hidden
            not-prose
            relative
            bg-slate-50
            rounded-xl
            dark:bg-slate-800/25
        "
    >
        <table
            v-if="achievements.length"
            class="border-collapse table-auto w-full text-sm"
        >
            <thead>
                <tr class="dark:text-white">
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        ID
                    </th>
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        Título
                    </th>
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        Descrição
                    </th>
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        Tipo
                    </th>
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        Imagem
                    </th>
                    <th
                        scope="col"
                        class="text-sm font-medium px-6 py-4 text-left"
                    >
                        Nível
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-for="achievement in achievements" class="">
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-6
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        <div class="flex">#{{ achievement.id }}</div>
                    </td>
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-8
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        {{ achievement.title }}
                    </td>
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-8
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        <span>
                            {{ achievement.description }}
                        </span>
                    </td>
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-8
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        {{ achievementTypes[achievement.type] }}
                    </td>
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-8
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        <img :src="achievement.image_url" :alt="achievement.title" width="100">
                    </td>
                    <td
                        class="
                            border-b border-slate-100
                            dark:border-slate-700
                            p-4
                            pl-8
                            text-slate-500
                            dark:text-slate-200
                        "
                    >
                        {{ achievement.level_label }}
                    </td>
                    <td
                        class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                    >
                        <div>
                            <button
                                @click.prevent="openModalToEdit(achievement.id)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                            >
                                Editar
                            </button>
                            <button
                                @click.prevent="
                                    handleDeleteAchievement(achievement.id)
                                "
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-500 hover:bg-red-400 transition ease-in-out duration-150"
                            >
                                Excluir
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <create-achievement-modal
        v-if="isModalOpen"
        @fetchAchievements="fetch"
        @closeModal="closeModal"
        :achievement-id="this.achievementId"
    />
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import VueSelect from "../../../components/forms/VueSelect.vue";
import CreateAchievementModal from "../modals/CreateAchievementModal.vue";
import { useToast } from "vue-toastification";

export default {
    components: {
        VueSelect,
        TransitionRoot,
        FormToggle,
        CreateAchievementModal,
    },

    setup() {        
        const achievementTypes = {
            plate: "Placas"
        }
        const toast = useToast();
        return { toast, achievementTypes };
    },

    data() {
        return {
            isModalOpen: false,
            loading: true,
            achievements: [],
            achievementId: null,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        closeModal() {
            this.isModalOpen = false;
            this.achievementId = null;
        },
        openModal() {
            this.isModalOpen = true;
        },
        openModalToEdit(id) {
            this.isModalOpen = true;
            this.achievementId = id;
        },
        fetch() {
            this.loading = true;

            axios
                .get("/api/achievements")
                .then(({ data }) => {
                    this.achievements = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        handleDeleteAchievement(id) {
            if (confirm("Tem certeza que deseja excluir esta conquista?")) {
                axios
                    .delete(`/api/achievements/${id}`)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success("Conquista excluída com sucesso");
                            this.fetch();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        }
                    );
            }
        },
    },
};
</script>
