<template>
    <router-link
        :to="route"
        class="text-indigo-400 flex hover:underline my-2"
    >
        <svg
            class="w-3 mr-1"
            viewBox="0 0 355 512"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <title>left</title>
            <path
                d="M257 64L291 98 128 262 291 426 257 460 61 262 257 64Z"
            ></path>
        </svg>
        Voltar
    </router-link>
</template>

<script>
    export default {
        props: {route: {type: Object, required: true}}
    }
</script>
