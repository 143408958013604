<template>
    <div class="prose md-render bg-white text-black dark:bg-gray-900 dark:text-gray-100 p-4" v-html="markdown.render(source)" />
</template>

<script setup>
import MarkdownIt from "markdown-it";
import MarkdownItAbbr from "markdown-it-abbr";
import MarkdownItAnchor from "markdown-it-anchor";
import MarkdownItFootnote from "markdown-it-footnote";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import MarkdownItSub from "markdown-it-sub";
import MarkdownItSup from "markdown-it-sup";
import MarkdownItTasklists from "markdown-it-task-lists";
import MarkdownItTOC from "markdown-it-toc-done-right";

const markdown = new MarkdownIt()
    .use(MarkdownItAbbr)
    .use(MarkdownItAnchor)
    .use(MarkdownItFootnote)
    .use(MarkdownItHighlightjs)
    .use(MarkdownItSub)
    .use(MarkdownItSup)
    .use(MarkdownItTasklists)
    .use(MarkdownItTOC);

defineProps({
    source: {
        type: String,
        default: "",
    },
});
</script>

<style lang="scss">
.md-render {
    // Default styles
    color: var(--tw-prose-body);

    h1, h2, h3, h4, h5, h6 {
        color: var(--tw-prose-headings);
    }

    // Dark mode styles
    @media (prefers-color-scheme: dark) {
        color: var(--tw-prose-invert-body);

        h1 {
            margin-bottom: 0 !important;
        }

        h2 {
            margin-top: 10px !important;
            margin-bottom: 10px !important;
        }

        h1, h2, h3, h4, h5, h6 {
            color: var(--tw-prose-invert-headings);
        }

        strong {
            color: var(--tw-prose-invert-bold);
        }
    }
}
</style>
