<template>
    <transition-root as="template" :show="true">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-white dark:bg-gray-700 rounded-lg px-4 pt-3 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    >
                        <form @submit.prevent="updateProduct()">
                            <div class="sm:items-start">
                                <div class="text-center sm:text-left">
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium dark:text-white border-b border-gray-600 mb-4 pb-2"
                                    >
                                        Editar Produto
                                    </dialog-title>
                                    <div>
                                        <div class="mt-2">
                                            <label
                                                for="large-input"
                                                class="block mb-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                                                >Nome</label
                                            >
                                            <input
                                                type="text"
                                                required
                                                v-model="product.name"
                                                class="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        </div>
                                        <div class="mt-2">
                                            <label
                                                for="large-input"
                                                class="block mb-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                                                >Página de Vendas</label
                                            >
                                            <input
                                                type="url"
                                                v-model="product.metadata.links.sales_page_url"
                                                class="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        </div>
                                        <div class="mt-2">
                                            <label
                                                for="large-input"
                                                class="block mb-2 text-xs font-medium text-gray-900 dark:text-gray-300"
                                                >Descrição</label
                                            >
                                            <textarea
                                                type="text"
                                                required
                                                v-model="product.description"
                                                rows="5"
                                                class="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                            >
                                <button
                                    type="submit"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Atualizar
                                </button>

                                <button
                                    type="button"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
Dialog,
DialogOverlay,
DialogTitle,
TransitionChild,
TransitionRoot,
} from "@headlessui/vue";
import { useToast } from "vue-toastification";
import FormToggle from "../../../components/forms/FormToggle.vue";

export default {
    props: ['product'],
    components: {
        FormToggle,
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        updateProduct() {
            axios
                .put('/api/product/' + this.product.id + '/update', this.product)
                .then(({ data }) => {
                    if (data) {
                        this.toast.success(
                            "Produto atualizado com sucesso!"
                        );
                    }
                    this.$emit("closeModal");
                })
                .catch(({ response }) => {
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {
                        this.toast.error(response?.data.errors[0]);
                    } else if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
                        this.toast.error(response?.data.message);
                    } else {
                        this.toast.error("Um erro inesperado ocorreu, as alterações não foram salvas.");
                    }
                    this.$emit("closeModalAndFetch");
                });
        },
    },
};
</script>
