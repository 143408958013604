<template>
    <line-chart
        :series="series"
        :dates="dates"
        :loading="selfLoading"
        class="mt-4"
    />
</template>

<script>
    import LineChart from "../charts/LineChart.vue";

    export default {
        components: {LineChart},
        props: ['dates', 'loading', 'products'],

        data() {
            return {
                selfLoading: true,
                series: []
            }
        },

        watch: {
            products() {
                this.fetch();
            }
        },

        methods: {
            fetch() {
                this.series = [];
                this.selfLoading = true;
                const draft = {
                    name: 'Rascunho',
                    lineWidth: 3,
                    data: []
                };
                const pending = {
                    name: 'Pendentes',
                    lineWidth: 3,
                    data: []
                };
                const approved = {
                    name: 'Aprovados',
                    lineWidth: 3,
                    data: []
                }
                const reproved = {
                    name: 'Reprovados',
                    lineWidth: 3,
                    data: []
                }
                const pendingCorrection = {
                    name: 'Pendentes de correção',
                    lineWidth: 3,
                    data: []
                }

                Object.entries(this.products).forEach(([key, value]) => {
                    draft.data.push([value.unix_date, value.draft]);
                    pending.data.push([value.unix_date, value.pending]);
                    approved.data.push([value.unix_date, value.approved]);
                    reproved.data.push([value.unix_date, value.reproved]);
                    pendingCorrection.data.push([value.unix_date, value['pending-correction']]);
                });
                const series = [];
                series.push(pending, approved, reproved, pendingCorrection);
                this.series = series;

                this.selfLoading = false;
            }
        }
    }
</script>
