<template>
  <div class="flex flex-col dark:text-white">
    <h1 class="text-lg font-bold text-gray-900 dark:text-white mb-4">Afiliados do Usuário</h1>

    <div class="mb-8 grid gap-6 md:grid-cols-1 xl:grid-cols-1">
      <card :loading="loading">
        <p class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400">
          Total de Afiliados
          <span class="ml-auto">
            {{ pagination.total ?? 0 }}
          </span>
        </p>
      </card>
    </div>

    <filters
      v-model="filters"
      @fetch="fetch"
      :loading="loading"
      search-filter
      search-label="Filtre por nome ou e-mail do afiliado"
    >
      <select
        v-model="sortBy"
        @change="fetch"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option value="" disabled>Ordenar por</option>
        <option v-for="option in sortOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </filters>

    <table-component
      :loading="loading"
      :headers="[
        'Usuário',
        'Email',
        'Status',
        'Data de cadastro',
        'Total de Comissões',
        'Score',
        'Ações'
      ]"
      :pagination="pagination"
      @fetch="fetch"
    >
      <tr v-for="affiliate in affiliates" :key="affiliate.id">
        <td class="table-cell-sm">{{ affiliate.name }}</td>
        <td class="table-cell-sm">{{ affiliate.email }}</td>
        <td class="table-cell-sm">
          <affiliate-status :status="affiliate.status" />
        </td>
        <td class="table-cell-sm">{{ formatDate(affiliate.created_at) }}</td>
        <td class="table-cell-sm">{{ $root.formatCurrency(affiliate.total_commission) }}</td>
        <td class="table-cell-sm">{{ affiliate.score }}</td>
        <td class="table-cell-sm">
          <router-link
            :to="{ name: 'user.commissions', params: { user_id: affiliate.user_id } }"
            class="text-indigo-400 hover:text-indigo-700 hover:underline"
          >
            Ver Perfil
          </router-link>
        </td>
      </tr>
    </table-component>
  </div>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import AffiliateStatus from "../../../components/layouts/AffiliateStatus.vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";
import Filters from "../../../components/layouts/Filters.vue";
import Card from "../../../components/layouts/Card.vue";

export default {
  components: {
    TableComponent,
    AffiliateStatus,
    Filters,
    Card
  },
  mixins: [HelperMixin],
  props: ["user"],
  data() {
    return {
      loading: false,
      affiliates: [],
      filters: {},
      sortBy: 'created_at',
      sortOptions: [
        { value: 'created_at', label: 'Data de Cadastro' },
        { value: 'total_commission', label: 'Total de Comissões' },
        { value: 'score', label: 'Score' }
      ],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: 10,
      },
    };
  },

  computed: {
    userId() {
      return this.$route.params.user_id ?? this.user.id;
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.loading = true;
      try {
        const response = await axios.get(`/api/user/${this.userId}/affiliates`, {
          params: {
            ...this.filters,
            sort_by: this.sortBy,
            page: this.pagination.current_page,
          },
        });
        this.affiliates = response.data.data;
        this.pagination = response.data;
        console.log({pagination: this.pagination});
      } catch (error) {
        console.error("Erro ao buscar afiliados:", error);
        this.$toast.error('Erro ao carregar afiliados');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>