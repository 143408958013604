<template>
    <div class="not-prose relative rounded-xl bg-slate-50 dark:bg-slate-800/25">
        <table class="w-full table-auto border-collapse text-sm">
            <slot name="thead"></slot>
            <thead class="dark:text-white">
                <tr>
                    <th
                        v-for="header in [
                            '',
                            'Regra',
                            'Valor',
                            'Peso',
                            'Score',
                        ]"
                        scope="col"
                        class="p-3 text-left text-sm font-medium"
                    >
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-for="(item, index) in score.scores">
                    <td class="table-cell-sm">
                        {{ index + 1 }}
                    </td>
                    <td class="table-cell-sm">
                        {{ item.description }}
                    </td>
                    <td class="table-cell-sm">
                        <component
                            v-if="typeof item.value == 'boolean'"
                            :is="
                                item.value ? 'CheckCircleIcon' : 'NoSymbolIcon'
                            "
                            class="mr-1 h-6 w-6 focus-visible:ring-0"
                            :class="{
                                'text-green-500': item.value,
                                'text-red-500': !item.value,
                            }"
                            aria-hidden="true"
                        />
                        <span v-else>
                            {{ item.value }}
                        </span>
                    </td>
                    <td class="table-cell-sm">
                        {{ item.weight }}
                    </td>
                    <td class="table-cell-sm">
                        <div class="flex items-center space-x-2">
                            {{ item.score }}
                            <div
                                class="ml-2"
                                v-if="
                                    index !== 0 &&
                                    score.scores[index - 1].score > item.score
                                "
                            >
                                <span
                                    class="text-red-500"
                                    v-if="score.scores[index - 1].score"
                                >
                                    -{{
                                        score.scores[index - 1].score -
                                        item.score
                                    }}
                                </span>
                                <span class="text-green-500" v-else>
                                    +{{
                                        item.score -
                                        score.scores[index - 1].score
                                    }}
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {
    CheckCircleIcon,
    NoSymbolIcon,
    ChevronDownIcon,
} from "@heroicons/vue/24/outline";

export default {
    components: { ChevronDownIcon, CheckCircleIcon, NoSymbolIcon },
    props: {
        score: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
