<template>
    <back-button :route="{ name: 'products' }"/>
    <div class="flex flex-col my-3">
        <user-heading
            v-if="storeProduct"
            :user="storeProduct.user"
        ></user-heading>
        <mozart-student-modal
            :student="studentToShow"
            @resetStudent="studentToShow = null;"
        />
        <nav-tabs :tabs="tabs" class="mb-5" />
        <filters
            class="my-3"
            :loading="loading"
            v-model="filters"
            search-filter
            search-label="Filtre pelo nome ou email"
            @fetch="
                students = [];
                next_page_url = false;
                this.pagination.current_page = 1;
                fetch();
            "
        >
        </filters>
        <table-component
            :loading="loading"
            :pagination="pagination"
            :headers="headers"
            @fetch="fetch"
            :selected="selected"
            :all-selected="all_selected"
        >
            <tr v-for="student in students" :key="student.id">
                <td class="table-cell-sm">
                    <span
                        :title="student.name"
                        class="block max-w-[80px] truncate"
                        v-tooltip="student.name"
                    >
                        {{ student.name }}
                    </span>
                </td>
                <td class="table-cell-sm">
                    <span
                        :title="student.email"
                        class="block"
                    >
                        {{ student.email }}
                    </span>
                </td>
                <td>
                    <div>
                        <button
                            @click.prevent="showStudent(student)"
                            type="button"
                            class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                        >
                            Detalhes
                        </button>
                        <button
                            v-if="storeProduct.members_portal_id"
                            @click.prevent="
                                mozartAccess(student)
                            "
                            :disabled="impersonateLoading"
                            type="button"
                            class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-green-500 rounded-md shadow hover:bg-green-400"
                        >
                            <svg v-if="impersonateLoading" class="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <font-awesome-icon
                                v-else
                                icon="fa-solid fa-sign-in"
                                class="mr-2"
                                aria-hidden="true"
                            />
                            Impersonate
                        </button>
                    </div>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import BackButton from "../../../components/layouts/navigation/BackButton.vue";
import NavTabs from "../../../components/layouts/NavTabs.vue";
import MozartStudentModal from "../modals/MozartStudentModal.vue";
import Filters from "../../../components/layouts/Filters.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import { mapActions, mapWritableState } from "pinia";
import { Store } from "../../../stores/store.js";
import UserHeading from "../../users/UserHeading.vue";

export default {
    name: "ProductStudents",
    components: { BackButton, NavTabs, UserHeading, Filters, MozartStudentModal, TableComponent },
    data() {
        return {
            tabs: [
                {
                    name: "details",
                    label: "Detalhes",
                    route: "product",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "students",
                    label: "Alunos",
                    route: "product.students",
                    params: { product_id: this.$route.params.product_id },
                },
                {
                    name: "members",
                    label: "Area de membros",
                    route: "product.members.contents",
                    params: { product_id: this.$route.params.product_id },
                }
            ],
            loading: false,
            impersonateLoading: false,
            students: [],
            studentToShow: null,
            next_page_url: false,
            selected: [],
            all_selected: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                types: [],
                date: [],
            },
        }
    },
    computed: {
        ...mapWritableState(Store, ["storeProduct", "permissions"]),
        headers() {
            return [
                "Nome",
                "Email",
                "Ações",
            ];
        },
    },
    async mounted() {
        if (!this.storeProduct) {
            await this.fetchProduct();
        }

        this.fetch();
    },
    methods: {
        ...mapActions(Store, ["fetchProduct"]),
        showStudent(student) {
            this.studentToShow = student;
        },
        async mozartAccess(student) {
            this.impersonateLoading = true;
            let token = null;
            let portalUrl = null;

            await axios.get(`/api/product/${this.storeProduct.id}/mozart-access-token?studentEmail=${student.email}`).then(({ data }) => {
                token = data.token;
                portalUrl = data.portal_url;
                this.impersonateLoading = false;
            }).catch(async (error) => {
                this.toast.error("Houve um erro ao redirecionar");
                this.impersonateLoading = false;
            });

            window.open(portalUrl + `/token-login?token=${token}`, "_blank");
        },
        fetch() {
            let params = this.filters;
            params["page"] = this.pagination.current_page;
            this.loading = true;
            axios.get('/api/product/' + this.storeProduct.id + '/mozart-students', {
                params: params
            }).then(({data}) => {
                this.students = data.data;
                this.pagination = {
                    ...this.pagination,
                    ...data,
                };

                this.next_page_url = data.next_page_url;
                this.loading = false;
            })
        },
    }
}
</script>
