<template>
    <table-component
        :loading="loading"
        :pagination="pagination"
        @fetch="fetch"
        :headers="[
            'Produto',
            ''
        ]"
    >
        <tr v-for="product in products" class="">
            <td class="table-cell">
                <div class="flex items-center">
                    <img
                        v-if="product.cover_picture"
                        :src="product.cover_picture"
                        class="object-contains"
                        style="width: 64px !important; height: 64px !important;"
                    >
                    {{product.id}} - {{product.name}}
                </div>
            </td>
            <td class="table-cell">
                <toggle-product
                    :product="product"
                    @fetch="fetch"
                ></toggle-product>
            </td>
        </tr>
    </table-component>
</template>

<script>
import Pagination from "../../../components/layouts/Pagination.vue";
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import HelperMixin from "../../../mixins/HelperMixin.vue";
import ToggleProduct from "../../../components/layouts/ToggleProduct.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
export default {
    props: ['user'],
    components: {TableComponent, Pagination, PaperClipIcon, ToggleProduct},
    mixins: [HelperMixin],

    data() {
        return {
            loading: false,
            products: [],
            next_page_url: false,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            const params = {};
            params['page'] = this.pagination.current_page;

            axios.get('/api/user/' + this.user.id + '/product', {
                params: params
            }).then(({data}) => {
                this.products = data.data;

                this.pagination = {
                    ...this.pagination,
                    ...data
                };

                this.next_page_url = data.next_page_url;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            });
        }
    }
}
</script>
