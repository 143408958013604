<template>
    <nav-tabs
        :exact="false"
        :tabs="[
            { label: 'Compliance', route: 'analytics.compliance' },
            { label: 'Usuários Ativos', route: 'analytics.active-users' },
            { label: 'Vendas', route: 'analytics.orders' },
        ]"
    />
    <router-view></router-view>
</template>

<script>
    import NavTabs from "../../components/layouts/NavTabs.vue";
    export default {
        components: { NavTabs },

        mounted() {

        }
    }
</script>
