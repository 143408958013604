<template>
    <input class="text-input" ref="inputRef" type="text" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
    name: "MoneyInput",
    props: {
        modelValue: Number, // Vue 2: value
        options: {
            type: Object,
            default: () => ({
                locale: "pt-BR",
                currency: "BRL",
                currencyDisplay: "symbol",
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: true,
                useGrouping: true,
                accountingSign: false,
            }),
        },
        cents: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        let newOptions = props.options;

        if (props.cents) {
            newOptions = {...newOptions, valueScaling: "precision"}
        }

        const { inputRef } = useCurrencyInput(newOptions);

        return { inputRef };
    },
};
</script>
