<template>
    <div class="flex items-center space-y-6 py-1">
        <img
            class="-mr-3 h-8 w-8 rounded-full"
            :src="
                user.avatar ||
                'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                    user.name
            "
        />
        <div class="ml-5 mt-0" style="margin-top: 0 !important">
            <p class="-mb-1 font-bold text-gray-500">{{ user.name }}</p>
            <p class="font-bold text-gray-700 dark:text-gray-300">
                {{ user.email }}
            </p>
        </div>

        <div class="ml-auto" style="margin-top: 0 !important">
            <p class="flex font-bold">
                <template v-if="user.cashoutReview">
                    <span
                        @click="open = true"
                        class="flex"
                        :class="{
                            'text-green-500 hover:cursor-pointer hover:underline':
                                status === 'approved',
                            'text-red-500 hover:cursor-pointer hover:underline':
                                status === 'rejected',
                            'text-yellow-500 hover:cursor-pointer hover:underline':
                                status === 'warning',
                            'text-yellow-300': status === 'pending',
                        }"
                    >
                        {{ statusName(user.cashoutReview.status) }}
                        <CheckIcon
                            v-if="status === 'approved'"
                            class="h-6 w-6"
                            aria-hidden="true"
                        />
                    </span>
                </template>
                <template v-else>
                    <span
                        @click.prevent="requestReview"
                        class="text-indigo-500 hover:cursor-pointer hover:underline"
                        >Solicitar</span
                    >
                </template>
            </p>
        </div>
        <review-modal
            v-if="user.cashoutReview && user.cashoutReview.status !== 'pending'"
            :cashout="cashout"
            :open="open"
            :review="user.cashoutReview"
            @close="open = false"
        />
    </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/20/solid";
import ReviewModal from "./ReviewModal.vue";

export default {
    components: { ReviewModal, CheckIcon },
    props: {
        user: { type: Object, required: true },
        cashout: { type: Object, required: true },
    },

    emits: ["fetch"],

    data() {
        return {
            open: false,
        };
    },

    computed: {
        status() {
            return this.user.cashoutReview
                ? this.user.cashoutReview.status
                : "pending";
        },
    },

    methods: {
        requestReview() {
            axios
                .post("/api/cashout/" + this.cashout.id + "/review", {
                    cashout_id: this.cashout.id,
                    user_id: this.user.id,
                    status: "pending",
                })
                .then(({ data }) => {
                    this.users = data;
                    this.loading = false;

                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        statusName(name) {
            switch (name) {
                case "pending":
                    return "Solicitado";
                case "approved":
                    return "Aprovado";
                case "warning":
                    return "Atenção";
                case "rejected":
                    return "Reprovado";
            }
        },
    },
};
</script>
