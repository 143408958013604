<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <div>
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria</label>

            <auto-complete-combobox
                v-model="form.category"
                :options="[
                    { value: 'personnel', name: 'Despesas com Pessoal' },
                    { value: 'administrative', name: 'Despesas administrativas' },
                    { value: 'financial', name: 'Despesas financeiras' },
                    { value: 'withdrawals', name: 'Despesas diretas com operações (SAQUES)' },
                    { value: 'marketing', name: 'Marketing' },
                    { value: 'third_party', name: 'Terceiros' },
                    { value: 'tools', name: 'Ferramentas' },
                    { value: 'infrastructure', name: 'Infraestrutura' },
                    { value: 'taxes', name: 'Impostos' },
                    { value: 'pro_labore', name: 'Pro Labore' },
                ]"
            />
        </div>
        <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor</label>
            <money-input
                v-model="form.amount"
                name="amount"
            />
        </div>

        <div>
            <label for="date" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data</label>
            <litepie-datepicker
                as-single
                v-model="form.date"
                :formatter="{
                  date: 'MM/YYYY',
                  month: 'MMM'
                }"
                :shortcuts="false"
            />
        </div>

        <div>
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400 mt-2">
                Descrição
            </label>
            <textarea
                v-model="form.description"
                id="message"
                rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
        </div>

        <submit-button :loading="loading" class="ml-auto">Salvar</submit-button>
    </form>
</template>

<script>
    import { vMaska } from "maska"
    import SubmitButton from "../forms/SubmitButton.vue";
    import MoneyInput from "../forms/MoneyInput.vue";
    import AutoCompleteCombobox from "../forms/AutoCompleteCombobox.vue";

    export default {
        props: {cost: {type: Object, default: null}},
        directives: { maska: vMaska },
        components: {AutoCompleteCombobox, MoneyInput, SubmitButton},
        emits: ['fetch'],
        data() {
            return {
                show: false,
                loading: false,
                form: {
                    amount: 0,
                    category: false,
                    date: '',
                    description: ''
                }
            }
        },

        beforeMount() {
            if (this.cost) {
                this.form = {
                    amount: this.cost.amount / 100,
                    category: this.cost.category,
                    date: this.cost.date,
                    description: this.cost.description
                };
            }
        },

        methods: {
            submit() {
                this.loading = true;

                let promise = this.cost ?
                    axios.put(`/api/cost/${this.cost.id}`, this.form) :
                    axios.post('/api/cost', this.form);

                promise.then(response => {
                        this.loading = false;
                        this.$emit('fetch');
                    })
                    .catch(error => {
                        this.loading = false;
                    })

            }
        }

    }
</script>
