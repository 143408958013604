<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <SvgLoader v-if="this.loading" />
                        <form @submit.prevent="handleSave" v-else>
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mt-3 text-center sm:mt-0 sm:text-left"
                                >
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-white"
                                    >
                                        Níveis
                                    </dialog-title>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex flex-col">
                                <div class="grid sm:grid-cols-2 gap-2 mb-3">
                                    <div>
                                        <label class="text-white">
                                            Nome
                                        </label>
                                        <input
                                            type="text"
                                            id="label"
                                            name="label"
                                            v-model="form.label"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Nome"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Pontuação
                                        </label>
                                        <money-input
                                            v-model="form.amount"
                                            name="amount"
                                            required
                                            cents
                                        />
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Sequência
                                        </label>
                                        <input
                                            type="number"
                                            min="1"
                                            id="place"
                                            name="place"
                                            v-model="form.place"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Sequência"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label class="text-white">
                                            Tipo
                                        </label>
                                        <select
                                            v-model="form.type"
                                            class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        >
                                            <option v-for="(rankingType, key) in rankingTypes" :key="key" :value="key">{{rankingType}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="mt-3 grid sm:grid-cols-2 gap-5">
                                    <button
                                        type="submit"
                                        class="mb-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
                                    >
                                        Confirmar
                                    </button>

                                    <button
                                        type="button"
                                        class="mb-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        @click="closeModal()"
                                        ref="cancelButtonRef"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useToast } from "vue-toastification";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";
import MoneyInput from "../../../components/forms/MoneyInput.vue";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        SvgLoader,
        MoneyInput
    },
    props: ["levelId"],
    setup() {        
        const rankingTypes = {
            billing: "Comissionamento"
        }
        const toast = useToast();
        return { toast, rankingTypes };
    },
    data() {
        return {
            loading: false,
            form: {
                label: null,
                amount: null,
                place: null,
                type: null,
            },
            level: null,
        };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        fetchLevels() {
            this.$emit("fetchLevels");
        },
        handleSave() {
            if (!this.levelId) {
                axios
                    .post(`/api/levels/`, this.form)
                    .then((response) => {
                        if (response) {
                            this.toast.success("Nível salvo com sucesso");
                            this.fetchLevels();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar nível");
                    });
            } else {
                axios
                    .put(
                        `/api/levels/${this.levelId}`,
                        this.form
                    )
                    .then((response) => {
                        if (response) {
                            this.toast.success("Nível salvo com sucesso");
                            this.fetchLevels();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar nível");
                    });
            }
        },
        findOne(id) {
            this.loading = true;

            axios
                .get(`/api/levels/${id}`)
                .then((response) => {
                    this.level = response.data;
                    this.form.label = this.level.label;
                    this.form.amount = this.level.amount;
                    this.form.place = this.level.place;
                    this.form.type = this.level.type;
                })
                .catch((error) => {
                    this.toast.error("Erro ao editar nível");
                })
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        if (this.levelId) {
            this.findOne(this.levelId);
        }
    },
};
</script>
