<script>
import {mapState} from "pinia";
import {Store} from "../stores/store";

export default {
    computed: {
        ...mapState(Store, ['permissions']),
    },
    methods: {
        hasPermission(name) {
            return this.permissions && this.permissions.find(permission => permission.name === name)
        },
    }
}
</script>
