<template>
    <div class="mt-6">
        <div class="dark:text-white">
            Parâmetros para cálculos da velocidade dos produtos:
        </div>
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg my-4 border border-gray-600">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Receita
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Qtd. de vendas
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Percentual de converção
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Qtd. de vendas de afiliados
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Reembolso
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Estorno
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Dias
                        </th>
                        <th scope="col" class="py-2 px-4">
                            Velocidade (máx)
                        </th>
                        <th scope="col" class="py-2 px-4 bg-gray-50 dark:bg-gray-800">
                            Velocidade (min)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                        <th scope="row" class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.revenue">
                        </th>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.sales_quantity">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.conversion_percentage">
                        </td>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.affiliate_sales_quantity">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.refund">
                        </td>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.chargeback">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.days">
                        </td>
                        <td class="py-4 px-4">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.max">
                        </td>
                        <td class="py-4 px-4 bg-gray-50 dark:bg-gray-800">
                            <input class="bg-transparent border-0 outline-none w-full border-b border-gray-500" type="number" v-model="setting.min">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <button
            @click.prevent="update"
            :disabled="false"
            type="button"
            class="mr-2 inline-flex items-center px-10 py-2 h-10 font-semibold leading-6 text-sm shadow rounded-md text-white hover:text-white hover:bg-indigo-400 bg-transparent bg-indigo-500 transition ease-in-out duration-150"
        >
            Salvar
        </button>
    </div>

</template>

<script>
    import VueSelect from "../../components/forms/VueSelect.vue";
    import { useToast } from "vue-toastification";

    export default {
        components: {VueSelect},
        data() {
            return {
                selected: null,
                loading: false,
                setting: [],
                recommended: [],
                bestRated: [],
            }
        },

        mounted() {
            this.fetch();
        },

        setup() {
            const toast = useToast();
            return { toast }
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/showcase/setting').then(({data}) => {
                    this.setting = data.setting;
                }).catch(({response}) => {
                    this.loading = false;
                })
            },
            update(){
                this.updateSetting();
            },
            updateSetting(){
                axios.put('/api/showcase/setting', {
                    setting: this.setting
                }).then(({response}) => {
                    this.toast.success("Configuração salva com sucesso!", {timeout: 2000});
                }).catch(({response}) => {
                    this.toast.error("Erro ao salvar configuração!", {timeout: 2000});
                });
            },
        }
    }
</script>
