<template>
    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-4 mb-3">
        <card
            v-if="loading"
            v-for="n in 4"
            :loading="true"
        />
        <stat-card
            v-else
            v-for="stat in cards"
            :loading="loading"
            :name="stat.name"
            :stat="stat.stat"
            :previous="stat.previous"
        />
    </dl>
</template>

<script>
import StatCard from "../../layouts/StatCard.vue";
import Card from "../../layouts/Card.vue";

export default {
    components: {Card, StatCard},
    props: ['loading', 'products', 'previousProducts'],

    data() {
        return {
            cards: [],
        };
    },

    watch: {
        loading() {
            if (!this.loading) {
                this.fetch();
            }
        }
    },

    methods: {
        fetch() {
            this.cards = [];
            let totals = {
                pending: {
                    name: 'Pendentes',
                    stat: 0,
                    previous: 0
                },
                approved: {
                    name: 'Aprovados',
                    stat: 0,
                    previous: 0
                },
                reproved: {
                    name: 'Reprovados',
                    stat: 0,
                    previous: 0
                },
                pendingCorrection: {
                    name: 'Correção Pendente',
                    stat: 0,
                    previous: 0
                }
            };

            Object.entries(this.products).forEach(([key, value]) => {
                totals.pending.stat += value['pending'];
                totals.approved.stat += value['approved'];
                totals.pendingCorrection.stat += value['pending-correction'];
                totals.reproved.stat += value['reproved'];
            });

            Object.entries(this.previousProducts).forEach(([key, value]) => {
                totals.pending.previous += value['pending'];
                totals.approved.previous += value['approved'];
                totals.pendingCorrection.previous += value['pending-correction'];
                totals.reproved.previous +=value['reproved'];
            })

            this.cards.push(totals.pending, totals.approved, totals.pendingCorrection, totals.reproved);
        }
    }
}
</script>
