<template>
    <div class="flex flex-col dark:text-white">
        <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
            Assinatura
        </h3>
        <div class="min-w-0 w-full h-50 rounded-lg overflow-hidden bg-white dark:bg-gray-800 mb-4 drop-shadow-sm p-5">
            <div v-if="!loading" class="grid grid-cols-3 gap-3">
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Número</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >#{{ subscription.id }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Periodicidade</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >{{ formatInterval(subscription.interval) }}</span
                    >
                </div>
                <div class="flex flex-col" v-if="subscription.next_charge">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Data da Próxima Cobrança</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >
                        {{ formatDateWithoutTime(subscription.next_charge) }}
                        -
                        <button
                            class="text-indigo-500 hover:underline"
                            @click.prevent="cancel()"
                        >
                            Cancelar
                        </button>
                    </span>
                </div>
                <div class="flex flex-col" v-if="subscription.canceled_at">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Data do Cancelamento</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >{{ formatDateWithoutTime(subscription.canceled_at) }}
                        -
                        <button
                            class="text-indigo-500 hover:underline"
                            @click.prevent="reactivate()"
                        >
                            Reativar
                        </button>
                    </span>
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Quantidade de Cobranças a serem Realizadas</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >{{ subscription.max_charges ?? 'Renovação Automática' }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Quantidade de Cobranças Bem-sucedidas</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >{{ subscription.successful_charges }}</span
                    >
                </div>
                <div class="flex flex-col">
                    <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Quantidade de Cobranças Mal-sucedidas</span
                    >
                    <span
                        class="text-gray-400 dark:text-gray-200"
                        >{{ subscription.failed_charges }}</span
                    >
                </div>
                <div class="flex flex-col">
                      <span
                        class="text-gray-400 dark:text-gray-400 font-bold uppercase text-sm"
                        >Assinatura Migrada</span
                    >

                    <info-col>
                        <img v-if="subscription.legacy_id" src="https://www.svgrepo.com/show/410398/check.svg" alt="check" style="max-width: 15px;" />
                        <img v-if="!subscription.legacy_id" src="https://www.svgrepo.com/show/151290/close.svg" alt="close" style="max-width: 15px;" />
                    </info-col>
                </div>
            </div>
            <div v-else>
                <span class="text-gray-400 dark:text-gray-200">
                    Realizando solicitação...
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../mixins/HelperMixin.vue";

export default {
    props: ["subscription"],
    emits: ["updated"],
    mixins: [HelperMixin],
    data() {
        return {
            loading: false,
            next_page_url: false,
            commissions: [],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
        };
    },
    methods: {
        formatInterval(interval) {
            switch (interval) {
                case 0:
                    return "Semanal";
                case 1:
                    return "Mensal";
                case 2:
                    return "Bimestral";
                case 3:
                    return "Trimestral";
                case 6:
                    return "Semestral";
                case 12:
                    return "Anual";
                default:
                    return "-";
            }
        },
        cancel() {
            if (confirm("Tem certeza que deseja cancelar essa assinatura?")) {
                this.loading = true;

                axios.post('/api/subscription/' + this.subscription.id + '/cancel', {
                    permissions: this.permissions,
                }).then(({data}) => {
                    this.$emit('updated');

                    setTimeout(() => {
                        this.$notify({
                            group: "success",
                            title: "Success",
                            text: data?.message || "Solicitação realizada com sucesso!"
                        }, 4000);

                        this.loading = false;
                    }, 1000);
                }).catch(({response}) => {
                    this.$notify({
                        group: "error",
                        title: "Error",
                        text: response?.data?.message || response?.data?.errors[0] || "Não foi possível concluir essa solicitação."
                    }, 4000);

                    this.loading = false;
                });
            }
        },
        reactivate() {
            if (confirm("Tem certeza que deseja reativar essa assinatura?")) {
                this.loading = true;

                axios.post('/api/subscription/' + this.subscription.id + '/reactivate', {
                    permissions: this.permissions,
                }).then(({data}) => {
                    this.$emit('updated');

                    setTimeout(() => {
                        this.$notify({
                            group: "success",
                            title: "Success",
                            text: data?.message || "Solicitação realizada com sucesso!"
                        }, 4000);

                        this.loading = false;
                    }, 1000);
                }).catch(({response}) => {
                    this.$notify({
                        group: "error",
                        title: "Error",
                        text: response?.data?.message || response?.data?.errors[0] || "Não foi possível concluir essa solicitação."
                    }, 4000);

                    this.loading = false;
                });
            }
        },
    }
};
</script>
