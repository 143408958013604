<template>
    <span
        class="mr-2 rounded"
        :class="[getCashoutStatusClass(status), sizeClass]"
    >
        {{ getCashoutStatusName(status) }}
    </span>
</template>

<script>
import BankMixin from "../../mixins/BankMixin.vue";
export default {
    props: {
        status: {
            type: String,
        },
        size: {
            type: String,
            default: "md",
        },
    },

    mixins: [BankMixin],

    computed: {
        sizeClass() {
            return this.size === "md"
                ? "px-2.5 py-1.5 text-sm"
                : "px-1.5 py-1 text-xs";
        },
    },
};
</script>
