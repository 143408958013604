<template>
    <ticto-loading v-if="loading" />
    <div class="my-5">
        <div style="display: flex">
            <VideoCameraIcon class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400" />
            <h3 class="mb-5 text-lg font-medium leading-9 dark:text-white">
                Vídeos
            </h3>
        </div>

        <filters
            v-model="filters"
            @fetch="
                pagination.current_page = 1;
                users = [];
                next_page_url = false;
                fetch();
            "
            :loading="loading"
            search-filter
            search-label="Filtre por nome"
            class="my-5"
        />

        <div class="mt-10">
            <h3 class="mb-5 text-lg font-medium leading-6 dark:text-white">
                <button
                    @click="openModal()"
                    :disabled="loading"
                    type="button"
                    :class="{ 'cursor-not-allowed': loading }"
                    class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                >
                    <VideoCameraIcon
                        class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                    />
                    Novo Vídeo
                </button>
            </h3>
            <table class="w-full table-auto border-collapse text-sm">
                <thead class="bg-white dark:bg-slate-800">
                    <tr>
                        <th
                            scope="col"
                            class="p-3 text-left dark:text-slate-200"
                        >
                            Nome
                        </th>
                        <th
                            scope="col"
                            class="p-3 text-left dark:text-slate-200"
                        >
                            Projeto
                        </th>

                        <th
                            scope="col"
                            class="p-3 text-left dark:text-slate-200"
                        >
                            URL Sistema
                        </th>

                        <th
                            scope="col"
                            class="p-3 text-left dark:text-slate-200"
                        >
                            Vídeos
                        </th>

                        <th
                            scope="col"
                            class="p-3 text-left dark:text-slate-200"
                        >
                            Ações
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="bg-white dark:bg-slate-800">
                    <tr v-for="video in videos">
                        <td
                            class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            {{ video?.name }}
                        </td>
                        <td
                            class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            {{ video?.project_name }}
                        </td>

                        <td
                            class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            {{ video?.url }}
                        </td>

                        <td
                            class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            <button
                                @click="openModalVideos(video)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                <VideoCameraIcon
                                    class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                                />
                                Vídeos
                            </button>
                        </td>

                        <td>
                            <button
                                @click="openModal(video)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                <PencilIcon
                                    class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                                />
                                Editar
                            </button>
                        </td>
                        <td>
                            <button
                                @click.prevent="destroy(video.id)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="ml-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                            >
                                <TrashIcon
                                    class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                                />
                                Excluir
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <modal size="xl" :open="modal" @close="modal = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Vídeos
        </h1>

        <form @submit.prevent="submitForm">
            <div class="mt-5 flex-col sm:mt-4 sm:flex">
                <div class="mb-3 grid gap-2 sm:grid-cols-2">
                    <div>
                        <label class="text-white"> Nome </label>
                        <input
                            type="text"
                            id="name"
                            v-model="form.name"
                            name="name"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Nome"
                            required
                        />
                    </div>
                    <div>
                        <label class="text-white"> Projeto </label>
                        <select
                            v-model="form.project_name"
                            id="type"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        >
                            <option value="thor">Thor</option>
                            <!-- <option value="mozart">Mozart</option> -->
                        </select>
                    </div>
                </div>

                <div>
                    <label class="text-white"> URL Sistema </label>
                    <input
                        v-model="form.url"
                        type="text"
                        id="url"
                        name="url"
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                        placeholder="URL Sistema - Ex: /dashboard"
                    />
                </div>

                <div class="mt-4">
                    <h1
                        class="mb-2 mt-4 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
                    >
                        Novo Vídeo
                    </h1>

                    <div
                        class="flex items-center bg-indigo-800 p-2 leading-none text-indigo-100 lg:inline-flex lg:rounded-full text-center"
                        role="alert"
                    >
                        <span
                            class="mr-3 flex rounded-full bg-red-500 px-2 py-1 text-xs font-bold uppercase"
                            >Aviso</span
                        >
                        <span class="mr-2 flex-auto text-left font-semibold"
                            >Adicione vídeos diretamente do Youtube</span
                        >
                    </div>
                    <div>
                        <div
                            v-for="(item, index) in form.image_url"
                            :key="index"
                            class="mb-3 grid gap-2 sm:grid-cols-2"
                        >
                            <div>
                                <label class="text-white"> Título </label>
                                <input
                                    type="text"
                                    v-model="item.title"
                                    :name="'title' + index"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="Título"
                                    required
                                />
                            </div>
                            <div>
                                <label class="text-white"> URL </label>
                                <input
                                    type="url"
                                    v-model="item.url"
                                    :name="'url' + index"
                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                    placeholder="URL"
                                    required
                                />
                            </div>
                        </div>
                        <button
                            type="button"
                            @click="addItem()"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="mb-10 ml-2 mt-4 inline-flex items-center rounded-md bg-blue-500 px-2 py-1 text-sm font-semibold text-white shadow transition duration-150 ease-in-out hover:bg-blue-400"
                        >
                            <PlusCircleIcon
                                class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                            />
                            Novo Vìdeo
                        </button>
                    </div>
                </div>

                <submit-button
                    :loading="loading"
                    class="ml-auto"
                    :disabled="loading"
                >
                    Salvar
                </submit-button>
            </div>
        </form>
    </modal>

    <modal size="xl" :open="modalVideos" @close="modalVideos = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Vídeos
        </h1>

        <div v-for="(videos, index) in video" class="text-center">
            <p class="text-center text-white">{{ videos.title }}</p>

            <iframe
                width="560"
                height="315"
                :key="index"
                :src="getEmbedUrl(videos.url)"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            ></iframe>

            <button
                @click.prevent="destroyVideo(videos.url)"
                :disabled="loading"
                type="button"
                :class="{ 'cursor-not-allowed': loading }"
                class="mb-10 ml-2 mt-4 inline-flex items-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
            >
                <TrashIcon class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400" />
                Excluir
            </button>
        </div>
    </modal>
</template>

<script>
//filepond
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    CheckCircleIcon,
    DevicePhoneMobileIcon,
    PencilIcon,
    PlusCircleIcon,
    StopCircleIcon,
    TrashIcon,
    VideoCameraIcon,
} from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import Filters from "../../components/layouts/Filters.vue";
import Modal from "../../components/layouts/Modal.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import Manager from "../../components/manager/Manager.vue";
import StoreModal from "../../components/manager/modals/StoreModal.vue";

export default {
    components: {
        EmptyState,
        StoreModal,
        Manager,
        VueSelect,
        UsersSelect,
        TictoLoading,
        DevicePhoneMobileIcon,
        CheckCircleIcon,
        VideoCameraIcon,
        PlusCircleIcon,
        PencilIcon,
        FontAwesomeIcon,
        StopCircleIcon,
        TrashIcon,
        Filters,
        Modal,
        SubmitButton,
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            modal: false,
            video: null,
            modalVideos: false,
            counter: 0,
            form: {
                id: null,
                project_name: null,
                name: null,
                url: null,
                image_url: [{ title: "", url: "" }],
            },
            videos: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
            },
            next_page_url: false,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            const params = this.filters;
            axios.get("/api/tutorials", { params: params }).then(({ data }) => {
                this.loading = false;
                this.videos = data.data;
            });
        },
        openModal(video) {
            this.closeModal();
            if (video) {
                this.form.project_name = video.project_name;
                this.form.name = video.name;
                this.form.url = video.url;
                this.form.id = video.id;
                this.form.image_url = video.image_url;
                this.video = video.image_url;
            }
            this.modal = true;
        },
        openModalVideos(video) {
            this.video = video.image_url;
            this.modalVideos = true;
        },
        closeModal() {
            this.video = [];
            this.form.project_name = null;
            this.form.name = null;
            this.form.url = null;
            this.form.image_url = [];
            this.loading = false;
            this.modal = false;
        },
        addItem() {
            this.form.image_url.push({ title: "", url: "" });
            this.counter++;
        },
        getEmbedUrl(videoUrl) {
            const videoId = this.extractVideoId(videoUrl);
            return `https://www.youtube.com/embed/${videoId}`;
        },
        extractVideoId(videoUrl) {
            return videoUrl.split("v=")[1];
        },
        destroy(id) {
            this.loading = true;
            axios
                .delete(`/api/tutorials/${id}`)
                .then(({ data }) => {
                    this.toast.success("Vídeo deletado com sucesso!");
                    this.closeModal();
                    this.fetch();
                    this.loading = false;
                })
                .catch(({ error }) => {
                    this.toast.error("Ocorreu um erro, tente novamente!");
                });
        },
        destroyVideo(video) {
            axios
                .post(`/api/tutorials/${this.form.id}/video`, { url: video })
                .then(({ data }) => {
                    this.toast.success("Vídeo deletado com sucesso!");
                    this.closeModal();
                    this.fetch();
                    this.loading = false;
                })
                .catch(({ error }) => {
                    this.toast.error("Ocorreu um erro, tente novamente!");
                });
        },
        submitForm() {
            this.loading = true;

            if (this.form.id == null) {
                axios
                    .post(`/api/tutorials`, this.form)
                    .then(({ data }) => {
                        this.toast.success("Vídeo cadastrado com sucesso!");
                        this.closeModal();
                        this.fetch();
                    })
                    .catch(({ error }) => {
                        this.toast.error("Ocorreu um erro, tente novamente!");
                    });
            } else {
                axios
                    .put(`/api/tutorials/${this.form.id}`, this.form)
                    .then(({ data }) => {
                        this.toast.success("Vídeo alterado com sucesso!");
                        this.closeModal();
                        this.fetch();
                    })
                    .catch(({ error }) => {
                        this.toast.error("Ocorreu um erro, tente novamente!");
                    });
            }
        },
    },
};
</script>
