<template>
    <div class="mb-8 grid gap-6 md:grid-cols-2 xl:grid-cols-5">
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
                Disponível
            </p>
            <p
                class="text-white-100 flex text-lg font-semibold dark:text-gray-200"
            >
                {{ $root.formatCurrency(balance.available_balance) }}
                <img
                    v-if="balance.available_balance == payables.available"
                    src="https://www.svgrepo.com/show/410398/check.svg"
                    class="ml-2"
                    alt="check"
                    style="max-width: 20px"
                />
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
                Antecipação
            </p>
            <p class="text-white-100 text-lg font-semibold dark:text-gray-200">
                {{ $root.formatCurrency(balance.anticipation_balance) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
                Em análise
            </p>
            <p class="text-white-100 text-lg font-semibold dark:text-gray-200">
                {{ $root.formatCurrency(payables.under_review) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
                Pendente
            </p>
            <p
                class="text-white-100 flex text-lg font-semibold dark:text-gray-200"
            >
                {{ $root.formatCurrency(balance.pending_balance) }}
                <img
                    v-if="balance.pending_balance == payables.pending"
                    src="https://www.svgrepo.com/show/410398/check.svg"
                    class="ml-2"
                    alt="check"
                    style="max-width: 20px"
                />
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
            >
                Total
                <ExclamationCircleIcon
                    class="ml-1 h-4 w-4 text-gray-500 focus-visible:ring-0"
                    aria-hidden="true"
                    v-tooltip="'Disponível+Pendente'"
                />
            </p>
            <p class="text-white-100 text-lg font-semibold dark:text-gray-200">
                {{
                    $root.formatCurrency(
                        balance.available_balance + balance.pending_balance,
                    )
                }}
            </p>
        </card>
    </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";

import { mapWritableState } from "pinia";
import { Store } from "../../stores/store";
import Card from "../layouts/Card.vue";
export default {
    components: { Card, ExclamationCircleIcon },
    props: ["user"],

    data() {
        return {
            loading: false,
            balance: {
                available_balance: 0,
                pending_balance: 0,
                anticipation_balance: 0,
            },
            pending_records: 0,
            payables: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["userPendingRecords"]),
    },

    mounted() {
        this.fetch();
        Echo.channel("user-" + this.$route.params.user_id).listen(
            ".App\\Events\\BalanceUpdating",
            (e) => {
                this.loading = e.updating;

                if (!e.updating) {
                    this.fetch();
                }
            },
        );
    },
    methods: {
        fetch() {
            this.userPendingRecords = 0;
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/balance")
                .then(({ data }) => {
                    this.balance = data.balance;
                    this.userPendingRecords = data.pending_records;
                    this.payables = data.payables;
                    this.loading = false;
                });
        },
    },
};
</script>
