<template>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Uuid',
            'Saldo',
            ''
        ]"
        @fetch="fetch"
    >
        <tr v-for="log in logs">
            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{log.created_at}}
            </td>
            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                {{log.batch_uuid}}
            </td>
            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                <div class="flex flex-col">
                    <span v-if="log.balance">
                        Disponível: <strong>{{ $root.formatCurrency(log.balance.available) }}</strong>
                    </span>
                    <span v-if="log.balance">
                        Pendente: <strong>{{ $root.formatCurrency(log.balance.pending) }}</strong>
                    </span>
                </div>
            </td>
            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                <log-modal :log="log" :user="user"></log-modal>
            </td>
        </tr>
    </table-component>
</template>

<script>
import Filters from "../../../components/layouts/Filters.vue";
import LogModal from "./log/LogModal.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";

export default {
    components: {TableComponent, Filters, LogModal},
    props: ['user'],
    data() {
        return {
            loading: false,
            next_page_url: false,
            logs: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
        }
    },

    mounted() {
        this.fetch();
        Echo.channel('user-' + this.$route.params.user_id)
            .listen('.App\\Events\\BalanceUpdating', (e) => {
                this.balance_updating = e.updating

                if(!e.updating) {
                    this.logs = [];
                    this.next_page_url = false;
                    this.fetch();
                }
            });
    },

    methods: {
        show(log) {
            axios.get('/api/user/' + this.user.id + '/balance/log/' + log.batch_uuid)
            .then(({data}) => {
            }).catch(error => {
                this.loading = false;
                // this.$toast.error(error.response.data.message);
            });
        },
        fetch() {
            this.loading = true;

            const params = {};
            params['page'] = this.pagination.current_page;

            axios.get('/api/user/' + this.user.id + '/balance/log', {
                params: params
            }).then(({data}) => {
                this.logs = data.data;

                this.pagination = {
                    ...this.pagination,
                    ...data
                };

                this.next_page_url = data.next_page_url;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                // this.$toast.error(error.response.data.message);
            });
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        }
    }
}
</script>
