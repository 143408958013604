<template>
    <span
        class="flex flex-wrap rounded-lg px-3 py-1 font-bold uppercase"
        :class="{
            'bg-green-500 text-white': audit.status === 'open',
            'bg-red-400 text-white': audit.status === 'closed',
        }"
    >
        {{ status }}
    </span>
</template>

<script>
import { NoSymbolIcon } from "@heroicons/vue/24/outline";

export default {
    components: { NoSymbolIcon },
    props: ["audit"],
    computed: {
        status() {
            switch (this.audit.status) {
                case "open":
                    return "Iniciado";
                case "closed":
                    return "Finalizado";
                default:
                    return this.audit.status;
            }

            return this.audit.status;
        },
    },
};
</script>
