<template>
    <div class="flex flex-col dark:text-white">
        <form
            @submit.prevent="$emit('fetch')"
            class="mt-5 flex content-center items-center space-x-3"
        >
            <input
                v-model="filters.external_id"
                type="text"
                id="id"
                class="text-input"
                placeholder="Id Externo"
            />
            <input
                v-model="filters.email"
                type="text"
                id="id"
                class="text-input"
                placeholder="Email"
            />
            <submit-button @click.prevent="fetch" :loading="loading">
                Filtrar
            </submit-button>
        </form>

        <table-component
            :loading="loading"
            :pagination="pagination"
            select-all
            :selected="selected"
            :all-selected="all_selected"
            :headers="[
                'Pedido',
                'Produto',
                'Produtor',
                'Cliente',
                'Método de Pagamento',
                'Valor do Pedido',
                'Data do Pedido',
                'Status',
            ]"
            @fetch="fetch"
            @selectAll="selectAll"
        >
            <template v-slot:exportHeader>
                <div
                    @click.prevent="exportExcel"
                    class="flex content-center justify-center rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                >
                    <SvgLoader v-if="loading_export" />
                    <CloudArrowDownIcon
                        class="mr-1 h-5 w-5 flex-shrink-0 self-center"
                    />
                    Exportar
                </div>
            </template>
            <tr v-for="order in orders">
                <td class="table-cell-sm flex">
                    <input
                        :id="'check-order-' + order.id"
                        name="orders"
                        type="checkbox"
                        class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                        v-model="selected"
                        :value="order.id"
                    />

                    <router-link
                        :to="{
                            name: 'user.order',
                            params: { order_id: order.id },
                        }"
                        class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                    >
                        #{{ order.id }}
                    </router-link>
                </td>
                <td class="table-cell-sm">
                    {{ order.items[0] ? order.items[0].product?.name : "-" }}
                    <font-awesome-icon
                        v-if="order.items.length > 1"
                        class="ml-1"
                        icon="fa-solid fa-circle-plus"
                    />
                </td>
                <td class="table-cell-sm">
                    <button
                        class="text-indigo-500 hover:underline"
                        @click.prevent="
                            manageUser(order.items[0].product?.user)
                        "
                    >
                        {{
                            order.items[0]
                                ? order.items[0].product?.user?.name
                                : "-"
                        }}
                    </button>
                </td>
                <td class="table-cell-sm text-xs">
                    {{ order.customer ? order.customer["name"] : "-" }}
                </td>
                <td class="table-cell-sm">
                    <payment-type
                        :method="
                            order.transactions[0]
                                ? order.transactions[0].payment_method
                                : null
                        "
                        :hasAmountCard2="order.amount_card2"
                        :brandCard1="
                            order.transactions[0] && order.transactions[0].card
                                ? order.transactions[0].card.brand
                                : null
                        "
                        :brandCard2="
                            order.transactions[1] && order.transactions[1].card
                                ? order.transactions[1].card.brand
                                : null
                        "
                    ></payment-type>
                </td>
                <td class="table-cell-sm">
                    {{
                        $root.formatCurrency(order.amount + order.amount_card2)
                    }}
                </td>
                <td class="table-cell-sm text-xs">
                    {{ order.created_at }}
                </td>
                <td class="table-cell-sm">
                    <status
                        size="sm"
                        :status="
                            order.transactions[order.transactions.length - 1]
                                .status
                        "
                        :isRefundFromHood="
                            order.transactions[order.transactions.length - 1]
                                .is_refund_from_hood
                        "
                    ></status>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import Card from "../../components/layouts/Card.vue";
import Filters from "../../components/layouts/Filters.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import PaymentType from "../../components/layouts/PaymentType.vue";
import Status from "../../components/layouts/Status.vue";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import { Store } from "../../stores/store";
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: {
        SubmitButton,
        SvgLoader,
        TableComponent,
        Pagination,
        Filters,
        Status,
        PaymentType,
        CloudArrowDownIcon,
        Card,
    },
    mixins: [HelperMixin],
    data() {
        return {
            all_selected: false,
            selected: [],
            loading: false,
            next_page_url: false,
            orders: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            cards: {
                amount: 0,
                count: 0,
            },
            defaultDates: [
                moment().subtract(1, "month").format("DD/MM/YYYY"),
                moment().format("DD/MM/YYYY"),
            ],
            filters: {
                external_id: "",
                email: "",
                text: "",
                status: [],
                payment_method: [],
            },
            loading_export: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["user"]),
    },

    watch: {
        selected(array) {
            if (array.length === 0) {
                this.all_selected = false;
            }
        },
    },

    mounted() {
        this.user = false;
    },

    methods: {
        exportExcel() {
            if (this.loading_export) {
                return false;
            }

            if (this.selected.length < 1) {
                return this.$toast.error(error.response.data.message);
            }

            this.loading_export = true;

            const params = this.filters;

            if (this.selected.length < 1000) {
                params.selected = this.selected;
            } else {
                delete params.selected;
            }

            axios
                .get("/api/order/export/excel", {
                    params: {
                        ...params,
                    },
                })
                .then(({ data }) => {
                    this.$notify(
                        {
                            group: "success",
                            title: "Success",
                            text: "Você receberá a planilha por email.",
                        },
                        4000,
                    );
                    this.loading_export = false;
                })
                .catch((error) => {
                    this.loading_export = false;
                    if (error.response) {
                        return this.$toast.error(error.response.data.message);
                    }
                });
        },
        selectAll() {
            this.all_selected = !this.all_selected;
            if (this.all_selected) {
                axios
                    .get("/api/order/select-all", {
                        params: this.filters,
                    })
                    .then(({ data }) => {
                        this.selected = data;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.selected = [];
            }
        },
        clearFilters() {
            this.selected = [];
            this.all_selected = false;
            this.filters = {
                text: "",
                status: [],
                payment_method: [],
            };
            this.next_page_url = false;
            this.orders = [];
            this.fetch();
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;

            axios
                .get("/api/order/search", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.storeorder = false;
                    this.orders = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data.meta,
                    };

                    console.log({ data });

                    this.next_page_url = data.meta.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$toast.error(error.response.data.message);
                });
        },
        manage(order) {},
        manageUser(user) {
            this.user = user;
            this.$router.push({
                name: "user.commissions",
                params: { user_id: user.id },
            });
        },
    },
};
</script>
