<template>
    <modal class="min-h-full" size="4xl" :open="modal" @close="$emit('close')">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        >
            <h1
                class="flex flex-row flex-wrap content-center items-center text-sm font-bold uppercase text-white"
            >
                <img
                    class="mr-2 h-8 w-8 rounded-full"
                    :src="
                        'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                        manager.name
                    "
                    alt=""
                />
                Usuários {{ manager.name }}
                <submit-button
                    class="ml-auto"
                    size="sm"
                    @click="create = !create"
                    >Adicionar</submit-button
                >
            </h1>

            <users-select v-if="create" v-model="selectedUsers" />

            <div class="space-y-2 divide-y divide-slate-400 pt-1">
                <div v-for="user in users" class="flex items-center py-2">
                    <img
                        class="mr-2 h-8 w-8 rounded-full"
                        :src="
                            user.avatar ||
                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                manager.name
                        "
                    />
                    {{ user.name }} {{ user.email }}
                    <button class="ml-auto" @click="remove(user)">
                        <x-circle-icon
                            class="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
            <submit-button
                v-if="!loading"
                :loading="submitLoading"
                class="ml-auto"
                @click.prevent="submit"
            >
                Salvar
            </submit-button>
        </div>
        <ticto-loading v-if="loading" />
        <empty-state class="mt-4" v-if="!loading && !users.length" />
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";
import UsersSelect from "../../forms/UsersSelect.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import EmptyState from "../../layouts/EmptyState.vue";
import TictoLoading from "../../layouts/TictoLoading.vue";
import { useToast } from "vue-toastification";

export default {
    components: {
        TictoLoading,
        EmptyState,
        SubmitButton,
        UsersSelect,
        CheckCircleIcon,
        XCircleIcon,
        Modal,
    },
    props: {
        manager: { type: Object },
        modal: { type: Boolean, default: false },
    },
    emits: ["close", "fetch"],

    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            create: false,
            loading: true,
            users: [],
            selectedUsers: [],
            submitLoading: false,
        };
    },

    watch: {
        modal() {
            if (this.modal) {
                this.users = [];
                this.fetch();
            }
        },

        selectedUsers() {
            this.selectedUsers.map((userId) => {
                // if userId does not exist in users
                if (!this.users.find((u) => u.id === userId)) {
                    axios.get("/api/user/" + userId).then(({ data }) => {
                        this.users.push(data);
                    });
                }
            });
        },
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/account-manager/" + this.manager.uuid + "/users")
                .then(({ data }) => {
                    this.users = data;
                    this.loading = false;
                });
        },

        submit() {
            this.submitLoading = true;

            axios
                .put("/api/account-manager/" + this.manager.uuid + "/users", {
                    userIds: this.users.map((u) => u.id),
                })
                .then(({ data }) => {
                    this.submitLoading = false;
                    this.users = [];
                    this.fetch();
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.toast.error(error?.response?.data?.message);
                    this.submitLoading = false;
                });
        },
        remove(user) {
            this.users = this.users.filter((u) => u.id !== user.id);
        },
    },
};
</script>
