<template>
    <div
        class="mb-3 overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="flex justify-between">
                <h3 class="mt-1 text-lg font-medium leading-6 dark:text-white">
                    Coprodutores
                </h3>
            </div>
            <div class="py-4">
                <div v-if="coproducers.length">
                    <table-component
                        :loading="loading"
                        :headers="['Nome', 'E-mail']"
                        :pagination="false"
                        @fetch="fetch"
                    >
                        <tr
                            v-for="coproducer in coproducers"
                            :key="coproducer.id"
                        >
                            <td class="table-cell-sm">
                                <span v-tooltip="coproducer.name" class="block">
                                    {{ coproducer.name }}
                                </span>
                            </td>
                            <td class="table-cell-sm">
                                <span
                                    v-tooltip="coproducer.email"
                                    class="block"
                                >
                                    {{ coproducer.email }}
                                </span>
                            </td>
                        </tr>
                    </table-component>
                </div>

                <p v-else class="text-red-500">Não possui</p>
            </div>
        </div>
    </div>
    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="flex justify-between">
                <h3 class="mt-1 text-lg font-medium leading-6 dark:text-white">
                    Afiliados
                </h3>
            </div>
            <div class="py-4">
                <div v-if="affiliates.length">
                    <table-component
                        :loading="loading"
                        :headers="['Nome', 'E-mail']"
                        :pagination="false"
                        @fetch="fetch"
                    >
                        <tr v-for="affiliate in affiliates" :key="affiliate.id">
                            <td class="table-cell-sm">
                                <span v-tooltip="affiliate.name" class="block">
                                    {{ affiliate.name }}
                                </span>
                            </td>
                            <td class="table-cell-sm">
                                <span v-tooltip="affiliate.email" class="block">
                                    {{ affiliate.email }}
                                </span>
                            </td>
                        </tr>
                    </table-component>
                </div>

                <p v-else class="text-red-500">Não possui</p>
            </div>
        </div>
    </div>
</template>

<script>
import TableComponent from "../../components/layouts/table/TableComponent.vue";

export default {
    components: {
        TableComponent,
    },

    props: ["user"],

    data() {
        return {
            loading: true,
            coproducers: [],
            affiliates: [],
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get(`/api/user/${this.user.id}/coproducers`)
                .then(({ data }) => {
                    this.coproducers = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
            axios
                .get(`/api/user/${this.user.id}/affiliates`)
                .then(({ data }) => {
                    this.affiliates = data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
