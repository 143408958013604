<template>
    <Menu as="div" class="relative ml-3">
        <div>
            <MenuButton
                class="flex max-w-xs items-center rounded-full bg-gray-800 text-sm text-xs focus:outline-none"
                v-slot="{ open }"
            >
                <span class="sr-only">Open user menu</span>
                <button class="btn-primary-sm">
                    <slot name="button-content">Ações</slot>
                    <ChevronDownIcon
                        class="ml-1 w-4 transition-all ease-in-out"
                        :class="{ 'rotate-180': open }"
                        aria-hidden="true"
                    />
                </button>
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <slot name="menu-items"></slot>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

export default {
    components: {
        Menu,
        MenuButton,
        MenuItems,
        ChevronDownIcon,
    },
};
</script>
