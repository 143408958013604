<template>
    <transition-root as="template" :show="true">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-10 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block transform overflow-hidden rounded-lg bg-gray-700 px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
                    >
                        <form @submit.prevent="impersonateUser">
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mt-3 text-center sm:mt-0 sm:text-left"
                                >
                                    <dialog-title
                                        as="h3"
                                        class="mb-0 text-lg font-medium leading-6 text-white"
                                    >
                                        Impersonate
                                    </dialog-title>
                                </div>
                            </div>
                            <div class="flex justify-center" v-if="!codeSent">
                                <ticto-loading class="mx-auto"></ticto-loading>
                            </div>
                            <div class="my-3 flex flex-col" v-if="impersonated">
                                <h4
                                    class="flex items-center text-lg text-green-400"
                                >
                                    <CheckCircleIcon
                                        class="mr-2 h-10 w-10"
                                        aria-hidden="true"
                                    ></CheckCircleIcon>
                                    <span>Código validado com sucesso! </span>
                                </h4>
                                <p class="my-4 text-sm text-white">
                                    Faça o login na dashboard e acesse o time do
                                    usuário selecionado.
                                </p>
                                <p class="mb-3 text-sm text-orange-500">
                                    O acesso é válido por 15 minutos.
                                </p>
                                <a
                                    target="_blank"
                                    class="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    href="https://dash.ticto.com.br"
                                >
                                    Ir para a dashboard
                                    <ArrowTopRightOnSquareIcon
                                        class="ml-2 h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </a>
                            </div>
                            <form
                                class="mt-5 flex-col sm:mt-4 sm:flex"
                                @submit.prevent="impersonateUser"
                                v-if="codeSent && !impersonated"
                            >
                                <p class="text-md text-white">
                                    Você vai ser adicionado na central de
                                    colaboradores. Insira o código enviado em
                                    seu e-mail ou aplicativo abaixo:
                                </p>
                                <p v-for="error in errors" class="text-red-500">
                                    {{ error[0] }}
                                </p>
                                <div class="my-3">
                                    <label
                                        for="code"
                                        class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                                        >Código:</label
                                    >
                                    <input
                                        v-model="form.code"
                                        type="text"
                                        name="code"
                                        id="code"
                                        class="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    :disabled="loading"
                                    class="mb-3 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none disabled:cursor-wait sm:w-auto sm:text-sm"
                                >
                                    <svg
                                        v-if="loading"
                                        class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Confirmar
                                </button>

                                <button
                                    type="button"
                                    class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </form>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { mapWritableState } from "pinia";
import { Store } from "../../../stores/store";
import TictoLoading from "../../../components/layouts/TictoLoading.vue";
import {
    ArrowTopRightOnSquareIcon,
    CheckCircleIcon,
} from "@heroicons/vue/24/outline";

export default {
    components: {
        ArrowTopRightOnSquareIcon,
        TictoLoading,
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        CheckCircleIcon,
    },
    data() {
        return {
            errors: [],
            reason: "",
            impersonated: false,
            loading: false,
            codeSent: false,
            form: {
                code: "",
            },
        };
    },
    computed: {
        ...mapWritableState(Store, ["user"]),
    },
    mounted() {
        this.sendCode();
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        sendCode() {
            axios
                .post("/api/user/" + this.user.id + "/impersonate/code")
                .then(({ data }) => {
                    this.codeSent = true;
                });
        },
        impersonateUser() {
            this.loading = true;
            this.errors = [];
            axios
                .post("/api/user/" + this.user.id + "/impersonate", {
                    code: this.form.code,
                })
                .then(({ data }) => {
                    this.impersonated = true;
                })
                .catch(({ response }) => {
                    this.errors = response.data.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
