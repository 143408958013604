<template>
    <div></div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import MultipleSelect from "./MultipleSelect.vue";
    export default {
        props: ['placeholder','options'],
        components: {MultipleSelect, Multiselect},
        data() {
            return {
                value: [],
                model: []
            }
        },

        watch: {
            model(value) {
                this.$emit('input', value);
            }
        },

        methods: {
            open() {
                this.$refs.multiselect.open();
            }
        }
    }
</script>
