<template>
    <Listbox
        as="div"
        class="min-w-full"
        v-model="selected"
        multiple
    >
        <ListboxLabel v-if="label" class="block text-sm font-medium text-gray-700 dark:text-white mb-1">{{label}}</ListboxLabel>
        <div class="relative min-w-full">
            <ListboxButton v-slot="{ open }" class="dark:bg-gray-900 dark:text-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <span class="block truncate dark:text-white">{{ selected.length > 0 ? selectedOptions : placeholder }}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon :class="{'rotate-180': open}" class="transition-all h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="option in options" :key="option.value" :value="option.value" v-slot="{ active, selected }">
                        <li :class="[active || selected ? 'text-white bg-indigo-600' : 'text-gray-900', 'hover:cursor-pointer cursor-default select-none relative py-2 pl-3 pr-9']">
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ option.label }}
                          </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
    import {ChevronDownIcon} from '@heroicons/vue/24/outline'
    import {Listbox, ListboxButton, ListboxOptions, ListboxOption, ListboxLabel,} from '@headlessui/vue';

    export default {
        components: {ListboxLabel, Listbox, ListboxButton, ListboxOptions, ListboxOption, ChevronDownIcon},
        props: {
            label: {type: String, required: false},
            placeholder: {type: String, required: false},
            options: {type: Array, required: true},
            modelValue: {required: true}
        },

        data() {
            return {
                selected: this.modelValue,
            }
        },

        computed: {
            selectedOptions() {
                return this.selected.map(value => {
                    const option = this.options.find(option => option.value === value);
                    return option ? option.label : '';
                }).join(', ');
            }
        },

        watch: {
            selected(value) {
                this.$emit('update:modelValue', value);
            },
            modelValue(value) {
                this.selected = value;
            }
        },
    }
</script>
