<template>
    <div
        class="flex items-center justify-center bg-transparent px-4 py-12 sm:px-6 lg:px-8"
    >
        <div class="w-full max-w-md space-y-8">
            <form
                @submit.prevent="submit"
                class="mt-8 space-y-6"
                action="#"
                method="POST"
            >
                <div class="-space-y-px rounded-md shadow-sm">
                    <div>
                        <label for="card-number" class="sr-only"
                            >Número do Cartão</label
                        >
                        <input
                            id="card-number"
                            name="card-number"
                            type="text"
                            v-model="cardNumber"
                            required
                            class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholder="Número do Cartão"
                        />
                    </div>
                    <div>
                        <label for="card-name" class="sr-only"
                            >Nome do Titular</label
                        >
                        <input
                            id="card-name"
                            name="card-name"
                            type="text"
                            v-model="cardName"
                            required
                            class="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholder="Nome do Titular"
                        />
                    </div>
                    <div class="flex">
                        <div class="w-1/2">
                            <label for="expiry-date" class="sr-only"
                                >Data de Validade</label
                            >
                            <input
                                v-maska
                                data-maska="##/####"
                                id="expiry-date"
                                name="expiry-date"
                                type="text"
                                v-model="expiryDate"
                                required
                                class="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="MM/AA"
                            />
                        </div>
                        <div class="w-1/2">
                            <label for="cvc" class="sr-only">CVC</label>
                            <input
                                id="cvc"
                                name="cvc"
                                type="text"
                                v-model="cvc"
                                required
                                class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                placeholder="CVC"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { vMaska } from "maska";
export default {
    directives: { maska: vMaska },
    data() {
        return {
            cardNumber: "",
            cardName: "",
            expiryDate: "",
            cvc: "",
        };
    },
    methods: {
        async submit() {
            const splitExpiryDate = this.expiryDate.split("/");
            const encrypted = await PagSeguro.encryptCard({
                publicKey:
                    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB",
                holder: this.cardName,
                number: this.cardNumber,
                expMonth: splitExpiryDate[0],
                expYear: splitExpiryDate[1],
                securityCode: this.cvc,
            });

            setTimeout(() => {
                axios
                    .post("/api/credit-card", {
                        encrypted: encrypted.encryptedCard,
                    })
                    .then(({ data }) => {});
            }, 200);
        },
    },
};
</script>
