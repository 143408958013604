<template>
    <div class="mt-5">
        <div class="grid grid-cols-5 gap-4">
            <div>
                <navigation
                    :navigation="[
                        { name: 'Usuários', route: {name:'analytics.compliance.users'}, icon: UsersIcon },
                        { name: 'Produtos', route: {name:'analytics.compliance.products'}, icon: CubeIcon },
                    ]"
                />
            </div>
            <div class="col-span-4">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import Navigation from "../../../components/layouts/navigation/vertical/Navigation.vue";
    import { UsersIcon, CubeIcon} from "@heroicons/vue/24/outline";

    export default {
        methods: { UsersIcon, CubeIcon},
        components: {Navigation},

        mounted() {
            if (this.$route.name === 'analytics.compliance') {
                this.$router.push({ name: 'analytics.compliance.users' })
            }
        }
    }
</script>
