<template>
    <div v-tooltip="os">
        <font-awesome-icon
            :icon="browserIcon"
            class="h-5 w-5 text-gray-200 dark:text-gray-200"
            aria-hidden="true"
        />
    </div>
</template>

<script>
export default {
    props: { os: { required: true } },

    computed: {
        browserIcon() {
            if (!this.os) {
                return "fa-brands fa-chrome";
            }

            // check if browser string contains safari
            if (
                ["iOS", "iPhone", "Mac", "Macintosh", "OS X"].includes(this.os)
            ) {
                return "fa-brands fa-apple";
            }

            if (this.os.includes("Android")) {
                return "fa-brands fa-android";
            }

            if (this.os.includes("Windows")) {
                return "fa-brands fa-windows";
            }

            return "fa-brands fa-chrome";
        },
    },
};
</script>
