<template>
    <div
        class="text-center text-sm font-medium text-gray-500 dark:text-gray-400"
    >
        <ul class="-mb-px flex flex-wrap">
            <li class="mr-2" v-for="tab in tabs">
                <router-link
                    :to="
                        tab.hasOwnProperty('params')
                            ? { name: tab.route, params: tab.params }
                            : { name: tab.route }
                    "
                    class="border-3 inline-block rounded-t-lg border-b-2 border-transparent p-4"
                    v-bind="activeClass"
                >
                    {{ tab.label }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        exact: {
            type: Boolean,
            default: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
    },

    computed: {
        activeClass() {
            let classString =
                "font-bold text-indigo-500 border-indigo-500 dark:border-indigo-500";
            return this.exact
                ? { "exact-active-class": classString }
                : { "active-class": classString };
        },
    },
};
</script>
