<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <div>
            <label for="title" class="block mb-2 text-sm font-medium text-white"
                >Título</label
            >
            <input
                v-model="form.title"
                type="text"
                name="title"
                id="title"
                class="text-input"
                required
            />
        </div>
        <div>
            <label
                for="message"
                class="block mb-2 text-sm font-medium text-white"
                >Mensagem</label
            >
            <div class="bg-white">
                <QuillEditor
                    id="message"
                    :toolbar="[
                        'bold',
                        'italic',
                        'underline',
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { color: [] },
                        { background: [] },
                        'image',
                    ]"
                    v-model:content="form.message"
                    contentType="html"
                    required
                />
            </div>
        </div>
        <div class="mb-2">
            <label for="type" class="block mb-2 text-sm font-medium text-white"
                >Tipo</label
            >
            <select
                v-model="form.type"
                id="type"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            >
                <option value="default">Padrão</option>
            </select>
        </div>
        <div>
            <label for="link" class="block mb-2 text-sm font-medium text-white"
                >Link (opcional)</label
            >
            <input
                v-model="form.link"
                type="text"
                name="link"
                id="link"
                class="text-input"
                placeholder="https://"
            />
        </div>
        <div>
            <label
                for="begin_at"
                class="block mb-2 text-sm font-medium text-white"
                >Exibir a partir de</label
            >
            <input
                v-model="form.begin_at"
                type="datetime-local"
                name="begin_at"
                id="begin_at"
                class="text-input"
                required
            />
        </div>
        <div>
            <label
                for="end_at"
                class="block mb-2 text-sm font-medium text-white"
                >Exibir até</label
            >
            <input
                v-model="form.end_at"
                type="datetime-local"
                name="end_at"
                id="end_at"
                class="text-input"
                required
            />
        </div>
        <form-toggle
            v-model="form.is_active"
            :loading="loading"
            label="Ativo?"
        />

        <submit-button :loading="loading" class="ml-auto">Salvar</submit-button>
    </form>
</template>

<script>
import { vMaska } from "maska";
import SubmitButton from "../forms/SubmitButton.vue";
import FormToggle from "../forms/FormToggle.vue";
import { useToast } from "vue-toastification";
import { QuillEditor } from "@vueup/vue-quill";

export default {
    props: { notice: { type: Object, default: null } },
    directives: { maska: vMaska },
    components: { SubmitButton, FormToggle, QuillEditor },
    emits: ["fetch"],
    data() {
        return {
            show: false,
            loading: false,
            form: {
                title: "",
                message: "",
                icon: "",
                type: "default",
                link: "",
                is_active: true,
                begin_at: "",
                end_at: "",
            },
        };
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    mounted() {
        if (this.notice) {
            this.form = {
                title: this.notice.title,
                message: this.notice.message,
                icon: this.notice.icon,
                type: this.notice.type,
                link: this.notice.link,
                is_active: this.notice.is_active,
                begin_at: this.notice.non_formated_begin_at,
                end_at: this.notice.non_formated_end_at,
            };
        }
    },

    methods: {
        async submit() {
            if (this.form.message == '<p><br></p>' || !this.form.message) {
                this.form.message = '';
                this.toast.error("O campo Mensagem deve ser preenchido.");
            }

            this.loading = true;

            let promise = this.notice
                ? axios.put(`/api/notices/${this.notice.id}`, this.form)
                : axios.post("/api/notices", this.form);

            promise
                .then((response) => {
                    this.loading = false;
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
