<template>
    <div class="w-full">
        <h3 class="text-lg leading-6 font-medium text-gray-400 mb-3">Agenda</h3>
        <highcharts class="hc" :options="chartObject" ref="chart"></highcharts>
    </div>
</template>

<script>
export default {
    data() {
        return {
            schedule: [],

            chartObject: {
                tooltip: {
                    formatter: function() {
                        let chart = this;

                        let date = new Date(chart.x);
                        let formattedDate = date.toLocaleDateString("pt-BR", {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        });

                        return `<b>${formattedDate}</b><br />${chart.points
                            .sort((pointA, pointB) => pointB.y - pointA.y)
                            .map((point) => {
                                const currency = (point.y).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                });
                                return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ${currency}`;
                            })
                            .join("<br />")}`;
                    },
                    xDateFormat: '%d/%m/%Y',
                    shared: true,
                    split: false,
                    enabled: true
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: true
                },

                title: {
                    align: 'left',
                    text: null
                },

                labels: {
                    format: '{value:%l:%M %p }'
                },

                xAxis: {
                    type: 'datetime',
                },

                yAxis: {
                    title: null,
                },

                series: []
            }
        };
    },

    watch: {
        date() {
            this.chartObject.series = [];
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            axios.get('/api/financial/payable/schedule').then(({data}) => {
                this.schedule = data;

                let chartData = [];
                this.schedule.map((day) => {
                    chartData.push([day.unix_date, parseFloat(day.amount)]);
                });

                this.chartObject.series.push({
                    name: 'Saldo',
                    lineWidth: 3,
                    data: chartData
                });
            });
        }
    }
};
</script>
