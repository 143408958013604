<template>
    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-4 mb-3">
        <card
            v-if="loading"
            v-for="n in 4"
            :loading="true"
        />
        <stat-card
            v-else
            v-for="stat in cards"
            :loading="loading"
            :name="stat.name"
            :stat="stat.stat"
            :previous="stat.previous"
        />
    </dl>
</template>

<script>
    import StatCard from "../../layouts/StatCard.vue";
    import Card from "../../layouts/Card.vue";

    export default {
        components: {Card, StatCard},
        props: ['loading', 'signups', 'previousSignups'],

        data() {
            return {
                cards: [],
            };
        },

        watch: {
            loading() {
                if (!this.loading) {
                    this.fetch();
                }
            }
        },

        methods: {
            fetch() {
                this.cards = [];
                let totals = {
                    signups: {
                        name: 'Cadastros',
                        stat: 0,
                        previous: 0
                    },
                    waiting: {
                        name: 'Incompletos',
                        stat: 0,
                        previous: 0
                    },
                    pending: {
                        name: 'Pendentes',
                        stat: 0,
                        previous: 0
                    },
                    approved: {
                        name: 'Aprovados',
                        stat: 0,
                        previous: 0
                    },
                    reproved: {
                        name: 'Reprovados',
                        stat: 0,
                        previous: 0
                    },
                    pendingCorrection: {
                        name: 'Correção Pendente',
                        stat: 0,
                        previous: 0
                    }
                };

                Object.entries(this.signups).forEach(([key, value]) => {
                    totals.signups.stat += value['signups'];
                    totals.waiting.stat += value['onboarding-waiting'];
                    totals.approved.stat += value['onboarding-approved'];
                    totals.approved.stat += value['onboarding-reproved'];
                    totals.pendingCorrection.stat += value['onboarding-pending-correction'];
                });

                Object.entries(this.previousSignups).forEach(([key, value]) => {
                    totals.signups.previous += value['signups'];
                    totals.waiting.previous += value['onboarding-waiting'];
                    totals.approved.previous += value['onboarding-approved'];
                    totals.approved.previous += value['onboarding-reproved'];
                    totals.pendingCorrection.previous += value['onboarding-pending-correction'];
                })

                this.cards.push(totals.signups, totals.approved, totals.waiting, totals.pendingCorrection);
            }
        }
    }
</script>
