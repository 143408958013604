<template>
    <div>
        <label
            v-if="label"
            class="block text-sm font-medium text-gray-700 dark:text-white"
            :for="label"
        >
            {{ label }}
        </label>
        <input
            class="text-input-sm"
            :value="modelValue"
            :type="type"
            :placeholder="placeholder"
            :maxlength="maxlength"
            @input="$emit('update:modelValue', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        maxlength: {
            type: Number,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
    },
    emits: ["update:modelValue"],
};
</script>
