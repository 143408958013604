<template>
    <card :loading="loading" size="sm">
        <p
            class="align-center mb-2 flex text-sm font-medium text-gray-600 dark:text-gray-400"
        >
            {{ metric.name }}
            <span class="ml-auto"> i </span>
        </p>
        <p class="text-white-100 flex text-lg font-semibold dark:text-gray-200">
            TPN: {{ metric.tpn }}
        </p>
        <p class="text-white-100 flex text-lg font-semibold dark:text-gray-200">
            TPV: {{ metric.tpv }}
        </p>
    </card>
</template>

<script>
import Card from "../../layouts/Card.vue";

export default {
    components: { Card },
    props: ["metric", "loading"],
};
</script>
