<template>
    <div class="flex flex-col">
        <template v-if="loading">
            <span class="text-gray-400 dark:text-gray-400 font-bold text-sm">{{ title }}</span>
            <div class="animate-pulse h-2 bg-gray-500 rounded mt-3"></div>
        </template>
        <template v-else>
            <span class="text-gray-400 dark:text-gray-400 font-bold text-sm">{{ title }}</span>
            <span class="text-gray-400 dark:text-gray-200"><slot/></span>
        </template>
    </div>
</template>

<script>
    export default {
        props: {title: {type: String}, loading: {type: Boolean, default: false}}
    }
</script>
