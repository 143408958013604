<template>
    <modal
        class="min-h-full"
        size="4xl"
        :open="!!resource || show"
        @close="show = false;$emit('resetResource')"
    >
        <display-modifications-modal
            :properties="propertiesToShow"
            :storeProduct="storeProduct"
            @resetProperties="propertiesToShow = null;"
        />
        <div class="flex flex-col flex-wrap w-full space-y-3 divide-y dark:text-white divide-slate-500 min-h-fit">
            <h1 class="flex flex-row flex-wrap items-center content-center pb-1 mb-2 text-sm text-lg font-bold text-white border-b border-slate-500">
                Registros de Atividade - {{ resource.name }}
            </h1>
            <table-component
                :loading="loading"
                :pagination="pagination"
                :headers="headers"
                @fetch="fetch"
                :selected="selected"
                :all-selected="all_selected"
            >
                <tr v-for="activity in activities" :key="activity.id">
                    <td class="table-cell-sm">
                        <span
                            :title="activity.causer_name"
                            class="block max-w-[80px] truncate"
                            v-tooltip="activity.causer_name"
                        >
                            {{ activity.causer_name }}
                        </span>
                    </td>
                    <td class="table-cell-sm">
                        <span
                            :title="activity.causer_email"
                            class="block max-w-[120px] truncate"
                        >
                            {{ activity.causer_email }}
                        </span>
                    </td>
                    <td class="table-cell-sm">
                        <span
                            :title="activity.log_name"
                            class="block max-w-[80px]"
                        >
                            {{ eventType(activity.log_name) }}
                        </span>
                    </td>
                    <td class="table-cell-sm">
                        <span
                            :title="activity.created_at"
                            class="block max-w-[80px]"
                        >
                            {{ activity.created_at }}
                        </span>
                    </td>
                    <td>
                        <div>
                            <button
                                v-if="activity.log_name === 'created' || activity.log_name === 'updated'"
                                @click.prevent="showProperties(activity.properties)"
                                type="button"
                                class="inline-flex items-center justify-center px-2 py-1 ml-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow hover:bg-indigo-400"
                            >
                                <EyeIcon
                                    class="w-5 h-5 text-white"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </td>
                </tr>
            </table-component>
        </div>
    </modal>
</template>

<script>
    import Modal from "../../../components/layouts/Modal.vue";
    import TableComponent from "../../../components/layouts/table/TableComponent.vue";
    import DisplayModificationsModal from "./DisplayModificationsModal.vue";
    import {
        EyeIcon
    } from "@heroicons/vue/24/outline";


    export default {
        props: {resource: {type: Object, default: null}, storeProduct: {type: Object, default: null}, type: {type: String, default: null}},
        components: {Modal, EyeIcon, DisplayModificationsModal, TableComponent},
        emits: ['fetch'],
        data() {
            return {
                show: false,
                loading: false,
                activities: [],
                propertiesToShow: null,
                next_page_url: false,
                selected: [],
                all_selected: false,
                pagination: {
                    total: 0,
                    per_page: 10,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11,
                },
                filters: {
                    text: "",
                    types: [],
                    date: [],
                },
            }
        },
        computed: {
            headers() {
                return [
                    "Alterado por",
                    "Email",
                    "Evento",
                    "Última atualização",
                    "Ações",
                ];
            },
        },
        watch: {
            resource() {
                if (this.resource) {
                    this.fetch();
                }
            }
        },
        methods: {
            showProperties(properties) {
                this.propertiesToShow = properties;
            },
            eventType(event) {
                let eventType = {
                    "created": "Criação",
                    "updated": "Atualização",
                    "deleted": "Exclusão",
                }

                return eventType[event] ?? event;
            },
            fetch() {
                let params = this.filters;
                params["page"] = this.pagination.current_page;
                this.loading = true;

                axios.get('/api/product/' + this.storeProduct.id + '/mozart-activities' + `?subject_id=${this.resource.id}&subject_type=${this.type}`, {
                    params: params
                }).then(({data}) => {
                    this.activities = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data.links,
                        ...data.meta,
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
            },
        }
    }
</script>
