<template>
    <div class="px-3">
        <h3
            class="my-3 flex border-b border-gray-500 pb-2 text-lg font-bold leading-6 text-white"
        >
            Avaliar Saque
            <router-link
                v-if="cashout"
                :to="{
                    name: 'finnancial.cashout-details',
                    params: { cashout_id: cashout.id },
                }"
                target="_blank"
                class="ml-2 flex text-indigo-400 hover:cursor-pointer hover:text-indigo-600"
            >
                #{{ cashout.id }}
                <ArrowTopRightOnSquareIcon class="h-5 w-5" aria-hidden="true" />
            </router-link>
            <router-link
                v-if="cashout"
                :to="{
                    name: 'user.audit.commissions',
                    params: { user_id: cashout.user_id },
                }"
                target="_blank"
                class="ml-2 flex text-indigo-400 hover:cursor-pointer hover:text-indigo-600"
            >
                #USER
                <ArrowTopRightOnSquareIcon class="h-5 w-5" aria-hidden="true" />
            </router-link>
            <span class="ml-1">
                <cashout-status :status="cashout.status" />
            </span>
        </h3>

        <div class="my-3">
            <cashout-tags :cashout="cashout" />
        </div>

        <div class="mb-3 grid grid-cols-3 gap-1" v-if="cashout">
            <info-col title="VALOR SOLICITADO">
                {{ $root.formatCurrency(cashout.amount) }}
            </info-col>
            <info-col title="TAXA APLICADA">
                {{ $root.formatCurrency(cashout.fee) }}
            </info-col>
            <info-col title="VALOR A RECEBER">
                {{ $root.formatCurrency(cashout.net_value) }}
            </info-col>
        </div>

        <p class="font-md text-gray-400">Saldo</p>
        <div class="mb-3 grid grid-cols-2 gap-1" v-if="cashout && form.balance">
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Disponível
                </p>
                <p
                    class="text-white-100 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(form.balance.available) }}
                </p>
            </card>
            <card :loading="loading" size="sm">
                <p
                    class="mb-1 text-xs font-medium text-gray-600 dark:text-gray-400"
                >
                    Pendente
                </p>
                <p
                    class="text-white-100 mb-0 text-sm font-semibold dark:text-gray-200"
                >
                    {{ $root.formatCurrency(form.balance.pending) }}
                </p>
            </card>
        </div>

        <p class="font-md text-gray-400">Comissões</p>
        <cashout-payables :cashout="cashout" products />

        <form @submit.prevent="submit">
            <cards-radio-group
                v-model="form.status"
                :options="[
                    { label: 'Aprovado', value: 'approved' },
                    { label: 'Atenção', value: 'warning' },
                    { label: 'Reprovado', value: 'rejected' },
                ]"
            />

            <label
                for="content"
                class="mb-2 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-400"
            >
                Descrição
            </label>
            <textarea
                v-model="form.content"
                id="content"
                rows="4"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            ></textarea>
            <submit-button class="mt-4 w-full" :loading="loading">
                Enviar
            </submit-button>
        </form>
    </div>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import CardsRadioGroup from "../../forms/CardsRadioGroup.vue";
import { Store } from "../../../stores/store.js";
import { mapWritableState } from "pinia";
import InfoCol from "../../layouts/InfoCol.vue";
import Card from "../../layouts/Card.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import CommissionCards from "./CommissionCards.vue";
import CashoutPayables from "../../../views/finnancial/cashout/CashoutPayables.vue";
import CashoutTags from "../CashoutTags.vue";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/outline";
import CashoutStatus from "../../layouts/CashoutStatus.vue";
export default {
    components: {
        CashoutStatus,
        ArrowTopRightOnSquareIcon,
        CashoutTags,
        CashoutPayables,
        CommissionCards,
        SubmitButton,
        Card,
        InfoCol,
        CardsRadioGroup,
        Modal,
    },
    props: {
        cashout: { required: true },
        review: { default: false },
    },
    emits: ["close", "fetch"],

    data() {
        return {
            loading: false,
            form: {
                status: "approved",
                content: "",
                cashout_id: this.cashout.id,
                user_id: "",
                balance: {
                    available: 0,
                    pending: 0,
                },
                commissions: {},
            },
        };
    },

    watch: {
        cashout() {
            if (this.cashout) {
                this.form.cashout_id = this.cashout.id;
            }
        },
    },

    computed: {
        ...mapWritableState(Store, ["authUser"]),
    },

    mounted() {
        this.form.user_id = this.cashout.user_id;
        this.form = this.review;
        if (this.form.status === "pending") {
            this.form.status = "approved";
        }
    },

    methods: {
        submit() {
            this.loading = true;
            axios
                .put(
                    "/api/cashout/" +
                        this.cashout.id +
                        "/review/" +
                        this.review.id,
                    this.form,
                )
                .then(({ data }) => {
                    this.loading = false;
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        fetchBalance() {
            this.loading = true;
            axios
                .get("/api/cashout/" + this.cashout.id + "/balance")
                .then(({ data }) => {
                    this.form.balance = {
                        available: data.event_properties.available_balance,
                        pending: data.event_properties.pending_balance,
                    };
                })
                .finally(() => (this.loading = false));
        },
        updateCommissions(event) {
            this.form.commissions = event.commissions;
        },
    },
};
</script>
