<template>
    <h3 class="text-lg font-bold leading-6 font-medium dark:text-white mb-3">
        Usuários
    </h3>

    <user-totals v-if="!loading"/>

    <div class="flex content-center items-center mb-3">
        <div class="grow">
            <h3 class="text-lg font-bold leading-6 font-medium dark:text-white">
                Dados Financeiros
            </h3>
        </div>
        <div class="w-[230px] max-w-[250px] ml-auto">
            <litepie-datepicker
                class="max-w-[300px]"
                v-model="filters.date"
                :formatter="{
                  date: 'DD/MM/YYYY',
                  month: 'MMM'
                }"
                :shortcuts="false"
            ></litepie-datepicker>
        </div>
    </div>

    <ticto-loading v-if="loading"/>
    <register-chart :dates="filters.date" class="mt-4"/>
</template>
<script>
    import RegisterChart from "../RegisterChart.vue";
    import TictoLoading from "../../../../components/layouts/TictoLoading.vue";
    import UserTotals from "../../../../components/analytics/users/UserTotals.vue";
    export default {
        components: {UserTotals, RegisterChart, TictoLoading},
        data() {
            return {
                loading: false,
                filters: {
                    date: [
                        moment().subtract(1, 'month').format('DD/MM/YYYY'),
                        moment().format('DD/MM/YYYY')
                    ]
                },
            }
        }
    }
</script>
