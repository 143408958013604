<template>
    <div class="min-w-0 rounded-lg drop-shadow-sm overflow-hidden bg-white dark:bg-gray-800">
        <div
            v-if="loading"
            class="animate-pulse flex"
            :class="{
               'p-4': size === 'md',
               'p-2': size === 'sm',
            }"
        >
            <div class="flex-1 space-y-2 py-1 flex flex-col">
                <div class="h-2 bg-gray-500 rounded"></div>
                <div class="h-2 bg-gray-500 rounded w-1/2"></div>
            </div>
        </div>
        <div v-else
             class="flex items-center"
             :class="{
                'p-4': size === 'md',
                'p-2': size === 'sm',
             }"
        >
            <div class="w-full">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {type: Boolean, default: false},
            size: {type: String, default: 'md'},
        }
    }
</script>
