<template>
    <div v-if="show">
        <submit-button
            :loading="loading"
            @click.prevent="submit"
            class="mt-3 w-full"
        >
            {{
                audit.fraud_confirmed ? "CONFIRMAR FRAUDE" : "FRAUDE CONFIRMADA"
            }}
        </submit-button>
    </div>
</template>

<script>
import SubmitButton from "../forms/SubmitButton.vue";
import permissionMixin from "../../mixins/PermissionMixin.vue";
export default {
    components: { SubmitButton },
    props: ["audit"],
    mixins: [permissionMixin],
    data() {
        return {
            loading: false,
        };
    },

    emits: ["fetch"],
    computed: {
        show() {
            return (
                this.audit &&
                this.audit.status === "open" &&
                this.hasPermission("confirm-fraud.audits")
            );
        },
    },

    methods: {
        submit() {
            this.loading = true;
            return axios
                .post("/api/audit/" + this.audit.id + "/confirm-fraud")
                .then(({ data }) => {
                    this.$emit("fetch");
                    this.loading = false;
                });
        },
    },
};
</script>
