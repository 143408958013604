<template>
    <transactions v-if="order" :order="order" @updated="fetch"/>

    <div class="grid grid-cols-3 gap-5">
        <div class="col-span-2" v-if="order">
            <order-details :order="order"/>
            <subscription-details :subscription="order.subscription" v-if="order.subscription" @updated="fetch"/>
            <order-customer :order="order"/>
            <order-items :order="order"/>
            <order-commissions :order="order"/>
            <order-refund-history :refundHistories="order.refund_history" :order="order"/>
            <order-activity :order="order"/>
        </div>
        <div>
            <payment-method-card :loading="loading" :order="order" :transaction_index="0" />
            <payment-method-card :loading="loading" :order="order" :transaction_index="1" v-if="order.is_two_cards"/>
            <order-history :order="order"></order-history>
        </div>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon, ClockIcon } from "@heroicons/vue/24/outline";
import Card from "../../components/layouts/Card.vue";
import Status from "../../components/layouts/Status.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import Logs from "./Logs.vue";
import OrderActivity from "./OrderActivity.vue";
import OrderCommissions from "./OrderCommissions.vue";
import OrderCustomer from "./OrderCustomer.vue";
import OrderDetails from "./OrderDetails.vue";
import OrderHistory from "./OrderHistory.vue";
import OrderItems from "./OrderItems.vue";
import OrderLog from "./OrderLog.vue";
import OrderRefundHistory from "./OrderRefundHistory.vue";
import PaymentMethodCard from "./PaymentMethodCard.vue";
import SubscriptionDetails from "./SubscriptionDetails.vue";
import Transactions from "./Transactions.vue";

export default {
    components: {
        OrderRefundHistory,
        OrderCommissions,
        Transactions,
        Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
        OrderLog, OrderItems, OrderDetails, OrderCustomer, OrderHistory, Card, Status,
        PaymentMethodCard, Logs, OrderActivity, SubscriptionDetails, ClockIcon, ChevronDownIcon
    },
    mixins: [HelperMixin],
    data() {
        return {
            loading: true,
            order: false,
            tabs: [
                { name: "customer", label: "Cliente", route: "order.customer" },
                {
                    name: "items",
                    label: "Itens do Pedido",
                    route: "order.items",
                },
                { name: "logs", label: "Logs", route: "order.logs" },
                {
                    name: "activity",
                    label: "Atividade",
                    route: "order.activity",
                },
                { name: "history", label: "Histórico", route: "order.history" },
            ],
        };
    },

    computed: {
        paymentTypeName() {
            if (this.order && this.order.transactions) {
                return this.order.transactions[0].payment_method;
            }
        },
        netValue() {
            if (
                this.order &&
                this.order.paid_amount &&
                this.order.total_outputs
            ) {
                return this.order.paid_amount - this.order.total_outputs;
            }
            return 0;
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/order/" + this.$route.params.order_id)
                .then(({ data }) => {
                    this.order = data;
                    this.loading = false;
                });
        },
        cardsDataClass() {
            if (this.order.is_two_cards) {
                return "grid gap-6 mb-5 md:grid-cols-4 xl:grid-cols-4";
            } else {
                return "grid gap-6 mb-5 md:grid-cols-3 xl:grid-cols-3";
            }
        },
    },
};
</script>
