<template>
    <div>
        <ticto-loading v-if="loading" />
        <notification-modal
            :open="add"
            @close="add = false"
            @fetch="
                add = false;
                fetch;
            "
        />
        <div class="my-5">
            <div class="flex">
                <DevicePhoneMobileIcon
                    class="mr-2 h-8 w-7 flex-shrink-0 text-gray-400"
                />
                <h3 class="mb-5 text-lg font-medium leading-9 dark:text-white">
                    Mobile
                </h3>
            </div>

            <filters
                v-model="filters"
                @fetch="
                    pagination.current_page = 1;
                    users = [];
                    next_page_url = false;
                    fetch();
                "
                :loading="loading"
                search-filter
                search-label="Filtre por nome, e-mail, cpf ou cnpj"
                class="my-5"
            >
                <template #actions>
                    <submit-button
                        v-if="hasPermission('mobile.broadcast')"
                        @click.prevent="add = true"
                        :loading="loading"
                    >
                        Broadcast
                    </submit-button>
                </template>
            </filters>

            <div class="mt-10">
                <h3 class="mb-5 text-lg font-medium leading-6 dark:text-white">
                    Dispositivos
                </h3>

                <table-component
                    :loading="loading"
                    :headers="[
                        'Usuário',
                        'Sistema',
                        'Token',
                        'Notificar',
                        'Som Atual',
                        'Criado em',
                        'Ações',
                    ]"
                    :pagination="pagination"
                    @fetch="fetch"
                >
                    <tr class="table-cell-sm" v-for="device in devices">
                        <td class="pl-3">
                            {{ device.name }}
                        </td>
                        <td class="text-center">
                            <os-icon :os="device.os" />
                        </td>
                        <td>
                            <span class="block max-w-[100px] truncate">
                                {{ device.token }}
                            </span>
                        </td>
                        <td class="text-center">
                            <CheckCircleIcon
                                v-if="device.app_notificate"
                                class="mx-auto block h-5 w-5 flex-shrink-0 text-green-400"
                                aria-hidden="true"
                            />
                            <font-awesome-icon
                                v-else
                                :icon="['fas', 'xmark']"
                                style="color: #ff0000"
                            />
                        </td>
                        <td>
                            <span
                                v-if="device.sound == 1"
                                class="flex flex-wrap"
                            >
                                Cash is King
                            </span>
                            <span
                                v-if="device.sound == 2"
                                class="flex flex-wrap"
                            >
                                Mario Bros
                            </span>
                            <span
                                v-if="device.sound == 3"
                                class="flex flex-wrap"
                            >
                                Street Figther
                            </span>
                        </td>
                        <td>
                            {{ device.created_at }}
                        </td>
                        <td>
                            <button
                                @click="openModal(device)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'envelope']"
                                />
                                Enviar Notificação
                            </button>
                        </td>
                        <td>
                            <button
                                @click.prevent="destroy(device.id)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="ml-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                            >
                                <font-awesome-icon :icon="['fas', 'trash']" />
                                Remover Dispositivo
                            </button>
                        </td>
                    </tr>
                </table-component>
            </div>
        </div>

        <modal class="min-h-full" :open="modal" @close="modal = false">
            <div
                class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
            ></div>
            <h1
                class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
            >
                Enviar Notificação para {{ device.name }}
            </h1>

            <form @submit.prevent="sendTestNotification">
                <div class="mt-5 flex-col sm:mt-4 sm:flex">
                    <div class="mb-3 grid gap-2 sm:grid-cols-1">
                        <div>
                            <label class="text-white"> Título </label>
                            <input
                                type="text"
                                id="title"
                                v-model="form.title"
                                name="title"
                                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder="Título"
                                required
                            />
                        </div>
                        <div>
                            <label class="text-white"> Descrição </label>
                            <input
                                type="text"
                                id="body"
                                v-model="form.body"
                                minlength="10"
                                name="body"
                                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder="Descrição"
                                required
                            />
                        </div>

                        <div>
                            <label class="text-white"> Link </label>
                            <input
                                v-model="form.link"
                                type="url"
                                id="link"
                                name="link"
                                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                placeholder="Link"
                            />
                        </div>

                        <button
                            type="submit"
                            class="ml-2 inline-flex items-center justify-center rounded-md bg-blue-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            Enviar Notificação
                        </button>
                    </div>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    CheckCircleIcon,
    DevicePhoneMobileIcon,
    StopCircleIcon,
} from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import Filters from "../../components/layouts/Filters.vue";
import Modal from "../../components/layouts/Modal.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import Manager from "../../components/manager/Manager.vue";
import StoreModal from "../../components/manager/modals/StoreModal.vue";
import PaginationObject from "../../mixins/PaginationObject.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import OsIcon from "../../components/layouts/antifraud/OsIcon.vue";
import NotificationModal from "./NotificationModal.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
export default {
    components: {
        SubmitButton,
        NotificationModal,
        OsIcon,
        TableComponent,
        EmptyState,
        StoreModal,
        Manager,
        VueSelect,
        UsersSelect,
        TictoLoading,
        DevicePhoneMobileIcon,
        CheckCircleIcon,
        FontAwesomeIcon,
        StopCircleIcon,
        Filters,
        Modal,
    },
    mixins: [PaginationObject, PermissionMixin],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            add: false,
            loading: false,
            modal: false,
            device: null,
            form: {
                body: null,
                title: null,
                link: null,
            },
            devices: [],
            filters: {
                text: "",
            },
            next_page_url: false,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            const params = this.filters;
            axios
                .get("/api/mobile-devices", {
                    params: {
                        ...params,
                        page: this.pagination.current_page,
                    },
                })
                .then(({ data }) => {
                    this.devices = data.data;
                    this.loading = false;
                    this.setPagination(data);
                });
        },
        openModal(device) {
            this.device = device;
            this.modal = true;
        },
        closeModal() {
            this.device = null;
            this.modal = false;
        },
        destroy(id) {
            this.loading = true;
            axios
                .delete(`/api/mobile-devices/${id}/destroy`)
                .then(({ data }) => {
                    this.toast.success("Dispositivo deletado com sucesso!");
                    this.fetch();
                    this.loading = false;
                })
                .catch(({ error }) => {
                    this.toast.error("Ocorreu um erro, tente novamente!");
                });
        },
        sendTestNotification() {
            this.loading = true;

            axios
                .post(
                    `/api/mobile-devices/${this.device.id}/send-test`,
                    this.form,
                )
                .then(({ data }) => {
                    this.toast.success(
                        "Notificação teste enviada com sucesso!",
                    );
                    this.closeModal();
                    this.fetch();
                    this.loading = false;
                })
                .catch(({ error }) => {
                    //this.toast.error("Ocorreu um erro, tente novamente!");
                });
        },
    },
};
</script>
