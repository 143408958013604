<script>
export default {
    methods: {
        getBooleanName(bool) {
            switch (bool) {
                case 1:
                    return "Sim";
                case 0:
                    return "Não";
                default:
                    return bool;
            }
        },
        getPersonTypeName(type) {
            switch (type) {
                case "person":
                    return "Física";
                case "company":
                    return "Jurídica";
                default:
                    return type;
            }
        },
        transactionStatusName(status) {
            switch (status) {
                case "aborted":
                    return "Abortado";
                case "delayed":
                    return "Atrasado";
                case "authorized":
                    return "Autorizado";
                case "chargeback":
                    return "Chargeback";
                case "disputed":
                    return "Em Disputa";
                case "closed":
                    return "Encerrado";
                case "expired":
                    return "Expirado";
                case "error":
                    return "Incompleta";
                case "waiting_payment":
                    return "Pendente";
                case "trial":
                    return "Período de Testes";
                case "processing":
                    return "Processando";
                case "claimed":
                    return "Reclamado";
                case "refused":
                    return "Recusado";
                case "refunded":
                    return "Reembolsado";
                case "refund_requested":
                    return "Reembolso Solicitado";
                case "chargeback_refunded":
                    return "Estornado por Chargeback";
                default:
                    return "Em Análise";
            }
        },
        getStatusName(status) {
            switch (status) {
                case "draft":
                    return "Rascunho";
                case "onboarding-waiting":
                    return "Aguardando Onboarding";
                case "onboarding-pending":
                    return "Onboarding Pendente";
                case "onboarding-approved":
                    return "Onboarding Aprovado";
                case "onboarding-reproved":
                    return "Onboarding Reprovado";
                case "onboarding-pending-correction":
                    return "Onboarding Pendente de Correção";
                case "approved":
                    return "Aprovado";
                case "reproved":
                    return "Reprovado";
                case "pending":
                    return "Análise Pendente";
                case "pending-correction":
                    return "Pendente de Correção";
                default:
                    return status;
            }
        },
        getStatusClass(status) {
            switch (status) {
                case "approved":
                    return "bg-green-500 text-white";
                case "reproved":
                    return "bg-red-500 text-white";
                case "pending":
                    return "bg-yellow-600 text-white";
                default:
                    return "bg-blue-500 text-white";
            }
        },
        formatCnpj(cnpj) {
            if (cnpj)
                return cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    "$1.$2.$3/$4-$5",
                );
            return "-";
        },
        formatCpf(cpf) {
            if (cpf)
                return cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3-$4",
                );

            return "-";
        },
        formatDate(date = null) {
            if (date) {
                return new Date(date).toLocaleString("pt-BR");
            }
            return "-";
        },
        humanDate(date = null) {
            if (!date) {
                return "-";
            }
            //format like 4 days ago...
            return new Date(date).toLocaleDateString("pt-BR", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        },
        formatDateWithoutTime(date = null) {
            if (date?.includes("/")) {
                return date.split(" ")[0];
            }
            if (date) {
                return new Date(date).toLocaleDateString("pt-BR");
            }
            return "-";
        },
        formatBankCode(code) {
            return code.padStart(3, 0);
        },
        formatPhone(phone) {
            if (phone) {
                return phone.replace(/(\d{4,5})(\d{4})/, "$1-$2");
            }
            return "-";
        },

        formatDocumentName(collection) {
            switch (collection) {
                case "socialContract":
                case "social_contract":
                    return "Contrato Social";
                case "cnpjCard":
                case "cnpj_card":
                    return "Cartão CNPJ";
                case "bankDocument":
                case "bank_document":
                    return "Documento Bancário";
                case "selfiePhoto":
                case "selfie_photo":
                    return "Selfie";
                case "documentFront":
                case "document_front":
                    return "Frente Documento";
                case "documentBack":
                case "document_back":
                    return "Verso Documento";
                case "emancipatedDocument":
                case "emancipated_document":
                    return "Documento Emancipação";
                default:
                    return collection;
            }
        },
    },
};
</script>
