<template>
    <div class="flex flex-col">
        <h3
            class="align-center mb-2 flex align-bottom text-lg font-bold font-medium text-gray-400"
        >
            Global
        </h3>
        <line-chart :series="series" :loading="loading" />
        <h3
            class="align-center my-5 flex align-bottom text-lg font-bold font-medium text-gray-400"
        >
            Comp/Caixa

            <submit-button
                class="ml-auto"
                size="sm"
                @click="store"
                :loading="loading"
            >
                Atualizar
            </submit-button>
        </h3>
        <line-chart :series="chart2" :loading="loading" />
    </div>
</template>

<script>
import LineChart from "../../analytics/charts/LineChart.vue";
import SubmitButton from "../../forms/SubmitButton.vue";

export default {
    components: { SubmitButton, LineChart },
    props: ["user"],
    data() {
        return {
            loading: false,
            months: [],
            series: [],
            chart2: [],
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        store() {
            this.loading = true;
            axios
                .post("/api/user/" + this.user.id + "/metrics/chargeback-rate")
                .then(() => this.fetch())
                .finally(() => (this.loading = false));
        },
        fetch() {
            axios
                .get("/api/user/" + this.user.id + "/metrics/chargeback-rate", {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then(({ data }) => {
                    this.months = data;

                    const tpv = {
                        name: "TPV",
                        lineWidth: 3,
                        data: [],
                    };
                    const tpn = {
                        name: "TPN",
                        lineWidth: 3,
                        data: [],
                    };

                    this.months = data.map((month) => {
                        tpv.data.push([month.unix_date, month.tpv_global]);
                        tpn.data.push([month.unix_date, month.tpn_global]);
                    });

                    this.series = [tpv, tpn];

                    const boxTPN = {
                        name: "TPN Caixa",
                        lineWidth: 3,
                        data: [],
                    };

                    const boxTPV = {
                        name: "TPV Caixa",
                        lineWidth: 3,
                        data: [],
                    };

                    const compTPN = {
                        name: "TPN Competência",
                        lineWidth: 3,
                        data: [],
                    };

                    const compTPV = {
                        name: "TPV Competência",
                        lineWidth: 3,
                        data: [],
                    };

                    this.months = data.map((month) => {
                        boxTPN.data.push([month.unix_date, month.tpn_box ?? 0]);
                        boxTPV.data.push([month.unix_date, month.tpv_box ?? 0]);
                        compTPN.data.push([
                            month.unix_date,
                            month.tpn_comp ?? 0,
                        ]);
                        compTPV.data.push([
                            month.unix_date,
                            month.tpv_comp ?? 0,
                        ]);
                    });

                    this.chart2 = [boxTPN, boxTPV, compTPN, compTPV];
                })
                .then(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
