<template>
    <modal
        size="lg"
        :open="show"
        @closeModal="show = false; $emit('closeModal')"
        @closeModalAndFetch="show = false; $emit('closeAndFetchModal')"
    >
        <form class="flex flex-col flex-wrap w-full space-y-3 text-white" @submit.prevent="submit">
            <h1 class="border-b pb-2 border-slate-500">Sinalizar Reembolso Externo</h1>
            <label for="reason" class="sr-only">Reason</label>

            <div v-if="errors.length > 0" class="alert">
                <div v-for="error in errors" :key="error">
                    {{ error }}
                </div>
            </div> 

            <textarea
                type="reason"
                id="reason"
                class="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Qual o motivo do reembolso ser feito externamente?"
                rows="4"
                v-model="reason"
                required
            />

            <div class="flex flex-row-reverse mt-5">
                <submit-button
                    :loading="loading"
                    :disabled="loading"
                >
                    Enviar
                </submit-button>

                <button
                    :disabled="loading"
                    type="button"
                    class="inline-flex justify-center px-4 py-2 mr-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="$emit('closeModal')"
                >
                    Cancelar
                </button>
            </div>
        </form>
    </modal>
</template>

<script>
    import Modal from "../../../components/layouts/Modal.vue";
    import SubmitButton from "../../../components/forms/SubmitButton.vue";

    export default {
        props: ['transaction', 'show'],
        components: {SubmitButton, Modal},

        data() {
            return {
                loading: false,
                reason: null,
                errors: [],
                message: null,
            }
        },

        methods: {
            submit() {
                this.errors = [];
                this.message = null;
                this.loading = true;
                axios.post('/api/order/' + this.transaction.order_id + '/transactions/' + this.transaction.id + '/external-refund', {
                    reason: this.reason
                }).then(({data}) => {
                    this.loading = false;
                    this.$emit('closeModalAndFetch');

                    this.$notify({
                        group: "success",
                        title: "Success",
                        text: data?.message || "Solicitação realizada com sucesso!"
                    }, 4000);  
                }).catch(({response}) => {
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {
                        this.errors = response.data.errors;
                    } else if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
                        this.errors = [response.data.message];
                    }
                    this.loading = false;
                })
            }
        }
    }
</script>
