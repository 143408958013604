<template>
    <p
        class="text-md mb-2 font-bold uppercase text-gray-600 dark:text-gray-400"
    >
        Ebooks
    </p>
    <div
        v-for="ebook in ebooks"
        class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
    >
        <ticto-loading v-if="loading"></ticto-loading>

        <p
            class="flex items-center text-lg text-red-400"
            v-if="!ebooks && !loading"
        >
            <XCircleIcon class="mr-1 h-7 w-7 text-red-400" /> O E-book ainda não
            foi anexado.
        </p>

        <p
            v-if="ebooks && !loading"
            v-tooltip="ebook.name"
            class="flex w-full flex-wrap flex-wrap items-center truncate text-lg dark:text-white"
        >
            {{ ebook.name }}

            <a
                @click="viewEbook(ebook.id)"
                :disabled="loading"
                class="ml-auto font-medium text-indigo-600 hover:cursor-pointer hover:text-indigo-500"
                >Download</a
            >
        </p>
    </div>
</template>

<script>
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import { XCircleIcon } from "@heroicons/vue/24/outline";

export default {
    components: { TictoLoading, XCircleIcon },
    props: ["product"],
    data() {
        return {
            loading: true,
            ebooks: false,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/product/" + this.product.id + "/ebook")
                .then(({ data }) => {
                    this.ebooks = data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        viewEbook(id) {
            axios
                .get("/api/product/" + this.product.id + `/ebook/${id}/show`)
                .then(({ data }) => {
                    window.open(data.url, "_blank");
                });
        },
    },
};
</script>
