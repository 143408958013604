<script>
export default {
    methods: {
        formatCommissionType(type) {
            switch (type) {
                case "ticto":
                    return "Ticto";
                case "affiliate":
                    return "Afiliado";
                case "manager":
                    return "Coprodutor";
                case "coproducer":
                    return "Coprodutor";
                case "provider":
                    return "Fornecedor";
                case "referral":
                    return "Indicação";
                case "producer":
                    return "Produtor";
                case "cashback":
                    return "Cashback";
                default:
                    return "Venda Padrão";
            }
        },
    },
};
</script>
