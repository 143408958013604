<template>
    <schedule-chart v-if="!loading" :dates="schedule"></schedule-chart>
</template>

<script>
    import ScheduleChart from "./ScheduleChart.vue";

    export default {
        components: {ScheduleChart},
        props: ['user'],
        data() {
            return {
                loading: true,
                schedule: []
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                axios.get('/api/user/'+ this.user.id +'/balance/schedule')
                    .then(({data}) => {
                        this.schedule = data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$toastr.error(error.response.data.message);
                    });
            }
        }
    }
</script>
