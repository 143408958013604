<template>
    <h2 class="mt-5 mb-2 text-gray-400 text-lg">Saldo</h2>
    <dl class="grid grid-cols-1 gap-5 sm:grid-cols-3">
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Disponível
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.available) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Antecipável
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.anticipation) }}
            </p>
        </card>
        <card :loading="loading">
            <p
                class="mb-2 text-gray-400 dark:text-gray-400 uppercase font-bold text-sm"
            >
                Pendente
            </p>
            <p
                class="text-2xl font-light text-gray-700 dark:text-gray-200 flex"
            >
                {{ $root.formatCurrency(balance.pending) }}
            </p>
        </card>
    </dl>
    <user-balances></user-balances>
</template>

<script>
    import Card from "../../components/layouts/Card.vue";
    import UserBalances from "./UserBalances.vue";
    export default {
        components: {Card, UserBalances},
        data() {
            return {
                loading: false,
                stats: [

                ],
                balance: {
                    anticipation: 0,
                    available: 0,
                    pending: 0
                }
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/financial/stats').then(({data}) => {
                    this.balance = data;
                    this.loading = false;
                }).catch(({response}) => {
                    this.loading = false;
                })
            }
        }
    }
</script>
