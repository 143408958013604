<template>
    <div class="flex flex-col dark:text-white">

        <filters
            v-model="filters"
            @fetch="orders = []; next_page_url = false; pagination.current_page = 1; fetch();"
            :loading="loading"
            date-filter
            id-filter
            :default-dates="defaultDates"
            search-filter
            status-filter
            payment-method-filter
            search-label="Nome ou e-mail do produtor ou cliente, número do pedido ou código do gateway"
            @clearFilters="clearFilters"
            credit-card-q-a
            class="mt-4"
        ></filters>



        <div class="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-3">
            <card :loading="loading">
                <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400 flex align-center">
                    Vendas
                    <span class="ml-auto">
                        {{cards.count}}
                    </span>
                </p>
                <p class="text-lg font-semibold text-white-100 dark:text-gray-200 flex">
                    {{ $root.formatCurrency(cards.amount) }}
                </p>
            </card>
        </div>

        <table-component
            :loading="loading"
            :pagination="pagination"
            select-all
            :selected="selected"
            :all-selected="all_selected"
            :headers="[
                'Pedido',
                'Produto',
                'Produtor',
                'Cliente',
                'Método de Pagamento',
                'Valor do Pedido',
                'Data do Pedido',
                'Status',
            ]"
            @fetch="fetch"
            @selectAll="selectAll"
        >
            <template v-slot:exportHeader>
                <div
                    @click.prevent="exportExcel"
                    class="p-3 hover:cursor-pointer hover:bg-slate-900 rounded-lg flex content-center justify-center"
                >
                    <SvgLoader v-if="loading_export"/>
                    <CloudArrowDownIcon class="mr-1 flex-shrink-0 self-center h-5 w-5" />
                    Exportar
                </div>
            </template>
            <tr v-for="order in orders">
                <td class="table-cell-sm flex">
                    <input
                        :id="'check-order-' + order.id"
                        name="orders"
                        type="checkbox"
                        class="checked:shadow-xl focus:ring-indigo-500 h-4 w-4 text-red-300 border-red-300 rounded mr-3"
                        v-model="selected"
                        :value="order.id"
                    />

                    <router-link
                        :to="{
                            name: 'user.order', params: { order_id: order.id }
                        }"
                        class="text-indigo-400 hover:underline hover:text-indigo-700 hover:cursor-pointer"
                    >
                        #{{ order.id }}
                    </router-link>
                </td>
                <td class="table-cell-sm">
                    {{ (order.items[0])? order.items[0].product?.name : '-' }}
                    <font-awesome-icon
                        v-if="order.items.length > 1"
                        class="ml-1"
                        icon="fa-solid fa-circle-plus"
                    />
                </td>
                <td class="table-cell-sm">
                    <button
                        class="text-indigo-500 hover:underline"
                        @click.prevent="manageUser(order.items[0].product?.user)"
                    >
                        {{ (order.items[0])? order.items[0].product?.user?.name : '-' }}
                    </button>
                </td>
                <td class="table-cell-sm text-xs">
                    {{ (order.customer)? order.customer['name'] : '-' }}
                </td>
                <td class="table-cell-sm">
                    <payment-type
                        :method="order.transactions[0] ? order.transactions[0].payment_method : null"
                        :hasAmountCard2="order.amount_card2"
                        :brandCard1="order.transactions[0] && order.transactions[0].card ? order.transactions[0].card.brand : null"
                        :brandCard2="order.transactions[1] && order.transactions[1].card ? order.transactions[1].card.brand : null"
                    ></payment-type>
                </td>
                <td class="table-cell-sm">
                    {{ $root.formatCurrency(order.amount + order.amount_card2) }}
                </td>
                <td class="table-cell-sm text-xs">
                    {{ order.created_at }}
                </td>
                <td class="table-cell-sm">
                    <status
                        size="sm"
                        :status="order.transactions[order.transactions.length - 1].status"
                        :isRefundFromHood="order.transactions[order.transactions.length - 1].is_refund_from_hood"
                    ></status>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import Card from "../../components/layouts/Card.vue";
import Filters from "../../components/layouts/FiltersOrders.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import PaymentType from "../../components/layouts/PaymentType.vue";
import Status from "../../components/layouts/Status.vue";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import { Store } from "../../stores/store";

export default {
    components: {SvgLoader, TableComponent, Pagination, Filters, Status, PaymentType, CloudArrowDownIcon, Card },
    mixins: [HelperMixin],
    data() {
        return {
            all_selected: false,
            selected: [],
            loading: false,
            next_page_url: false,
            orders: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
            cards: {
                amount: 0,
                count: 0,
            },
            defaultDates: [
                moment().subtract(1, 'month').format('DD/MM/YYYY'),
                moment().format('DD/MM/YYYY'),
            ],
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
            loading_export: false
        };
    },

    computed: {
        ...mapWritableState(Store, ['user']),
    },

    watch: {
        selected(array) {
            if (array.length === 0) {
                this.all_selected = false;
            }
        }
    },

    mounted() {
        this.user = false;

        setTimeout(() => {
            this.fetch();
        }, 500);
    },

    methods: {
        exportExcel() {
            if (this.loading_export) {
                return false;
            }

            if (this.selected.length < 1) {
                return this.$toast.error(error.response.data.message);
            }

            this.loading_export = true;

            const params = this.filters;

            if (this.selected.length < 1000) {
                params.selected = this.selected;
            } else {
                delete params.selected;
            }

            axios.get("/api/order/export/excel", {
                params: {
                    ...params
                },
            }).then(({data}) => {
                this.$notify({
                    group: "success",
                    title: "Success",
                    text: "Você receberá a planilha por email."
                }, 4000);
                this.loading_export = false;
            }).catch((error) => {
                this.loading_export = false;
                if (error.response) {
                    return this.$toast.error(error.response.data.message);
                }
            });
        },
        selectAll() {
            this.all_selected = !this.all_selected;
            if (this.all_selected) {
                axios.get("/api/order/select-all", {
                    params: this.filters,
                }).then(({ data }) => {
                    this.selected = data;
                }).catch((error) => {
                    this.loading = false;
                    this.$toast.error(error.response.data.message);
                });
            } else {
                this.selected = [];
            }
        },
        clearFilters() {
            this.selected = [];
            this.all_selected = false;
            this.filters = {
                text: "",
                status: [],
                payment_method: [],
            }
            this.next_page_url = false;
            this.orders = [];
            this.fetch();
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params['page'] = this.pagination.current_page;

            axios
                .get("/api/order", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.storeorder = false;
                    this.orders = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data.meta
                    };

                    this.next_page_url = data.meta.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    // this.$toast.error(error.response.data.message);
                });

            axios.get('/api/order/cards', {
                params: params
            }).then(({data}) => {
                this.cards = data;
            })


        },
        manage(order) {
        },
        manageUser(user) {
            this.user = user;
            this.$router.push({
                name: 'user.commissions',
                params: { user_id: user.id }
            });
        },
    },
};
</script>
