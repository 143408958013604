<template>
    <div class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
        <div class="flex w-0 flex-1 items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd"></path>
            </svg>
            <span class="ml-2 w-0 flex-1 truncate dark:text-white">{{ collectionLabel }}</span>
        </div>
        <div class="ml-4 flex-shrink-0">
            <a @click.prevent="update = !update" class="hover:cursor-pointer font-medium text-indigo-600 hover:text-indigo-500">Alterar</a>
        </div>
    </div>
    <div v-if="update" class="p-3">
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">Upload</label>

        <div class="flex items-center">
            <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file" ref="file">
            <submit-button
                @click.prevent="submit"
                :loading="loading"
                class="ml-1"
                size="sm"
            >
                Enviar
            </submit-button>
        </div>
    </div>
</template>

<script>
    //import toast
    import { useToast } from "vue-toastification";
    import SubmitButton from "../forms/SubmitButton.vue";

    export default {
        components: {SubmitButton},
        props: ['user', 'collectionLabel', 'collectionName'],
        emits: ['fetch'],
        data() {
            return {
                loading: false,
                file: false,
                progress: 0,
                update: false
            }
        },

        setup() {
            const toast = useToast();
            return { toast };
        },

        methods: {
            submit() {
                if (!this.$refs.file.files[0]) return;

                this.loading = true;

                Vapor.store(this.$refs.file.files[0], {
                    progress: progress => {
                        this.progress = Math.round(progress * 100);
                    }
                }).then(response => {
                    axios.put('/api/user/'+ this.user.id + '/documents', {
                        uuid: response.uuid,
                        key: response.key,
                        bucket: response.bucket,
                        name: this.$refs.file.files[0].name,
                        content_type: this.$refs.file.files[0].type,
                        collection: this.collectionName
                    }).then(response => {
                        this.toast.success('Documento atualizado com sucesso!');
                    }).catch(error => {
                        this.toast.error('Erro ao atualizar documento!');
                    }).finally(() => {
                        this.loading = false;
                        this.update = false;
                        this.$emit('fetch');
                    });
                });
            }
        }

    }
</script>
