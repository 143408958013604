<template>
    <card class="mb-2">
        <timeline-skeleton v-if="loading" />
        <ol
            v-else
            class="relative w-full border-l border-gray-200 dark:border-gray-700"
        >
            <li class="mb-10 ml-4" v-for="status in statuses">
                <div
                    class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white dark:border-gray-900"
                    :class="chargebackStatusClass(status.status)"
                ></div>
                <time
                    class="mb-1 text-sm font-normal leading-none text-gray-200 text-gray-800 dark:text-gray-500"
                >
                    {{ status.created_at }}
                </time>
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    {{ chargebackStatusName(status.status) }}
                </h3>
                <p
                    class="flex text-base text-sm font-normal text-gray-500 dark:text-gray-400"
                    v-if="status.user"
                >
                    {{ status.user.name }} {{ status.user.email }}
                </p>
                <p
                    v-if="status.description"
                    class="mt-1 rounded-lg bg-slate-900 p-4 text-base font-normal text-gray-500 dark:text-gray-200"
                >
                    {{ status.description }}
                </p>
            </li>
            <li class="ml-4">
                <div
                    class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700"
                ></div>
                <time
                    class="mb-1 text-sm font-normal leading-none text-gray-900 dark:text-gray-500"
                >
                    {{ chargeback.created_at }}
                </time>
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Chargeback Apresentado
                </h3>
                <p
                    class="text-base font-normal text-gray-500 dark:text-gray-200"
                >
                    <a
                        @click="approve = !approve"
                        v-if="
                            ['requested', 'pending'].includes(chargeback.status)
                        "
                        class="inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                    >
                        Alterar Status
                        <svg
                            class="ml-2 h-3 w-3"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </a>
                </p>
            </li>
        </ol>
    </card>

    <TransitionRoot
        :show="approve"
        enter="transition-opacity duration-150"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <div
            class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
        >
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                Alterar Status
            </h3>
            <label
                for="countries"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
            >
                Selecione uma opção
            </label>
            <select
                v-model="form.status"
                id="countries"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            >
                <option :value="false" selected>Status</option>
                <option v-for="option in options" :value="option.value">
                    {{ option.label }}
                </option>
            </select>

            <div v-if="form.status === 'reproved'">
                <label
                    for="message"
                    class="mb-2 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
                    >Motivo</label
                >
                <textarea
                    v-model="form.description"
                    id="message"
                    rows="4"
                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="Your message..."
                ></textarea>
            </div>

            <submit-button
                :loading="loading"
                @click.prevent="modal = true"
                class="mt-3 w-full"
            >
                Enviar
            </submit-button>
        </div>
    </TransitionRoot>

    <modal :open="modal" @close="modal = false">
        <h3 class="text-md font-semibold text-gray-900 dark:text-white">
            Alterar Status

            <span class="text-sm font-light text-gray-900 dark:text-white">
                Disputa #{{ chargeback.id }}
            </span>
        </h3>

        <p class="my-2 text-red-400">
            <strong>Atenção:</strong> Essa ação não pode ser desfeita.
        </p>

        <p class="my-2 text-sm dark:text-white">
            Novo status:
            <strong
                :class="[
                    chargebackStatusClass(form.status),
                    'rounded-lg px-2 py-1',
                ]"
                >{{ chargebackStatusName(form.status) }}</strong
            >
        </p>

        <div
            v-if="['approved', 'rejected'].includes(form.status)"
            class="flex flex-col"
        >
            <p class="text-sm font-bold dark:text-white">Essa alteração vai:</p>
            <div class="flex flex-col">
                <ul class="ml-5 list-disc text-sm dark:text-white">
                    <li v-if="chargeback.fee_setup">Cobrar taxa do cliente;</li>
                    <li>
                        Atualizar o status da transação para
                        <strong>
                            {{
                                form.status === "approved"
                                    ? "Autorizado"
                                    : "Chargeback"
                            }}
                        </strong>
                    </li>
                </ul>
            </div>
        </div>
        <submit-button
            :loading="loading"
            @click.prevent="submit"
            class="mt-3 w-full"
        >
            Confirmar
        </submit-button>
    </modal>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";

import TimelineSkeleton from "../../layouts/skeleton/TimelineSkeleton.vue";
import Card from "../../layouts/Card.vue";
import ChargebackStatusName from "../../../mixins/ChargebackStatusName.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import Modal from "../../../components/layouts/Modal.vue";

export default {
    components: { SubmitButton, Card, TimelineSkeleton, TransitionRoot, Modal },
    props: ["chargeback"],
    mixins: [ChargebackStatusName],

    data() {
        return {
            modal: false,
            loading: true,
            statuses: [],
            approve: false,
            form: {
                status: false,
                description: null,
            },
        };
    },

    computed: {
        options() {
            const options = [
                { value: "pending", label: "Pendente" },
                { value: "approved", label: "Aprovada" },
                { value: "rejected", label: "Rejeitada" },
            ];

            return options.filter(
                (option) => option.value !== this.chargeback.status,
            );
        },
    },

    mounted() {
        this.fetch();
    },

    emits: ["fetch"],

    methods: {
        fetch() {
            this.loading = true;
            return axios
                .get(
                    "/api/chargebacks/" +
                        this.$route.params.dispute_id +
                        "/statuses",
                )
                .then(({ data }) => {
                    this.statuses = data;
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            return axios
                .put(
                    "/api/chargebacks/" + this.$route.params.dispute_id,
                    this.form,
                )
                .then(({ data }) => {
                    this.fetch();
                    this.loading = false;
                    this.approve = false;
                    this.modal = false;
                    this.$emit("fetch");
                })
                .catch(({ response }) => {
                    this.loading = false;

                    if (response.data.hasOwnProperty("message")) {
                        this.$notify(
                            {
                                group: "error",
                                title: "Error",
                                text: response.data.message,
                            },
                            4000,
                        );
                    }

                    if (response.data.hasOwnProperty("errors")) {
                        this.$notify(
                            {
                                group: "error",
                                title: "Error",
                                text: response.data.errors[0],
                            },
                            4000,
                        );
                    }
                });
        },
    },
};
</script>
