<template>
    <h1 class="text-lg font-bold text-gray-900 dark:text-white">Sellers</h1>
    <filters
        class="my-4"
        v-model="filters"
        date-filter
        search-filter
        @fetch="
            pagination.current_page = 1;
            sellers = [];
            fetch();
        "
    >
    </filters>
    <form-toggle label="Em auditoria" v-model="filterUserAudit" />

    <table-component
        :loading="loading"
        :headers="[]"
        :pagination="pagination"
        v-model="pagination"
        @fetch="fetch"
    >
        <template v-slot:thead>
            <thead class="dark:text-white">
                <tr>
                    <th
                        v-for="(header, index) in [
                            '',
                            'Usuário',
                            'Auditoria',
                            'Score',
                        ]"
                        scope="col"
                        class="p-3 text-left text-sm font-medium"
                        :class="{
                            'hover:cursor-pointer': sortable.includes(header),
                        }"
                        @click="sortField(header, sort.direction)"
                    >
                        <div class="flex items-center">
                            <div v-if="sort && sort.field === header">
                                <component
                                    :is="
                                        sort.direction === 'asc'
                                            ? 'ArrowDownIcon'
                                            : 'ArrowUpIcon'
                                    "
                                    class="mr-1 h-3 w-3"
                                />
                            </div>
                            <span>{{ header }}</span>
                        </div>
                    </th>
                </tr>
            </thead>
        </template>

        <tr v-for="seller in sellers">
            <td class="table-cell">
                {{ seller.updated_at }}
            </td>
            <td class="table-cell">
                <router-link
                    :to="{
                        name: 'user.metrics',
                        params: { user_id: seller.user_id },
                    }"
                    class="text-indigo-500 hover:text-indigo-700"
                >
                    {{ seller.user.name }} - {{ seller.user.email }}
                </router-link>
            </td>
            <td class="table-cell">
                <router-link
                    v-if="seller.user_audit"
                    class="text-indigo-500 hover:text-indigo-700"
                    :to="{
                        name: 'audit.trackings',
                        params: {
                            audit_id: seller.user_audit.id,
                        },
                    }"
                >
                    {{ seller.user_audit.hash }}
                </router-link>
            </td>
            <td class="table-cell">
                {{ seller.score }}
            </td>
        </tr>
    </table-component>
</template>

<script>
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import Filters from "../../components/layouts/Filters.vue";
import {
    DocumentMagnifyingGlassIcon,
    ArrowUpIcon,
    ArrowDownIcon,
} from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import { Store } from "../../stores/store";
import FormToggle from "../../components/forms/FormToggle.vue";

export default {
    components: {
        FormToggle,
        DocumentMagnifyingGlassIcon,
        Filters,
        TableComponent,
        ArrowUpIcon,
        ArrowDownIcon,
    },
    data() {
        return {
            filterUserAudit: null,
            loading: false,
            filters: {},
            sellers: [],
            sortable: ["Score"],
            sort: {
                field: "score",
                direction: "asc",
            },
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    computed: {
        ...mapWritableState(Store, ["user"]),
    },

    mounted() {
        this.fetch();
    },

    methods: {
        sortField(field, direction) {
            if (!this.sortable.includes(field)) {
                return;
            }
            if (field === this.sort.field) {
                direction = direction === "asc" ? "desc" : "asc";
            }

            this.sort.direction = direction;
            this.sort.field = field;
            this.fetch();
        },
        fetch() {
            this.user = false;
            this.loading = true;

            let sort = false;
            if (this.sort.field) {
                let sortField = this.sort.field.toLowerCase();
                sort =
                    this.sort.direction === "desc"
                        ? `-${sortField}`
                        : sortField;
            }
            let filters = this.filters;
            filters["filter[userAudit]"] = this.filterUserAudit;

            axios
                .get("/api/seller", {
                    params: {
                        page: this.pagination.current_page,
                        ...filters,
                        sort,
                    },
                })
                .then(({ data }) => {
                    this.sellers = data.data;
                    this.pagination = data;
                    this.loading = false;
                });
        },
    },
};
</script>
