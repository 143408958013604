<template>
    <h3 class="mt-5 mb-2  font-medium text-gray-400 text-lg flex">
        Clicks
        <small class="ml-auto text-indigo-500 hover:underline hover:cursor-pointer" @click="table = !table">
            Visualizar
        </small>
    </h3>

    <div class="grid grid-cols-4 mt-3 gap-4 dark:text-white">
        <aggs-card
            :loading="loading"
            name="Browsers"
            :buckets="aggs.browser"
        />
        <aggs-card
            :loading="loading"
            name="Cidades"
            :buckets="aggs.city"
        />
        <aggs-card
            :loading="loading"
            name="Ip"
            :buckets="aggs.ip"
        />
        <aggs-card
            :loading="loading"
            name="Dispositivo"
            :buckets="aggs.device"
        />
    </div>

    <modal
        size="4xl"
        :open="table"
        @close="table = false"
    >
        <table-component
            @fetch="fetch"
            :loading="loading"
            :pagination="pagination"
            :headers="[
                'Data',
                'Fingerprint',
                'Ip',
                'Localização',
                'Dispositivo',
                'Src'
            ]"
        >
            <tr v-for="tracking in clicks">
                <td class="table-cell">
                    {{tracking._source.created_at}}
                </td>
                <td class="table-cell">
                    {{ tracking._source.fingerprint.substr(tracking._source.fingerprint.length - 10) }}
                </td>
                <td class="table-cell">
                    {{ tracking._source.ip }}
                </td>
                <td class="table-cell">
                    <div class="flex flex-col" v-if="tracking._source.location">
                        <span v-if="tracking._source.location.regionCode">
                            <strong>{{ tracking._source.location.regionCode }}:</strong> {{ tracking._source.location.regionName }}
                        </span>
                        <span>
                            <strong v-if="tracking._source.location.countryCode">{{ tracking._source.location.countryCode }}:</strong>
                            <strong v-if="tracking._source.location.countryName">{{ tracking._source.location.countryName }}:</strong> {{ tracking._source.location.cityName }}
                        </span>
                    </div>
                </td>
                <td class="table-cell">
                    <div class="flex flex-col">
                        <div>
                            {{ tracking._source.browser }} {{ tracking._source.browser_version }}
                        </div>
                        <div>
                            {{ tracking._source.platform }} {{ tracking._source.platform_version }}
                        </div>
                    </div>
                </td>
                <td class="table-cell">
                    <div class="flex flex-col">
                        <div v-if="tracking._source.src">
                            <strong>Src: </strong> {{ tracking._source.src }}
                        </div>
                        <div v-if="tracking._source.sck">
                            <strong>Sck: </strong> {{ tracking._source.sck }}
                        </div>
                        <div v-if="tracking._source.utm_campaign">
                            <strong>Utm_campaign: </strong> {{ tracking._source.utm_campaign }}
                        </div>
                        <div v-if="tracking._source.utm_content">
                            <strong>utm_content: </strong> {{ tracking._source.utm_content }}
                        </div>
                        <div v-if="tracking._source.utm_medium">
                            <strong>utm_medium: </strong> {{ tracking._source.utm_medium }}
                        </div>
                        <div v-if="tracking._source.utm_name">
                            <strong>utm_name: </strong> {{ tracking._source.utm_name }}
                        </div>
                        <div v-if="tracking._source.utm_source">
                            <strong>utm_name: </strong> {{ tracking._source.utm_source }}
                        </div>
                        <div v-if="tracking._source.utm_term">
                            <strong>utm_term: </strong> {{ tracking._source.utm_term }}
                        </div>
                    </div>
                </td>
            </tr>
        </table-component>
    </modal>
</template>

<script>
    import Pagination from "../../../components/layouts/Pagination.vue";
    import AggsCard from "../../../components/cashouts/AggsCard.vue";
    import Modal from "../../../components/layouts/Modal.vue";
    import TableComponent from "../../../components/layouts/table/TableComponent.vue";
    export default {
        props: ['cashout'],
        components: {TableComponent, Modal, AggsCard, Pagination},
        data() {
            return {
                table: false,
                loading: false,
                clicks: [],
                pagination: {
                    total: 0,
                    per_page: 35,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
                aggs: {
                    browser: {},
                    city: {},
                    ip: {},
                    device: {}
                }
            }
        },

        mounted() {
            this.fetch()
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = {};
                params['page'] = this.pagination.current_page;

                axios.get('/api/cashout/' + this.cashout.id + '/payables/clicks', {
                    params
                })
                .then(({data}) => {
                    this.clicks = data.hits.hits;
                    this.aggs = data.aggregations

                    this.pagination.total = data.hits.total.value;
                    this.pagination.from = this.pagination.current_page * this.pagination.per_page - this.pagination.per_page + 1;
                    this.pagination.to = this.pagination.current_page * this.pagination.per_page;
                    this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page);

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
            }
        }
    }
</script>
