<template>
    <TransitionRoot
        appear
        :show="selected.length > 0"
        enter="transition-opacity duration-75"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-150"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <div
            class="w-ful absolute z-30 mb-4 grid max-w-4xl grid-cols-4 divide-x divide-slate-700 rounded-lg bg-slate-800 shadow"
        >
            <div class="rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900">
                <input
                    @click.stop="$emit('selectAll')"
                    :checked="allSelected"
                    id="select-all"
                    aria-describedby="comments-description"
                    name="select-all"
                    type="checkbox"
                    class="h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                    :value="true"
                />
                {{ selected.length }} Selecionado{{
                    selected.length > 1 ? "s" : ""
                }}
            </div>
            <slot name="exportHeader"></slot>
        </div>
    </TransitionRoot>

    <empty-state v-if="!loading && pagination && pagination.total === 0" />
    <div v-else>
        <slot name="tableHeader"></slot>
        <div
            class="not-prose relative rounded-xl bg-slate-50 dark:bg-slate-800/25"
        >
            <table class="w-full table-auto border-collapse text-sm">
                <slot name="thead"></slot>
                <thead class="dark:text-white">
                    <tr>
                        <th
                            v-for="(header, index) in headers"
                            scope="col"
                            class="p-3 text-left text-sm font-medium"
                        >
                            <div class="flex" v-if="index === 0 && selectAll">
                                <input
                                    @click.stop="$emit('selectAll')"
                                    :checked="allSelected"
                                    id="select-all-cashouts"
                                    aria-describedby="comments-description"
                                    name="all-cashouts"
                                    type="checkbox"
                                    class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                                    :value="true"
                                />
                                {{ header }}
                            </div>
                            <span v-else>{{ header }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white dark:bg-slate-800">
                    <tr v-if="loading" v-for="n in 5">
                        <td
                            v-if="headers.length < 1"
                            v-for="n in 5"
                            class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            <div
                                class="h-2 w-full animate-pulse rounded bg-gray-500"
                            ></div>
                        </td>
                        <td
                            v-else
                            v-for="header in headers"
                            class="border-b border-slate-100 p-4 pl-8 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                        >
                            <div
                                class="h-2 w-full animate-pulse rounded bg-gray-500"
                            ></div>
                        </td>
                    </tr>
                    <slot v-else></slot>
                </tbody>
            </table>
            <div class="my-5 flex w-full" v-if="pagination">
                <div class="mx-auto mb-3 flex flex-col items-center">
                    <span class="mb-1 dark:text-white">
                        Exibindo {{ pagination.from }} a
                        {{ pagination.to }} registros de {{ pagination.total }}
                    </span>
                    <pagination
                        :pagination="pagination"
                        :offset="1"
                        @paginate="$emit('fetch')"
                    ></pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import Pagination from "../Pagination.vue";
import EmptyState from "../EmptyState.vue";

export default {
    components: { EmptyState, Pagination, TransitionRoot },
    props: {
        headers: { type: Array, required: true },
        pagination: {},
        loading: { type: Boolean, required: true, default: true },
        selectAll: { type: Boolean, required: false, default: false },
        allSelected: { type: Boolean, required: false, default: false },
        selected: { type: Array, default: [] },
    },
    emits: ["selectAll", "fetch"],
};
</script>
