<template>
    <div class="flex space-x-1">
        <QueueListIcon
            v-if="commission.order.has_smart_installment"
            v-tooltip="'Parcelamento Inteligente'"
            class="h-5 w-5"
        />
        <ShoppingBagIcon
            v-if="commission.order_item.is_bump"
            v-tooltip="'Order Bump'"
            class="h-5 w-5"
        />
        <ArrowPathRoundedSquareIcon
            v-if="commission.order.subscription"
            v-tooltip="'Assinatura'"
            class="h-5 w-5"
        />
    </div>
</template>

<script>
import {
    QueueListIcon,
    ShoppingBagIcon,
    ArrowPathRoundedSquareIcon,
} from "@heroicons/vue/24/outline";
export default {
    components: {
        ShoppingBagIcon,
        QueueListIcon,
        ArrowPathRoundedSquareIcon,
    },
    props: {
        commission: Object,
    },
};
</script>
