<template>
    <div class="flex flex-col">
        <h3
            class="align-center mb-2 flex align-bottom text-lg font-bold font-medium text-gray-400"
        >
            <span>Score</span>
            <span
                v-if="updating"
                class="ml-2 flex animate-pulse items-center rounded bg-gray-600 px-2 align-middle text-xs text-white dark:bg-gray-200 dark:text-gray-800"
                :class="{
                    '': loading,
                }"
            >
                Atualizando
            </span>

            <div class="ml-auto flex items-center">
                <p v-if="score" class="mr-1">
                    Atualizado em: {{ score.created_at }}
                </p>
                <submit-button
                    size="sm"
                    :loading="loading"
                    :disabled="loading || updating"
                    @click="store"
                >
                    Calcular
                </submit-button>
            </div>
        </h3>

        <ticto-loading v-if="loading" />
        <template v-else>
            <div class="grid grid-cols-3 gap-2">
                <div>
                    <score-gauge :score="score.amount" />
                </div>

                <score-table v-if="score" class="col-span-2" :score="score" />
            </div>
        </template>
    </div>
</template>

<script>
import SubmitButton from "../forms/SubmitButton.vue";
import TictoLoading from "../layouts/TictoLoading.vue";
import Card from "../layouts/Card.vue";
import ScoreGauge from "./score/ScoreGauge.vue";
import ScoreTable from "./score/ScoreTable.vue";
export default {
    props: ["user"],
    components: { ScoreTable, Card, TictoLoading, SubmitButton, ScoreGauge },

    data() {
        return {
            updating: false,
            loading: true,
            seller: false,
            score: false,
        };
    },

    mounted() {
        this.fetch();

        Echo.channel("user-score-" + this.user.id).listen(
            ".App\\Events\\Score\\UpdatingScore",
            (e) => {
                this.updating = e.updating;

                if (!e.updating) {
                    this.fetch();
                }
            },
        );
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get("/api/user/" + this.user.id + "/score")
                .then(({ data }) => {
                    this.seller = data.seller;
                    this.score = data.score;
                    this.loading = false;
                });
        },
        store() {
            this.loading = true;

            axios
                .post("/api/user/" + this.user.id + "/score")
                .then(({ data }) => {
                    this.loading = false;
                    this.fetch();
                });
        },
    },
};
</script>
