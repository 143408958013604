<template>
    <modal size="lg" :open="show" @close="show = false">
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Importar Comissões
        </h1>

        <label class="text-sm dark:text-white">Usuário</label>
        <users-select
            class="my-2 text-sm text-white"
            v-model="selectedUser"
            v-if="!user"
        />

        <card v-if="user">
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <img
                        :src="
                            user.avatar ||
                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                user.name
                        "
                        alt
                        class="h-auto max-w-full rounded-full border-none align-middle shadow"
                        style="width: 40px; height: 40px"
                    />
                    <span class="ml-2 dark:text-white">{{ user.email }}</span>
                </div>

                <TrashIcon
                    class="h-4 w-4 cursor-pointer text-red-500 disabled:cursor-not-allowed"
                    :disabled="loading"
                    @click="selectedUser = false"
                    aria-hidden="true"
                />
            </div>
        </card>
        <commission-cards v-if="user" :user="user" size="sm" />
        <cards-radio-group
            class="my-0 text-white"
            id="import-label"
            v-model="form.label"
            :options="[
                { label: 'Ok', value: 'ok' },
                { label: 'Fraude', value: 'fraud' },
            ]"
            :disabled="loading"
        />

        <submit-button
            class="mt-4"
            :loading="loading"
            :disabled="loading || !user || form.label === ''"
            @click="submit"
        >
            Importar
        </submit-button>
    </modal>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import UsersSelect from "../forms/UsersSelect.vue";
import UserHeading from "../../views/users/UserHeading.vue";
import Filters from "../layouts/Filters.vue";
import Card from "../layouts/Card.vue";
import { RadioGroup } from "@headlessui/vue";
import CardsRadioGroup from "../forms/CardsRadioGroup.vue";
import Modal from "../layouts/Modal.vue";
import CommissionCards from "../user/CommissionCards.vue";
import SubmitButton from "../forms/SubmitButton.vue";

export default {
    components: {
        SubmitButton,
        CommissionCards,
        Modal,
        CardsRadioGroup,
        RadioGroup,
        Card,
        Filters,
        UserHeading,
        UsersSelect,
        TrashIcon,
    },
    emits: ["fetch"],
    data() {
        return {
            show: true,
            loading: false,
            filters: {
                text: "",
                status: [],
                payment_method: [],
            },
            selectedUser: [],
            user: false,

            form: {
                label: "",
            },
        };
    },

    watch: {
        selectedUser() {
            if (this.selectedUser.length > 0) {
                this.fetchUser();
            }
        },
    },

    methods: {
        fetchUser() {
            if (this.selectedUser.length < 1) {
                return;
            }

            axios.get("/api/user/" + this.selectedUser[0]).then(({ data }) => {
                this.user = data;
                this.selectedUser = [];
            });
        },
        submit() {
            this.loading = true;

            axios
                .post("/api/detector/import", {
                    user_id: this.user.id,
                    label: this.form.label,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.show = false;
                    this.$emit("fetch");
                });
        },
    },
};
</script>
