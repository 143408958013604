<script>
export default {
    data() {
        return {
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
        }
    },

    methods: {
        setPagination(pagination) {
            // unset pagination.data and merge objects
            delete pagination.data;
            this.pagination = {
                ...this.pagination,
                ...pagination
            };
        }
    }
}
</script>
