<template>
    <back-button :route="{ name: 'finnancial.chargebacks.disputes' }" />
    <div class="my-3 flex flex-col">
        <h2 class="tex flex text-lg font-bold capitalize dark:text-white">
            Disputa #{{ chargeback.id }}

            <div v-if="!chargeback.fee_setup" class="ml-auto flex items-center">
                <small class="text-sm text-red-300">Taxa não configurada</small>
                <button
                    type="button"
                    :disabled="loading"
                    class="btn-primary-sm ml-2 text-sm"
                    @click.prevent="config = !config"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-cog"
                        class="mr-1 dark:text-white"
                        aria-hidden="true"
                    />
                    Configurações
                </button>
                <config-modal
                    v-if="producer"
                    :user="producer"
                    :show="config"
                    @close="
                        fetch();
                        config = false;
                    "
                />
            </div>
        </h2>
    </div>
    <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
            <card>
                <div class="grid grid-cols-4 gap-2">
                    <info-col title="Transação" :loading="loading">
                        <router-link
                            class="block truncate text-indigo-500 hover:underline"
                            v-tooltip="chargeback.transaction.hash"
                            :to="{
                                name: 'user.order',
                                params: {
                                    order_id: chargeback.order_id,
                                },
                            }"
                        >
                            {{ chargeback.transaction.hash }}
                        </router-link>
                    </info-col>
                    <info-col title="Id Externo" :loading="loading">
                        <span
                            class="block max-w-xs truncate"
                            v-tooltip="chargeback.external_id"
                        >
                            {{ chargeback.external_id }}
                        </span>
                    </info-col>
                    <info-col title="Valor" :loading="loading">
                        {{
                            $root.formatCurrency(
                                chargeback.transaction.paid_amount,
                            )
                        }}
                    </info-col>
                    <info-col title="Status" :loading="loading">
                        <status
                            class="max-w-sm"
                            :status="chargeback.transaction.status"
                        />
                    </info-col>

                    <div
                        class="col-span-4 grid grid-cols-4 gap-1 rounded bg-gray-200 p-3 dark:bg-slate-900"
                    >
                        <info-col title="Cliente" :loading="loading">
                            {{ customer.name }}
                        </info-col>
                        <info-col title="Email" :loading="loading">
                            <span
                                class="block truncate"
                                v-tooltip="customer.email"
                                >{{ customer.email }}</span
                            >
                        </info-col>
                        <info-col title="CPF/CNPJ" :loading="loading">
                            {{
                                formatCpf(customer.cpf) ??
                                formatCnpj(customer.cnpj) ??
                                "-"
                            }}
                        </info-col>
                        <info-col title="Telefone" :loading="loading">
                            <span v-if="customer.phone">
                                {{
                                    customer.phone.ddi +
                                    " " +
                                    customer.phone.ddd +
                                    " " +
                                    customer.phone.number
                                }}
                            </span>
                        </info-col>
                    </div>
                </div>
            </card>

            <h3 class="text-md my-2 font-bold text-gray-400">Comissões</h3>

            <table-component
                :loading="loading"
                :headers="['Usuário', 'Produto', 'Tipo', 'Comissão', 'Taxa']"
            >
                <tr v-for="commission in chargeback.commissions">
                    <td class="table-cell-sm">
                        <router-link
                            class="text-indigo-500 hover:underline"
                            :to="{
                                name: 'user.financial',
                                params: {
                                    user_id: commission.user_id,
                                },
                            }"
                        >
                            {{ commission.user.name }}
                        </router-link>
                    </td>
                    <td class="table-cell-sm">
                        {{ commission.product.name }}
                    </td>
                    <td class="table-cell-sm">
                        {{ formatCommissionType(commission.type) }}
                    </td>
                    <td class="table-cell-sm">
                        {{ $root.formatCurrency(commission.amount) }}
                    </td>
                    <td class="table-cell-sm">
                        {{
                            commission.fee
                                ? $root.formatCurrency(commission.fee)
                                : "-"
                        }}
                    </td>
                </tr>
            </table-component>
        </div>
        <div>
            <card class="mb-3" :loading="loading">
                <div class="flex flex-col dark:text-white">
                    <div class="flex justify-between">
                        <span>Taxas: </span>
                        <span class="text-lg font-thin text-indigo-400">
                            {{
                                chargeback.fee
                                    ? $root.formatCurrency(chargeback.fee)
                                    : "--"
                            }}
                        </span>
                    </div>
                    <div class="flex justify-between">
                        <span>Porcentagem: </span>
                        <span class="text-sm font-thin text-indigo-400">
                            {{
                                chargeback.fee_percentage
                                    ? chargeback.fee_percentage + "%"
                                    : "--"
                            }}
                        </span>
                    </div>
                </div>
            </card>
            <card
                v-if="chargeback"
                v-for="card in chargeback.order.customer.cards"
                class="mb-3"
            >
                <div class="flex flex-col dark:text-white">
                    <div class="flex items-center">
                        <gateway-icon
                            :gateway="chargeback.transaction.gateway"
                            class="mr-2"
                        />
                        <card-brand-icon
                            v-if="card.brand"
                            :brand="card.brand"
                            class="mr-2"
                        />
                        {{ formatCardNumber(card.first_6, card.last_4) }}
                    </div>
                    <div>{{ card.holder }}</div>
                </div>
            </card>
            <statuses :chargeback="chargeback" @fetch="fetch" />
        </div>
    </div>
</template>

<script>
import BackButton from "../../../../components/layouts/navigation/BackButton.vue";
import UserHeading from "../../../users/UserHeading.vue";
import TimelineSkeleton from "../../../../components/layouts/skeleton/TimelineSkeleton.vue";
import Card from "../../../../components/layouts/Card.vue";
import Statuses from "../../../../components/finnancial/chargebacks/Statuses.vue";
import InfoCol from "../../../../components/layouts/InfoCol.vue";
import CardBrandIcon from "../../../../components/layouts/icons/CardBrandIcon.vue";
import GatewayIcon from "../../../../components/layouts/icons/GatewayIcon.vue";
import TableComponent from "../../../../components/layouts/table/TableComponent.vue";
import CommissionType from "../../../../mixins/CommissionType.vue";
import HelperMixin from "../../../../mixins/HelperMixin.vue";
import Status from "../../../../components/layouts/Status.vue";
import ConfigModal from "../../../../components/finnancial/chargebacks/ConfigModal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapWritableState } from "pinia";
import { Store } from "../../../../stores/store";

export default {
    mixins: [CommissionType, HelperMixin],
    components: {
        FontAwesomeIcon,
        ConfigModal,
        Status,
        TableComponent,
        GatewayIcon,
        CardBrandIcon,
        InfoCol,
        Statuses,
        Card,
        TimelineSkeleton,
        UserHeading,
        BackButton,
    },
    data() {
        return {
            config: false,
            chargeback: false,
            loading: true,
            statuses: [],
            approve: false,
        };
    },

    computed: {
        customer() {
            if (!this.chargeback.order) {
                return false;
            }
            return this.chargeback.order.customer;
        },
        producer() {
            if (!this.chargeback.order) {
                return false;
            }

            return this.chargeback.commissions.find(
                (commission) => commission.type === "producer",
            ).user;
        },
        ...mapWritableState(Store, ["user"]),
    },

    mounted() {
        this.fetch();
        this.user = false;
    },

    methods: {
        fetch() {
            this.loading = true;
            return axios
                .get("/api/chargebacks/" + this.$route.params.dispute_id)
                .then(({ data }) => {
                    this.chargeback = data;
                    this.loading = false;
                });
        },
        formatCardNumber(first_6, last_4) {
            if (!first_6 || !last_4) {
                return "****";
            }
            const first6 = first_6.toString().replace(/\d{4}(?=.)/g, "$& ");
            return first6 + "****" + last_4;
        },
    },
};
</script>
