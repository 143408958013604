<template>
    <div class="my-3 flex flex-col dark:text-white">
        <h1 class="item-center mb-3 flex text-xl">
            Importações
            <button
                class="btn-primary-sm ml-auto text-xs"
                @click="create = !create"
                :disabled="loading"
            >
                Importar
            </button>
        </h1>
        <create-form v-if="create" @fetch="fetch" />

        <ticto-loading v-if="loading" />
        <div
            class="not-prose relative divide-y overflow-hidden overflow-hidden rounded-xl bg-slate-50 dark:divide-slate-700 dark:bg-slate-800/25"
        >
            <detector-import
                v-if="!loading"
                v-for="detectorImport in imports"
                :detector-import="detectorImport"
                @fetch="fetch"
            />
        </div>
    </div>
</template>

<script>
import Filters from "../../components/layouts/Filters.vue";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import DetectorImport from "../../components/fraudDetector/DetectorImport.vue";
import CreateForm from "../../components/fraudDetector/CreateForm.vue";

export default {
    components: {
        CreateForm,
        DetectorImport,
        TictoLoading,
        UsersSelect,
        Filters,
    },

    data() {
        return {
            loading: false,
            create: false,
            imports: [],
        };
    },

    mounted() {
        this.fetch();

        Echo.channel("fraud-detector").listen(
            ".App\\Events\\FraudDetector\\ImportUpdated",
            (e) => {
                this.imports = this.imports.map((detectorImport) => {
                    if (detectorImport.uuid === e.import.uuid) {
                        detectorImport = e.import;
                    }
                    return detectorImport;
                });
            },
        );
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get("/api/detector/import").then(({ data }) => {
                this.imports = data;
                this.loading = false;
            });
        },
    },
};
</script>
