<template>
    <img v-tooltip="brand" :src="url" :alt="brand" class="h-[32px] w-[32px]" />
</template>

<script>
export default {
    props: {
        brand: {
            type: String,
            required: true,
        },
    },

    computed: {
        url() {
            let brand = this.brand.toLowerCase();

            if (brand === "MASTER" || brand === "master") {
                brand = "mastercard";
            }

            return (
                "https://d1hwwmlgegwbz2.cloudfront.net/assets/icons/card_brands/" +
                brand +
                ".svg"
            );
        },
    },
};
</script>
