<template>
    <h3 class="mb-2 font-medium  text-gray-400 text-lg flex">
        Reviews
        <button
            v-if="hasPermission('review.cashouts')"
            @click.prevent="open = true"
            :disabled="loading"
            type="button"
            :class="{ 'cursor-not-allowed': loading }"
            class="btn-primary-xs ml-auto"
        >
            Avaliar
        </button>
    </h3>
    <review-modal
        :cashout="cashout"
        :open="open"
        @close="open = false"
        @fetch="open = false;fetch()"
    />
    <card class="mb-3">
        <div v-if="loading" v-for="n in 3" class="animate-pulse flex space-x-4 flex-col" :class="{'mb-3': n !== 3}">
            <div class="flex space-y-6 py-1 items-center">
                <div class="h-8 w-8 rounded-full -mr-3 bg-gray-500"></div>
                <div class="flex-grow mt-0 ml-5" style="margin-top: 0 !important;">
                    <div class="h-2 bg-gray-500 rounded w-full mb-3"></div>
                    <div class="h-2 bg-gray-500 rounded w-3/4"></div>
                </div>
            </div>
        </div>

        <div class="flex flex-col">
            <cashout-review
                v-for="user in users"
                :user="user"
                :cashout="cashout"
                @fetch="fetch"
            />
        </div>
    </card>
</template>

<script>
    import Card from "../../layouts/Card.vue";
    import CashoutReview from "./CashoutReview.vue";
    import permissionMixin from "../../../mixins/PermissionMixin.vue";
    import ReviewModal from "./ReviewModal.vue";
    import CardsRadioGroup from "../../forms/CardsRadioGroup.vue";

    export default {
        components: {CardsRadioGroup, ReviewModal, CashoutReview, Card},
        props: ['cashout'],
        mixins: [permissionMixin],

        data() {
            return {
                loading: true,
                users: [],
                open: false
            }
        },

        watch: {
            cashout() {
                if (this.cashout) {
                    this.fetch();
                }
            }
        },

        methods: {
            fetch() {
                this.loading = true;
                axios.get('/api/cashout/' + this.cashout.id + '/review')
                    .then(({data}) => {
                        this.users = data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                    });
            }
        }
    }
</script>
