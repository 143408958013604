<template>
    <div>
        <nav-tabs :tabs="routes" :exact="false"/>
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavTabs from "../../components/layouts/NavTabs.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";
import { mapState } from "pinia";
import {Store} from "../../stores/store";

export default {
    components: {NavTabs},
    mixins: [PermissionMixin],
    data() {
        return {
            routes: [
                { label: "Dashboard", route: "finnancial.dashboard" },
                { label: "Recebíveis", route: "finnancial.payables"},
                { label: "Contas Bancárias", route: "finnancial.bank-accounts" },
                { label: "Saques", route: "finnancial.cashout-control" },
                { label: "Antecipações", route: "finnancial.anticipation-control" },
            ],
        };
    },

    computed: {
        ...mapState(Store, ['permissions'])
    },

    watch: {
        permissions() {
            this.permissionMenus();
        }
    },

    mounted() {
        this.permissionMenus();
    },

    methods: {
        permissionMenus() {
            this.pushRoute({ label: 'Custos', route: 'costs', permission: 'manage.costs' });
            this.pushRoute({ label: 'Reviews', route: 'finnancial.reviews', permission: 'review.cashouts' });
            this.pushRoute({ label: 'Chargebacks', route: 'finnancial.chargebacks', permission: 'manage.disputes' });
            this.pushRoute({ label: 'Moedas', route: 'finnancial.currency-gateway', permission: 'manage.currencies' });
        },
        pushRoute(route) {
            if (this.hasPermission(route.permission) && !this.routes.some(route => route.route === route)) {
                this.routes.push(route);
            }
        }
    }
};
</script>
