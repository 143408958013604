<template>
    <h3
        class="flex mb-2 text-lg font-medium font-bold text-gray-400 align-bottom align-center"
    >
        <span> Comissões </span>

        <select
            v-model="interval"
            :disabled="loading"
            class="ml-auto rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        >
            <option :value="7">7 dias</option>
            <option :value="30">30 dias</option>
            <option :value="60">60 dias</option>
            <option :value="0">Todo o Período</option>
        </select>
    </h3>
    <div class="grid grid-cols-3 gap-3 mb-4">
        <card :loading="loading" :size="size">
            <p
                class="flex mb-2 text-sm font-medium text-gray-600 align-center dark:text-gray-400"
            >
                Pagas
                <span class="ml-auto">
                    {{ cards.paid.count }}
                </span>
            </p>
            <p
                class="flex text-lg font-semibold text-white-100 dark:text-gray-200"
            >
                {{ formatCurrency(cards.paid.amount) }}
            </p>
        </card>
        <card :loading="loading" :size="size">
            <p
                class="flex mb-2 text-sm font-medium text-gray-600 align-center dark:text-gray-400"
            >
                Reembolsadas
                <span class="ml-auto">
                    {{ cards.refunded.count }} {{ refundPercentage }}%
                </span>
            </p>
            <p class="text-lg font-semibold text-white-100 dark:text-gray-200">
                {{ formatCurrency(cards.refunded.amount) }}
            </p>
        </card>
        <card :loading="loading" :size="size">
            <p
                class="flex mb-2 text-sm font-medium text-gray-600 align-center dark:text-gray-400"
            >
                Disputa + ChargeBack
                <span class="ml-auto">
                    {{ cards.disputed.count + cards.chargeback.count }} {{ cards.disputed.percentage + cards.chargeback.percentage / 2 }}%
                </span>
            </p>
            <p class="text-lg font-semibold text-white-100 dark:text-gray-200">
                {{ formatCurrency(Number(cards.disputed.amount) + Number(cards.chargeback.amount)) }}
            </p>
        </card>

    </div>
</template>

<script>
import Card from "../layouts/Card.vue";
export default {
    components: { Card },
    props: {
        user: { required: true },
        size: { type: String, default: "md" },
    },
    data() {
        return {
            loading: true,
            interval: 30,
            cards: {
                paid: {
                    total: 0,
                    count: 0,
                },
                refunded: {
                    total: 0,
                    count: 0,
                },
                disputed: {
                    total: 0,
                    count: 0,
                },
                chargeback: {
                    total: 0,
                    count: 0,
                },
            },
        };
    },

    computed: {
        chargebackPercentage() {
            if (this.cards.chargeback.count === 0) {
                return 0;
            }

            return (
                (this.cards.chargeback.count / this.cards.paid.count) *
                100
            ).toFixed(2);
        },
        refundPercentage() {
            if (this.cards.refunded.count === 0) {
                return 0;
            }

            return (
                (this.cards.refunded.count / this.cards.paid.count) *
                100
            ).toFixed(2);
        },
    },

    watch: {
        interval() {
            this.fetch();
        },
        user() {
            this.fetch();
        },
    },

    mounted() {
        if (this.user) {
            this.fetch();
        }
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/commission/summaryCards", {
                    params: {
                        interval: this.interval,
                    },
                })
                .then(({ data }) => {
                    this.cards = data;
                    this.loading = false;
                });
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
    },
};
</script>
