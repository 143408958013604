<template>
    <span
        class="my-2 my-3 rounded px-2.5 py-1.5 text-sm font-medium"
        :class="{
            'bg-green-500 text-white': status == 'active',
            'bg-red-500 text-white':
                status == 'inactive' || status == 'blocked',
            'bg-yellow-500 text-white':
                status != 'active' &&
                status != 'inactive' &&
                status != 'blocked',
        }"
    >
        {{ statusName }}
    </span>
</template>

<script>
export default {
    props: ["status"],
    computed: {
        statusName() {
            switch (this.status) {
                case "inactive":
                    return "Inativo";
                case "active":
                    return "Ativo";
                case "blocked":
                    return "Bloqueado";
                case "expired":
                    return "Expirado";
                default:
                    return this.status;
            }
        },
    },
};
</script>
