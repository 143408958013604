<template>
    <button
        type="button"
        @click="openModal"
        class="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
        Detalhes
    </button>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog :open="isOpen" @close="setIsOpen" class="relative z-50">
            <!-- The backdrop, rendered as a fixed sibling to the panel container -->
            <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

            <!-- Full-screen scrollable container -->
            <div class="fixed inset-0 overflow-y-auto">
                <!-- Container to center the panel -->
                <div class="flex min-h-full items-center justify-center">
                    <!-- The actual dialog panel -->
                    <DialogPanel class="w-full max-w-4xl rounded bg-white dark:bg-slate-500 pt-3">
                        <DialogTitle class="text-lg font-medium leading-6 dark:text-slate-800 px-3 mb-3">
                            {{user.email}} - {{log.batch_uuid}}
                        </DialogTitle>

                        <div class="overflow-x-auto">
                            <div class="inline-block min-w-full">
                                <div class="overflow-hidden not-prose relative bg-slate-50 overflow-hidden dark:bg-slate-800/25">
                                    <table class="border-collapse table-auto w-full text-sm">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                Data
                                            </th>
                                            <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                Descrição
                                            </th>
                                            <th scope="col" class="text-sm font-medium  px-6 py-4 text-left">
                                                Propriedades
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white dark:bg-slate-800">
                                        <tr v-for="log in logs">
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{log.created_at}}
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                {{log.description}}
                                            </td>
                                            <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-200">
                                                <div class="flex flex-col">
                                                    <span v-for="(property, name) in log.properties">
                                                        <strong>{{name}}</strong>: {{property}}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue'

export default {
    props: ['log', 'user'],
    components: {Dialog, DialogPanel, DialogTitle, DialogDescription, TransitionRoot, TransitionChild},
    data() {
        return {
            isOpen: false,
            loading: false,
            logs: []
        }
    },

    methods: {
        fetch() {
            this.loading = true;
            axios.get('/api/user/' + this.user.id + '/balance/log/' + this.log.batch_uuid).then(({data}) => {
                this.logs = data;
            }).catch(error => {
                this.loading = false;
                // this.$toast.error(error.response.data.message);
            });
        },
        closeModal() {
            this.isOpen = false;
        },
        openModal() {
            this.isOpen = true;
            this.fetch();
        },
        setIsOpen(boolean) {
            this.isOpen = boolean;
        }
    }
}

function handleDeactivate() {
    // ...
}
</script>
