<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                    >
                        <SvgLoader v-if="this.loading" />
                        <form @submit.prevent="handleSave" v-else>
                            <div class="sm:flex sm:items-start">
                                <div
                                    class="mt-3 text-center sm:mt-0 sm:text-left"
                                >
                                    <dialog-title
                                        as="h3"
                                        class="text-lg leading-6 font-medium text-white"
                                    >
                                        Template de mensagem
                                    </dialog-title>
                                    <div class="mt-2"></div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-4 sm:flex flex-col">
                                <div class="grid sm:grid-cols-1">
                                    <label for="instance_id" class="text-white">
                                        Nome
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        v-model="form.name"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Nome"
                                        required
                                    />
                                </div>
                                <div class="mt-3 grid sm:grid-cols-1">
                                    <label for="instance_id" class="text-white">
                                        Evento
                                    </label>
                                    <select
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Evento"
                                        v-model="form.event"
                                        required
                                    >
                                        <option value="refused">
                                            Compra recusada
                                        </option>
                                        <option value="expired">
                                            Boleto atrasado
                                        </option>
                                        <option value="abandoned_cart">
                                            Carrinho abandonado
                                        </option>
                                    </select>
                                </div>

                                <div class="mt-3">
                                    <label for="message" class="text-white"
                                        >Mensagem</label
                                    >
                                    <div class="bg-white">
                                        <QuillEditor
                                            id="message"
                                            :toolbar="[
                                                'bold',
                                                'italic',
                                                'underline',
                                            ]"
                                            v-model:content="this.form.message"
                                            contentType="html"
                                            required
                                        />
                                    </div>
                                </div>

                                <div class="mt-3 grid sm:grid-cols-2 gap-5">
                                    <button
                                        type="submit"
                                        class="mb-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
                                    >
                                        Confirmar
                                    </button>

                                    <button
                                        type="button"
                                        class="mb-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        @click="closeModal()"
                                        ref="cancelButtonRef"
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import VueSelect from "../../../components/forms/VueSelect.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useToast } from "vue-toastification";
import SvgLoader from "../../../components/layouts/SvgLoader.vue";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        VueSelect,
        QuillEditor,
        SvgLoader,
    },
    props: ["templateId"],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            form: {
                name: null,
                event: null,
                message: null,
            },
            template: null,
        };
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        fetchTemplates() {
            this.$emit("fetchTemplates");
        },
        handleSave() {
            if (!this.templateId) {
                axios
                    .post(`/api/whatsapp-automations-templates/`, this.form)
                    .then((response) => {
                        if (response) {
                            this.toast.success("Template salvo com sucesso");
                            this.fetchTemplates();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar template");
                    });
            } else {
                axios
                    .put(
                        `/api/whatsapp-automations-templates/${this.templateId}`,
                        this.form
                    )
                    .then((response) => {
                        if (response) {
                            this.toast.success("Template salvo com sucesso");
                            this.fetchTemplates();
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao salvar template");
                    });
            }
        },
        findOne(id) {
            this.loading = true;

            axios
                .get(`/api/whatsapp-automations-templates/${id}`)
                .then((response) => {
                    this.template = response.data;
                    this.form.name = this.template.name;
                    this.form.event = this.template.event;
                    this.form.message = this.template.message;
                })
                .catch((error) => {
                    this.toast.error("Erro ao editar template");
                })
                .finally(() => (this.loading = false));
        },
    },
    mounted() {
        if (this.templateId) {
            this.findOne(this.templateId);
        }
    },
};
</script>
