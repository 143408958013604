<template>
    <h2 class="mb-3 flex items-center text-lg dark:text-white">
        <span> Produtor </span>

        <form-toggle
            class="ml-auto"
            v-model="hide.products"
            :loading="loading"
            label="Apenas com Vendas"
        />
    </h2>
    <table-component
        :loading="loading"
        :headers="['Produto', 'Status', 'Comissões', '']"
    >
        <tr v-for="product in sortedProducts">
            <td class="table-cell-sm">
                {{ product.name }}
            </td>
            <td class="table-cell-sm">
                <product-status :status="product.status" />
            </td>
            <td class="table-cell-sm dark:text-white">
                {{ $root.formatCurrency(product.total_commission) }}
            </td>
            <td class="table-cell-sm dark:text-white"></td>
        </tr>
    </table-component>
    <h2 class="my-3 flex items-center text-lg dark:text-white">
        <span> Afiliado </span>

        <form-toggle
            class="ml-auto"
            v-model="hide.affiliates"
            :loading="loading"
            label="Apenas com Vendas"
        />
    </h2>
    <table-component
        :loading="loading"
        :pagination="affiliatePagination"
        :headers="[
            'Código',
            'Produto',
            'Status',
            'Comissões',
            'Data da Primeira Comissão',
            'Dias até Primeira Comissão',
        ]"
    >
        <tr v-for="affiliate in sortedAffiliates">
            <td class="table-cell-sm">
                {{ affiliate.code }}
            </td>
            <td class="table-cell-sm">
                {{ affiliate.product.name }}
                <BuildingStorefrontIcon
                    v-if="affiliate.origin === 'showcase'"
                    v-tooltip="'Vitrine'"
                    class="ml-1 h-5 w-5"
                />
            </td>
            <td class="table-cell-sm">
                <affiliate-status :status="affiliate.status" />
            </td>
            <td class="dark:text-white">
                {{ $root.formatCurrency(affiliate.total_commission) }}
            </td>
            <td class="dark:text-white">
                {{
                    affiliate.first_commission_date
                        ? new Date(
                              affiliate.first_commission_date,
                          ).toLocaleDateString()
                        : "N/A"
                }}
            </td>
            <td class="dark:text-white">
                {{
                    affiliate.days_to_first_commission !== null
                        ? affiliate.days_to_first_commission
                        : "N/A"
                }}
            </td>
        </tr>
    </table-component>
</template>

<script>
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import ProductStatus from "../../../components/layouts/ProductStatus.vue";
import TailwindCheckbox from "../../../components/forms/TailwindCheckbox.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import AffiliateStatus from "../../../components/layouts/AffiliateStatus.vue";
import { BuildingStorefrontIcon } from "@heroicons/vue/24/outline";

export default {
    components: {
        BuildingStorefrontIcon,
        AffiliateStatus,
        FormToggle,
        TailwindCheckbox,
        ProductStatus,
        TableComponent,
    },
    props: ["user"],
    data() {
        return {
            hide: {
                products: true,
                affiliates: true,
            },
            affiliates: [],
            products: [],
            table: false,
            loading: true,
            clicks: [],
            affiliatePagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            pagination: {
                total: 0,
                per_page: 35,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
        };
    },

    computed: {
        sortedProducts() {
            if (this.hide.products) {
                // sort by total commission
                return this.products
                    .filter((product) => product.total_commission)
                    .sort((a, b) => {
                        return b.total_commission - a.total_commission;
                    });
            }

            return this.products;
        },
        sortedAffiliates() {
            if (this.hide.affiliates) {
                return this.affiliates
                    .filter((product) => product.total_commission)
                    .sort((a, b) => {
                        return b.total_commission - a.total_commission;
                    });
            }

            return this.affiliates;
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            this.loading = true;

            await Promise.all([this.loadProducts(), this.loadAffiliates()]);

            this.loading = false;
        },
        async loadProducts() {
            return axios
                .get("/api/user/" + this.user.id + "/product/audit")
                .then(({ data }) => {
                    this.products = data;
                });
        },
        async loadAffiliates() {
            return axios
                .get("/api/user/" + this.user.id + "/affiliate")
                .then(({ data }) => {
                    this.affiliates = data.data;
                    this.affiliatePagination = data;
                });
        },
    },
};
</script>
