<template>
    <component
        :is="!boolean ? 'XMarkIcon' : 'CheckIcon'"
        class="h-5 w-5"
        :class="!boolean ? 'text-red-500' : 'text-green-500'"
    />
</template>

<script>
import { CheckIcon, XMarkIcon } from "@heroicons/vue/20/solid";

export default {
    props: { boolean: { default: false } },
    components: { CheckIcon, XMarkIcon },
};
</script>
